import { useCallback, useEffect, useMemo, useState } from "react"
import { CustomUserPbiData } from "../../domains/powerBi/models/powerBiInterfaces"
import { usePbiReportsInGroup } from "../../domains/powerBi/services/service.pbi"
import { useSessionContext } from "../../domains/session/store/sessionContext"
import { UserType } from "../../domains/users/UserInterface"
import { ONLINE_MODE } from "../../shared/config/appConfig"
import AccountManager from './AccountManager.json'
import AdminData from './AdminData.json'
import OperatorData from './OperatorData.json'
import SuperAdminData from './SuperAdminData.json'
import SupervisorData from './SupervisorData.json'
import ClientData from './ClientData.json' 
import { setNewMenu } from "./utils/setNewMenu"
import { useTodayNewsbyTagFP } from "../../domains/news/services/service.news"
import { useTodayNewPurchaseRequest } from "../../domains/stock/persistance/useTodayNewPurchaseRequest"

//Menus
export const useUserMenu = (defaultType?:UserType) => {
  
    const {user} = useSessionContext()
    const [menu,setMenu] = useState<any>()
    const userType = useMemo(()=>defaultType || user?.type,[defaultType,user?.type])

    const {data:news} = useTodayNewsbyTagFP()
    const {purchaseRequests} = useTodayNewPurchaseRequest()

    const offlineMenu = useCallback((menu:any[])=> {
        return (() => {
            if(ONLINE_MODE) return menu
            else return menu.filter((item:any) => item.offline)
        })().map((m)=>{

            if(m.type === 'news') 
            return {
                ...m,
                badge:news?.length || 0
            }

            if(m.type === 'stock') 
            return {
                ...m,
                badge:purchaseRequests?.quantity || 0
            }
            else 
            return {
                ...m,
                badge:0
            }
        })
    },[news,purchaseRequests?.quantity])

    //userTypeMenues
    const userMenu = useMemo(()=>({
        SA:offlineMenu(SuperAdminData.menu),
        AC:offlineMenu(AccountManager.menu),
        A:offlineMenu(AdminData.menu),
        S:offlineMenu(SupervisorData.menu),
        C:offlineMenu(ClientData.menu),
        O:offlineMenu(OperatorData.menu)  
    }),[offlineMenu])


    //switch from user type menus
    const handleUserType = useCallback((userTypeValue:UserType) =>{
        return setMenu(userMenu[userTypeValue])
        /* 
        return setMenu(()=>{
            if(userType === 'C')
            return userMenu[userTypeValue].map((menu)=>{
                return{
                    ...menu,
                    route:`${currentPbigroupName}/${menu.route}`
                }
            })
        })
        */
    },[userMenu])

    //setting defaults menues
    useEffect(()=>{
        //control user menu type that is gonna be render
        if((userType)){
            handleUserType(userType)  
        }
    },[handleUserType, userType])

    return {menu:menu, setMenu, userType:userType}

}


export const useMenu = (customConfig?:CustomUserPbiData) => {
    const { menu, setMenu, userType } = useUserMenu(customConfig?.defaultUser)
    const reports = usePbiReportsInGroup(customConfig)

    useEffect(() => {
        if(menu){
            if(userType === 'C'){
                if((reports) && (reports.length > 0)){
                    setMenu(setNewMenu(reports,menu))
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reports,userType,!!menu]);

    return menu
}

