import React from "react";
import { CrudFormType } from "../../../../shared/form/models/FormTypes";
import { CreateUpdateIncomeProps, IncomeInterface } from "../types/Income";
import { useCreateUpdate } from "../../../../shared/form/crud/hooks/useCreateUpdate";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import { uiFormInterface } from "../../../../shared/form/crud/types/crudTypes";
import DefaultFormLayout from "../../../../components/form/DefaultFormLayout";
import FieldSelector from "../../../../shared/form/components/FieldSelector";
import { useIncomeForm } from "../hooks/useIncomeForm";
import {
  CreateIncome,
  DeleteIncome,
  UpdateIncome,
} from "../services/IncomeService";
import { useSessionContext } from "../../../../domains/session/store/sessionContext";

const CreateUpdateIncomeForm: React.FC<CreateUpdateIncomeProps> = ({
  actionType,
  mode,
  item,
  lubricant
}) => {
  const { action, resetStatus, status, error, message } = useCreateUpdate(
    {
      create: CreateIncome,
      update: UpdateIncome,
      delete: DeleteIncome,
    },
    actionType,
    ["GetAllIncomes", "GetIncomeByLubricant", "GetReferrer"]
  );

  const { lang } = useLangLabels();
  const { tagFP } = useSessionContext();

  const { uiModel, handleSubmit, reset } = useIncomeForm(tagFP, lubricant, item);

  const ui = (() => {
    const formName = lang.incomeForm.singular;

    switch (actionType) {
      case CrudFormType.create:
        const create: uiFormInterface = {
          icon: "add",
          title: `${lang.actionTypes.create} ${formName}`,
          variant: "button",
          mode: mode || "modal",
        };
        return create;

      case CrudFormType.update:
        const update: uiFormInterface = {
          icon: "edit",
          title: `${lang.actionTypes.update} ${formName}`,
          variant: "iconButton",
          mode: mode || "content",
        };
        return update;
    }
  })();

  //Methods
  const submit = (data: IncomeInterface) => {
    const formattedData: IncomeInterface = {
      ...data,
      admissionDate: new Date(data?.admissionDate || "").toISOString(),
    };

    action(formattedData);
  };

  const handleReset = () => {
    resetStatus();
    reset();
  };

  return (
    <DefaultFormLayout
      mode={ui.mode}
      icon={ui.icon}
      variant={ui.variant}
      buttonTitle={ui.title}
      popoverTitle={ui.title}
      {...{ status, error, message }}
      onChange={handleSubmit(submit)}
      onClose={handleReset}
    >
      {uiModel.map((props, index) => (
        <FieldSelector key={index} fieldProps={props} />
      ))}
    </DefaultFormLayout>
  );
};

export default React.memo(CreateUpdateIncomeForm);
