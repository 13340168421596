import React from 'react';
import FlexContainer from '../../../components/containers/FlexContainer';
import { CrudFormType } from '../../../shared/form/models/FormTypes';
import { GridModelInterface } from '../../../shared/gridModel/gridModelEntity';
import { useLangLabels } from '../../../shared/lang/services/useLangLabels';
import VirtualGrid,{CarrousellActinInterface} from '../../../shared/react-table/VirtualGrid';
import CUSupplyForm from '../CUSupplyForm';
import DeleteSupplyForm from '../DeleteSupplyForm';
import { useConsumablesGridModel, useProtectionsGridModel, useToolsGridModel } from '../lang/useSuppliesGridModel';
import { EnumSupplyTypes, SupplyInterface, SupplyType } from '../model/SupplyInterface';
import AssignCancelMultipleSupplies from '../molecules/AssignCancelMultipleSupplies';
import { useSuppliesByType } from '../services/service.supplies';


interface Props {
    virtualGridWidth:string
    type: EnumSupplyTypes
}

const ManageSuppliesPage:React.FC<Props> = ({type,virtualGridWidth}) => {
    
    const {data:supplies} = useSuppliesByType(type)

    const {lang} = useLangLabels()

    const carrousellComponents:CarrousellActinInterface = [
        {icon:'edit',popoverTitle:lang.crudActions.update, component:(item:SupplyInterface)=><CUSupplyForm item={item} actionType={CrudFormType.update} />}
    ]
    
    const gridModel:{[key:string]:GridModelInterface} = {
        'HERRAMIENTA':useToolsGridModel(),
        'CONSUMIBLE':useConsumablesGridModel(),
        'PROTECCION':useProtectionsGridModel()
    }
 
    return(
        <VirtualGrid {...{
            width:virtualGridWidth,
            title:lang.supplies[type].title.plural,
            items:supplies,
            carrousellComponents,
            gridModel:gridModel[type],
            headerActions:(item:any)=><>
                <AssignCancelMultipleSupplies type={type}/>
                <CUSupplyForm key={type} actionType={CrudFormType.create} supplyType={type} />
            </>,
            itemActions:(item)=><DeleteSupplyForm type={type} item={item} />
        }} />
    )
}

export default ManageSuppliesPage
