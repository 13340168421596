import React from 'react';
import DefaultFormLayout from '../../components/form/DefaultFormLayout';
import FieldSelector from '../../shared/form/components/FieldSelector';
import { useCreateUpdate } from '../../shared/form/crud/hooks/useCreateUpdate';
import { CUEntityFormPropsType, uiFormInterface } from '../../shared/form/crud/types/crudTypes';
import { CrudFormType } from '../../shared/form/models/FormTypes';
import { useLangLabels } from '../../shared/lang/services/useLangLabels';
import { useSessionContext } from '../session/store/sessionContext';
import { ComponentInterface } from './models/ComponentInterface';
import { ComponentsCRUDDependencies, CreateComponent, DeleteComponent, UpdateComponent } from './services/service.components';
import { useComponentForm } from './services/useComponentForm';


type props = CUEntityFormPropsType<ComponentInterface>

const CUComponentForm: React.FC<props> = ({item, actionType}) => {

    const {tagFP} = useSessionContext()
    //DB 
    const { action, resetStatus,status, error, message } = useCreateUpdate({
        create: CreateComponent,
        update: UpdateComponent,
        delete: DeleteComponent
    },actionType, ComponentsCRUDDependencies)

    //UI
    const { lang } = useLangLabels()

    const { uiModel, handleSubmit, reset } = useComponentForm(tagFP, item)

    const ui = (() => {
        const formName = lang.components.title.singular 
        switch (actionType) {
            case CrudFormType.create:
                const create:uiFormInterface = {
                    icon:'add',
                    title:`${lang.actionTypes.create} ${formName.toLowerCase()}`,
                    variant:'button',
                    mode:'modal'
                }
                
                return create

            case CrudFormType.update:
                const update:uiFormInterface = {
                    icon:'edit',
                    title:`${lang.actionTypes.update} ${formName.toLowerCase()}`,
                    variant:'iconButton',
                    mode:'content'
                }
                return update
        }
    })()

    //Methods
    const submit = (data:ComponentInterface) => {
        action(data)
    }

    const handleReset = () => {
        resetStatus()
        reset()
    }

    return(
        <DefaultFormLayout
            mode={ui.mode}
            icon={ui.icon}    
            variant={ui.variant}
            buttonTitle={ui.title}
            popoverTitle={ui.title}
            {...{status,error,message}} 
            onChange={handleSubmit(submit)}  
            onClose={handleReset} 
        >
            {uiModel.map((props,index)=>
                <FieldSelector key={index} fieldProps={props} />
            )}
        </DefaultFormLayout>
    )
};

export default CUComponentForm;