import { PeriodInterface, usePeriod } from '../../../shared/date/usePeriod';
import { CrudFormType } from '../../../shared/form/models/FormTypes';
import { useLangLabels } from '../../../shared/lang/services/useLangLabels';
import VirtualGrid, { CarrousellActinInterface } from '../../../shared/react-table/VirtualGrid';
import CUObservationForm from '../CUObservationForm';
import DeleteObservationForm from '../DeleteObservationForm';
import { useObservationsGridModel } from '../lang/useObservationsGridModel';
import { ObservationInterface } from '../models/ObservationTypes';
import { useObservationsByPeriodAndTagFP } from '../services/observationServices';
import { InfoSurveyInterface, SurveyInterface } from '../../surveys/model/SurveyInterface';

interface Props{
    virtualGridWidth?:string
    virtualGridHeight?:number
    observations: ObservationInterface[]
    contained?: boolean
    survey?: SurveyInterface & InfoSurveyInterface
}


const SurveyObservations:React.FC<Props> = ({virtualGridWidth, virtualGridHeight, observations, contained, survey}) => {
    const {lang} = useLangLabels()
    const gridModel = useObservationsGridModel()

    const createObservationData = survey !== undefined 
        ? {
            type: "OBSERVACION",
            taskType: survey.taskType,
            equipment: survey.equipment,
            tagTGD: survey.tagTGD,
            surveyId: survey.id,
            anomaly: survey.anomalies ?? JSON.stringify([]),
            date: new Date(),
        }
        : undefined


    return(
        <VirtualGrid {...{
            width:virtualGridWidth ?? "100%",
            height:virtualGridHeight ?? undefined,
            contained: contained ?? true,
            title:lang.observations.plural,
            items:observations,
            gridModel,
            itemActions: (item) => [
                <DeleteObservationForm observation={item} />,
                <CUObservationForm  item={item} actionType={CrudFormType.update} mode='modal' />
            ],
            headerActions: (item: any) => survey ? <CUObservationForm actionType={CrudFormType.create} observationData={createObservationData} /> : <></>,
        }}/>
    )
}

export default SurveyObservations
