import React, { useEffect } from 'react';
import { CircularProgress, IconButton,} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDeleteArea } from './services/service.areas';
import TgdPopover from '../../components/popovers/TgdPopover';

import { AreaInterface } from './models/AreaTypes';
import { useClickPosition } from '../../shared/globals/hooks/useClickPosition';
import { useSnackbar } from '../../components/snackbar/hooks/useSnackbar';

interface Props{
    item:AreaInterface
}


const DeleteAreaForm:React.FC<Props> = ({item}) => {
        
    const {deleteArea,status,message} = useDeleteArea()
    
    const {position,getClickPosition} = useClickPosition()
    const {setData} = useSnackbar()

    const submit = (item:AreaInterface) => {
        deleteArea(item)
    }

    useEffect(()=> {

        if(status === 'success'){
            setData({
                modal:true,
                errors:message,
                severity:status,
                position
            })
        }

        if(status === 'error'){
            setData({
                modal:true,
                errors:message,
                severity:status,
                position
            })
        }

    },[status])

    return (
        status === 'loading' 
        ?   <CircularProgress size='20px' />
        :   <TgdPopover mode='hover' title='Eliminar area'>
                <IconButton size='small' onClick={(e)=>getClickPosition(e,()=>submit(item))}>
                    <DeleteIcon />
                </IconButton>
            </TgdPopover>
    );
}

export default React.memo(DeleteAreaForm);
