import { CrudFormType } from "../../../shared/form/models/FormTypes"
import { useLangLabels } from "../../../shared/lang/services/useLangLabels"
import VirtualGrid, { CarrousellActinInterface } from "../../../shared/react-table/VirtualGrid"
import CUAddedReasonForm from "../CUAddedReasonForm"
import DeleteAddedReasonForm from "../DeleteAddedReasonForm"
import { useAddedReasonGridModel } from "../lang/useAddedReasonGridModel"
import { AddedReasonInterface } from "../models/AdedReasonTypes"
import { usePlantAddedReasons } from "../services/service.addedReasons"



interface Props{
    virtualGridWidth:string
}



const ManageAddedReasonsPage:React.FC<Props> = ({virtualGridWidth}) => {

    const {lang} = useLangLabels()
    const {data:addedReasons} = usePlantAddedReasons()
    const addedReasonGridModel = useAddedReasonGridModel() 
    const carrousellComponents:CarrousellActinInterface = [
        {icon:'edit',popoverTitle:lang.crudActions.update ,component:(item:AddedReasonInterface)=><CUAddedReasonForm item={item} actionType={CrudFormType.update} />}
    ]
    
    return(   
        
        <VirtualGrid {...{
            width:virtualGridWidth,
            items:addedReasons,
            gridModel:addedReasonGridModel,
            title:lang.addedReasons.plural,
            carrousellComponents,
            headerActions:(item:any)=><CUAddedReasonForm actionType={CrudFormType.create} />,
            itemActions:(item)=><DeleteAddedReasonForm item={item} />,
        }}/>
        
        /* <DynamicGrid
        gridModel={useAddedReasonGridModel()}
            height='55vh' 
            title='Motivo agregado'
            items={addedReasons || []}
            items={addedReasons || []}
            loading={ isLoading}
            carrousellActions={carrousellActions}
            itemActions={(item:any)=><>
                <DeleteAddedReasonForm item={item} />
            </>}
        /> */
    )
          
}


export default ManageAddedReasonsPage;
