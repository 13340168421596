import React from 'react';
import { useModal } from '../containers/modal/hooks/useModal';
import TgdModal from '../containers/modal/TgdModal';
import CustomButton, { ButtonColorType, ButtonVariant, CustomIconType } from './CustomButton';

interface Props{
    children:React.ReactNode
    variant?: ButtonVariant
    icon:CustomIconType
    buttonText?:string
    title?:string
    color?: ButtonColorType
    popoverTitle?:string
    onClose?:(item?:any)=>void
    fullwidth?:boolean,
    seze?:'small' | 'default' | 'large'
    disabled?:boolean
    modalTitle?:boolean
}
const ModalButton:React.FC<Props> = ({onClose,fullwidth,variant,children,icon,buttonText,color,title,popoverTitle,disabled,modalTitle}) => {
    
    const {modal, onClose:closeModal, onOpen} = useModal()

    const defaultVariant:ButtonVariant = variant || 'button' 
    const defaultModalTitle = modalTitle !== undefined 
        ? modalTitle
            ?title
            :undefined 
        : title

    const handleClose = ()=>{
        onClose && onClose()
        closeModal()
    }
  
    return (<>
            <CustomButton
                disabled={disabled || false} 
                variant={defaultVariant} 
                action={onOpen} 
                popoverTitle={popoverTitle || title} 
                icon={icon} 
                title={buttonText} 
                color={color} 
            />

            <TgdModal fullWidth={fullwidth || true} title={defaultModalTitle} open={modal} handleClose={handleClose}>
                {children}
            </TgdModal>
    </>);
}

export default React.memo(ModalButton);
