export enum TaskType {
    inspection='INSPECCION',
    lubrication='LUBRICACION',
    cleaning='LIMPIEZA',
    repairment='REPARACION',
    puntuation='PUNTUACION',
    intervention='INTERVENCION',
    control='CONTROL',
 }

export const SurveyTasksValues = {
    inspection:TaskType.inspection,
    lubrication:TaskType.lubrication,
    cleaning:TaskType.cleaning,
    repairment:TaskType.repairment,
    puntuation:TaskType.puntuation,
    intervention:TaskType.intervention,
    control:TaskType.control
} 
