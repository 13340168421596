import { ListItem, ListItemAvatar, ListItemText, Paper, TextField, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { CustomIcon } from '../../components/buttons/CustomButton'
import FlexContainer from '../../components/containers/FlexContainer'
import HandleUndefined from '../../components/containers/handleUndefined/HandleUndefined'
import { useModal } from '../../components/containers/modal/hooks/useModal'
import Modal from '../../components/containers/modal/Modal'
import PageContainer from '../../components/containers/PageContainer'
import CarrousellToolbar from '../../components/containers/toolbar/CarrousellToolbar'
import { useCarrousell } from '../../components/containers/toolbar/hooks/useCarrousell'
import { Divider } from '../../components/Divider'
import TgdTabs from '../../components/tabs/TgdTabs'
import CriticalityIndicator from '../../domains/criticalities/components/CriticalityIndicator'
import { useCriticalitiesGridModel } from '../../domains/criticalities/lang/useCriticalitiesGridModel'
import { CriticalityInterface, CriticalityTypes } from '../../domains/criticalities/models/criticalityInterface'
import { useCriticalityByTypeByTagFP, usePlantCriticality } from '../../domains/criticalities/services/service.criticality'
import CreateNewForm from '../../domains/news/components/CreateNewForm'
import { NewInterface } from '../../domains/news/model/NewsInterface'
import { useNewsByTagFP } from '../../domains/news/services/service.news'
import { useUser } from '../../domains/session/store/sessionStore'
import { useDate } from '../../shared/date/useDate'
import { objectMapper } from '../../shared/globals/utils/objectMapper'
import { useLangLabels } from '../../shared/lang/services/useLangLabels'
import VirtualGrid from '../../shared/react-table/VirtualGrid'

const NewView:React.FC<{items:NewInterface[]}> = ({items}) => {

  const {lang} = useLangLabels()
  const modalProps = useModal()
  const carrousellProps = useCarrousell(items.length) 
  const item = (items && items.length > 0 )? items[carrousellProps.current] : undefined

  const {data:criticalities} = useCriticalityByTypeByTagFP(CriticalityTypes.observation)
  const criticality = criticalities?.find((c:CriticalityInterface)=>c.description === item?.criticality) 

  return(
    <HandleUndefined callback={modalProps.onOpen} isError={items.length > 0}>
      {(callback)=>
        <Modal icon='view' variant='iconButton' popoverTitle={lang.actionTypes.view} {...modalProps} onOpen={callback}>
          <CarrousellToolbar {...carrousellProps} />
          <ListItem>
              <ListItemAvatar>
                  <CustomIcon icon='date' />
              </ListItemAvatar>
              <ListItemText 
                  primary={item?.type}
                  secondary={item?.newsDate.slice(0,10)}
              />
              <ListItemText 
                  secondary={`${lang.globalentityValues.id}: ${item?.id}`}
              />
              {criticality && <CriticalityIndicator criticality={criticality} />}
              
          </ListItem>
          <Divider/>
          <ListItem>
              <FlexContainer padding='24px 0px'>
                  <Typography variant={'body1'}>
                      <strong>
                          {item?.description}
                      </strong>
                  </Typography>
              </FlexContainer>
          </ListItem>
          <Divider/>
          {   
              item?.equipment &&
              <ListItem>
                  <ListItemText 
                      primary={item.equipment}
                      secondary={lang.news.equipment}
                  />
              </ListItem>
          }
          <Divider/>
          <FlexContainer>
              <ListItem>
                  <ListItemText 
                      primary={item?.area}
                      secondary={lang.areas.title.singular}
                      />
              </ListItem>
              <ListItem>
                  <ListItemText 
                      primary={item?.sector}
                      secondary={lang.sectors.title.singular}
                      />
              </ListItem>
          </FlexContainer>
      </Modal>
      }
    </HandleUndefined>
  )
}



const NewList:React.FC<{news?:NewInterface[],headerActions?:React.ReactNode}> = ({news,headerActions}) => {

  const {lang} = useLangLabels()
  
  const { data:criticalities } = usePlantCriticality()
  const criticalitiesMap = objectMapper(criticalities,'description')
  const CriticalityGridModel = useCriticalitiesGridModel()

  const criticalityField = (item:CriticalityInterface)=>{
      const criticalityDescription:string | undefined = item.description
      const color = `${criticalitiesMap && criticalityDescription ? criticalitiesMap[criticalityDescription]?.color: 'unset' }`
      return (
          <CustomIcon 
          icon='state' 
          style={{ color }} 
          />
      )
  }

  return (
      <VirtualGrid {...{
        gridModel:CriticalityGridModel,
        title:lang.news.plural,
        items:news,
        headerActions:()=><>
            {headerActions && headerActions }
            <CreateNewForm />
        </>,
        renderOption:(item)=>criticalityField(item),
        bulkActions:(items)=><NewView items={items} />
      }}/>
  )
}

const NewsPage:React.FC<{}> = () => {


  const { nowYear, nowDay, nowMonth } = useDate()
  const defaultDate = `${nowYear}-${nowMonth}-${nowDay}`
  const [date, setDate] = useState<undefined | string>(defaultDate);
  const {data:news} = useNewsByTagFP(date)
  const plantNews = news?.filter((n)=>n.type === 'PLANTA')
  const placeNews = news?.filter((n)=>n.type === 'ENTORNO')

  const tabs = [
      {name:'Todas',news:news, onChange:()=>setDate(undefined)},
      {name:'Entorno',news:placeNews,onChange:()=>setDate(defaultDate)},
      {name:'Planta',news:plantNews,onChange:()=>setDate(defaultDate)},
  ]

  useEffect(()=>{
    setDate(undefined)
  },[])

  return (<>
      <Paper>
          <TgdTabs
              items={tabs}
              content={(value)=>{
                  return <NewList 
                  key={value.news + date} 
                  news={value.news} 
                  headerActions={
                          <TextField
                              size='small'
                              variant='outlined'
                              type='date'
                              onChange={(e:any)=>setDate(e.target.value)}
                              label=''
                              color='secondary'
                              value={date}
                          />
                      }
                  />
              }}
              labelIterator='name' 
          />
      </Paper>
  
  
  </>);
}

export default NewsPage