
import { InputComponentTypes } from '../../../shared/form/models/FormTypes';
import { useForm } from '../../../shared/form/useForm';
import { useLangLabels } from '../../../shared/lang/services/useLangLabels';
import { AreaInterface } from '../models/AreaTypes';



export const useAreaForm = (tagFP:string,item?:AreaInterface) => {
    const {lang} = useLangLabels()
    return useForm<AreaInterface>({fields:{
        id:{
            label:lang.globalentityValues.id,
            placeholder:lang.globalentityValues.id,
            value:item?.id || 0,
            component:InputComponentTypes.none
        },
        area:{
            label:lang.areas.form.label.area,
            placeholder:lang.areas.form.label.area,
            value:item?.area || '',
            component:InputComponentTypes.text,
            validations:{
                required:{value: true, message: lang.validations.messages.required},
                min:{value:1, message:lang.validations.messages.minLength},
                max:{value:50, message:lang.validations.messages.maxLength},
            }
        },
        email:{
            label:lang.areas.form.label.email,
            placeholder:lang.areas.form.label.email,
            value:item?.email ||'',
            component:InputComponentTypes.text,
            helper:lang.areas.form.label.emailHelper,
            validations:{
                required:{value:true,message:lang.validations.messages.required}
            }
        },
        referrer:{
            label:lang.areas.form.label.referrer,
            placeholder:lang.areas.form.label.referrer,
            value:item?.referrer ||'',
            component:InputComponentTypes.text,
            validations:{
                required:{ value:true, message:lang.validations.messages.required },
            }
        },
        tagFP:{
            label:lang.globalentityValues.id,
            placeholder:lang.globalentityValues.id,
            value:tagFP,
            component:InputComponentTypes.none,
            validations:{
                required:{value:true,message:lang.validations.messages.required},
            }
        }
    }})
} 