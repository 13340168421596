import React from 'react';
import DefaultFormLayout from '../../components/form/DefaultFormLayout';

import FieldSelector from '../../shared/form/components/FieldSelector';
import { useCreateUpdate } from '../../shared/form/crud/hooks/useCreateUpdate';
import { CUEntityFormPropsType, uiFormInterface } from '../../shared/form/crud/types/crudTypes';
import { CrudFormType } from '../../shared/form/models/FormTypes';
import { useLangLabels } from '../../shared/lang/services/useLangLabels';
import { useSessionContext } from '../session/store/sessionContext';
import { SectorInterface } from './models/SectorInterface';
import { CreateSector, DeleteSector, UpdateSector, SectorCRUDDependencies } from './services/service.sectors';
import { useSectorForm } from './services/useSectorForm';

type CUSectorFormProps = CUEntityFormPropsType<SectorInterface>


const CUSectorForm: React.FC<CUSectorFormProps> = ({item, actionType, icon, variant, mode }) => {

    const {tagFP} = useSessionContext()
    //DB 
    const { action, resetStatus,status, error, message } = useCreateUpdate({
        create: CreateSector,
        update: UpdateSector,
        delete: DeleteSector
    },actionType, SectorCRUDDependencies)

    //UI
    const { lang } = useLangLabels()
    const { uiModel, handleSubmit, reset } = useSectorForm(tagFP,item)

    const ui = (() => {
        const formName = lang.sectors.title.plural 
        switch (actionType) {
            case CrudFormType.create:
                const create:uiFormInterface = {
                    icon:icon || 'add',
                    title:`${lang.actionTypes.create} ${formName.toLowerCase()}`,
                    variant:variant || 'button',
                    mode:mode || 'modal'
                }
                
                return create

            case CrudFormType.update:
                const update:uiFormInterface = {
                    icon: icon || 'edit',
                    title:`${lang.actionTypes.update} ${formName.toLowerCase()}`,
                    variant: variant || 'iconButton',
                    mode: mode || 'content'
                }
                return update
        }
    })()

    //Methods
    const submit = (data:SectorInterface) => {
        action(data)
    }

    const handleReset = () => {
        resetStatus()
        reset()
    }

    return(
        <DefaultFormLayout
            mode={ui.mode}
            icon={ui.icon}    
            variant={ui.variant}
            buttonTitle={ui.title}
            popoverTitle={ui.title}
            {...{status,error,message}} 
            onChange={handleSubmit(submit)}  
            onClose={handleReset} 
        >
            {uiModel.map((props,index)=>
                <FieldSelector key={index } fieldProps={props} />
            )}
        </DefaultFormLayout>
    )
};

export default CUSectorForm;