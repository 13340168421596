
import { ReactComponent as InventoryIcon1 } from '../../../assets/icons/svg/inventoryIcon.svg'
import { ReactComponent as HelmetIcon1 } from '../../../assets/icons/svg/Helmet.svg'
import { ReactComponent as OilCanIcon1 } from '../../../assets/icons/svg/oilCanIcon.svg'
import { ReactComponent as Permissions1 } from '../../../assets/icons/svg/Permissions.svg'
import { USER_ROUTES } from '../../../shared/routes/routes'
import { CheckListItemsType } from '../interfaces/checkListInterfaces'
import { CustomIcon } from '../../../components/buttons/CustomButton'
import { useDailySupplies } from '../../supply/services/service.supplies'
import { EnumSupplyTypes } from '../../supply/model/SupplyInterface'
import { usePerson } from '../../session/store/sessionStore'
import { useSessionContext } from '../../session/store/sessionContext'
import { useQueryPersistanceState } from '../../../shared/react-query/hooks/useQueryPersistanceState'
import { useDailyLubricants } from '../../lubricant/services/service.lubricants'
import { useDailyPermissions } from '../../permisions/services/service.permission'
import { useEffect, useMemo } from 'react'
import { PersonInterface } from '../../person/models/PersonInterface'
import { LubricationPointInterface } from '../../lubricationPoints/model/lubricationPointsInterface'
import { lubricatorState } from '../../users/lubricator/lubricatorEntity'
import { useLubricatorStore } from '../../users/lubricator/lubricatorStore'


const checkItems:{[key:string]:CheckListItemsType} = {
    consumable:EnumSupplyTypes.consumable,
    tool:EnumSupplyTypes.tool,
    protection:EnumSupplyTypes.protection,
    lubricant:'LUBRICANTE',
    permission:'PERMISO',
}


export const colectorHomeSupplies = ():CheckListItemsType[] =>{
    return [
        checkItems.consumable,
        checkItems.tool,
        checkItems.protection,
        checkItems.lubricant,
        checkItems.permission
    ]
} 

export const usecheckedItemsIcons = () => ({
    [checkItems.tool]: <CustomIcon icon='tool'/>,
    [checkItems.consumable]: <CustomIcon icon='inventory' style={{width:'80px'}} />,
    [checkItems.protection]: <CustomIcon icon='helmet' />,
    [checkItems.permission]: <CustomIcon icon='permission' />,
    [checkItems.lubricant]: <CustomIcon icon='oilCan' />
})



export const colectorHomeRoutes = () => ({
    [checkItems.tool]: USER_ROUTES.dailyTools,
    [checkItems.consumable]: USER_ROUTES.dailyConsumables,
    [checkItems.protection]: USER_ROUTES.dailyProtection,
    [checkItems.permission]: USER_ROUTES.dailyPermissions,
    [checkItems.lubricant]: USER_ROUTES.dailyLubricants
})

export const checkListsColectorListConfig = () => ({
    [checkItems.tool]: {
        iterator:'supply',
        primaryText:['supply'],
        secondaryText:[''],
    },
    [checkItems.consumable]: {
        iterator:'supply',
        primaryText:['supply'],
        secondaryText:[''],
    },
    [checkItems.protection]: {
        iterator:'supply',
        primaryText:['supply'],
        secondaryText:[''],
    },
    [checkItems.permission]: {
        iterator:'permission',
        primaryText:['permissionName'],
        secondaryText:[''],
    },
    [checkItems.lubricant]: {
        iterator:'lubricant',
        primaryText:['lubricant',],
        secondaryText:['type',],
    }
})

export const useCheckListController = () => {

   
    const {data,status} = useLubricatorStore()
    const icons = usecheckedItemsIcons()
    const {person} = useSessionContext()
    const ColectorHomeRoutes = colectorHomeRoutes()
    const list = checkListsColectorListConfig()

    //constructing data key to diferentiate concerns on high level users
    const lubricatorNumber = person?.role === 'Operario' 
        ? person.lubricatorNumber
        : data?.operator?.lubricatorNumber || 0

    const LocalStateKey = `CollectorCollectedSupplies-${lubricatorNumber}`

    const supplyList = useMemo(()=>({
        permissions:data?.permissions,
        lubricants:data?.lubricants,
        tools:data?.supplies?.filter((supply:any)=>supply.supply.type === EnumSupplyTypes.tool),
        consumables:data?.supplies?.filter((supply:any)=>supply.supply.type === EnumSupplyTypes.consumable),
        protections:data?.supplies?.filter((supply:any)=>supply.supply.type === EnumSupplyTypes.protection),
    }),[data])


    const [ colectedColectedSupplies, setColectedColectedSupplies ] = useQueryPersistanceState<{[key:string]:{value:any,picked:any[]}}>(LocalStateKey,{
            [checkItems.tool]: {
                value:supplyList.tools || [],
                picked:[]
            },
            [checkItems.consumable]:{
                value:supplyList.consumables || [],
                picked:[]
            },
            [checkItems.protection]:{
                value:supplyList.protections || [],
                picked:[]
            },
            [checkItems.permission]:{
                value:data?.permissions || [],
                picked:[]
            },
            [checkItems.lubricant]:{
                value:data?.lubricants || [],
                picked:[]
            }
        },{
            enabled:status === 'success' && !!data,
            unique:true
    })

    const formModel = {
        [checkItems.tool]:{
            name:{
                singular:{
                    key:'',
                    default:'Herramienta'
                },
                plural:{
                    key:'',
                    default:'Herramientas'
                }
            },
            icon:<CustomIcon icon={'add'} />,

        },
        [checkItems.consumable]:{
            name:{
                singular:{
                    key:'',
                    default:'Consumible'
                },
                plural:{
                    key:'',
                    default:'Consumibles'
                }
            },
            icon:<InventoryIcon1/>
        },
        [checkItems.protection]:{
            name:{
                singular:{
                    key:'',
                    default:'Protección'
                },
                plural:{
                    key:'',
                    default:'Protecciones'
                }
            },
            icon:<HelmetIcon1 height='90%'/>,
        },
        [checkItems.lubricant]:{
            name:{
                singular:{
                    key:'',
                    default:'Lubricante'
                },
                plural:{
                    key:'',
                    default:'Lubricantes'
                }
            },
            icon:<OilCanIcon1/>,
        },
        [checkItems.permission]:{
            name:{
                singular:{
                    key:'',
                    default:'Permiso'
                },
                plural:{
                    key:'',
                    default:'Permisos'
                }
            },
            icon:<Permissions1/>,
        },
    }
    
    const disabled = {
        [checkItems.tool]:false,
        [checkItems.consumable]: false,
        [checkItems.protection]: false,
        [checkItems.permission]: false,
        [checkItems.lubricant]: false
    }

    const notification = (type:CheckListItemsType) => {
        return colectedColectedSupplies 
            ?   ((colectedColectedSupplies[type]?.value?.length || 0) - colectedColectedSupplies[type]?.picked?.length || 0) 
            :   0
    }
    
    const handleCheckUncheck = (itemId:any,type:CheckListItemsType) => {
        colectedColectedSupplies && 
            (colectedColectedSupplies[type].picked.includes(itemId)
                ?   setColectedColectedSupplies({
                    ...colectedColectedSupplies,
                    [type]:{
                        ...colectedColectedSupplies[type],
                        picked:[
                            ...(colectedColectedSupplies[type].picked.filter(id => id !== itemId))
                        ]
                    }
                })
                
                :   setColectedColectedSupplies({
                        ...colectedColectedSupplies,
                        [type]:{
                            ...colectedColectedSupplies[type],
                            picked:[
                                ...(colectedColectedSupplies[type].picked),
                                itemId
                            ]
                        }
                    })
            )
        }

    return{
        formModel,
        data:colectedColectedSupplies,
        routes:ColectorHomeRoutes,
        setState:setColectedColectedSupplies,
        disabled: disabled,
        list:list,
        notification:notification,
        action:handleCheckUncheck,
        icons
    }
}

