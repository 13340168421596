

export const supplyParser = (supplies:string | undefined) => {
   
        const _supplies: undefined | string[][] = supplies && JSON.parse(supplies).map((supply:string)=> supply.split('|'))
        const tools = _supplies?.filter((supply)=> supply[0] === 'HERRAMIENTA').map((supply)=>supply[1]) || []
        const securityItems = _supplies?.filter((supply)=> supply[0] === 'PROTECCION').map((supply)=>supply[1]) || []
        const consumables = _supplies?.filter((supply)=> supply[0] === 'CONSUMIBLE').map((supply)=>supply[1]) || []
        
        return{
            tools,
            securityItems,
            consumables,
        }
    
}
