import React, { createContext, useContext, useEffect, useState } from 'react'
import { useClickPosition } from '../../../globals/hooks/useClickPosition'
import ComponentMode from '../../../../components/componentMode/ComponentMode'
import TgdPopover from '../../../../components/popovers/TgdPopover'
import { useSnackbar } from '../../../../components/snackbar/hooks/useSnackbar'
import { Snackbar } from '@material-ui/core'
import { useModal } from '../../../../components/containers/modal/hooks/useModal'
import styled from '@emotion/styled'
import { useClipboardContext } from './store/clipboardContext'
import { stringify } from 'querystring'



const ContextMenu = styled<any>('div')`
    position:absolute;
    background-color:#FFF;
    width:200px;
    height:auto;
    top:${(props)=>props.y}px;
    left:${(props)=>props.x}px;
    display:${props => props.open ? 'flex' : 'none' };
`






export const RightClick:React.FC<{data:any}> = ({data, children}) => {
  
    const {copy} = useClipboardContext()
    
    const handleData = (e:any) =>{
        e.preventDefault()
        navigator.clipboard.writeText(data)        
        copy(data)
    }

    return (
        <div onContextMenu={handleData}>
            {children}
        </div>
    )
}
