import React from 'react';
import {usePurchaseRequestsByTagFP} from '../../../../domains/stock/purchase-requests/service.purchaseRequests'

import { useSessionContext } from '../../../../domains/session/store/sessionContext';
import VirtualGrid from '../../../../shared/react-table/VirtualGrid';
import { PurchaseGridModel } from '../../../../domains/stock/purchase-requests/lang/usePurchaseRequestGridModel';
import CreatePurchaseRequestForm from '../../../../domains/stock/purchase-requests/components/CreatePurchaseRequestForm';
import SetPurchaseRequestStatus from '../../../../domains/stock/purchase-requests/components/SetPurchaseRequestStatus';
import UpdatePurchaseRequestForm from '../../../../domains/stock/purchase-requests/components/UpdatePurchaseRequestForm';



interface Props{
    tagFP?:string
}

const PurchaseRequestsGrid:React.FC<Props> = ({tagFP}) => {

    const {currentPlant} = useSessionContext()
    const componentTagFP = tagFP || currentPlant?.tagFP
    const {data:purchaseRequests} = usePurchaseRequestsByTagFP(componentTagFP)
    const title = 'Solicitudes de compra'
 
    return (<> 

    <VirtualGrid
        {...{
            tagFP,
            title:title,
            items:purchaseRequests,
            gridModel:PurchaseGridModel,
            headerActions:(item:any)=><CreatePurchaseRequestForm mode='modal'/>,
            renderOption:(item)=> <SetPurchaseRequestStatus item={item} />
            
        }}
    >
        {(items)=><>
            <UpdatePurchaseRequestForm items={items}/>     
        </>}
    </VirtualGrid>


        {/* <DynamicGrid 
            rowsPerPage={12}
            gridModel={PurchaseGridModel}
            title={title}
            height={'65vh'}
            items={purchaseRequests || []}
            loading={purchaseRequests ? false : true}
            headerActions={(item:Any)=><CreatePurchaseRequestForm mode='modal'/>}
            itemActions={(item)=><>
                <SetPurchaseRequestStatus item={item} />
                <UpdatePurchaseRequestForm item={item}/>     
            </>}
        />  */}

    </>);
}

export default React.memo(PurchaseRequestsGrid);
