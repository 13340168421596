import { useState } from "react";

export const usePopoverEvent = () => {

    const [currentTarget, setCurrentTarget] = useState<HTMLButtonElement | HTMLDivElement | null>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
        setCurrentTarget(event.currentTarget);
    };

    const handleClosePopover = () => {
        setCurrentTarget(null)
    }

    return {
        id:'simple-popover',
        currentTarget,
        setPopoverTarget:handleClick,
        handleClosePopover
    }
}