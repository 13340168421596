import { useState } from "react";
import { useUser } from "../../domains/session/store/sessionStore";

type CallBack<T> = (value: T) => T;
type SetValue<T> = (value: T | CallBack<T>) => void;
type UseBasicLocalStorage<T> = [T, SetValue<T>];

const useBasicLocalStorage = <T extends any>(
  key: string,                  // The key under which the value will be stored in localStorage
  initialValue: T,              // The initial value if nothing is stored
  storeLocal: boolean = true    // Indicates whether to store in localStorage or not (default value: true)
): UseBasicLocalStorage<T> => {
  const { data: user } = useUser();

  const cacheKey = storeLocal ? `${user?.email}-${key}` : null;

  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      if (cacheKey) {
        const item = localStorage.getItem(cacheKey);
        return item ? JSON.parse(item) : initialValue;
      }
      return initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  // Function to set the value, which can be a direct value or a transformation function
  const setValue: SetValue<T> = (value) => {
    try {
      const newValue =
        typeof value === "function"
          ? (value as CallBack<T>)(storedValue)   // If it's a function, apply the transformation
          : value;

      setStoredValue(newValue);

      if (cacheKey) {
        localStorage.setItem(cacheKey, JSON.stringify(newValue));
      }
    } catch (error) {
      console.error(error);
    }
  };

  return [storedValue, setValue];
};

export default useBasicLocalStorage;
