import React from 'react';
import DeletePermitionForm from '../DeletePermitionForm';
import { usePermissionsByTagFP } from '../services/service.permission';
import AssignCancellPermissionForm from '../components/AssignCancelMultiplePermissionForm';
import { PermissionInterface } from '../model/PermissionInterface';
import CUPermissionForm from '../CUPermissionsForm';
import { usePermissionsGridModel } from '../lang/usePermissionsGridModel';
import { useSessionContext } from '../../session/store/sessionContext';
import { useLangLabels } from '../../../shared/lang/services/useLangLabels';
import VirtualGrid, { CarrousellActinInterface } from '../../../shared/react-table/VirtualGrid';
import { CrudFormType } from '../../../shared/form/models/FormTypes';

interface Props{
    virtualGridWidth:string
}

const ManagePermissionsPage:React.FC<Props> = ({virtualGridWidth}) => {
    
    const {lang} = useLangLabels()
    const {tagFP} = useSessionContext()
    const {data:permissions} = usePermissionsByTagFP()

    const carrousellComponents:CarrousellActinInterface = [
        {
            icon: 'edit',
            popoverTitle: lang.crudActions.update,
            component: (item: PermissionInterface) => <CUPermissionForm {...{item, actionType: CrudFormType.update}} />
        }, 
    ] 

    return(

        <VirtualGrid {...{
            width:virtualGridWidth,
            title:'',
            items:permissions,
            gridModel:usePermissionsGridModel(),
            carrousellComponents,
            headerActions:(item:any) =><>
                <AssignCancellPermissionForm />
                <CUPermissionForm {...{tagFP,actionType:CrudFormType.create}} />
            </>,
            itemActions:(item)=><DeletePermitionForm item={item}/>
        }}/>
    )
}

export default ManagePermissionsPage
