import React, { useEffect, useMemo, useState } from "react"
import { useBlockLayout, useTable, useResizeColumns, useRowSelect, useSortBy } from "react-table"
import { FixedSizeList } from "react-window"

import { CELL_HEIGHT, CELL_WIDTH, ROWS_CUANTITY, SCROLLBAR_SIZE, TableContainer } from "./components/TableContainer"
import { useCheckboxColumn } from "./hooks/useCheckboxColumn"

import _ from 'lodash';
import FlexContainer from "../../components/containers/FlexContainer"
import { useQueryPersistanceState } from "../react-query/hooks/useQueryPersistanceState"
import { RightClick } from "./components/rightClick/RightClick"
import { ArrowDownwardOutlined, ArrowDropDownOutlined, KeyboardArrowDown, KeyboardArrowUp, Sort } from "@material-ui/icons"

import Pagination from "./components/pagination/Pagination"

const VirtualizedGrid = React.memo(({height, itemCount, itemSize, width, RenderRow}) =>{
  return (
  <FixedSizeList {...{height, itemCount, itemSize, width,}} >
    {RenderRow}
  </FixedSizeList>)
})


const defaultCustomHooks = [() =>{}]


const VirtualGridAdapter = ({ columns, data, children, allItemsCount, width, height, customHooks = defaultCustomHooks,onSelectionModelChange, paginationOptions, totalItems })=> {
  // Use the state and functions returned from useTable to build your UI

  const defaultColumn = React.useMemo(
    () => ({
      width: CELL_WIDTH
    }),
  [],)

  const getRowId = (row, relativeIndex, parent) => {
    return parent ? [parent.id, row.id].join(".") : row.id;
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    state,
    rows,
    totalColumnsWidth,
    prepareRow,
    selectedFlatRows,
  } = useTable(
      {
          columns,
          data,
          getRowId,
          defaultColumn,
          autoResetSelectedRows:false
      },
      useBlockLayout,
      useResizeColumns,
      useSortBy,
      useRowSelect,
      ...customHooks,
      useCheckboxColumn,
  )

  const RenderRow = React.memo(({ index, style }) => {
    const row = rows[index]
    prepareRow(row)
    return (
      <div
        {...row.getRowProps({
            style,
        })}
        className={row.isSelected ? 'tr-selected' : 'tr'}
      >
          {row.cells.map((cell,index) => {
              return (
                <div {
                  ...cell.getCellProps({
                    style:{
                      display:'grid',
                      alignContent:'center'
                      },
                      onClick:(i)=> cell.column.id !== 'renderOptions' && row.toggleRowSelected()
                    })
                  } className="td">
                    <RightClick data={cell.row.original[cell.column.id]}>
                      {cell.render('Cell')}
                    </RightClick>
                  </div>
              )
          })}
      </div>
    )
  })
  
  useMemo(()=>{
    const onSelection = selectedFlatRows.map((item)=>item.original)
    onSelectionModelChange && onSelectionModelChange(onSelection)
  },[state.selectedRowIds]) 
  

  const selectedData = useMemo(()=>{
    const result = selectedFlatRows.map((item)=>{
      return item.original
    })  
    return(result)
  },[selectedFlatRows])

  const componentHeight = ()=>{
    if(height){
      return allItemsCount < height ? allItemsCount : height
    }
    return ROWS_CUANTITY
  }

  

  return (
  <TableContainer width={width} height={componentHeight()}>
    
    {children && children(selectedData)}
    
    <div {...getTableProps()} className="table">
        
        <div>
          {headerGroups.map(headerGroup => (
            <div {...headerGroup.getHeaderGroupProps()} className="tr">
              {headerGroup.headers.map((column,index) => (
                  <div  {...column.getHeaderProps()} className="th">
                  {column.render('Header')}
                      {index !== 0 ? 
                        <span className="sort" {...column.getSortByToggleProps()}>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? <KeyboardArrowDown style={{fontSize:'1rem',marginleft:'4px'}} fontSize="small"/>
                              : <KeyboardArrowUp style={{fontSize:'1rem',marginleft:'4px'}} fontSize="small"/>
                            : <Sort style={{fontSize:'1rem',marginleft:'4px'}} fontSize="small" />}
                        </span>
                        : null
                      }
                      <div {...column?.getResizerProps() }
                      className={`resizer ${
                        column.isResizing ? 'isResizing' : ''
                      }`}
                      />
                  </div>
              ))}
              </div>
          ))}
        </div>

        <div {...getTableBodyProps()}>
          <VirtualizedGrid
            height={componentHeight() * CELL_HEIGHT }
            itemCount={rows.length}
            itemSize={CELL_HEIGHT}
            width={totalColumnsWidth + SCROLLBAR_SIZE}
            RenderRow={RenderRow}
          />
        </div>

    </div>

    {allItemsCount &&
      <FlexContainer align="center" justify='flex-end' padding="8px 0px">
        { 
        paginationOptions && totalItems
          ? <Pagination paginationOptions={paginationOptions} totalItems={totalItems} selectedData={selectedData} /> 
          : `${selectedData?.length}/${data?.length || 0}/${allItemsCount}`
        } 
      </FlexContainer>
    }

  </TableContainer>
  )
}

export default React.memo(VirtualGridAdapter)