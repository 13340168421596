import { apiCall, } from "../../../shared/axios/axios-config"
import { useQuery } from "../../../shared/react-query/react-query-conf"
import { useSessionContext } from "../../session/store/sessionContext"
import { getViewDateDay } from "../../../shared/date/utils"

import { PeriodInterface } from "../../../shared/date/usePeriod"
import { ObservationInterface } from "../models/ObservationTypes"
import { 
    PrivateQueryData, 
    ItemsAndPaginationResponseInterface, 
    PaginationInterface,
    FilterInterface,
} from "../../../shared/globals/utilsGlobalTypes"


// ----------------------------------------------------------------------------------------------------
// Interfaces

type PaginatedObservationsLubricationPointByPeriodInterface<T = any> = PaginationInterface & FilterInterface<T> & {
    firstDate?: string, 
    secondDate?: string, 
    tagFP: string
}

// ----------------------------------------------------------------------------------------------------
// Services

export const PaginatedObservationsLubricationPointByPeriod = (
    { data, token }: PrivateQueryData <PaginatedObservationsLubricationPointByPeriodInterface <ObservationInterface> >
    ) => {
        return apiCall< ItemsAndPaginationResponseInterface<ObservationInterface, PaginatedObservationsLubricationPointByPeriodInterface> >({
            method: "POST",
            url: `/PaginatedObservationsLubricationPointByPeriod`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data
        })
}

// ----------------------------------------------------------------------------------------------------
// Adapters

export const usePaginatedObservationsLubricationPointByPeriod = (
    period: PeriodInterface | undefined, 
    { pageIndex, pageSize }: { pageIndex: number, pageSize: number },
    { filters, searchValue }: { filters: any, searchValue: string }
) => {

    const { token, tagFP } = useSessionContext()
    const canFetch = [!!period?.firstDate,!!period?.secondDate,!!tagFP,!!token,token != ''].includes(false) ? false : true

    const data = {
        tagFP,
        ...period,
        pageIndex,
        pageSize,
        filters,
        searchValue
    }

    return useQuery<ItemsAndPaginationResponseInterface<ObservationInterface, PaginatedObservationsLubricationPointByPeriodInterface>>({
        queryKey: ["PaginatedObservationsLubricationPointByPeriod", period, pageIndex, pageSize, filters, searchValue], 
        queryFn: () => PaginatedObservationsLubricationPointByPeriod({ data, token }),
        enabled: canFetch,
        select: (data) => {
            return {
                items: data?.items.map((i) => ({
                    ...i,
                    modifiedDate: getViewDateDay(i?.modifiedDate),
                })),
                pagination: {
                    ...data?.pagination
                }
            }
        },
        staleTime:0,
    })
}