import React,{useEffect} from 'react';
import { useDeleteSectorVerification } from './services/service.sectors';
import CustomButton from '../../components/buttons/CustomButton';

import { useSnackbar } from '../../components/snackbar/hooks/useSnackbar';
import { SectorInterface } from './models/SectorInterface';
import { useStatusProcessor } from '../../shared/queries/StatusProcessor';
import { useClickPosition } from '../../shared/globals/hooks/useClickPosition';
import { CircularProgress } from '@material-ui/core';
import FlexContainer from '../../components/containers/FlexContainer';


interface Props{
    item:SectorInterface
}
const DeleteSectorFrom:React.FC<Props> = ({item}) => {
    
    const { status:deleteStatus, validate, errors, query:{status, message} } = useDeleteSectorVerification()

    const {position,getClickPosition} = useClickPosition()
    const { setData } = useSnackbar()

    useEffect(()=>{
        
        if(deleteStatus === 'error'){
            
            const componentsError = (
                (errors?.equipments?.length 
                    ? (errors.equipments.length > 1 
                        ? `${errors.equipments.length} Equipos` 
                        : `${errors.equipments.length} Equipo`) 
                    : '')
            )

            setData({
                modal:true,
                errors:`Este sector no se puede eliminar porque está siendo usado en: ${componentsError}` ,
                severity:'warning',
                position:position
            })
            
        }

        if(deleteStatus === 'success'){
            setData({
                modal:true,
                errors:message || '',
                severity:deleteStatus ? 'success' : 'warning',
                position:position
            })
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[deleteStatus,errors])


    return (
        deleteStatus === 'loading' || status === 'loading'
        ? <FlexContainer justify={'center'} align='center'><CircularProgress size='24px' /></FlexContainer>
        : <CustomButton 
            status={status}
            icon='delete' 
            variant='iconButton' 
            action={(e)=>getClickPosition(e,()=>validate(item))}
            popoverTitle='Eliminar sector'
        />
    );
}

export default React.memo(DeleteSectorFrom);
