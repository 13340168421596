import React from 'react';
import { List, ListItem, Typography, Divider, ListItemText, ListItemSecondaryAction } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { LocalStateSupply, SupplyStateDimention } from '../model/SupplyInterface';
import { ComponentUIModes } from '../../../components/componentMode/componentModesInterfaces';
import { SurveyTasksValues, TaskType } from '../../surveys/tasks/model/TaskInterface';
import FlexContainer from '../../../components/containers/FlexContainer';
import theme from '../../../assets/theme';
import PageContainer from '../../../components/containers/PageContainer';
import { Alert } from '@material-ui/lab';


interface Props{
    supplies:LocalStateSupply[]
    title?:string
    headerAction?:React.ReactNode
    handleDeleteItem?:(item?:any)=>void
    mode?:ComponentUIModes
    inmutableValues?:LocalStateSupply[]
    tasktype?:TaskType
}

const SupplyList:React.FC<Props> = ({ mode, headerAction, title, handleDeleteItem, supplies, inmutableValues,tasktype }) => {
    
    const _mode = mode || 'content'
    
    const supplyItem = ([supply,index]:any,undeletable?:boolean) => (
            <ListItem key={index}>
                <ListItemText 
                primary={supply.supply} 
                secondary={<span>
                    <span>{supply.type}</span> 
                    {tasktype && tasktype === SurveyTasksValues.puntuation 
                        ? null
                        : <> | <span style={{color:supply.state === SupplyStateDimention.needed ? theme.palette.info.main : theme.palette.secondary.main}}>{supply.state}</span> </>
                    }
                </span>}
                />
                <ListItemSecondaryAction>
                    <FlexContainer align='center'>
                        {handleDeleteItem && !undeletable &&
                        <IconButton onClick={()=>handleDeleteItem(index)}>
                            <DeleteIcon />
                        </IconButton>}
                    </FlexContainer>
                </ListItemSecondaryAction>
            </ListItem>
        )
    

    return(<>
        <List style={{width:'100%'}}>
            <FlexContainer align='center' justify='space-between' padding={"8px 0px"}>
                <Typography variant='h6'>{title}</Typography>
                <FlexContainer>
                    {_mode ==='content' && headerAction && headerAction}
                </FlexContainer>
            </FlexContainer>
            <Divider/> 
            {inmutableValues && inmutableValues?.map((...supply)=> supplyItem(supply,true))}
            {supplies.length > 0 
                ? supplies?.map((...supply) => supplyItem(supply))
                : <PageContainer padding={'8px 0px'}>
                    <Alert severity='info' >
                        No se han cargado consumibles
                    </Alert>
                </PageContainer>
            }
        </List>

    </>)
}

export default React.memo(SupplyList);
