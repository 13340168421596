import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import { IncomeInterface } from "../types/Income";
import { InputComponentTypes } from "../../../../shared/form/models/FormTypes";
import { useForm } from "../../../../shared/form/useForm";
import { useDate } from "../../../../shared/date/useDate";

export const useIncomeForm = ( tagFP: string, lubricant?: string, item?: IncomeInterface) => {
  const { lang } = useLangLabels();
  const { ISOToDateTime } = useDate()

  const incomeForm = useForm<IncomeInterface>({
    fields: {
      id: {
        value: item?.id, 
        component: InputComponentTypes.none,
      },
      admissionDate: {
        label: lang.incomeForm.admissionDate,
        placeholder: lang.incomeForm.admissionDate,
        value: item?.admissionDate ? ISOToDateTime(item.admissionDate, false) : ISOToDateTime(new Date().toISOString(), false), 
        component: InputComponentTypes.dateTime,
      },
      lotNumber: { 
        label: lang.incomeForm.lotNumber,
        placeholder: lang.incomeForm.lotNumber,
        value: item?.lotNumber || "", 
        component: InputComponentTypes.text,
        validations: {
          required: {
              value: true,
              message: lang.validations.messages.required
          }
        },
      },
      referrer: {
        label: lang.incomeForm.referrer,
        placeholder: lang.incomeForm.referrer,
        value: item?.referrer || "", 
        inputConfig: {
          tagFP: item?.tagFP || tagFP
        },
        component: InputComponentTypes.referrer,
        validations: {
          required: {
              value: true,
              message: lang.validations.messages.required
          }
        },
      },
      lubricant: {
        label: lang.lubricantForm.singular,
        placeholder: lang.lubricantForm.singular,
        value: lubricant ?? (item?.lubricant || ""), 
        inputConfig: {
          tagFP: item?.tagFP || tagFP
        },
        component: InputComponentTypes.lubricant,
        validations: {
          required: {
              value: true,
              message: lang.validations.messages.required
          }
        },
        disabled: lubricant ? true : false
      },
      quantity: {
        label: lang.incomeForm.quantity,
        placeholder: lang.incomeForm.quantity,
        value: item?.quantity || 1, 
        component: InputComponentTypes.number,
        validations: {
          pattern: {
              value: /^[+]?([1-9][0-9]*(?:\.[0-9]*)?|\.[0-9]+)$/,
              message: lang.validations.messages.pattern
          }
        }
      },
      date: {
        component: InputComponentTypes.none,
      },
      tagFP: {
        value: item?.tagFP || tagFP, 
        component: InputComponentTypes.none,
      },
    },
  });

  return incomeForm;
};
