import React from 'react';
import { usePlantAnomalies } from '../services/service.anomalies';
import DeleteAnomalyForm from '../DeleteAnomalyForm'
import { AnomalyInterface } from '../models/anomaliesTypes';
import CUAnomalyForm from '../CUAnomalyForm';

import { useAnomaliesGridModel } from '../lang/useAnomaliesGridModel';
import { useLangLabels } from '../../../shared/lang/services/useLangLabels';
import VirtualGrid, { CarrousellActinInterface } from '../../../shared/react-table/VirtualGrid';
import { CrudFormType } from '../../../shared/form/models/FormTypes';
import { getViewDateDayHour } from '../../../shared/date/utils';

interface Props{
    virtualGridWidth:string
}

const ManageAnomaliesPage:React.FC<Props> = ({virtualGridWidth}) => {
    
    const {data:anomalies} = usePlantAnomalies()
    const {lang} = useLangLabels()

    // Para revisar
    const newAnomalies = anomalies?.map(anomaly => ({
        ...anomaly,
        date: getViewDateDayHour(anomaly.date)
    })) || [];

    const carrousellComponents:CarrousellActinInterface = [
        {
            icon:'edit',
            popoverTitle:lang.crudActions.update,
            component:(item:AnomalyInterface) => <CUAnomalyForm item={item} actionType={CrudFormType.update} />
        }
    ]

    const anomalyGRidModel = useAnomaliesGridModel()
    
    return(
        <VirtualGrid {...{
            items:anomalies,
            gridModel:anomalyGRidModel,
            width:virtualGridWidth,
            title:lang.anomalies.plural,
            carrousellComponents,
            headerActions:(item:any)=> <CUAnomalyForm actionType={CrudFormType.create} /> ,
            itemActions:(item)=><DeleteAnomalyForm item={item}/>
        }}/>
    )
}


export default React.memo(ManageAnomaliesPage);
