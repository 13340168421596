import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';
import CustomButton from '../../../components/buttons/CustomButton';
import ModalButton from '../../../components/buttons/ModalButton';
import { useAssignCancelMultiplePermissions, usePermissionsByTagFP } from '../services/service.permission';
import TgdSelectableTable from '../../../components/grids/TgdSelectableTable';
import { useSessionContext } from '../../session/store/sessionContext';
import { useEquipmentsByTagFP } from '../../lubricationPoints/services/lubricationPointsAdapters';
import { useLubricantsGridModel } from '../../lubricant/lang/useLubricantsGridModel';
import { usePermissionsGridModel } from '../lang/usePermissionsGridModel';
import { useLubricationPointsGridModel } from '../../lubricationPoints/lang/useLubricationPointsGridModel';

interface Props{
}

const AssignCancellPermissionForm:React.FC<Props> = ({}) => {
    const {tagFP} = useSessionContext()
    const [step,setStep] = useState(1)
    const {data:equipments} = useEquipmentsByTagFP()
    const {data:permissions} = usePermissionsByTagFP()

    const [selectedEquipments, setSelectedEquipments ] = useState([])
    const [selectedPermission, setSelectedPermissions ] = useState([])

    const { handleAssigCancel,status,reset } = useAssignCancelMultiplePermissions()

    /* const equipmentColumns:GridColumns = [
        {headerName:'Sector',field:'sector',width:170},
        {headerName:'Tag Planta',field:'plantTag',width:170},
        {headerName:'Equipo',field:'equipment',width:170},
        {headerName:'Tipo',field:'type',width:170},
        {headerName:'Criticidad',field:'criticality',width:170},
    ]
    
    const permissionColumns:GridColumns= [
        {headerName:'ID',field:'id'},
        {headerName:'Nombre',field:'permissionName'},
        {headerName:'Descripción',field:'description',width:170},
        {headerName:'Código',field:'code',width:170},
        {headerName:'Área',field:'area',width:170},
        {headerName:'Sector',field:'sector',width:170}
    ] */
    
    const handleAssign = () =>{
        handleAssigCancel(selectedEquipments,selectedPermission,'A')
    }

    const handleReset = () => {
        reset()
        setSelectedEquipments([])
        setSelectedPermissions([])
        setStep(1)
    }


    const equipmentGridModel = useLubricationPointsGridModel()
    const permissionGridModel = usePermissionsGridModel()

    return( 
       <ModalButton icon='addList' title='Asignar permisos' onClose={handleReset}>
       
            {status === 'error' && <><Alert severity='error'>Ha ocurrido un error</Alert></>}
            {status === 'success' && <><Alert>Asignaciones realizadas</Alert></>}
            
            {step === 1 &&
                <TgdSelectableTable
                    title='Seleccionar equipos'
                    items={equipments} 
                    gridModel={equipmentGridModel}
                    headerActions={<CustomButton icon='next' {...(selectedEquipments?.length > 0) ? {disabled:false} : {disabled:true}} action={()=>setStep(2)}/>}
                    handleChange={setSelectedEquipments}
                />
            }
               
            {step === 2 && 
                <TgdSelectableTable
                    title='Permisos que se le asignarán'
                    items={permissions} 
                    gridModel={permissionGridModel}
                    headerActions={<>
                    <CustomButton icon='before' action={handleReset}/>
                    <CustomButton icon='save'  status={status} {...(selectedPermission?.length > 0) ? {disabled:false} : {disabled:true}} action={handleAssign} />
                    </>}
                    handleChange={(items)=>setSelectedPermissions(items)}
                />

            }

        </ModalButton>
    )
}

export default React.memo(AssignCancellPermissionForm);
