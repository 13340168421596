import { Button, makeStyles, Paper } from "@material-ui/core"
import { Link } from "react-router-dom"
import { CustomIcon } from "../../components/buttons/CustomButton"
import PageContainer from "../../components/containers/PageContainer"
import ListToolbar from "../../components/toolbar/ListToolbar"
import UpdatePersonForm from "../../domains/person/components/UpdatePersonForm"
import { useSessionContext } from "../../domains/session/store/sessionContext"
import { USER_ROUTES } from "../../shared/routes/routes"

const useStyles = makeStyles(theme => ({
    flexButton:{
        width:'100%',
        backgroundColor:'rgba(255, 255, 255, 0.3)',
        borderRadius:'5px',
        padding:'5px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        '&:hover':{
            backgroundColor:'rgba(255, 255, 255, 0.6)',
            cursor:'pointer'
        }
  }
  }))

export const ConfigButton:React.FC<{}> = ({}) => {
    const classes = useStyles()
    return(
        <Button className={classes.flexButton} component={Link} to={`${USER_ROUTES.config}`}/* to={`/${USER_ROUTES.home}/${(user?.type ==='C') ? workspace : dimention}/${USER_ROUTES.config}`} */>
            <CustomIcon style={{color:'white'}} icon='config' />
        </Button>
    )
}

const ConfigPage:React.FC<{}> = ({}) => {
    const {person} = useSessionContext()
    return(
        person ?
        <PageContainer>
            <Paper>
                <ListToolbar title={'Configuraciones de la cuenta'} />
                <PageContainer>
                    <UpdatePersonForm person={person}/>
                </PageContainer>
            </Paper>
        </PageContainer>
        :null
    )
}



export default ConfigPage