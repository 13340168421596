import { SyncFileErrors } from '../../../domains/SyncBox/model/CollectorsSyncByTagFPType';
import { LangType } from './interfaces';


export interface language{
    stringDefaultValues:{
        withOutSupplies:string,
        notSpecified:string,
        withOutRoutes:string,
        withoutObservations:String
    }
    words:{
        info:string
        and:string,
        of:string,
        thisMasc:string,
        thisfem:string,
        available:string
        availables:string,
        more:string
        all:string
        yes:string
        no:string
        active:string
        inactive:string
        request:string
    },
    globalentityValues:{
        id:string
        tagFP:string
        tagTGD:string
        tagSTI:string
        date:string
        identifier:string
    },
    collectorSync:{
        title:string,
        itWasFind:string,
        databasesWithProblems:string,
        errors:{
           /*  [SyncFileErrors.Corrupted]:string, */
            [SyncFileErrors.failed]:string
            [SyncFileErrors.expired]:string
            
        },
        helpers:{
            /* [SyncFileErrors.Corrupted]:string, */
            [SyncFileErrors.failed]:string
            [SyncFileErrors.expired]:string
            unknown:string
        }
    },
    messages:{
        featureNotAvailableOffline:string
        featureNotAvailableOnline:string
        abilityNotAllowed:string
        lastTenSurveys:string
        lastTenObservations:string
        latestIncome: string
        surveyObservations:string,
        lubricationPointObsevetions:string,
        nothingSelected:string,
        features:{
            collectorConfig:string,
            supervisorSurvey:string,
            supervisorClientVisor:string
        },
        status:{
            success:string,
            error:string,
            loading:string, 
        }
    }
    grid:{
        actionsAlert:string,
        noRowsLabel: string,
        errorOverlayDefaultLabel: string,
        toolbarColumns: string,
        toolbarColumnsLabel: string,
        toolbarFilters: string,
        toolbarFiltersLabel: string,
        toolbarFiltersTooltipHide: string,
        toolbarFiltersTooltipShow: string,
        columnsPanelTextFieldLabel: string,
        columnsPanelTextFieldPlaceholder: string,
        columnsPanelDragIconLabel: string,
        columnsPanelShowAllButton: string,
        columnsPanelHideAllButton: string,
        toolbarExport: string,
        toolbarExportLabel: string,
        toolbarExportCSV: string,
    },
    filters:{
        title:string,
        addFilter:string,
        deleteFilters:string,
        add:string,
        searchByTerm:string,
        closeFilters:string,
        downloadData:string,
        filterData:string,
        all:string
        select:string,
        queryDateRange:string,
        from:string
        to:string,
        resetDateRange:string,
        and:string,
        andHelper:string,
        or:string,
        orHelper:string

    },
    sendEmail:{
        title:string,
        att:string,
        charge:string,
        recipient:string,
        emailBody:string,
    },
    crudActions:{
        update:string
        create:string
        delete:string
        archive:string
        desactivate:string
        activate:string
    },
    inputFields:{
        autocomplete:{
            clearText:string
            closeText:string
            openText:string
            loadingText:string
            email:string
        },
    },
    validations:{
        messages:{
            macAddress:string,
            justNumbers:string,
            justText:string,
            required:string,
            minLength:string,
            maxLength:string,
            allReadyExist:string
            pattern:string
        }
    }
    ui:{
        components:{
            viewMode:{
                title:string
            }
            name:string
        }
    }
    pages:{
        realTime:{
            sensors:string,
            equipments:string
        }
    }
    login:{
        form:{
            label:{
                user:string,
                password:string
            }
        }
        navigation:{
            name:string
        }
        legend:{
            years:string
        }
    },
    demo:{
        form:{
            completeName:string
            phone:string,
            company:string,
            email:string
        },
        prospects:string
    },
    persons:{
        newSupervisor:string
        newOperator:string
        newAdministrator:string
        newAccountManager:string
        newClient:string,
        userAdministration:string

        createstep1:string
        createstep2:string
        createFinishStep:string

        plural:string
        singular:string,
        name:string
        lastName:string
        email:string
        client:string
        clients:string
        operator:string
        operators:string
        supervisor:string
        supervisors:string
        superAdmin:string
        superAdmins:string
        admin:string
        admins:string
        accountManager:string
        accountManagers:string

        adress:string
        phone:string
        password:string
        referer:string
    }
    factories:{
        singular:string,
        plural:string,
        location:string,
        name:string,
        businessName:string,
        referrer:string,
        tagF:string,
        accountManagerFactories:string
        manageFactories:string
        noFactories:string
    },
    plants:{
      
        asignCancelPlants:string,
        clickToAssign:string,
        clickToCancel:string,
        singular:string,
        plural:string,
        factoryId:string,
        factory:string,
        location:string,
        name:string,
        plantId:string,
        referrer:string,
        tagFP:string,
        state:string,
    },
    language:{
        label:string,
        spanish:string,
        english:string,
    },
    formMessages:{
        required:string,
        float:string
        invalidEmail:string
    },
    stock:{
        product:string
        brand:string
        actualStock:string
        minimumRequired:string
        category:string
        subcategory:string
        measureUnit:string
        categoryArea:string
        providerArea:string
        complex:string
        hidden:string
        options:{
            simple:string,
            complex:string,
            archived:string,
            unArchived:string
        }
    }
    actionTypes:{
        activate:string,
        desactivate:string,
        view:string,
        apply:string,
        login:string,
        logout:string,
        create:string,
        deleteItem:string,
        update:string,
        assign:string
        save:string,
        exit:string,
        next:string,
        before:string
    },
    types:{
        plural:string
        singular:string
        form:{
            name:string
            type:string
        }
    }
    lubricationPoints:{
        equipments:string
        sector:string,
        plantTag:string,
        lowReason:string,
        labelId:string,
        state:string,
        criticality:string,
        egressDate:string
        function:string,
        equipment:string,
        review:string,
        location:string,
        lubricantType:string,
        routes:string,
        supplies:string,
        type:string,

        brand:string,
        model:string,
        element:string,
        component:string,
        lubricant:string,
        capacity:string,
        measureUnit:string,
        oilAnalysis:string,
        admissionDate:string,
        observations:string,
        tagTGDBefore:string,
        options:{
            active:string,
            disabled:string,
            all:string,
            oilAnalisys:string,
            withoutOilAnalisys:string
        }
        helpers:{
            whyAreEditingThisPoint:string
        },
        suggestedQuantity:string
    }
    infoLubricationPoints:{
        supplies:string
        labelReplacement:string
        lastLubrication:string
        lubricantQuantity:string
        leak:string
        pending:string
        updatedToday:string
        user:string
        lastSurveyId:string
        assetCriticality:string
        inaccessible:string
        lastInspectionDate:string
        updateReview:string
        temperature:string
        leakLevelRange:string
        withoutSupplies:string,//'SIN INSUMOS'
        assetGeneralPuntuation:string//'Puntuación general del activo.'
        assetPriority:string//'Importancia del activo'
        lastEquipmentState:string//'ULTIMO ESTADO DEL EQUIPO'
        withLeak:string//'Con Perdida' 
        withoutLeak:string//'Sin pérdida'
        lastAgregate:string//'ULTIMO AGREGADO'
        notPending:string//'Al Día'
        intervenedToday:string//'SE LO INTERVINO HOY',
        lastInspection:string//'ULTIMA INSPECCION'
        accesible:string//'Accesible'
        lastTemperature:string//'ULTIMA TEMPERATURA'
        missings:string//'FALTANTES'
        capacity:string//'CAPACIDAD'
        equipmentinformation:string//'Información del Equipo'
        lastSurveys:string //Últimos relevamientos
    }
    observations:{
        singular:string,
        plural:string,
        surveyId:string,
        equipment:string,
        modified:string,
        modifiedDate:string
        observationDate:string
        OTnumber:string
        state:string
        taskId:string
        taskRequestId:string
        taskType:string
        type:string
        element:string
        component:string
        user:string
    }
    addedReasons:{
        plural:string,
        singular:string,
        reason:string
    },
    anomalies:{
        singular:string,
        plural:string,
        anomalyName:string
    }
    news:{
        singular:string,
        plural:string,
        sector:string
        area:string,
        equipment:string,
        type:string,
        newsDate:string,
        description:string,
        criticality:string,
        user:string,
    }
    collectors:{
        plural:string
        singular:string
        activate:string
        desactivate:string
        desactivatedColectorWarning:string
        form:{
            active:string,
            disabled:string,
            name:string
            serialNumber:string
            collectorCode:string
            id:string
            tagFP:string
            state:string
            admissionDate:string
            egressDate:string
        }
    }
    criticalities:{
        title:{
            singular:string
            plural:string
        },
        form:{
            descripton:string
            number:string
            periodicity:string
            color:string
            type:string
            days:string
        }
    }
    lubricantForm:{
        singular:string,
        plural:string
        type:string,
        name:string,
        brand:string,
        measureUnit:string,
        requiresObservation:string
    },
    incomeForm:{
        singular: string
        plural: string
        lotNumber: string
        quantity: string
        admissionDate: string
        referrer: string
        confirmMessage: string
    },
    containers:{
        title:{
            singular:string,
            plural:string,
        },
        form:{
            name:string
            label:{
                name:string,
                lubricantType:string,
                lubricant:string,
                capacity:string,
                capacityHelper:string,
                coefficient:string,
                code:string,
                type:string
            }
        }
    },
    sectors:{
        title:{
            singular:string,
            plural:string,
        },
        form:{
            name:string,
            label:{
                sector: string
                sectorName: string
                code: string
            }
        }

    },
    areas:{
        title:{
            singular:string,
            plural:string,
        },
        form:{
            name:string,
            label:{
                area: string
                email: string
                emailHelper:string
                referrer: string
            }
        }
    },
    components:{
        title:{
            singular:string,
            plural:string,
        },
        form:{
            name:'',
            label:{
                component: string,
                code: string,
            }
        }
    },
    elements:{
        title:{
            singular:string,
            plural:string,
        },
        form:{
            name:'',
            label:{
                element: string,
                code: string,
            }
        }
    },
    supplies:{
        errorGetingSupplies:string
        title:{
            singular:string,
            plural:string
            form:{
                name:string,
                label:{
                    type: string,
                    supply: string,
                    description:string
                }
            }
            
        }
        HERRAMIENTA:{
            manageLubricationPointSupply:string
            title:{
                singular:string,
                plural:string,
            },
            form:{
                name:'',
                label:{
                    type: string,
                    supply: string,
                    description:string
                }
            }
        }
        CONSUMIBLE:{
            manageLubricationPointSupply:string
            title:{
                singular:string,
                plural:string,
            },
            form:{
                name:'',
                label:{
                    type: string,
                    supply: string,
                    description:string
                }
            }
        }
        PROTECCION:{
            manageLubricationPointSupply:string
            title:{
                singular:string,
                plural:string,
            },
            form:{
                name:'',
                label:{
                    type: string,
                    supply: string,
                    description:string
                }
            }
        },
        OBSERVACION:{
            manageLubricationPointSupply:string
            title:{
                singular:string,
                plural:string,
            },
            form:{
                name:'',
                label:{
                    type: string,
                    supply: string,
                    description:string
                }
            }
        }
    },
    permissions:{
        plural:string,
        singular:string,
        permissionName:string,
        description:string,
        code:string,
        area:string,
        sector:string,
        sectorHelper:string
        areaHelper:string
    }
    routes:{
        plural:string,
        singular:string,
        operator:string
        periodicity:string
        route:string
        scheduledDate:string
        newScheduledDate:string
        special:string
        routeName:string
        startDate:string
        state:string
        manageEquipmentRoutes:string,
        getRoutesError:string,
        assignRouteToEquipment:string,
        validations:{
            allRouteExistance:string
            lubricatorRouteExistance:string
            ableToCreateRoute:string
        }
    },
    surveys:{
        options:{
            impossibleToLubricate:string,
            normal:string,
            all:string,
            lubricates:string,
            withoutLubricate:string, 
            yes:string,
            no:string,
            outOfRoute:string,
            inRoute:string,
            inaccessible:string,
            accessible:string,
            manualCharge:string,
            Colector:string,
            done:string,
            notDone:string,
            neededLubrication:string,
            lubricationNotNeeded:string,
        }
        title: {
            plural: string
            singular: string
        }
        componentChange:string
        observationsQuantity:string
        equipment:string,
        element:string,
        component:string,
        sector:string,
        review:string,
        impossibleToLubricate:string,
        lubricates:string,
        routeDate:string,
        loadingDate:string,
        startTime:string,
        endTime:string,
        sampleExtraction:string,
        consumables:string,
        lubricantType:string,
        lubricant:string,
        quantity:string,
        measureUnit:string,
        reason:string,
        anomalies:string,
        outOfRoute:string,
        inaccessible:string,
        cleaning:string,
        otNumber:string,
        news:string,
        criticality:string,
        user:string,
        route:string,
        manualRegistration:string,
        areas:string,
        taskType:string,
        taskTime: string,
        taskDone:string,
        assetCriticality:string,
        needsLubrication:string,
        temperature:string
    }
    iotDevices:{
        actions:{
            desactivate:string
            activate:string
        }
        helpers:{
            youAreDesactivating:string,
            youAreActivating:string,
            youCanNotWorkWithDifrentKindOfItemStates:string,
            selectedItems:string,
            cleanState:string,
            dontForgetToSpecifyLowReason:string,
        }
        title:{
            singular:string,
            plural:string
        }
        form:{
            label:{
                device:string,
                deviceCode:string,
                admissionDate:string,
                egressDate:string,
                state:string,
                location:string,
                lowReason:string,
                batteryCapacity:string,
                lowBatteryAlarm:string,
            }
        }
    },
    iotSensorTypes:{
        title:{
            singular:string,
            plural:string
        }
        form:{
            label:{
                sensorType:string,
                sensorTypeCode:string,
            }
        }
    },
    STISensors:{
        title:{
            singular:string,
            plural:string
        },
        form:{
            label:{
                sensorType:string,
                sensorTypeCode:string,
                sensorAddress:string,
                channelQuantity:string,
                permanent:string,
                batteryCapacity:string,
            }
        }
        booleanSelector:{
            data:{
                fixed:string,
                mobile:string,
            }
        }
    },
    STIEquipments:{
        title:{
            singular:string,
            plural:string
        },
        form:{
            label:{
                equipment:string,
                labelId:string,
                sector:string,
                plantTag:string,
                tagSTI:string,
                type:string,
                brand:string,
                model:string,
                function:string,
                location:string,
                observations:string,
                admissionDate:string,
                egressDate:string,
                state:string,
                lowReason:string,
                criticality:string,
            }
        },
    },
    STISensorizedEquipment:{
        title:{
            singular:string,
            plural:string,
        },
        form:{
            label:{
                sensorTypeCode:string
                sensorType:string
                sensorAddress:string
                iotDeviceCode:string
                plantTag:string
                equipment:string
                tagSTI:string
                element:string
                sector:string
                location:string
                criticality:string
                observations:string
                state:string
                lowReason:string
                admissionDate:string
                egressDate:string
                temperature:string
                vibration:string
                xAxis:string
                yAxis:string
                zAxis:string
                channels:string
                pressure:string
            }
        }
    }
    reports:{
        report:string
        reports:string
        equipmentInfo:string,
        Observations:string,
        surveys:string,
        actions:string
        lubricationPoints:string,
        equipmentOnDate:string,
        routes:string
    }

}

export const useLang = (lang:LangType):language =>{

    const es:language= {
        stringDefaultValues:{
            withOutSupplies:'SIN INSUMOS',
            notSpecified:'SIN ESPECIFICAR',
            withOutRoutes:'SIN RUTAS',
            withoutObservations:'SIN OBSERVACION'
        },
        words:{
            info:'Info',
            and:'y',
            of:'de',
            thisMasc:'Este',
            thisfem:'Esta',
            available:'disponible',
            availables:'disponibles',
            more:'Más',
            all:'Todos',
            yes:'Si',
            no:'No',
            active:'Activo',
            inactive:'Inactivo',
            request:'Solicitud'
        },
        globalentityValues:{
            id:'Id',
            tagFP:'Planta',
            tagSTI:'Tag STI',
            tagTGD:'Tag TGD',
            date:'Fecha',
            identifier:'Identificador'
        },
        messages:{
            nothingSelected:'No has seleccionado ningun item',
            abilityNotAllowed:'No tienes los permisos necesarios para usar esta funcionalidad.',
            featureNotAvailableOffline:'Esta funcion solo se encuentra habilitada en la aplicación de la nube',
            featureNotAvailableOnline:'Esta funcion solo se encuentra habilitada en la aplicación local de cada planta',
            lastTenSurveys:'Ultimos 10 relevamientos',
            lastTenObservations:'Ultimos 10 Observaciones',
            latestIncome:'Últimos Ingresos',
            surveyObservations:'Observaciones del relevamiento',
            lubricationPointObsevetions:'Observaciones del punto',
            features:{
                collectorConfig:'Pronto podrá sincronizar y configurar el nuevo colector de TGD',
                supervisorSurvey:'Pronto podrá relevar los datos de un lubricador',
                supervisorClientVisor:'Pronto podrá ver el panel de un cliente',
            },
            status:{
                success:'Todo ha ido bien',
                error:'Hubo un error',
                loading:'Cargando', 
            }
        },
        collectorSync:{
            title:'Panel de sincronización de dispositivos',
            itWasFind:'Se encontraron',
            databasesWithProblems:'bases con los siguientes problemas:',
            errors:{
                /* [SyncFileErrors.Corrupted]:'Defectuosa', */
                [SyncFileErrors.failed]:'Fallida',
                [SyncFileErrors.expired]:'Expirada'
            },
            helpers:{
                /* [SyncFileErrors.Corrupted]:'La base de datos se encuentra defectuosa.', */
                [SyncFileErrors.failed]:'Contacte a soporte, la sincronización de esta base falló',
                [SyncFileErrors.expired]:'La base de datos tiene mas de 7 días. Por seguridad de los datos contacte a soporte para solucionar este problema',
                unknown:'Se desconoce la causa del error.'
            }
        },
        grid:{
            actionsAlert:'Seleccione uno o mas item para trabajar con los datos.',
            noRowsLabel: 'No hay datos.',
            errorOverlayDefaultLabel: 'Ha ocurrido un error.',
            toolbarColumns: 'Columnas',
            toolbarColumnsLabel: 'Seleccionar columnas',
            toolbarFilters: 'Filtros',
            toolbarFiltersLabel: 'Mostrar filtros',
            toolbarFiltersTooltipHide: 'Esconder filtros',
            toolbarFiltersTooltipShow: 'Mostrar filtros',
            columnsPanelTextFieldLabel: 'Buscar columna',
            columnsPanelTextFieldPlaceholder: 'Titulo de columna',
            columnsPanelDragIconLabel: 'Reordenar columna',
            columnsPanelShowAllButton: 'Mostrar todo',
            columnsPanelHideAllButton: 'Esconder todo',
            toolbarExport: 'Exportar',
            toolbarExportLabel: 'Exportar',
            toolbarExportCSV: 'Descargar as CSV',
        },
        filters:{
            title:'Buscar y filtrar',
            addFilter:'Agregar filtro',
            deleteFilters:'Eliminar filtros',
            add:'Agregar',
            searchByTerm:'Buscar por término',
            closeFilters:'Cerrar filtros',
            downloadData:'Descargar datos como .xlsx ',
            filterData:'Filtrar datos',
            all:'Todos',
            select :'Seleccionar',
            resetDateRange:'Limpiar rango de fecha',
            queryDateRange:'Consultar Rango de fecha',
            from:'Desde',
            to:'Hasta',
            and:'Y',
            andHelper:'Nuevo filtro "Y" (filtrará de manera estricta entre los registros ya seleccionados)',
            or:'O',
            orHelper:'Nuevo filtro "O" (filtrará y/o traerá todos los registros que cumplan con alguna de las características seleccionadas, al usar varios filtros "O", estaremos acumulando registros que cumplan con reglas)',
        },
        sendEmail:{
            title:'Enviar por email',
            att:'atte.',
            charge:'Area de lubricación de TGD Company',
            recipient:'Este email está siendo enviado a',
            emailBody:'Aquí tu mensaje...',
        },
        crudActions:{
            update:'Editar',
            create:'Crear',
            delete:'Eliminar',
            archive:'Archivar',
            desactivate:'Desactivar',
            activate:'Activar'
        },
        inputFields:{
            autocomplete:{
                email:'Email inválido',
                clearText:'Limpiar',
                closeText:'Cerrar',
                openText:'Abrir',
                loadingText:'Cargando..'
            },
            
        },
        validations:{
            messages:{
                macAddress:'Mac address inválida',
                justNumbers:'Solo números',
                justText:'Solo texto',
                required:'Requerido',
                minLength:'Demasiado corto',
                maxLength:'Demasiado largo',
                allReadyExist:'Ya existe',
                pattern:'invalido'
            },
        },
        ui:{
            components:{
                viewMode:{
                    title:'Cambiar vista'
                },
                name:'Nombre'
            },
        },
        pages:{
            realTime:{
                sensors:'Sensores',
                equipments:'Equipos'
            }
        },
        login:{
            form:{
                label:{
                    user:'Email',
                    password:'Contraseña'
                }
            },
            navigation:{
                name:'Solicitar demo'
            },
            legend:{
                years:'Años'
            }
        },
        demo:{
            form:{
                completeName:'Nombre completo',
                phone:'Teléfono',
                company:'Compañía',
                email:'Email'
            },
            prospects:'Prospectos'
        },
        persons:{
            newSupervisor:'Nuevo supervisor',
            newOperator:'Nuevo operador',
            newAdministrator:'Nuevo administrador',
            newAccountManager:'Nuevo gerente regional',
            newClient:'Nuevo cliente',
            userAdministration:'Administrar usuarios',

            createstep1:'Crea un gerente regional',
            createstep2:'Asigna fábricas a su cargo',
            createFinishStep:'Todos los pasos completados',


            accountManager:'Gerente regional',
            accountManagers:'Gerentes regionales',

            referer:'Referente',
            phone:'Teléfono',
            password:'Contraseña',

            plural:'Personas',
            singular:'Persona',
            name:'Nombre',
            lastName:'Apellido',
            adress:'Dirección',
            email:'Email',
            client:'Cliente',
            clients:'Clientes',
            operator:'Lubricador',
            operators:'Lubricadores',
            supervisor:'Supervisor',
            supervisors:'Supervisores',
            superAdmin:'Super administrador',
            superAdmins:'Super administradores',
            admin:'Administrador',
            admins:'Administradoress',
        },
        language:{
            label:'Idioma',
            spanish:'Español',
            english:'Ingles',
        },
        formMessages:{
            required:'Requerido',
            float:'Solo Numeros',
            invalidEmail:'Email no válido'
        },
        stock:{
            product:'Producto',
            brand:'Marca',
            actualStock:'Stock actual',
            minimumRequired:'Mínimo',
            category:'Categoría',
            subcategory:'Subcategoría',
            measureUnit:'Unidad de medida',
            categoryArea:'Area de la categoría',
            providerArea:'Area del proveedor',
            complex:'Reposición compleja',
            hidden:'Archivados',
            options:{
                simple:'Simple',
                complex:'Compleja',
                archived:'Archivados',
                unArchived:'Sin archivar'
            }
        },
        actionTypes:{
            activate:'Activar',
            desactivate:'Desactivar',
            view:'Ver',
            apply:'Solicitar',
            login:'Ingresar',
            logout:'Salir',
            create:'Crear',
            deleteItem:'Eliminar',
            update:'Editar',
            assign:'Asignar',
            save:'Guardar',
            exit:'Salir',
            before:'atras',
            next:'Próximo'
        },
        lubricationPoints:{
            equipments:'Equipos',
            sector:'Sector',
            plantTag:'Tag planta ',
            lowReason:'Razón de baja',
            labelId:'Id de etiqueta',
            state:'Estado del punto',
            criticality:'Criticidad',
            egressDate:'Fecha de egreso',
            function:'Funcion',
            equipment:'Equipo',
            review:'Revision',
            location:'Ubicación',
            lubricantType:'Tipo de lubricante',
            routes:'Rutas',
            supplies:'Insumos',
            type:'Tipo',
            brand:'Marca',
            model:'Modelo',
            element:'Elemento',
            component:'Componente',
            lubricant:'Lubricante',
            capacity:'Capacidad',
            measureUnit:'Unidad de medida',
            oilAnalysis:'Analisis de aceite',
            admissionDate:'Fecha de admisión',
            observations:'Observaciones',
            tagTGDBefore:'tag TGD anterior',
            options:{
                active:'Activo',
                disabled:'Inactivo',
                all:'Todos',
                oilAnalisys:'Si',
                withoutOilAnalisys:'No'
            },
            helpers:{
                whyAreEditingThisPoint:'¿Porqué estas editando este punto?'
            },
            suggestedQuantity:'Cantidad sugerida'
        },
        infoLubricationPoints:{
            supplies:'Insumos faltantes',
            labelReplacement:'Reemplazo de etiqueta',
            lastLubrication:'Ultima lubricación',
            lubricantQuantity:'Cantidad de lubricante',
            leak:'Pérdida',
            pending:'Pendiente',
            updatedToday:'Actualizado hoy',
            user:'Usuario',
            lastSurveyId:'Id último relevamiento',
            assetCriticality:'Criticidad del activo',
            inaccessible:'Inaccesible',
            lastInspectionDate:'fecha de la última inspección',
            updateReview:'Cambio de revision',
            temperature:'Temperatura',
            leakLevelRange:'Rango de pérdida',
            withoutSupplies:'SIN INSUMOS',
            assetGeneralPuntuation:'Puntuación general del activo.',
            assetPriority:'Importancia del activo',
            lastEquipmentState:'ULTIMO ESTADO DEL EQUIPO',
            withLeak:'Con pédida' ,
            withoutLeak:'Sin pérdida',
            lastAgregate:'ULTIMO AGREGADO',
            notPending:'Al Día',
            intervenedToday:'SE LO INTERVINO HOY',
            lastInspection:'ULTIMA INSPECCION',
            accesible:'Accesible',
            lastTemperature:'ULTIMA TEMPERATURA',
            missings:'FALTANTES',
            capacity:'CAPACIDAD',
            equipmentinformation:'Información del equipo',
            lastSurveys:'Últimos relevamientos'
        },
        types:{
            plural:'Tipo',
            singular:'Tipos',
            form:{
                name:'Nombre',
                type:'Tipo'
            }
        },
        factories:{
            manageFactories:'Manejar los accesos a fábrica',
            noFactories:'No se ha encontrado ninguna fábrica',
            accountManagerFactories:'Fábricas del manager regional',
            singular:'Fábrica',
            plural:'Fábricas',
            location:'ubicación',
            name:'Nombre fábrica',
            businessName:'Razón social',
            referrer:'Referente',
            tagF:'Tag de fábrica'
        },
        plants:{
            asignCancelPlants:'Administrar acceso a plantas',
            clickToAssign:'Click para asignar',
            clickToCancel:'Click para cancelar',
            factory:'Fábrica',
            singular:'Planta',
            plural:'Plantas',
            factoryId:'Id de fábrica',
            location:'Dirección',
            name:'Nombre',
            plantId:'Id de planta',
            referrer:'Referente',
            tagFP:'Tag de planta Tgd',
            state:'Estado',
        },
        observations:{
            singular:'Observación',
            plural:'Observaciones',
            surveyId:'Id relevamiento',
            equipment:'Id equipo',
            modified:'Modificdo',
            modifiedDate:'Fecha modificación',
            observationDate:'Fecha de la observación',
            OTnumber:'Nº OT',
            state:'Estado',
            taskId:'Id tarea',
            taskRequestId:'Id solicitud de tarea',
            taskType:'Tipo de tarea',
            type:'Tipo',
            element:'Elemento',
            component:'Componente',
            user:'Usuario'
        },
        addedReasons:{
            plural:'Motivos agregados',
            singular:'Motivo agregado',
            reason:'Nombre motivo agregado'
        },
        anomalies:{
            singular:'Anomalía',
            plural:'Anomalias',
            anomalyName:'Nombre de anomalia'
        },
        news:{
            user:'Usuario',
            criticality:'Criticidad',
            singular:'Novedad',
            plural:'Novedades',
            sector:'Sector de la novedad',
            area:'Area',
            equipment:'Equipo',
            type:'Tipo de novedad',
            newsDate:'Fecha de novedad',
            description:'Descripción',
        },
        criticalities:{
            title:{
                singular:'Criticidad',
                plural:'Criticidades'
            },
            form:{
                descripton:'Nombre',
                number:'Nº de prioridad',
                periodicity:'Periodicidad',
                color:'Color',
                type:'Tipo de criticidad',
                days:'Prioridad en días'
            }
        },
        collectors:{
            plural:'Colectores',
            singular:'Colector',
            activate:'Activar colector',
            desactivate:'Desactivar colector',
            desactivatedColectorWarning:'Este colector está desactivado',
            form:{
                active:'Activo',
                disabled:'Desactivado',
                id:'Id',
                name:'Nombre',
                serialNumber:'Numero de serie',
                collectorCode:'Código de colector',
                tagFP:'Planta',
                state:'Estado',
                admissionDate:'Fecha de ingreso',
                egressDate:'Fecha de Egreso',
            }
        },
        lubricantForm:{
            singular:'Lubricante',
            plural:'Lubricantes',
            type:'Tipo de lubricante',
            name:'Nombre',
            brand:'Marca',
            measureUnit:'Unidad de medida',
            requiresObservation:'Requiere observacion'
        },
        incomeForm:{
            singular: "Ingreso",
            plural: "Ingresos",
            lotNumber: "Número de lote",
            quantity: "Cantidad",
            admissionDate: "Fecha de ingreso",
            referrer: "Referente",
            confirmMessage: "¿Está seguro de que desea eliminar este ingreso?"
        },
        containers:{
            title:{
                singular:'Recipiente',
                plural:'Recipientes',
            },
            form:{
                name:'',
                label:{
                    name:'Nombre',
                    lubricantType:'Tipo Lubricante',
                    lubricant:'Lubricante',
                    capacity:'Capacidad',
                    coefficient:'Coeficiente',
                    code:'Código',
                    type:'Tipo',
                    capacityHelper:'La capacidad está basada en la unidad de medida del lubricante',
                }
            }
        },
        sectors:{
            title:{
                singular:'Sector',
                plural:'Sectores',
            },
            form:{
                name:'',
                label:{
                    sector: 'Sector en planta',
                    sectorName: 'Sector',
                    code: 'Código',
                }
            }
    
        },
        areas:{
            title:{
                singular:'Área',
                plural:'Áreas',
            },
            form:{
                name:'',
                label:{
                    area: 'Nombre área',
                    email: 'Emails',
                    emailHelper:'Se pueden asignar varios emails, separados por coma y sin espacios',
                    referrer: 'Nombre referente',
                }
            }
    
        },
        components:{
            title:{
                singular:'Componente',
                plural:'Componentes',
            },
            form:{
                name:'',
                label:{
                    component: 'Nombre',
                    code: 'Código',
                }
            }
        },
        elements:{
            title:{
                singular:'Elemento',
                plural:'Elementos',
            },
            form:{
                name:'',
                label:{
                    element: 'Nombre',
                    code: 'Código',
                }
            }
        },
        supplies:{
            errorGetingSupplies:'Ha ocurrido un error consultando los insumos de esta planta',
            title:{
                singular:'Insumo',
                plural:'Insumos',
                form:{
                    name:'',
                    label:{
                        type: 'Tipo',
                        supply: 'Nombre',
                        description:'Descripción'
                    }
                }
            },
            HERRAMIENTA:{
                manageLubricationPointSupply:'Gestionar herramientas del punto:',
                title:{
                    singular:'Herramienta',
                    plural:'Herramientas',
                },
                form:{
                    name:'',
                    label:{
                        type: 'Tipo',
                        supply: 'Nombre',
                        description:'Descripción de la herramienta '
                    }
                }
            },
            CONSUMIBLE:{
                manageLubricationPointSupply:'Gestionar consumibles del punto:',
                title:{
                    singular:'Consumible',
                    plural:'Consumibles',
                },
                form:{
                    name:'',
                    label:{
                        type: 'Tipo',
                        supply: 'Nombre',
                        description:'Descripción del consumible '
                    }
                }
            },
            PROTECCION:{
                manageLubricationPointSupply:'Gestionar protecciones del punto:',
                title:{
                    singular:'Proteccion',
                    plural:'Protecciones',
                },
                form:{
                    name:'',
                    label:{
                        type: 'Tipo',
                        supply: 'Nombre',
                        description:'Descripción de la protección '
                    }
                }
            },
            OBSERVACION:{
                manageLubricationPointSupply:'not Setted',
                title:{
                    singular:'Observacion',
                    plural:'Observaciones',
                },
                form:{
                    name:'',
                    label:{
                        type: 'Tipo',
                        supply: 'Nombre',
                        description:'Descripción de la observaciones'
                    }
                }
            }
        },
        permissions:{
            plural:'Permisos',
            singular:'Permiso',
            permissionName:'Nombre del permiso',
            description:'Descripcion',
            code:'codigo',
            area:'Area',
            sector:'Sector',
            sectorHelper:'Convierte este permiso en un permiso global para el sector seleccionado (todos los equipos del sector requeriran este permiso),de lo contrario al dejar este campo vacio podras usar este permiso de manera aislada',
            areaHelper:'Ésta es el área a la cual se le debera pedir la solicitud o la emision del permiso solicitado'
        },
        routes:{
            scheduledDate:'Fecha programada',
            newScheduledDate:'Nueva fecha programada',
            special:'Ruta especial',
            singular:'Ruta',
            plural:'Rutas',
            operator:'Operador',
            periodicity:'Periodicidad en días',
            route:'Ruta',
            routeName:'Nombre de ruta',
            startDate:'Fecha de inicio',
            state:'Estado',
            manageEquipmentRoutes:'Gestionar rutas del equipo',
            getRoutesError:'Ha ocurrido un error al consultar las rutas de esta planta',
            assignRouteToEquipment:'Asignar rutas al equipo:',
            validations:{
                allRouteExistance:'Esta ruta ya existe para todos los operadores de esta planta',
                lubricatorRouteExistance:'Este nombre de ruta ya existe para el lubricador',
                ableToCreateRoute:'Solo podra crearla para lubricadores faltantes',

            }
        },
        surveys:{
            options:{
                impossibleToLubricate:'IMPOSIBLE DE LUBRICAR',
                normal:'NORMAL',
                all:'TODOS',
                lubricates:'SI',
                withoutLubricate:'NO', 
                yes:'SI',
                no:'NO',
                outOfRoute:'FUERA DE RUTA',
                inRoute:'EN RUTA',
                inaccessible:'INACCESIBLE',
                accessible:'ACCESIBLE',
                manualCharge:'CARGA MANUAL',
                Colector:'COLECTOR',
                done:'REALIZADA',
                notDone:'SIN REALIZAR',
                neededLubrication:'SI',
                lubricationNotNeeded:'NO',
            },
            title: {
                plural: "Relevamientos",
                singular: "Relevamiento",
            },
            componentChange:'Cambio de componente',
            observationsQuantity:'Cantidad de observaciones',
            equipment:'Equipo',
            element:'Elemento',
            component:'Componente',
            sector:'Sector',
            review:'Revision',
            impossibleToLubricate:'Imposible de lubricar',
            lubricates:'Se lubricó',
            routeDate:'Fecha de Ruta',
            loadingDate:'Fecha de carga',
            startTime:'Inicio',
            endTime:'Fin',
            sampleExtraction:'Extracción de muestra',
            consumables:'Consumibles',
            lubricantType:'Tipo de lubricante',
            lubricant:'Lubricante',
            quantity:'Cantidad de lubricante',
            measureUnit:'Unidad de medida',
            reason:'Motivo',
            anomalies:'Anomalías',
            outOfRoute:'Fuera de ruta',
            inaccessible:'Inaccesible',
            cleaning:'Limpieza del punto',
            otNumber:'Nº de OT',
            news:'Novedades',
            criticality:'Criticidad',
            user:'Usuario',
            route:'Ruta',
            manualRegistration:'Registro manual',
            areas:'Area',
            taskType:'Tipo de Tarea',
            taskTime:'Tiempo de tarea',
            taskDone:'Tarea realizada',
            assetCriticality:'Criticidad del activo',
            needsLubrication:'Necesitó lubricación',
            temperature:'Temperatura'
        },
        iotDevices:{

            actions:{
                desactivate:'Desactivar dispositivo',
                activate:'Activar dispositivo'
            },
            helpers:{
                youAreDesactivating:'Estás desactivando:',
                youAreActivating:'Estás activando:',
                youCanNotWorkWithDifrentKindOfItemStates:'No puedes trabajar sobre un grupo de dispositivos que tegan diferentes estados',
                selectedItems:'Items seleccionados',
                cleanState:'Seguir trabajando',
                dontForgetToSpecifyLowReason:'No olvides especificar una nueva razón de baja',
            },
            title:{
                singular:'Dispositivo',
                plural:'Dispositivos'
            },
            form:{
                label:{
                    device:'Nombre dispositivo',
                    deviceCode:'Mac address',
                    admissionDate:'Fecha de admisión',
                    egressDate:'Fecha de egreso',
                    state:'Desactivado',
                    location:'Ubicación',
                    lowReason:'Motivo de baja',
                    batteryCapacity:'Capacidad de batería',
                    lowBatteryAlarm:'Alarma de batería baja',
                }
            }
        },
        iotSensorTypes:{
            title:{
                singular:'Tipo de sensor',
                plural:'Tipos de sensores'
            },
            form:{
                label:{
                    sensorType:'Tipo de sensor',
                    sensorTypeCode:'Código',
                }
            }
        },
        STISensors:{
            title:{
                singular:'Sensor',
                plural:'Sensores'
            },
            form:{
                label:{
                    sensorType:'Tipo de Sensor',
                    sensorTypeCode:'Código del tipo de sensor',
                    sensorAddress:'Mac address',
                    channelQuantity:'Cantidad de canales',
                    permanent:'Ubicación permamnente',
                    batteryCapacity:'Valor de batería máximo',
                }
            },
            booleanSelector:{
                data:{
                    fixed:'PERMANENTE',
                    mobile:'TEMPORAL',
                }
            }
        },
        STIEquipments:{
            title:{
                singular:'Equipo ',
                plural:'Equipos STI'
            },
            form:{
                label:{
                    equipment:'Equipo',
                    labelId:'Nº de etiqueta',
                    sector:'Sector',
                    plantTag:'Tag de planta',
                    tagSTI:'Tag STI',
                    type:'Tipo',
                    brand:'Marca',
                    model:'Modelo',
                    function:'Función',
                    location:'Ubicación',
                    observations:'Observaciones',
                    admissionDate:'Fecha de admision',
                    egressDate:'Fecha de egreso',
                    state:'Estado',
                    lowReason:'Motivo de baja',
                    criticality:'Criticidad',
                }
            },
        },
        STISensorizedEquipment:{
            title:{
                singular:'Sensorización',
                plural:'Sensorizaciones',
            },
            form:{
                label:{
                    sensorTypeCode:'Código del typo de sensor',
                    vibration:'Vibración',
                    sensorType:'Tipo de sensor',
                    sensorAddress:'Mac address del sensor',
                    iotDeviceCode:'Codigo Gateway',
                    plantTag:'Tag de planta',
                    equipment:'Equipo',
                    tagSTI:'Tag STI',
                    element:'Elemento',
                    sector:'Sector',
                    location:'Ubicación',
                    criticality:'Criticidad',
                    observations:'Observaciones',
                    state:'Estado',
                    lowReason:'Motivo de baja',
                    admissionDate:'Fecha de admisión',
                    egressDate:'Fecha de egreso',
                    temperature:'Temperatura',
                    xAxis:'Eje X',
                    yAxis:'Eje Y',
                    zAxis:'Eje Z',
                    channels:'Canales',
                    pressure:'Presion',
                }
            }
        },
        reports:{
            report:'Reporte',
            reports:'Reportes',
            equipmentInfo:'Información del punto',
            Observations:'Observaciones',
            surveys:'Relevamientos',
            actions:'Acciones',
            lubricationPoints:'Puntos de lubricación',
            equipmentOnDate:'Punto de lubricación al día',
            routes:'Rutas'
        }
    }

    const en:language ={
        stringDefaultValues:{
            withOutSupplies:'WITHOUT SUPPLIES',
            notSpecified:'NOT SPECIFIED',
            withOutRoutes:'WITHOUT ROUTES',
            withoutObservations:'WITHOUT OBSERVATIONS'
        },
        words:{
            info:'Info',
            and:'and',
            of:'of',
            thisMasc:'this',
            thisfem:'this',
            available:'available',
            availables:'availables',
            more:'more',
            all:'All',
            yes:'Yes',
            no:'No',
            active:'Active',
            inactive:'Inactive',
            request:'Request'
        },
        globalentityValues:{
            id:'Id',
            tagFP:'Plant',
            tagSTI:'STI Tag',
            tagTGD:'TGD Tag',
            date:'Date',
            identifier:'Identifier'
        },
        messages:{
            nothingSelected:'Nothing selected',
            abilityNotAllowed:'you do not have the necessary permissions to use this function',
            featureNotAvailableOffline:'This feature is an online only feature',
            featureNotAvailableOnline:'This feature is a local only feature',
            lastTenSurveys:'last 10 surveys',
            lastTenObservations:'last 10 observations',
            latestIncome: 'Latest Income',
            surveyObservations:'Survey observations',
            lubricationPointObsevetions:'Lubrication point observations',
            features:{
                collectorConfig:'We sorry! Soon you will be able to sync and config your new TGD Collector',
                supervisorSurvey:'We sorry! Soon you will be able to survey as Lubricator user',
                supervisorClientVisor:'We sorry! Soon you will be able to se your client dashboard'
            },
            status:{
                success:'Everithing it`s been successfully',
                error:'we found a problem',
                loading:'loading', 
            }
        },
        collectorSync:{
            title:'Device sync panel',
            itWasFind:'It wasn found', 
            databasesWithProblems: 'databases wiith this problems',
            errors:{
                /* [SyncFileErrors.Corrupted]:'Corrupted', */
                [SyncFileErrors.failed]:'Failed',
                [SyncFileErrors.expired]:'Expired'
            },
            helpers:{
                /* [SyncFileErrors.Corrupted]:'The data base is broken.', */
                [SyncFileErrors.failed]:'Syncronization has failed, contact support to fix this issue.',
                [SyncFileErrors.expired]:'Database is more than 7 days older, it considered too old to make impact in the TGD database, contact support to fix this problem.',
                unknown:'Unknown error.'
            }
        },
        grid:{
            actionsAlert:'Select one or more items to work.',
            noRowsLabel: 'No data',
            errorOverlayDefaultLabel: 'An error has occourred',
            toolbarColumns: 'Columns',
            toolbarColumnsLabel: 'Select Columns',
            toolbarFilters: 'Filters',
            toolbarFiltersLabel: 'Show Filters',
            toolbarFiltersTooltipHide: 'Hide filters',
            toolbarFiltersTooltipShow: 'Show Filters',
            columnsPanelTextFieldLabel: 'Search column',
            columnsPanelTextFieldPlaceholder: 'Column title',
            columnsPanelDragIconLabel: 'Reorder Column',
            columnsPanelShowAllButton: 'Show all',
            columnsPanelHideAllButton: 'Hide all',
            toolbarExport: 'Export',
            toolbarExportLabel: 'Export',
            toolbarExportCSV: 'Download Csv',
        },
        filters:{
            title:'Search and filter',
            addFilter:'Add filter',
            deleteFilters:'Delete filters',
            add:'Add',
            searchByTerm:'Search by term',
            closeFilters:'Close filters',
            downloadData:'Download data as .xlsx ',
            filterData:'Filter data',
            all:'All',
            select :'Select',
            resetDateRange:'Clear date range',
            queryDateRange:'Query date range ',
            from:'from',
            to:'to',
            and:'AND',
            andHelper:'"AND" filter permits specify data entries to obtain an exact data group that matches every "AND" filter',
            or:'OR',
            orHelper:'"OR" filters permits accumulate data entries to obtain mutch larger data matches',
        
        },
        sendEmail:{
            title:'Send via email',
            att:'att.',
            charge:'Lubrication area, TGD company',
            recipient:'This email will be sent to',
            emailBody:'Your message here...',
        },
        crudActions:{
            update:'Update',
            create:'Create new',
            delete:'Delete',
            archive:'Archive',
            desactivate:'Desactivate',
            activate:'Activate'
        },
        inputFields:{
            autocomplete:{
                email:'invalid email',
                clearText:'Clear',
                closeText:'Close',
                openText:'Open',
                loadingText:'Loading..'
            },
            
        },
        validations:{
            messages:{
                macAddress:'Invalid MAC address',
                justNumbers:'Just numbers',
                justText:'Just text',
                required:'Required',
                minLength:'To short',
                maxLength:'To long',
                allReadyExist:'All ready exist',
                pattern:'invalid'
            },
        },
        ui:{
            components:{
                viewMode:{
                    title:'ChangeView'
                },
                name:'Name'
            },
            
        },
        pages:{
            realTime:{
                sensors:'sensors',
                equipments:'equipments'
            }
        },
        login:{
            form:{
                label:{
                    user:'Email',
                    password:'Password'
                }
            },
            navigation:{
                name:'Get trial demo'
            },
            legend:{
                years:'years'
            }
        },
        demo:{
            form:{
                completeName:'Complete name',
                phone:'Phone',
                company:'Company',
                email:'Email'
            },
            prospects:'Prospects'
            
        },
        persons:{
            newSupervisor:'New supervisor',
            newOperator:'New operator',
            newAdministrator:'New administrator',
            newAccountManager:'New account manager',
            newClient:'New client',
            userAdministration:'Manage users',

            accountManager:'Account manager',
            accountManagers:'Account managers',

            createstep1:'add a account mannager',
            createstep2:'Manage factories at his name',
            createFinishStep:'All steps finished',
            
            referer:'Referer',
            phone:'Phone',
            password:'Password',
            plural:'Persons',
            singular:'Person',
            name:'Name',
            lastName:'Last name',
            adress:'Adress',
            email:'Email',
            client:'Client',
            clients:'Clients',
            operator:'Lubricator',
            operators:'Lubricators',
            supervisor:'Supervisor',
            supervisors:'Supervisors',
            superAdmin:'Super Administrator',
            superAdmins:'Super Administrators',
            admin:'Administrator',
            admins:'Administrators',
        },
        language:{
            label:'Language',
            spanish:'Spanish',
            english:'English',
        },
        formMessages:{
            required:'Required',
            float:'Only a number',
            invalidEmail:'Invalid email'
        },
        stock:{
            product:'Product',
            brand:'Brand',
            actualStock:'Anual Stock',
            minimumRequired:'Minimum',
            category:'Category',
            subcategory:'Sub category',
            measureUnit:'Meassure unit',
            categoryArea:'Category area',
            providerArea:'Provider Area',
            complex:'Complex',
            hidden:'hidden',
            options:{
                simple:'Simple reposition',
                complex:'Complex reposition',
                archived:'Hidden',
                unArchived:'Showed'
            }
        },
        actionTypes:{
            activate:'Activate',
            desactivate:'Desactivate',
            view:'View',
            apply:'Apply',
            login:'Login',
            logout:'Logout',
            create:'Create',
            deleteItem:'Delete',
            update:'Update',
            assign:'Assign',
            save:'save',
            exit:'Exit',
            before:'Before',
            next:'Next'
        },
        factories:{
            manageFactories:'manage access to factories',
            noFactories:'No items found',
            accountManagerFactories:'Account manager factories',
            singular:'Factory',
            plural:'Factories',
            location:'Location',
            name:'FactoryName',
            businessName:'Business name',
            referrer:'Referer',
            tagF:'Factory tag'
        },
        plants:{
            asignCancelPlants:'Manage allowed plants',
            clickToAssign:'Click to assign',
            clickToCancel:'Click to cancel',
            factory:'Factory',
            singular:'Plant',
            plural:'Plants',
            factoryId:'Factory id',
            location:'Adress',
            name:'Name',
            plantId:'PlantId',
            referrer:'Referrer',
            tagFP:'Tgd plant tag',
            state:'State',
        },
        types:{
            plural:'Type',
            singular:'Types',
            form:{
                name:'Name',
                type:'Type'
            }
        },
        lubricationPoints:{
            equipments:'Equipments',
            sector:'Sector',
            plantTag:'Plant tag ',
            lowReason:'Low Reason',
            labelId:'Label id',
            state:'Lubrication point State',
            criticality:'criticality',
            egressDate:'Egress date',
            function:'Function',
            equipment:'Equipment',
            review:'Review',
            location:'Location',
            lubricantType:'Lubricant type',
            routes:'Routes',
            supplies:'supplies',
            type:'Type',
            brand:'Brand',
            model:'Model',
            element:'Elment',
            component:'Component',
            lubricant:'Lubricant',
            capacity:'Capacity',
            measureUnit:'Meassure unit',
            oilAnalysis:'Oil analysis',
            admissionDate:'Admission date',
            observations:'Obsevations',
            tagTGDBefore:'Tag TGD before',
            options:{
                active:'Active',
                disabled:'Disabled',
                all:'All',
                oilAnalisys:'Yes',
                withoutOilAnalisys:'No'
            },
            helpers:{
                whyAreEditingThisPoint:'¿Why are you editin this component?'
            },
            suggestedQuantity:'Sugested quantity'
        },
        infoLubricationPoints:{
            supplies:'missing supplies',
            labelReplacement:'labelReplacement',
            lastLubrication:'lastLubrication',
            lubricantQuantity:'lubricantQuantity',
            leak:'leak',
            pending:'pending',
            updatedToday:'updatedToday',
            user:'user',
            lastSurveyId:'lastSurveyId',
            assetCriticality:'assetCriticality',
            inaccessible:'inaccessible',
            lastInspectionDate:'lastInspectionDate',
            updateReview:'updateReview',
            temperature:'temperature',
            leakLevelRange:'Leak level range',
            withoutSupplies:'WITHOUT SUPPLIES',
            assetGeneralPuntuation:'Asser general puntuation.',
            assetPriority:'Asset importance',
            lastEquipmentState:'LAST EQUIPMENT STATE',
            withLeak:'With leak' ,
            withoutLeak:'Without leak',
            lastAgregate:'LAST AGREGATE',
            notPending:'UP TO DATE',
            intervenedToday:'INTERVENED TODAY',
            lastInspection:'LAST INSPECTION',
            accesible:'Accessible',
            lastTemperature:'LAST TEMPERATURE',
            missings:'MISSINGS',
            capacity:'CAPACITY',
            equipmentinformation:'EQUIPMENT INFORMATION',
            lastSurveys:'Last surveys'
        },
        observations:{
            singular:'Observation',
            plural:'Observations',
            surveyId:'Survey Id',
            equipment:'Equipment id',
            modified:'Modified',
            modifiedDate:'Modified date',
            observationDate:'Observation date',
            OTnumber:'OT number',
            state:'State',
            taskId:'Task id',
            taskRequestId:'Task request id',
            taskType:'Task type',
            type:'Type',
            element:'Element',
            component:'Component',
            user:'User'
        },
        addedReasons:{
            plural:'Added reasons',
            singular:'Added reason',
            reason:'Added reason name'
        },
        anomalies:{
            singular:'Anomaly',
            plural:'Anomalies',
            anomalyName:'Anomaly name'
        },
        news:{
            singular:'New',
            plural:'News',
            sector:'New Sector',
            area:'Area',
            equipment:'Lubrication point',
            type:'New type',
            newsDate:'New date',
            description:'Description',
            user:'User',
            criticality:'Criticality',
        },
        collectors:{
            plural:'Collectors',
            singular:'Collector',
            activate:'Activate collector',
            desactivate:'Desactivate collector',
            desactivatedColectorWarning:'This collector is desactivated',
            form:{
                active:'Activated',
                disabled:'Desactivated',
                id:'Id',
                name:'name',
                serialNumber:'Serial number',
                collectorCode:'Collector',
                tagFP:'Plant',
                state:'State',
                admissionDate:'Admission date',
                egressDate:'Egress date',
            }
        },
        lubricantForm:{
            singular:'Lubricant',
            plural:'Lubricants',
            type:'Type',
            name:'Name',
            brand:'Brand',
            measureUnit:'Measure unit',
            requiresObservation:'Requires observation'
        },
        incomeForm:{
            singular: "Income",
            plural: "Incomes",
            lotNumber: "Lot number",
            quantity: "Quantity",
            admissionDate: "Admission date",
            referrer: "referrer",
            confirmMessage: "Are you sure you want to delete this income?"
        },
        criticalities:{
            title:{
                singular:'Criticality',
                plural:'Criticalities'
            },
            form:{
                descripton:'Name',
                number:'Priority number',
                periodicity:'Periodicity',
                color:'Color',
                type:'Criticality Type',
                days:'Priority in days'
            }
        },
        containers:{
            title:{
                singular:'Container',
                plural:'Containers',
            },
            form:{
                name:'',
                label:{
                    name:'Name',
                    lubricantType:'LubricantType',
                    lubricant:'Lubricant',
                    capacity:'Capacity',
                    coefficient:'Coefficient',
                    code:'Code',
                    type:'Type',
                    capacityHelper:'Capacity unit need to match lubricant meassure unit',
                }
            }
        },
        sectors:{
            title:{
                singular:'Sector',
                plural:'Sectors',
            },
            form:{
                name:'',
                label:{
                    sector: 'Plant sector',
                    sectorName: 'Sector',
                    code: 'Code',
                }
            }
    
        },
        areas:{
            title:{
                singular:'Area',
                plural:'Areas',
            },
            form:{
                name:'',
                label:{
                    area: 'Area name',
                    email: 'Email list',
                    emailHelper:'you can write a few email coma separated, to send email to',
                    referrer: 'Referrer name',
                }
            }
    
        },
        components:{
            title:{
                singular:'Compoent',
                plural:'Components',
            },
            form:{
                name:'',
                label:{
                    component: 'Name',
                    code: 'Code',
                }
            }
        },
        elements:{
            title:{
                singular:'Element',
                plural:'Elements',
            },
            form:{
                name:'',
                label:{
                    element: 'Name',
                    code: 'Code',
                }
            }
        },
        supplies:{
            errorGetingSupplies:'Error fetching plants supplies',
            title:{
                singular:'Supply',
                plural:'Supplies',
                form:{
                    name:'',
                    label:{
                        type: 'Type',
                        supply: 'Name',
                        description:'Description'
                    }
                },
            },
            HERRAMIENTA:{
                manageLubricationPointSupply:'Manage tools of:',
                title:{
                    singular:'Tool',
                    plural:'Tools',
                },
                form:{
                    name:'',
                    label:{
                        type: 'Type',
                        supply: 'Name',
                        description:'Tool description'
                    }
                }
            },
            CONSUMIBLE:{
                manageLubricationPointSupply:'Manage consumable of:',
                title:{
                    singular:'Consumable',
                    plural:'Consumables',
                },
                form:{
                    name:'',
                    label:{
                        type: 'type',
                        supply: 'name',
                        description:'Consumable description'
                    }
                }
            },
            PROTECCION:{
                manageLubricationPointSupply:'Manage protection elements of',
                title:{
                    singular:'Protection',
                    plural:'Protections',
                },
                form:{
                    name:'',
                    label:{
                        type: 'Type',
                        supply: 'Name',
                        description:'Protection description'
                    }
                }
            },
            OBSERVACION:{
                manageLubricationPointSupply:'not setted',
                title:{
                    singular:'Observation',
                    plural:'Observations',
                },
                form:{
                    name:'',
                    label:{
                        type: 'Type',
                        supply: 'Name',
                        description:'Observation description'
                    }
                }
            }
        },
        permissions:{
            plural:'Permissions',
            singular:'Permission',
            permissionName:'Permission Name',
            description:'Description',
            code:'Code',
            area:'Area',
            sector:'Sector',
            sectorHelper:'Make this permission sector global (all components with the selected sector gonna require this permission)',
            areaHelper:'This will be the current permission issuance area'

        },
        routes:{
            scheduledDate:'scheduledDate',
            newScheduledDate:'newScheduledDate',
            special:'Special route',
            singular:'Route',
            plural:'Routes',
            operator:'Operator',
            periodicity:'Periodicity',
            route:'route',
            routeName:'Route name',
            startDate:'Start date',
            state:'State',
            manageEquipmentRoutes:'Manage lubrication point routes',
            getRoutesError:'An error occurred while fetching plant routes data',
            assignRouteToEquipment:'Assign routes to lubrication point:',
            validations:{
                allRouteExistance:'You can not create this rioute, because allredy exist for all of operators in the sistem',
                lubricatorRouteExistance:'This route exist for operators',
                ableToCreateRoute:'you can create it to another operators',

            }
        },
        surveys:{
            options:{
                impossibleToLubricate:'Impossible to lubricate',
                normal:'normal',
                all:'All',
                lubricates:'Lubricated',
                withoutLubricate:'Without lubricate', 
                yes:'yes',
                no:'no',
                outOfRoute:'Out of route',
                inRoute:'In route',
                inaccessible:'Inaccessible',
                accessible:'Accessible',
                manualCharge:'Manual charge',
                Colector:'Collector',
                done:'Done',
                notDone:'Not done',
                neededLubrication:'Neded lubrication',
                lubricationNotNeeded:'lubrication not neded',
            },
            title: {
                plural: "Surveys",
                singular: "Survey",
            },
            componentChange:'Component has changed',
            observationsQuantity:'ObservationQuantity',
            equipment:'Equipment',
            element:'Element',
            component:'Component',
            sector:'Sector',
            review:'Review',
            impossibleToLubricate:'Impossible to lubricate',
            lubricates:'Lubricates',
            routeDate:'Route date',
            loadingDate:'Loading date',
            startTime:'Start',
            endTime:'Finish',
            sampleExtraction:'Sample extraction',
            consumables:'Consumables',
            lubricantType:'LubricantTipe',
            lubricant:' Lubricant',
            quantity:'Lubricant quantity',
            measureUnit:'Meassure unit',
            reason:'Added reason',
            anomalies:'Anomalies',
            outOfRoute:'Out of ROute',
            inaccessible:'Inaccessible',
            cleaning:'Cleaning',
            otNumber:'OT Number ',
            news:'News',
            criticality:'Criticality',
                user:'User',
            route:'Route',
            manualRegistration:'Manual registrationm',
            areas:'Area',
            taskType:'Task type',
            taskTime:'Task time',
            taskDone:'Task done',
            assetCriticality:'Asset criticality',
            needsLubrication:'Needs lubrication',
            temperature:'Temperature'
        },
        iotDevices:{
            actions:{
                desactivate:'Desactivate device',
                activate:'Activate device'
            },
            helpers:{
                youAreDesactivating:'You are desactivating:',
                youAreActivating:'You are activating:',
                youCanNotWorkWithDifrentKindOfItemStates:'You can not work with items with difrent states',
                selectedItems:'Selected Items',
                cleanState:'Continue working',
                dontForgetToSpecifyLowReason:'Don`t forget to specify a new lowReason',
            },
            title:{
                singular:'Device',
                plural:'Devices'
            },
            form:{
                label:{
                    device:'Device name',
                    deviceCode:'Device code',
                    admissionDate:'Admission date',
                    egressDate:'Egress date',
                    state:'State',
                    location:'Location',
                    lowReason:'Low Reason',
                    batteryCapacity:'Battery capacity',
                    lowBatteryAlarm:' Low battery alarm',
                }
            }
        },
        iotSensorTypes:{
            title:{
                singular:'Sensor type',
                plural:'Sensor types'
            },
            form:{
                label:{
                    sensorType:'Sensor type',
                    sensorTypeCode:'Sensor type code',
                }
            }
        },
        STISensors:{
            title:{
                singular:'Sensor',
                plural:'Sensors'
            },
            form:{
                label:{
                    sensorType:'Sensor tipe',
                    sensorTypeCode:'Type sensor code',
                    sensorAddress:'MAC Address',
                    channelQuantity:'Chanel quantity',
                    permanent:'Permanet location',
                    batteryCapacity:'Max battery value',
                }
            },
            booleanSelector:{
                data:{
                    fixed:'PERMANENT',
                    mobile:'TEMPORARY',
                }
            }
        },
        STIEquipments:{
            title:{
                singular:'Equipment',
                plural:'STI Equipments'
            },
            form:{
                label:{
                    equipment:'Equipment',
                    labelId:'Label Id',
                    sector:'Sector',
                    plantTag:'Plant Tag',
                    tagSTI:'Tag STI',
                    type:'Type',
                    brand:'Brand',
                    model:'Model',
                    function:'Function',
                    location:'Location',
                    observations:'Observation',
                    admissionDate:'Admission date',
                    egressDate:'Egress date',
                    state:'State',
                    lowReason:'LowReason',
                    criticality:'Criticidad',
                }
            },
        },
        STISensorizedEquipment:{
            title:{
                singular:'Sensorization',
                plural:'Sensorizations',
            },
            form:{
                label:{
                    sensorTypeCode:'sensorTypeCode',
                    vibration:'Vibration',
                    sensorType:'SensorType',
                    sensorAddress:'Sensor`s MAC Address',
                    iotDeviceCode:'Gateway Code',
                    plantTag:'Plant Tag',
                    equipment:'Equipment',
                    tagSTI:'Tag STI',
                    element:'Element',
                    sector:'Sector',
                    location:'Location',
                    criticality:'Criticality',
                    observations:'Observations',
                    state:'State',
                    lowReason:'Low reason',
                    admissionDate:'Admission date',
                    egressDate:'Egress date',
                    temperature:'Temperature',
                    xAxis:'X axis',
                    yAxis:'Y axis',
                    zAxis:'Z axis',
                    channels:'chanels',
                    pressure:'Pressure',
                }
            }
        },
        reports:{
            report:'Report',
            reports:'Reports',
            equipmentInfo:'Lubrication point info',
            Observations:'observations',
            surveys:'Reports',
            actions:'Actions',
            lubricationPoints:'Lubrication Points',
            equipmentOnDate:'Lubrication point on date',
            routes:'Routes'
        }
    }

    switch (lang) {
        case 'es':
            return es

        case 'en':
            return en

        default:
            return es
    }

}