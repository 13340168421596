import { LocalLaundryService } from "@material-ui/icons"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { apiCall, httpRequest } from "../../../shared/axios/axios-config"
import { CrudFormType } from "../../../shared/form/models/FormTypes"
import { PrivateQuery, PrivateQueryData, PrivateQueryDataTagFPInterface, PrivateQueryTagFPInterface } from "../../../shared/globals/utilsGlobalTypes"
import { useLangLabels } from "../../../shared/lang/services/useLangLabels"
import { useStatusProcessor } from "../../../shared/queries/StatusProcessor"
import { queryClient, useMutation, useQuery } from "../../../shared/react-query/react-query-conf"
import { MutationQueryObject } from "../../../shared/react-query/react-query-mutation-types"
import { useToken } from "../../session/store/sessionStore"
import { useLocalDeviceData } from "../../SyncBox/services/sincBoxServices"
import { CollectorInterface } from "../models/CollectorInterfaces"



const GetCollectorsByTagFP = ({tagFP,token}:PrivateQueryTagFPInterface) => {
    return apiCall({
        headers:{
            'Authorization':`Bearer ${token}`,
            tagFP
        },
        url:`/CollectorsByTagFP`,
    })
}


const CreateCollector = ({data,token}:PrivateQueryData) => {
    return apiCall({
        method:'POST',
        url:`/CreateCollector`,
        headers:{
            'Authorization': `Bearer ${token}`
        },           
        data
    })
}

const UpdateCollector = ({data,token}:PrivateQueryData) => {
    return apiCall({
        method:'POST',
        url:`/UpdateCollector`,
        headers:{
            'Authorization': `Bearer ${token}`
        },           
        data
    })
}





/*  -------------------------*/
/* ------------------QUERIES */
/* --------------------------*/

export const useCollectorsByTagFP = (tagFP:string) =>{
    
    const token = useToken()
    const {data:localData} = useLocalDeviceData()
    
    const allowed = [!!localData?.ip, !!token, !!tagFP].includes(false) ? false : true
    
    const collectorsQuery = useQuery({
        queryKey:'CollectorsByTagFP',
        enabled:allowed,
        queryFn:()=>GetCollectorsByTagFP({tagFP,token}),
        select:(data:CollectorInterface[] | undefined)=>{
            return data?.map((colector)=>{
                const result: (CollectorInterface & { ip:string }) = {
                    ...colector,
                    ip:localData?.ip!
                }
                return result 
            })
        }
    })
    
    const status = useStatusProcessor(collectorsQuery)

    return{
        ...collectorsQuery,
        ...status,
        
    }
}




/*  ---------------------------*/
/* ------------------MUTATIONS */
/* ----------------------------*/
export const useCreateUpdateCollector = ({tagFP,type,item}:{tagFP:string,type:CrudFormType,item?:CollectorInterface}) =>{

    const token = useToken()

    const createQuery = useMutation(CreateCollector,{
        onSuccess:()=>{
            queryClient.invalidateQueries('CollectorsByTagFP')
            queryClient.invalidateQueries('AllCollectors')
        }
    })

    const updateQuery = useMutation(UpdateCollector,{
        onSuccess:()=>{
            queryClient.invalidateQueries('CollectorsByTagFP')
            queryClient.invalidateQueries('AllCollectors')
        }
    })

    const query = ( type === CrudFormType.create)
    ? createQuery 
    : updateQuery


    const { lang } =  useLangLabels()

    const status = useStatusProcessor(query)

    const {register, setValue, handleSubmit, watch, errors, reset} = useForm()

    useEffect(()=>{
        //registerDefault
        register('id',{required:{value:true,message:lang.validations.messages.required}})
        register('tagFP',{required:{value:true,message:lang.validations.messages.required}})
        register('name',{required:{value:true,message:lang.validations.messages.required}})
        register('serialNumber',{required:{value:true,message:lang.validations.messages.required}})
        register('collectorCode')
        register('state',{required:{value:true,message:lang.validations.messages.required}})
       

        if(item){
            switch (type) {
                case CrudFormType.update:
                    //register
                    register('admissionDate',{required:{value:true,message:lang.validations.messages.required}})
                    register('egressDate',{required:{value:true,message:lang.validations.messages.required}})
                    //setDefault
                    setValue('id',item.id)
                    setValue('name',item.name)
                    setValue('serialNumber',item.serialNumber)
                    setValue('collectorCode',item.collectorCode)
                    setValue('state',item.state)
                    setValue('admissionDate',item.admissionDate)
                    setValue('egressDate',item.egressDate)
                    break
                }
        }

        //SetDefault
        setValue('id',0)
        setValue('tagFP',tagFP)
        setValue('state',true)

    },[item, type])

    const handleCreateUpdateCollector = (data:CollectorInterface) => {
        switch (type) {
            case CrudFormType.create:
                return createQuery.mutate({
                    token,
                    data
            })
            case CrudFormType.update:
                return updateQuery.mutate({
                    token,
                    data
            })
        } 
    }

    const handleReset = () =>{
        status.reset()
        query.reset()
        type === CrudFormType.create && reset()
    }

    return {
        handleCreateUpdateCollector,
        query:{
            ...query,
            ...status
        },
        handleSubmit,
        form:watch(),
        errors,
        setValue,
        reset:handleReset
    }

}