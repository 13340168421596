import React, { useEffect, useMemo, useState } from 'react'
import { CircularProgress, Divider, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography } from '@material-ui/core'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import SimpleAutocompleteSelect from '../../components/autocomplete/SimpleAutocompleteSelect'
import FlexContainer from '../../components/containers/FlexContainer'
import TgdTextField from '../../components/fields/TextField/TgdTextField'
import { useGenericAPICall } from './hooks/useGenericAPICall'
import EndpointsAutomationScripts, { ApiProcedure } from './scripts/EndpointsAutomationScripts'
import { executeRecursivePromise } from './utils/executeRecursivePromise'
import CustomButton, { CustomIcon, CustomIconType } from '../../components/buttons/CustomButton'
import ComponentMode from '../../components/componentMode/ComponentMode'
import CodeEditor from '@uiw/react-textarea-code-editor';
import PageContainer from '../../components/containers/PageContainer'
import { useModal } from '../../components/containers/modal/hooks/useModal'

interface PostManConfig  {
    name?:string,
    recursive?:boolean,
    endpoint?:string
}

type MethodType = 'POST' |'GET'

interface PostManAction {
    name?:string
    req:AxiosRequestConfig<any>,
    res:AxiosResponse<any>[],
    setBody:(data:any)=>void,
    setMethod:(data:MethodType)=>void,
    setEndpoint:(data:string)=>void,
    setIsRecursive:(data:boolean)=>void,
    isLoading:boolean,
    isRecursive:boolean
    submit:()=>Promise<any>
    nextRequestData:any,
    setNextRequestData:(value:any)=>void
}

const usePostMan = (config?:PostManConfig):PostManAction => {

    

    const call = useGenericAPICall()

    const initialReq:AxiosRequestConfig = {
        headers:undefined,
        method:'POST',
        data:[],
        url:''
    }

    const [isLoading,setIsLoading] = useState(false)
    const [recursive,setRecursive] = useState(config?.recursive !== undefined ? config.recursive : false)

    const [req,setReq] = useState({
        ...initialReq,
        url:`/${config?.endpoint}`
    })

    const [nextRequestData,setNextRequestData] = useState<any>()

    const [res,setRes] = useState<AxiosResponse[]>([])

    const send = async () => {
        
        let resAcc:any = []

        setIsLoading(true)
        if(recursive){
            executeRecursivePromise(
                req.data,(item)=>call({
                    ...req,
                    data:item
                }).then((response)=>{
                    resAcc.push({
                        res:response,
                        req:{
                            ...req,
                            data:item
                        }
                    } as any)
                }).catch((error)=>{
                    resAcc.push({
                        res:error,
                        req:{
                            ...req,
                            data:item
                        }
                    } as any)
                })
            )
            .then(()=>{
                setIsLoading(false)
                setRes(resAcc)
            })
            .catch((()=>{
                setRes(resAcc)
                setIsLoading(false)
            }))
            
        }else{
            return call(req)
            .then((res)=>{
                setRes(res)
                setIsLoading(false)

            }).catch((error)=>{
                setRes(error)
                setIsLoading(false)
            })
        }
    }

    const setBody = (body:any) => {
        setReq({
            ...req,
            data:body
        })
    }

    const setEndpoint = (endpoint:string) => {
        setReq({
            ...req,
            url:`${endpoint}`
        })
    }

    const setMethod = (method:MethodType) => {
        setReq({
            ...req,
            method
        })
    }

    const setIsRecursive = (value:boolean) => {
        setRecursive(value)
    }


    return{
        name:config?.name,
        req,
        res,
        setBody,
        setEndpoint,
        setIsRecursive,
        setMethod,
        isLoading,
        isRecursive:recursive,
        submit:send,
        nextRequestData,
        setNextRequestData
    }
}

const useCustomScript = (defaultFunction:string) => {

    const [params,setparams] = useState()
    const [makeCalculation,setMakeCalculation] = useState(false)
    const [fn,setFn] = useState<string>(defaultFunction)

    // eslint-disable-next-line no-new-func
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const adder = useMemo(() => new Function('params', `${fn}`),[makeCalculation])

    const handleAdder = (params:any) => {
        try{
            adder(params,fn)
            setMakeCalculation(false)
        }
        catch{
            alert('error en el código')
        }
    }

    const submit = (params:any) => {
        setparams(params)
        setMakeCalculation(true)
    }

    useEffect(()=>{
        if(makeCalculation) {
            handleAdder(params)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[makeCalculation,params])

    return {
        submit,
        setFn,
        fn
    }
}



const PostManAction:React.FC<PostManAction> = ({setBody,req, res, isLoading, isRecursive}) => {

    const TEXT_AREA_ROWS = 25

    const handleValueToInsert = (value: any) =>{
        setBody(JSON.parse(value as string))
    }

    const [makeCalculation,setMakeCalculation] = useState(false)
    const [fn,setfn] = useState(EndpointsAutomationScripts[0].script)
    const [fnResult,setfnResult] = useState<string>()

    // eslint-disable-next-line no-new-func
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const adder = useMemo(() => new Function('data', `${fn}`),[makeCalculation])

    useEffect(()=>{
        if(makeCalculation) {
            const result = adder(res,fn)
            setfnResult(JSON.stringify(result, null, "\t"))
            setMakeCalculation(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[makeCalculation,fn,res])


    return(
        <FlexContainer flexDirection='column' width={'100%'}>  
            <TgdTextField disabled value={req.url} onChange={()=>{}} />
            <FlexContainer>
                <FlexContainer align={'center'} padding='16px 0'>
                    <input disabled type="checkbox" defaultChecked={req.method === 'GET'} />
                    <label>GET</label>
                </FlexContainer>

                <FlexContainer align={'center'} padding='16px 0'>
                    <input disabled type="checkbox" defaultChecked={req.method === 'POST'}/>
                    <label>POST</label>
                </FlexContainer>

                <FlexContainer align={'center'} padding='16px 0'>
                    <input disabled type="checkbox" defaultChecked={isRecursive} />
                    <label>recursivo</label>
                </FlexContainer>

            </FlexContainer>

            <TgdTextField rows={TEXT_AREA_ROWS} label='Body' onChange={handleValueToInsert} />
                {isLoading ? <CircularProgress size='20px'/> :
                    <>
                        <TgdTextField value={JSON.stringify(res, null, "\t")} readonly rows={TEXT_AREA_ROWS} label='respuesta' onChange={()=>console.log()} />
                        <FlexContainer flexWrap='wrap' width='100%'>
                            <FlexContainer flexWrap='wrap' align='center' width='100%'  >
                                <SimpleAutocompleteSelect label='Seleccionar Scripts' items={EndpointsAutomationScripts} showTitle='name' onChange={(value)=>{
                                    const selectedScript = EndpointsAutomationScripts.find((item)=> item.name === value)
                                    setfn(selectedScript?.script!)
                                }}/>
                                <div>
                                    <CustomButton icon='lab' color='inherit' variant='iconButton' action={()=>setMakeCalculation(true)} />
                                </div>
                                <TgdTextField value={fn} rows={TEXT_AREA_ROWS} label='descubrir' onChange={(value)=>setfn(value.toString())} />
                            </FlexContainer>
                        </FlexContainer>
                        <TgdTextField value={fnResult} readonly rows={TEXT_AREA_ROWS} label='resultado' onChange={()=>console.log()} />
                        <br />
                    </>
                }
        </FlexContainer>
    )
}



const PostManProcedure:React.FC = () => {

    const actions = [
        usePostMan({
            name:'Crear survey',
            recursive:true,
            endpoint:'CreateSurvey'
        }),
        usePostMan({
            name:'Crear observaciones',
            recursive:false,
            endpoint:'CreateObservation'
        })
    ] 

    return (<>
        <FlexContainer padding={'0 24px'} flexDirection='column' >

            <List>
                {actions.map((action,index)=><>
                    <PostManActionR currentAction={action} nextAction={actions[index+1]}/>
                    <Divider/>
                </>)}
            </List>
            
        </FlexContainer>
    </>
    )
}

export default PostManProcedure


const CustomCodeEditor:React.FC<{value:string,onChange:(value:string)=>void}> = ({value,onChange}) => {
  return (
    <CodeEditor
      value={value}
      language="js"
      placeholder="Please enter JS code."
      autoFocus={true}
      onChange={(evn) => onChange(evn.target.value)}
      padding={15}
      minHeight={250}
      

      style={{
        whiteSpace:'nowrap',
        overflow:'auto',
        width:'100%',
        fontSize: 12,
        backgroundColor: "#f5f5f5",
        fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
      }}

    />
  );
}




const ApiQueryConfig:React.FC<{current:PostManAction,icon?:CustomIconType}> = ({current, icon='config'})=> {

    const {modal:open,handleModal:onClose} = useModal()

    return(
        <ComponentMode mode='drawer' drawerProps={{open,onClose,styles:{drawerWidth:'40vw'},anchor:'right'}} icon={icon} variant='iconButton'>
            <PageContainer >
                <FlexContainer>
                    <TgdTextField  value={current.req.url} onChange={(value)=>current.setEndpoint(value)}  />
                    <CustomButton icon='send' status={current.isLoading ? 'loading' : 'idle' } action={current.submit} />
                </FlexContainer>

                <FlexContainer >
                    <FlexContainer  padding='16px 0'>
                        <input type="checkbox" checked={current.req.method === 'GET'} onChange={()=>current.setMethod('GET')} />
                        <Typography color='secondary'>GET</Typography>
                    </FlexContainer>

                    <FlexContainer  padding='16px 0'>
                        <input  type="checkbox" checked={current.req.method === 'POST'} onChange={()=>current.setMethod('POST')} />
                        <Typography color='secondary'>POST</Typography>                    
                    </FlexContainer>

                    <FlexContainer padding='16px 0'>
                        <input  type="checkbox" checked={current.isRecursive} onChange={()=>current.setIsRecursive(!current.isRecursive)} />
                        <Typography color='secondary'>Recursivo</Typography>
                    </FlexContainer>

                </FlexContainer>


                <CustomCodeEditor {...{
                    value:JSON.stringify(current.req.data,null,2),
                    onChange:(value)=>current.setBody(JSON.parse(value))
                }}/>

                <br />

             

            </PageContainer>
            
        </ComponentMode>
    )
}

const ResponseAdapter:React.FC<{current:PostManAction, next?:PostManAction}> = ({current,next})=> {

    const {modal:open,handleModal:onClose} = useModal()

    const {submit:middleware,setFn:setMiddleware,fn } = useCustomScript(ApiProcedure[0].script)

    const handleMid = () => {
        middleware({
            current:{
                ...current,
            },
            next:next
        })
    }

    return(
        <ComponentMode mode='drawer' drawerProps={{open,onClose,styles:{drawerWidth:'80vw'},anchor:'right'}} icon='lab' variant='iconButton'>
            <PageContainer>
                <FlexContainer>
                    <FlexContainer flexDirection='column' width='50%' >
                        <Typography variant='h6' color='secondary'>Adaptador de la respuesta</Typography>
                        <SimpleAutocompleteSelect label='Seleccionar Scripts' items={ApiProcedure} showTitle='name' onChange={(value)=>{
                            const selectedScript = ApiProcedure.find((item)=> item.name === value)
                            setMiddleware(selectedScript?.script!)
                        }}/>
                        <CustomCodeEditor {...{
                            value:fn,
                            onChange:setMiddleware
                        }} />
                        <FlexContainer justify={'end'}>
                            <CustomButton 
                                action={handleMid}
                                status={current.isLoading ? 'loading' : 'idle'} 
                                icon='code' 
                                variant='iconButton' 
                                popoverTitle='Probar adaptador' 
                            />
                        </FlexContainer>
                    </FlexContainer>

                    <FlexContainer flexDirection='column' width='50%' >
                        <Typography variant='h6' color='secondary'>Siguiente acción</Typography>
                        <CustomCodeEditor {...{
                            value:JSON.stringify(current.nextRequestData ||'todavía no hay datos',null,'\t'),
                            onChange:value => current.setNextRequestData(JSON.parse(value))
                        }} />
                        <FlexContainer justify={'end'}>
                           {/*  <CustomButton icon='next' variant='iconButton' popoverTitle='realizar petición a la api'/> */}
                        </FlexContainer>
                    </FlexContainer>
                </FlexContainer>
            </PageContainer>
        </ComponentMode>
    )
}

const NextAction:React.FC<{current:PostManAction, next?:PostManAction}> = ({current,next})=> {


    const nextAction = usePostMan({
        name:next?.name,
        endpoint:next?.req.url?.split('/')[1],
        recursive:!!next?.isRecursive
    })

    useEffect(()=>{
        nextAction.setBody(current.nextRequestData)
    },[current.nextRequestData])

    return(!!next 
        ?    <ApiQueryConfig  icon='next' current={nextAction} />
        :    <CustomButton variant={'iconButton'} icon='next' disabled />
    )
}


const PostManActionR:React.FC<{currentAction:PostManAction, nextAction?:PostManAction}> = ({currentAction, nextAction}) => {


    return(
        <ListItem>
            
            <ListItemIcon>
                {currentAction.isLoading 
                    ? <CircularProgress size='20px'/>
                    : <CustomIcon icon={ 'flag' } />
                }
            </ListItemIcon>  
            <ListItemText>
                <Typography variant='h6'> {currentAction.name} </Typography>
            </ListItemText>          
            <ListItemSecondaryAction>
                <FlexContainer align={'center'} >
                    <ApiQueryConfig current={currentAction}  />
                    <CustomButton action={currentAction.submit} icon ={'send'} variant={'iconButton'} color='primary' status={currentAction.isLoading ? 'loading' : 'idle'} />
                    <ResponseAdapter current = {currentAction}  next={nextAction} />
                    <NextAction current = {currentAction} next={nextAction}  />
                </FlexContainer>
            </ListItemSecondaryAction>
        </ListItem>
    )
}