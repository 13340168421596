import FlexContainer from "../../../../components/containers/FlexContainer";
import RouterTabs, { TabsInterface, useRouteTabProps } from "../../../../components/containers/tabs/RouterTabs";
import { USER_ROUTES } from "../../../../shared/routes/routes";
import LubricantIncome from "./LubricantIncome";
import LubricantIncomeLog from "./LubricantLog";

const IncomeLogPage = () => {
  const tabs: TabsInterface[] = [
    {
      label: "Lubricantes",
      route: `${USER_ROUTES.plantManagement.lubricants}/*`,
      component: <LubricantIncomeLog virtualGridWidth="100%"/>
    },
    {
      label: "Todos los ingresos",
      route: `all-incomes`,
      component: <LubricantIncome item={{} as any}/>
    }
  ];

  const tabProps = useRouteTabProps(5, tabs);

  return (
    <FlexContainer padding={"0px 0px 0px 16px"} flexDirection="column">
      <RouterTabs {...tabProps} />
    </FlexContainer>
  );
};

export default IncomeLogPage;
