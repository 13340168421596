import { apiCall, } from "../../../shared/axios/axios-config"
import { useQuery } from "../../../shared/react-query/react-query-conf"
import { useSessionContext } from "../../session/store/sessionContext"
import { getViewDateDay } from "../../../shared/date/utils"

import { PeriodInterface } from "../../../shared/date/usePeriod"
import { SurveyInterface } from "../model/SurveyInterface"
import { 
    PrivateQueryData, 
    ItemsAndPaginationResponseInterface, 
    PaginationInterface,
    FilterInterface,
} from "../../../shared/globals/utilsGlobalTypes"


// ----------------------------------------------------------------------------------------------------
// Interfaces

type PaginatedSurveyLubricationPointByPeriodInterface<T = any> = PaginationInterface & FilterInterface<T> & {
    firstDate?: string, 
    secondDate?: string, 
    tagFP: string
}

// ----------------------------------------------------------------------------------------------------
// Services

export const PaginatedSurveyLubricationPointByPeriod = (
    { data, token }: PrivateQueryData <PaginatedSurveyLubricationPointByPeriodInterface <SurveyInterface> >
    ) => {
    return apiCall <ItemsAndPaginationResponseInterface <SurveyInterface, PaginatedSurveyLubricationPointByPeriodInterface<SurveyInterface> > > ({
        method: "POST",
        url: `/PaginatedSurveyLubricationPointByPeriod`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data, 
    })
}

// ----------------------------------------------------------------------------------------------------
// Adapters

export const usePaginatedSurveyLubricationPointByPeriod = (
    period: PeriodInterface | undefined, 
    { pageIndex, pageSize }: { pageIndex: number, pageSize: number },
    { filters, searchValue }: { filters: any, searchValue: string }
    ) => {

    const { token, tagFP } = useSessionContext()
    const canFetch = !!tagFP && !!token && !!period?.firstDate && !!period.secondDate

    const data = {
        tagFP,
        ...period,
        pageIndex,
        pageSize,
        filters,
        searchValue
    }

    return useQuery <ItemsAndPaginationResponseInterface <SurveyInterface, PaginatedSurveyLubricationPointByPeriodInterface<SurveyInterface> > > ({
        queryKey: ["PaginatedSurveyLubricationPointByPeriod", period, pageIndex, pageSize, filters, searchValue], 
        queryFn: () => PaginatedSurveyLubricationPointByPeriod({ data, token }),
        enabled: canFetch,
        select: (data) => {
            return {
                items: data?.items.map((i) => ({
                    ...i,
                    routeDate: getViewDateDay(i.routeDate),
                    modifiedDate: getViewDateDay(i.modifiedDate),
                })),
                pagination: data?.pagination
            }
        },
        staleTime:20000,
    })
}