import { Typography } from "@material-ui/core"
import { Alert, Skeleton } from "@material-ui/lab"
import React, {  useContext, useMemo, useState } from "react"
import FlexContainer from "../../components/containers/FlexContainer"
import PageContainer from "../../components/containers/PageContainer"
import ColumnFilterPanel from "../../components/filters/ColumnFilter/ColumnFilterPanel"
import { useFilterColumns } from "../../components/filters/ColumnFilter/useFilterColumns"
import DynamicFilter from "../../components/filters/DynamicFilter/DynamicFilter"
import { DynamicFiltersProps, useDynamicFilter } from "../../components/filters/DynamicFilter/services/useDynamicFilter"
import { PeriodChange } from "../../components/filters/DynamicFilter/services/usePeriodForm"
import SendEmailWithAttachment from "../../domains/emails/SendEmailWithAttachment"
import { flattenObjAllLevels } from "../globals/utils/dataFiltering"
import { valueFromFlatObjectkey } from "../globals/utils/valueFromFlatObjectkey"
import { GridModelInterface } from "../gridModel/gridModelEntity"
import { PeriodInterface } from "../reports/model/ReportInterface"
import CarrousellActions, { CarrousellActionsProps } from "./CarrouselActions"
import DownloadXlsx from "./components/downloadXlsx.tsx/DownloadXlsx"
import { ClipboardProvider } from "./components/rightClick/store/clipboardContext"
import { useRenderOptionColumn } from "./hooks/useRenderOptionColumn"
import VirtualGridAdapter from "./VirtualGridAdapter"
import { useDate } from "../date/useDate"
import { PaginationOptions } from "./hooks/usePagination"

export type CarrousellActinInterface = CarrousellActionsProps['carrousellComponents']

export interface VirtualGridProps{
    tagFP?:string
    title?:string
    width?:string
    items:any[] | undefined,
    gridModel:GridModelInterface
    carrousellComponents?:CarrousellActinInterface
    headerActions?:(item?:any)=>React.ReactElement,
    itemActions?:(item:any)=>React.ReactNode
    bulkActions?:(items:any[])=>React.ReactNode
    itemActionsExeptions?:((item: any, items?: any[] | undefined) => React.ReactNode) | undefined
    onSelectionModelChange?:(items:any[] | []) => void
    documentExport?:boolean
    documentFiltering?:boolean
    documentSendEmail?:boolean
    documentExportTitle?:string,
    renderOption?:(item:any)=>React.ReactElement
    height?:number
    contained?:boolean,
    period?:PeriodInterface |undefined
    periodChange?:PeriodChange
    paginationOptions?: PaginationOptions
    totalItems?: number
    customDynamicFilters?: DynamicFiltersProps
    entityName?: string
} 


const VirtualGrid:React.FC<VirtualGridProps> = React.memo(({periodChange, period, children, tagFP, contained, width, height, title, items, gridModel, headerActions, itemActions,bulkActions, renderOption, documentExport, documentFiltering = true, documentSendEmail, documentExportTitle, onSelectionModelChange, carrousellComponents, paginationOptions, totalItems, customDynamicFilters, entityName }) => {

    const { fromFormattedDateToDate, fromISOToDateTime, isValidDateFormat } = useDate();

    const formatDateIfValid = (value: string) => {
        const {isDateFormat, isFullDateFormat} = isValidDateFormat(value);

        if (isDateFormat) return fromFormattedDateToDate(value)
        if (isFullDateFormat) return fromISOToDateTime(value)
        if (value === undefined || value === "undefined") return 'Undefined value'
        return value;
    };

    const flatItems = useMemo(()=>{
        const flatted = items && flattenObjAllLevels(items[0])
        return flatted || {}
    },[items])
    
    const defaultDynamicFilters = useDynamicFilter(items || [], gridModel, period, periodChange, undefined, entityName)
    const dynamicFilters = customDynamicFilters ?? defaultDynamicFilters
    

    const {hiddenColumns, handleHiddenColumn, labels, handleActivateDesactivateAllColumns, visibleColumns } = useFilterColumns(flatItems, gridModel, entityName)

    const columns = useMemo(
        () => {
            let column:any = []

            const cellValue = (object:any,key:string)=>{
                const isgruped = key.includes('.')
                if(isgruped) return `${valueFromFlatObjectkey(object,key)}`
                else return `${object[key]}`
            }
            const entries = Object.entries(flatItems)

            entries.forEach(([key,value]:[string,any],index)=>{
                /* filter object pproperties that is not in gridModel */
                if(gridModel.hasOwnProperty(key)){
                    if(gridModel[key].hasOwnProperty('options')){
                        column.push({
                            id:key,
                            Header:gridModel[key].label,
                            accessor:(object:any)=>{
                                const value = JSON.parse(cellValue(object,key)) 
                                return gridModel[key].options?.find((option)=>option.value === value)?.name
                            },
                            key:key,
                        })
                    }else{
                        column.push({
                            id:key,
                            Header:gridModel[key].label,
                            accessor:(object:any) => cellValue(object,key),
                            Cell: ({ value }: {value: string}) => formatDateIfValid(value),
                            key:key,
                        })
                    }
                }
            })

            return column.filter((col:any)=>{
                return !hiddenColumns[col.key]
            })
        }
    ,[flatItems, gridModel, hiddenColumns])

    const rowsCount = useMemo(()=>items?.length || 0,[items?.length])

    const customComponentColumn = useRenderOptionColumn('more',renderOption)

    const content = <>
        <FlexContainer align="center" justify='space-between' width={'100%'} flexWrap='wrap'>
            <Typography variant={'h6'}>{title}</Typography>
            <FlexContainer padding="0px 0px 8px 0px" gap={'8px'}  >
                {documentFiltering && <>
                    <ColumnFilterPanel
                        onChange={handleHiddenColumn} 
                        labels={labels} 
                        hiddenColumns={hiddenColumns} 
                        gridModel={gridModel} 
                        handleActivateDesactivateAllColumns={handleActivateDesactivateAllColumns}  
                        />
                    <DynamicFilter {...dynamicFilters} />
                    </>
                }
                {headerActions && headerActions()}
            </FlexContainer>
        </FlexContainer>

        {!items 
            ?   
                    <Skeleton animation='pulse' variant="rect" height={500} width='100%'>
                    </Skeleton>
                
            : items.length > 0
                ? <VirtualGridAdapter
                    onSelectionModelChange={onSelectionModelChange}
                    height={height}
                    width={width || '100%'}
                    customHooks={[customComponentColumn]}
                    columns={columns} 
                    data={customDynamicFilters ? items : dynamicFilters.filtered || []} 
                    allItemsCount={rowsCount} 
                    paginationOptions = {paginationOptions}
                    totalItems={totalItems}
                >

                    {(data:any[] ) =>
                    
                        <FlexContainer justify='space-between' width={'100%' } flexWrap='wrap' /* height='38px' */>
                            <FlexContainer flexWrap='wrap'>
                                {data && data.length === 1 && itemActions && itemActions(data[0])}
                                {data && data.length > 0 && bulkActions && bulkActions(data)}
                                {data && carrousellComponents && data.length > 0 && <CarrousellActions {...{items:data,carrousellComponents}} />}
                            </FlexContainer>     
                            {
                                <FlexContainer height={'38px'}>

                                    {documentExport !== false &&
                                        <DownloadXlsx {...{
                                            items:data,
                                            gridModel,
                                            documentTitle:documentExportTitle || title,
                                            visibleColumns
                                        }}/>
                                    }

                                    {documentSendEmail !== false &&
                                        <SendEmailWithAttachment {...{ 
                                            variant:'iconButton',
                                            items:data,
                                            attachmentTitle:documentExportTitle || title,
                                            tagFP
                                        }}/> 
                                    }
                                    
                                </FlexContainer>
                            }
                        </FlexContainer>
                    }

            </VirtualGridAdapter>
            : <PageContainer /* width='100%' */ padding={'8px 0px'}>
                <Alert severity='info'>
                    No se ha encontrado ningun elemento.
                </Alert>
            </PageContainer>
        }
    </>
    return(
    <ClipboardProvider>
    {(contained === undefined || contained === true) &&
        
            <PageContainer backgroundColor={'#FFF'} width='100%'>
                {content}
            </PageContainer>
        
    }
    {contained === false && content}
    </ClipboardProvider>)
})


export default React.memo(VirtualGrid)