import { useEffect, useMemo, useState } from "react"
import { MutateOptions, QueryStatus } from "react-query"
import { apiCall, mutateApiCall } from "../../../shared/axios/axios-config"
import { useDate } from "../../../shared/date/useDate"
import { objectMapper } from "../../../shared/globals/utils/objectMapper"
import { PrivateQueryData, PrivateQueryTagFPInterface } from "../../../shared/globals/utilsGlobalTypes"
import { queryClient, useMutation, useQuery } from "../../../shared/react-query/react-query-conf"
import { /* useDailyLubricationPointsWithInfo, */ useEquipmentsByRoute } from "../../lubricationPoints/services/lubricationPointsAdapters"
import { EquipmentInterface, LubricationPointInterface } from "../../lubricationPoints/model/lubricationPointsInterface"
import { useSessionContext } from "../../session/store/sessionContext"
import { useToken } from "../../session/store/sessionStore"
import { AssignCancelRouteInterface, LastDelayedRoutesInterface, RouteInterface } from "./RoutesInterface"
import { MutateResponse } from "../../../shared/axios/models/MutateResponse"
import { useStatusProcessor } from "../../../shared/queries/StatusProcessor"
import { PersonInterface } from "../../person/models/PersonInterface"
import { usePlantOperators } from "../../person/services/personServices"


export const RouteCRUDDependencies = [
    'RoutesByTagFPState',
    'LastDelayedRoutes',
    'NextScheduledRoutes',
    'LastScheduledNotDone',
    'RoutesByTagFPState',
    'EquipmentsByTagFP',
    'LubricationPointByTagTGD',
    'DaoEquipmentsByTagFP',
    'DaoEquipmentsElementsByTagFP',
    'PlantRoutes',
    'EquipmentsByRoute',
    'AllLubricationPointsAndInfoByTagFP',
]



//----------------------------
//axios
//---------------------------

export const getOperatorDailyRoutes = (lubricatorNumber:number,tagFP:string,token:string) => {
    return apiCall({
        method: "get",
        url:`/TodayScheduledRoutesByOperator/${lubricatorNumber}`,
        headers:{
            Authorization:`Bearer ${token}`,
            tagFP
        }
    })
}

export const RoutesByTagFP = ({tagFP,token}:PrivateQueryTagFPInterface) => {
    return apiCall({
        method: "GET",
        url:`/RoutesByTagFP`,
        headers:{
            Authorization:`Bearer ${token}`,
            tagFP
        }
    })
}

export const CreateRoute = ({data,token}:PrivateQueryData) => {
    return mutateApiCall({
        method: "POST",
        url:`/CreateRoute`,
        headers:{
            Authorization:`Bearer ${token}`,
        },
        data
    })
}
export const UpdateRoute = ({data,token}:PrivateQueryData) => {
    return mutateApiCall({
        method: "POST",
        url:`/UpdateRoute`,
        headers:{
            Authorization:`Bearer ${token}`,
        },
        data
    })
}

export const DeleteRoute = ({data,token}:PrivateQueryData) => {
    return mutateApiCall({
        method: "POST",
        url:`/DeleteRoute`,
        headers:{
            Authorization:`Bearer ${token}`,
        },
        data
    })
}

export const SetNewScheduleDate = ({data,token}:PrivateQueryData) => {
    return mutateApiCall({
        method: "POST",
        url:`/SetNewScheduleDate`,
        headers:{
            Authorization:`Bearer ${token}`,
        },
        data
    })
}

export const AssignCancelRoute = ({data,token}:PrivateQueryData) => {
    return mutateApiCall({
        method: "POST",
        url:`/AssignCancelRoute`,
        headers:{
            Authorization:`Bearer ${token}`,
        },
        data
    })
    
}

 export const LastDelayedRoutes = ({tagFP,token}:PrivateQueryTagFPInterface) => {
    return apiCall({
        method: "POST",
        url:`/LastDelayedRoutes`,
        headers:{
            Authorization:`Bearer ${token}`,
        },
        data:{
            tagFP
        }
    })
} 


export const LastScheduledNotDone = ({tagFP,token}:PrivateQueryTagFPInterface) => {
    return apiCall({
        method: "POST",
        url:`/LastScheduledNotDone`,
        headers:{
            Authorization:`Bearer ${token}`,
        },
        data:{
            tagFP
        }
    })
}


export const NextScheduledRoutes = ({tagFP,token}:PrivateQueryTagFPInterface) => {

    return apiCall({
        method: "GET",
        url:`/NextScheduledRoutes`,
        headers:{
            Authorization:`Bearer ${token}`,
            tagFP
        },
    })
}

const RoutesByDay = (date:string, tagFP:string, token:string) => {
    return apiCall({
        method: "POST",
        url:`/DayDelayedRoutes`,
        headers:{
            Authorization:`Bearer ${token}`,
        },
        data:{
            date,
            tagFP
        }
    })
}

const NearlyRouteDate = ({data,token}:PrivateQueryData) => {
    return mutateApiCall({
        method: "POST",
        url:`/NearlyRouteDate`,
        headers:{
            Authorization:`Bearer ${token}`,
        },
        data
    })
}


/* const getOperatorDailyRoutes = (_:string,lubricatorNumber:string,tagFP:string,token:string) => {
    axios.defaults.headers.get['Authorization'] = `Bearer ${token}`
    axios.defaults.headers.get['tagFP'] = tagFP
    const config:AxiosRequestConfig = {
        method: "get",
        url:`${URL}/TodayScheduledRoutesByOperator/${lubricatorNumber}`,
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
} */


const setStartedRoute = ({data,token}:PrivateQueryData) => {
    return apiCall({
        method: "post",
        url:`/StartingRoute`,
        data,
        headers:{
            Authorization:`Bearer ${token}`
        }
    })
    
}

/*  const gDalayedRoutesByOperator = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/LastScheduledRoutesByOperator`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
} */

 export const RoutesInfo = ({data,token}:PrivateQueryData<{date:string,tagFP:string}>) => {
     return apiCall({
         method: "post",
         url:`/DayDelayedRoutes`,
         data,
         headers:{
             Authorization:`Bearer ${token}`
         }
     })
}



//----------------------------
//USeQUery
//---------------------------

export const useRoutesInfo = (queryDate?:string) => {
    //ROUTE STATE ADDING
    const {token,tagFP} = useSessionContext()

    const {nowDay,nowMonth,nowYear} = useDate()

    const date = queryDate || `${nowYear}-${nowMonth}-${nowDay}`

    return useQuery({
        queryKey:'RoutesInfo',
        queryFn:()=>RoutesInfo({data:{date,tagFP},token}),
        enabled:!!tagFP,
        staleTime:30000 
    })
}

export const useSetStartedRoute = () =>{

   const token = useToken()
   const query = useMutation(setStartedRoute)

   const startRoute = (data:{date:string,route:string,tagFP:string}):void => {
       query.mutate({
           token,
           data
       })
   }

   const status = useStatusProcessor(query)

   return {
       startRoute,
        query:{
            ...query,
            ...status
        }
    }
}



export const useRoutesByTagFP = () => {
    const {token,tagFP} = useSessionContext()
    return useQuery<RouteInterface[] | []>({
        queryKey:'PlantRoutes',
        queryFn:()=>RoutesByTagFP({tagFP,token}),
        enabled:!!tagFP,
    })
}

//PendingRoutes
export const useNearlyRouteDate = () => {

    const {tagFP,token} = useSessionContext() 
    const query = useMutation(NearlyRouteDate)

    const getNearlyDate = (routeNumber:string,options?: MutateOptions<MutateResponse, unknown, PrivateQueryData<any>, unknown> | undefined) => {
        return query.mutate({
            token,
            data:{
                tagFP,
                route:routeNumber
            }
        },options)
    }

    return {
        getNearlyDate,
        ...query,
    }

}

export const useLastDelayedRoutes = () => {
    const {tagFP,token} = useSessionContext()
    return useQuery<LastDelayedRoutesInterface[] | []>({
        queryKey:'LastDelayedRoutes',
        queryFn:()=>LastDelayedRoutes({tagFP,token}),
        enabled:!!tagFP,
        staleTime:5000,
    })
}

export const useLastScheduledNotDone= () => {
    const {tagFP,token} = useSessionContext()
    return useQuery<LastDelayedRoutesInterface[] | []>({
        queryKey:'LastScheduledNotDone',
        queryFn:()=>LastScheduledNotDone({tagFP,token}),
        enabled:!!tagFP,
        staleTime:5000,
    })
}

export const useNextScheduledRoutes= () => {
    const {token,tagFP} = useSessionContext()
    return useQuery<LastDelayedRoutesInterface[]>({
        queryKey:'NextScheduledRoutes',
        queryFn:()=>NextScheduledRoutes({tagFP,token}),
        enabled:!!tagFP,
        staleTime:5000,
    })
}

export const useRoutesByTagFPAndState = () => {
    const {token, tagFP} = useSessionContext()

    const { data:routesByTagFP } = useRoutesByTagFP()
    const { data:lastDelayedRoutes } = useLastDelayedRoutes()
    const { data:lastScheduledNotDone } = useLastScheduledNotDone()
    const { data:nextScheduledRoutes } = useNextScheduledRoutes()

    const lastScheduledNotDoneMapp = useMemo(()=>objectMapper(lastScheduledNotDone,'route'),[lastScheduledNotDone])
    const delayedRoutesMapp = useMemo(()=>objectMapper(lastDelayedRoutes,'route'),[lastDelayedRoutes])
    const nextScheduledRoutesmapp = useMemo(()=>objectMapper(nextScheduledRoutes,'route'),[nextScheduledRoutes])

    return useQuery({
        queryKey:'RoutesByTagFPState',
        queryFn:()=>{
            return routesByTagFP?.map((item)=>({
                ...item,
                //add lastDelayedRoutes && lastScheduledNotDone && nextScheduledRoutesmapp
                state:delayedRoutesMapp[item.routeName]?.state || lastScheduledNotDoneMapp[item.routeName]?.state || nextScheduledRoutesmapp[item.routeName]?.state  || 'AL DIA' ,
                newScheduledDate: (delayedRoutesMapp[item.routeName]?.newScheduledDate || lastScheduledNotDoneMapp[item.routeName]?.newScheduledDate || nextScheduledRoutesmapp[item.routeName]?.newScheduledDate)?.slice(0,-19) ,
                scheduledDate: (delayedRoutesMapp[item.routeName]?.scheduledDate || lastScheduledNotDoneMapp[item.routeName]?.scheduledDate || nextScheduledRoutesmapp[item.routeName]?.newScheduledDate)?.slice(0,-19) ,
            }))
        },
        enabled:!!delayedRoutesMapp && !!lastDelayedRoutes && !!lastScheduledNotDoneMapp && !!nextScheduledRoutesmapp,
        staleTime:60000
    })
    
    
}

//----------------------------
//mutations
//---------------------------



export const useRoutesByDate = () => {

    const {token,tagFP} = useSessionContext()
    const {nowDay,nowMonth,nowYear} = useDate()
    const date = useMemo(()=>`${nowYear}-${nowMonth}-${nowDay}`,[nowDay, nowMonth, nowYear])


    return useQuery({
        queryKey:'RoutesByDay',
        queryFn:()=>RoutesByDay(date,tagFP,token),
        enabled: !!token && !!tagFP,
        staleTime:20000
    })

}


export const useSetNewScheduleDate = () => {
    const token = useToken()

    const query = useMutation(SetNewScheduleDate,{
        onSuccess:(data:any)=>{
            //hydrate
            queryClient.setQueryData('RoutesByTagFPState',(old:any)=>{
                return old.map((item:any)=>{
                    if(item.routeName === data.route){
                        return({
                            ...item,
                            ...data
                        })
                    }
                    else return(item)
                })
            })

            //Security petition
            queryClient.invalidateQueries('LastDelayedRoutes')
            .then(()=> queryClient.invalidateQueries('NextScheduledRoutes'))
            .then(()=> queryClient.invalidateQueries('LastScheduledNotDone'))
            .then(()=> queryClient.invalidateQueries('RoutesByTagFPState'))
            .then(()=> queryClient.invalidateQueries('RoutesByDay'))
        }
    })

    const scheduleRoute = (data:{scheduledDate:string,newScheduledDate:string,route:string,tagFP:string}):void => {
        return query.mutate({
            token,
            data
        })
    }

    return {
        scheduleRoute,
        ...query
    }

}

export const useCreateRoute = () => {
    return useMutation(CreateRoute,{
        onSuccess:()=>{
            queryClient.invalidateQueries('PlantRoutes')
        }
    })
}

export const useUpdateRoute = () => {
    return useMutation(UpdateRoute,{
        onSuccess:()=>{
            queryClient.invalidateQueries('PlantRoutes')
        }
    })
}

export const useDeleteRoute = () => {
    return useMutation(DeleteRoute,{
        onSuccess:()=>{
            queryClient.invalidateQueries('PlantRoutes')
        }
    })
}

export const useAssignCancelRoutes = () => {
    const {token} = useSessionContext() 
    const query = useMutation(AssignCancelRoute,{
        onSuccess:()=>{
            queryClient.invalidateQueries('EquipmentsByTagFP')
            queryClient.invalidateQueries('LubricationPointByTagTGD')
            queryClient.invalidateQueries('DaoEquipmentsByTagFP')
            queryClient.invalidateQueries('DaoEquipmentsElementsByTagFP')
            queryClient.invalidateQueries('PlantRoutes')
            queryClient.invalidateQueries('EquipmentsByRoute')
            queryClient.invalidateQueries('AllLubricationPointsAndInfoByTagFP')
        }
    })
    const assignMultipleRoutes = (multipleObjects:AssignCancelRouteInterface[]) => {
        query.mutate({
            data:multipleObjects,
            token
        })
    }
    const cancelMultipleRoutes = (multipleObjects:AssignCancelRouteInterface[]) => {
        query.mutate({
            data:multipleObjects,
            token
        })
    }

    const assign = (equipment:LubricationPointInterface,route:string) => {
        query.mutate({
            data:[{
                route:route,
                equipment:equipment.tagTGD,
                state:'A',
                tagFP:equipment.tagFP
            }],
            token
        })
    }

    const cancel = (equipment:LubricationPointInterface,route:string) => {
        query.mutate({
            data:[{
                route:route,
                equipment:equipment.tagTGD,
                state:'C',
                tagFP:equipment.tagFP
            }],
            token
        })
    }
    
    return{
        assign,
        cancel,
        assignMultipleRoutes,
        cancelMultipleRoutes,
        query
    }

}



interface DeleteRoutesErrors {
    equipments?:EquipmentInterface[] | undefined
}

export const useDeleteRouteVerification = () => {
    const token = useToken()
    const query = useDeleteRoute()
    const {mutate:deleteItem} = query
    const [status, setStatus] = useState<QueryStatus>('idle')
    const [validationRoute,setValidationRoute] = useState<RouteInterface | {} |undefined >()

    const {data:equipmentsByRoute,status:equipmentsByRouteStatus,remove:removeEquipmentsByRoute} = useEquipmentsByRoute(validationRoute)
    const [errors, setErrors] = useState<DeleteRoutesErrors>({})

    const handleRemoveValidations = () => {
        removeEquipmentsByRoute()
    }

    const validate = (item:RouteInterface) => {
        setStatus('loading')
        setErrors({})
        handleRemoveValidations()
        setValidationRoute(item)
    }

    const handleDelete= () =>{
        if(equipmentsByRouteStatus === 'success'){
            if((equipmentsByRoute && equipmentsByRoute.length > 0)) {
                setErrors({equipments:equipmentsByRoute})
                setStatus('error')
                setValidationRoute(undefined)
            }
            else{
                deleteItem({
                    data:validationRoute,
                    token
                },{
                    onSuccess:()=>{
                        setStatus('success')
                        setValidationRoute(undefined)
                    },
                    onError:(err)=>console.log(err)
                })
            }
        }
    }


    
    useEffect(() => {
        validationRoute && handleDelete()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[equipmentsByRouteStatus])

    return  {
        errors,
        status,
        validate,
        query
    }

}

export const useAssignMultipleRoutesToMultipleEquipments = () => {

    const {assignMultipleRoutes,query} = useAssignCancelRoutes()
    const{ status,data,reset,error} = query
    const [assignationItems,setAssignationItems] = useState<AssignCancelRouteInterface[]>([])
  
    const handleAssigCancel = (equipments:EquipmentInterface[],routes:RouteInterface[],state:'A'|'C') => {
        setAssignationItems([])
        equipments.forEach(e => {
            routes.forEach(r => {
                setAssignationItems((assignationItems) =>[
                        ...assignationItems,
                        {
                            route:r.routeName,
                            equipment:e.tagTGD,
                            state:state,
                            tagFP:e.tagFP
                        }
                ])
            })
        })
    }

    useEffect(() => {
        if(assignationItems.length > 0){
            assignMultipleRoutes(assignationItems)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[assignationItems])
    
    return{
        handleAssigCancel,
        error,
        status,
        objectQuery:assignationItems,
        data,
        reset
    }
}

export const useDailyRoutesByLubricatorNumber = (operator?:PersonInterface) => {

    const {token, tagFP} = useSessionContext()   
    const lubricatorNumber = operator?.lubricatorNumber || 0

    return useQuery<any[]>({
        queryKey:`OperatorDailyRoutes-${lubricatorNumber}`,
        queryFn:()=>getOperatorDailyRoutes(lubricatorNumber, tagFP, token),
        enabled:lubricatorNumber > 0 && !!tagFP && !!token,
        select:(routes)=>{
            return routes.map(route => ({
                ...route,
                lubricationPoints:JSON.parse(route.lubricationPoints)
            }))
        },
        staleTime:1
    })
    
}

/* export const useDailyRoutes = (operator:PersonInterface) => {
    const {data:dailyOperatorLubricationPoints} = useDailyLubricationPointsWithInfo(operator)
    const query = useDailyRoutesByLubricatorNumber(operator)
    const {data:routesInfo} = useRoutesInfo()
   


    return useQuery({
        queryKey:'DailyRoutes',
        queryFn:()=>{
            return query.data?.map((route:any) =>{
                const routePoints = dailyOperatorLubricationPoints ? dailyOperatorLubricationPoints.filter((point:any) =>{
                    const result =  route.lubricationPoints.includes(point.tagTGD)
                    return result
                }) : []
                const routeState = routesInfo?.filter((r:any)=>(r.route === route.routeName))[0]?.state || 'PENDIENTE'
                
                return {
                    ...route,
                    state:routeState,
                    lubricationPoints:[
                        ...routePoints
                    ]
                }
            })
        },
        enabled:!!dailyOperatorLubricationPoints && !!query.data && !!routesInfo,
        staleTime:0
    })

} */