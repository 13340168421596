import { makeStyles, Paper } from '@material-ui/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import theme from '../../../assets/theme';
import EaseInOut from '../../../components/containers/animation/EaseInOut';
import FlexContainer from '../../../components/containers/FlexContainer';
import PointerContainer from '../../../components/containers/PointerContainer';
import { Plant } from '../../../domains/plants/PlantInterface';
import { useSessionContext } from '../../../domains/session/store/sessionContext';
import LogOutButton from '../../../layout/components/LogOutButton';
import { USER_ROUTES } from '../../../shared/routes/routes';
import { LoginPageContainer } from '../../login/components/LoginFormContainer';



const useStyles = makeStyles((theme) => ({
  plantSelectorContainer: {
    position:'absolute',
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(2),
      width: theme.spacing(35),
      height: '26vh',
      cursor:'pointer',
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      backgroundColor: '#fff',
      textTransform:'uppercase',
      '&:hover': {
        boxShadow: '0px 0px 47px -16px rgba(0,0,0,0.75)',
      }
    },
    justifyContent:'center',
    alignContent:'center',
    width:'100vw',
    height:'100vh',
    
    /* backgroundColor:'#EFEBE9', */
  },
  logOut:{
    position:'absolute',
    display:'flex',
    gap:'8px',
    top:'16px',
    right:'32px',

},

}));

const PlantSelector:React.FC<{}> = ({}) => {
  const classes = useStyles();
  const {plants,setNewPlant,person} = useSessionContext()
  const navigate = useNavigate()

  const handlePlant = (plant:Plant) => {
    navigate(`/${USER_ROUTES.home}/${person?.role}`)
    setNewPlant(plant)
  }

  return (
      <LoginPageContainer color={theme.palette?.secondary.main}>
        <EaseInOut>
          <FlexContainer flexWrap='wrap' justify='center'  overflowY='scroll' align='center'  width={'100vw'} height={'100vh'} >
            {plants.map((item:any,index:number) => (
              <PointerContainer key={`${item.factory} ${item.name}`}>
                <Paper elevation={4}  color="secondary" variant='outlined' onClick={()=> handlePlant(item)} > 
                  <FlexContainer justify='center' maxWidth={`${theme.spacing(36)}px`} padding={`${theme.spacing(3)}px`} height='200px' width={'250px'} align='center' flexWrap='wrap' >
                    {item.factory} {item.name} 
                  </FlexContainer>
                </Paper>
              </PointerContainer>
            ))} 
          </FlexContainer>
        
        </EaseInOut> 
        <div className={classes.logOut}>
          <LogOutButton title={'salir'}/>
        </div>
      </LoginPageContainer>
      )  

}

export default React.memo(PlantSelector)