import React from 'react';
import { makeStyles, TextField, Toolbar, Typography } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import PageContainer from '../PageContainer';

const useStyles = makeStyles((theme) => createStyles({
    FactoryListTolbar:{
        width:'100%',
        display:'flex',
        justifyContent:'space-between',
        padding:'0'
    }
  }));

  interface Props{
      title?:string
      searchValue?:string
      actions?:React.ReactNode
      handleSearch?:any
      searchBar?:boolean
  }
const TgdListToolbar:React.FC<Props> = ({title,searchValue,handleSearch,actions,searchBar}) => {
    const classes = useStyles()



    return (<>
            <Toolbar className={classes.FactoryListTolbar}>
                {title &&<PageContainer>
                    <Typography variant='h6'>
                        {title}
                    </Typography>
                </PageContainer>}
                {searchBar && 
                    <TextField 
                    style={{width:'300px',margin:'0 16px'}}
                    value={searchValue}
                    type='search' 
                    onChange={handleSearch} 
                    placeholder='buscar...' 
                    />
                }
                {actions && actions}
            </Toolbar> 
        </>
    );
}

export default TgdListToolbar;
