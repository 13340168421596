
import { useMemo } from 'react';
import { GridModelInterface } from '../../../shared/gridModel/gridModelEntity';
import { useLangLabels } from '../../../shared/lang/services/useLangLabels';


export const useLubricationPointsGridModel = () => {

    const {lang} = useLangLabels()

    const gridModel = useMemo<GridModelInterface>(()=>({
        /* 'renderOption':{label:'Acciones'}, */
        admissionDate:{label:lang.lubricationPoints.admissionDate},
        brand:{label:lang.lubricationPoints.brand},
        capacity:{label:lang.lubricationPoints.capacity,width:120},
        equipment:{label:lang.lubricationPoints.equipment,width:300},
        element:{label:lang.lubricationPoints.element,width:300},
        component:{label:lang.lubricationPoints.component,width:300},
        criticality:{label:lang.lubricationPoints.criticality},
        egressDate:{label:lang.lubricationPoints.egressDate},
        function:{label:lang.lubricationPoints.function},
        location:{label:lang.lubricationPoints.location},
        lowReason:{label:lang.lubricationPoints.lowReason},
        lubricant:{label:lang.lubricationPoints.lubricant},
        lubricantType:{label:lang.lubricationPoints.lubricantType},
        measureUnit:{label:lang.lubricationPoints.measureUnit},
        model:{label:lang.lubricationPoints.model},
        observations:{label:lang.lubricationPoints.observations},
        oilAnalysis:{
            label:lang.lubricationPoints.oilAnalysis,
            options:[
                {name:lang.lubricationPoints.options.oilAnalisys.toUpperCase(),value:true},
                {name:lang.lubricationPoints.options.withoutOilAnalisys.toUpperCase(),value:false},
                {name:lang.lubricationPoints.options.all.toUpperCase(),value:undefined}
            ]
        },
        plantTag:{label:lang.lubricationPoints.plantTag},
        review:{label:lang.lubricationPoints.review,width:120},
        //routeName:{label:lang.routes.routeName,width:120},
        routes:{label:lang.lubricationPoints.routes},
        permissions:{label:lang.permissions.plural},
        sector:{label:lang.lubricationPoints.sector},
        state:{
            label:lang.lubricationPoints.state,
            options:[
                {name:lang.lubricationPoints.options.active.toUpperCase(),value:true},
                {name:lang.lubricationPoints.options.disabled.toUpperCase(),value:false},
                {name:lang.lubricationPoints.options.all.toUpperCase(),value:undefined}
            ]
        },
        tagFP:{label:lang.globalentityValues.tagFP},
        tagTGD:{label:lang.globalentityValues.tagTGD},
        supplies:{label:lang.lubricationPoints.supplies},
        type:{label:lang.lubricationPoints.type},
        labelId:{label:lang.lubricationPoints.labelId,width:120},
        id:{label:lang.globalentityValues.id,width:100},
        'info.assetCriticality':{label:lang.infoLubricationPoints.assetCriticality},
        /* 'info.id':{label:'Id info'}, */
        'info.inaccessible':{
            label:lang.infoLubricationPoints.inaccessible,
            options:[
                {name:lang.words.yes.toUpperCase(),value:true},
                {name:lang.words.no.toUpperCase(),value:false},
                {name:lang.words.all.toUpperCase(),value:undefined}
            ]
        },
        'info.labelReplacement':{
            label:lang.infoLubricationPoints.labelReplacement,
            options:[
                {name:lang.words.yes.toUpperCase(),value:true},
                {name:lang.words.no.toUpperCase(),value:false},
                {name:lang.words.all.toUpperCase(),value:undefined}
            ]
        },
        'info.lastInspectionDate':{label:lang.infoLubricationPoints.lastInspectionDate},
        'info.lastLubrication':{label:lang.infoLubricationPoints.lastLubrication},
        'info.lastSurveyId':{label:lang.infoLubricationPoints.lastSurveyId},
        'info.leak':{
            label:lang.infoLubricationPoints.leak,
            options:[
                {name:lang.words.yes.toUpperCase(),value:true},
                {name:lang.words.no.toUpperCase(),value:false},
                {name:lang.words.all.toUpperCase(),value:undefined}
            ]
        },
        'info.pending':{
            label:lang.infoLubricationPoints.pending,
            options:[
                {name:lang.words.yes.toUpperCase(),value:true},
                {name:lang.words.no.toUpperCase(),value:false},
                {name:lang.words.all.toUpperCase(),value:undefined}
            ]},
        'info.tagFP':{label:lang.globalentityValues.tagFP},
        'info.tagTGD':{label:lang.globalentityValues.tagTGD},
        'info.supplies':{label:lang.infoLubricationPoints.supplies},
        'info.temperature':{label:lang.infoLubricationPoints.temperature},
        'info.updateReview':{
            label:lang.infoLubricationPoints.updateReview,
            options:[
                {name:lang.words.yes.toUpperCase(),value:true},
                {name:lang.words.no.toUpperCase(),value:false},
                {name:lang.words.all.toUpperCase(),value:undefined}
            ]},
        'info.updatedToday':{
            label:lang.infoLubricationPoints.updatedToday,
            options:[
                {name:lang.words.yes.toUpperCase(),value:true},
                {name:lang.words.no.toUpperCase(),value:false},
                {name:lang.words.all.toUpperCase(),value:undefined}
            ]},
        'info.user':{label:lang.infoLubricationPoints.user},
        'leakLevel.range':{label:lang.infoLubricationPoints.leakLevelRange},
        tagTGDBefore:{label:lang.lubricationPoints.tagTGDBefore},
        'info.lubricantQuantity':{label:lang.infoLubricationPoints.lubricantQuantity}, 
        /*
            'leakLevel.description':{label:'Color de perdida'},
            'leakLevel.leakQuantity':{label:'% de pérdida'},
            'leakLevel.max':{label:'Perdida máxima'},
            'leakLevel.min':{label:'Perdida mínima'},
            'leakLevel.neededQuantity':{label:'Cantidad necesaria por pérdida'}, 
        */
       suggestedQuantity:{label:lang.lubricationPoints.suggestedQuantity}
    }),[lang])
    return gridModel
}