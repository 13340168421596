import React from "react";
import { Divider, Typography, Paper } from "@material-ui/core";
import PageContainer from "../../../components/containers/PageContainer";
import { CustomIcon } from "../../../components/buttons/CustomButton";
import { USER_ROUTES } from "../../../shared/routes/routes";
import RouterTabs, { TabsInterface, useRouteTabProps } from "../../../components/containers/tabs/RouterTabs";
import StockPage from "../stock/StockPage";
import IncomeLogPage from "./components/IncomeLogPage";

interface Props {}

const LubricationSectionPage: React.FC<Props> = () => {
  const lubricationSection: TabsInterface[] = [
    {
      label: "Stock",
      route: USER_ROUTES.lubricationSection.stock,
      component: <StockPage />,
    },
    {
      label: "Gestion de Ingresos",
      route: USER_ROUTES.lubricationSection.incomeLog,
      component: <IncomeLogPage />,
    },
  ];

  const tabProps = useRouteTabProps(4, lubricationSection, {
    orientation:'vertical'
  })

  return (
    <>
      <Paper elevation={3}>
        <PageContainer>
          <Typography variant="h5">
            <CustomIcon icon="config" style={{ margin: "0px 10px -5px 0" }} />
            Sala de Lubricación
          </Typography>
        </PageContainer>
        
        <Divider />

        <RouterTabs {...tabProps} style={{overflow: "scroll"}}/>
      </Paper>
    </>
  );
};

export default React.memo(LubricationSectionPage);
