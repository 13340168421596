import React from "react";
import DefaultFormLayout from "../../components/form/DefaultFormLayout";
import FieldSelector from "../../shared/form/components/FieldSelector";
import { useCreateUpdate } from "../../shared/form/crud/hooks/useCreateUpdate";
import {
    CUEntityFormPropsType,
    uiFormInterface,
} from "../../shared/form/crud/types/crudTypes";
import { CrudFormType } from "../../shared/form/models/FormTypes";
import { useLangLabels } from "../../shared/lang/services/useLangLabels";
import { useSessionContext } from "../session/store/sessionContext";

import { ObservationInterface } from "./models/ObservationTypes";
import {
    DeleteObservation,
    ObservationCRUDDependencies,
    UpdateObservation,
    CreateObservation,
} from "./services/observationServices";
import { useObservationForm } from "./services/useObservationForm";

type CUObservationFormProps = CUEntityFormPropsType<ObservationInterface> & {
    observationData?: any;
};

const CUObservationForm: React.FC<CUObservationFormProps> = ({ item, actionType, icon, variant, mode, observationData }) => {
    const { tagFP } = useSessionContext();

    //DB
    const { action, resetStatus, status, error, message } = useCreateUpdate(
        {
            create: CreateObservation,
            update: UpdateObservation,
            delete: DeleteObservation,
        },
        actionType,
        ObservationCRUDDependencies
    );

    //UI
    const { lang } = useLangLabels();
    const { uiModel, handleSubmit, reset } = useObservationForm(tagFP, item);

    const ui = (() => {
        const formName = lang.observations.plural;
        switch (actionType) {
            case CrudFormType.create:
                const create: uiFormInterface = {
                    icon: icon || "add",
                    title: `${
                        lang.actionTypes.create
                    } ${formName.toLowerCase()}`,
                    variant: variant || "button",
                    mode: mode || "modal",
                };

                return create;

            case CrudFormType.update:
                const update: uiFormInterface = {
                    icon: icon || "edit",
                    title: `${
                        lang.actionTypes.update
                    } ${formName.toLowerCase()}`,
                    variant: variant || "iconButton",
                    mode: mode || "content",
                };
                return update;
        }
    })();

    //Methods
    const submit = (data: ObservationInterface) => {
        switch (actionType) {
            case CrudFormType.create:
                action([
                    {
                        area: data.area,
                        criticality: data.criticality,
                        observation: data.observation,
                        tagFP: data.tagFP,
                        ...observationData,
                    },
                ]);
                break;

            case CrudFormType.update:
                action({
                    ...item,
                    area: data.area,
                    criticality: data.criticality,
                    observation: data.observation,
                    tagFP: data.tagFP,
                });
                break;
        }
    };

    const handleReset = () => {
        resetStatus();
        reset();
    };

    return (
        <DefaultFormLayout
            mode={ui.mode}
            icon={ui.icon}
            variant={ui.variant}
            buttonTitle={ui.title}
            popoverTitle={ui.title}
            {...{ status, error, message }}
            onChange={handleSubmit(submit)}
            onClose={handleReset}
        >
            {uiModel.map((props, index) => (
                <FieldSelector key={index} fieldProps={props} />
            ))}
        </DefaultFormLayout>
    );
};

export default CUObservationForm;
