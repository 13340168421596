import React,{ useEffect } from 'react';
import {  useDeleteCriticalityVerification } from './services/service.criticality';
import { CircularProgress } from '@material-ui/core';

import CustomButton from '../../components/buttons/CustomButton';
import { CriticalityInterface } from './models/criticalityInterface';
import { useStatusProcessor } from '../../shared/queries/StatusProcessor';
import { useClickPosition } from '../../shared/globals/hooks/useClickPosition';
import { useSnackbar } from '../../components/snackbar/hooks/useSnackbar';


interface Props{
    item:CriticalityInterface
}
const DeleteCriticityForm:React.FC<Props> = ({item}) => {
            
    const {errors,status:deleteStatus,validate,query } = useDeleteCriticalityVerification()

    const {status,message} = useStatusProcessor(query)
    const {position,getClickPosition} = useClickPosition()

    const { setData } = useSnackbar()

    useEffect(()=>{
        if(deleteStatus === 'error'){
            const componentsError = (
                (errors?.equipments?.length 
                    ? (errors.equipments.length > 1 
                        ? `${errors.equipments.length} Equipos` 
                        : `${errors.equipments.length} Equipo`) 
                    : '')
            )
            setData({
                modal:true,
                errors:`Esta criticidad no se puede eliminar porque está siendo usada en: ${componentsError}` ,
                severity:'warning',
                position:position
            })
        }

        if(status === 'success'){
            setData({
                modal:true,
                errors:message,
                severity:'success',
                position:position
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[deleteStatus,status,errors])
     
    return (
        status === 'loading' 
            ? <CircularProgress size='20px' />
            :<CustomButton 
            variant='iconButton'
            status={status} 
            icon='delete' 
            popoverTitle='Eliminar criticidad' 
            action={(e)=>getClickPosition(e,()=>validate(item))}
            />   
    );
}

export default React.memo(DeleteCriticityForm);
