import { Divider } from "@material-ui/core";
import FlexContainer from "../../../../components/containers/FlexContainer";
import FieldSelector from "../../../../shared/form/components/FieldSelector";
import AddSupply from "../../../supply/molecules/AddSupply";
import useNeededSuppliesState from "../hooks/useNeededSuppliesState";
import { FormattedUiModel } from "../models/FormattedUiModel";
import {
    InfoSurveyInterface,
    SurveyInterface,
    UpdateSurveyInterface,
} from "../../model/SurveyInterface";
import { SurveyTasksValues } from "../../tasks/model/TaskInterface";
import { useLubricationPointByTagTGD } from "../../../lubricationPoints/services/lubricationPointsAdapters";
import { FormValidations } from "../../../../shared/form/models/FormTypes";
import { translateValue } from "../../../../components/filters/DynamicFilter/services/useDynamicFilter";
import { useEffect } from "react";

interface SurveyUpdateFormFieldsProps {
    formattedUiModel: FormattedUiModel<SurveyInterface & InfoSurveyInterface>;
    item: SurveyInterface & InfoSurveyInterface;
    setValue: (name: keyof UpdateSurveyInterface, value: any) => void;
    setValidations: (
        key: keyof UpdateSurveyInterface,
        validation: keyof FormValidations,
        value: any
    ) => void;
}

const SurveyUpdateFormFields = ({ formattedUiModel, item, setValue, setValidations }: SurveyUpdateFormFieldsProps) => {
    const { neededSuppliesState, setNeededSupplies, deleteSupply } = useNeededSuppliesState(formattedUiModel);

    const { value: tasktype } = formattedUiModel?.taskType;
    const { value: manualRegistration } = formattedUiModel?.manualRegistration;
    const impossibleToLubricate = translateValue(formattedUiModel?.impossibleToLubricate.value);
    const lubricates = translateValue(formattedUiModel?.lubricates.value);
    const needsLubrication = translateValue(formattedUiModel?.needsLubrication.value);
    const taskDone = translateValue(formattedUiModel?.taskDone.value);

    const isReasonRequired = formattedUiModel.reason.validations?.required?.value;
    const hasTemperatureErrors = formattedUiModel.temperature.error ? true : false;
    const hasTaskTimeErrors = formattedUiModel.taskTime.error ? true : false;
    const isLubrication = tasktype === SurveyTasksValues.lubrication;
    const disableDate = !manualRegistration ?? false;
    const disableLubricationFields = !lubricates || impossibleToLubricate;

    const { data: lubricationPoint = {} } = useLubricationPointByTagTGD(item?.tagTGD);

    const handleTaskDoneChanges = () => {
        if (!taskDone) {
            isLubrication && setValue("lubricates", false);
            setValue("temperature", 0);
            setValue("taskTime", 0);
        } else if (isLubrication && needsLubrication) {
            setValue("lubricates", true);
            setValue("impossibleToLubricate", false);
            setValue("temperature", item?.temperature);
            setValue("taskTime", item?.taskTime);
        }
    }

    const handleImpossibleToLubricateChanges = () => {
        if (isLubrication && impossibleToLubricate) {
            setValue("lubricates", false);
            setValue("taskDone", false);
            setValue("reason", "");
            setValue("quantity", 0);
            setValidations("reason", "required", { value: false });
        }
    }

    const handleLubricatesChanges = () => {
        if (isLubrication) {
            if (!lubricates) {
                needsLubrication && setValue("taskDone", false);
                setValue("reason", "");
                setValue("quantity", 0);
                setValidations("reason", "required", { value: false });
            } else if (!impossibleToLubricate) {
                setValue("reason", item?.reason);
                setValue("quantity", item?.quantity);
                setValue("taskDone", true);
                setValidations("reason", "required", { value: true });
            }
        }
    }

    const handleNeedsLubricationChanges = () => {
        if (isLubrication) {
            if (!needsLubrication) {
                setValue("lubricates", false);
                setValue("impossibleToLubricate", false);
                setValue("temperature", 0);
                setValue("taskDone", true);
                setValue("taskTime", 0);
                setValidations("reason", "required", { value: false });
                setValidations("taskTime", "required", { value: false });
            } else {
                lubricates
                    ? setValue("taskDone", item?.taskDone)
                    : setValue("taskDone", false);
                setValue("taskTime", item?.taskTime);
            }
        }
    }

    useEffect(() => {
        handleTaskDoneChanges();
    }, [taskDone]);

    useEffect(() => {    
        handleImpossibleToLubricateChanges();
    }, [impossibleToLubricate]);

    useEffect(() => {
        handleLubricatesChanges();
    }, [lubricates]);

    useEffect(() => {
        handleNeedsLubricationChanges();
    }, [needsLubrication]);

    return (
        <>
            <FlexContainer flexDirection="column" gap={"8px"}>
                <FlexContainer>
                    <FieldSelector
                        fieldProps={{
                                ...formattedUiModel.startTime,
                                disabled: disableDate,
                            } as any }
                    />
                    <FieldSelector
                        fieldProps={{
                                ...formattedUiModel.endTime,
                                disabled: disableDate,
                            } as any }
                    />
                </FlexContainer>
                <FieldSelector fieldProps={formattedUiModel.review} />
                <FieldSelector
                    fieldProps={{
                            ...formattedUiModel.temperature,
                            disabled: hasTemperatureErrors
                                ? false
                                : (isLubrication && !needsLubrication) || !taskDone,
                        } as any }
                />
                <FlexContainer>
                    <FieldSelector
                        fieldProps={
                            {
                                ...formattedUiModel.taskDone,
                                disabled: isLubrication && !needsLubrication,
                            } as any }
                    />
                    <FieldSelector
                        fieldProps={{
                                ...formattedUiModel.taskTime,
                                disabled: hasTaskTimeErrors
                                    ? false
                                    : (isLubrication && !needsLubrication) || !taskDone,
                            } as any }
                    />
                </FlexContainer>

                {isLubrication && (
                    <FieldSelector
                        fieldProps={formattedUiModel.needsLubrication}
                    />
                )}
            </FlexContainer>

            <AddSupply
                outOfRoute={item.outOfRoute}
                listTitle="Consumibles"
                formTitle={"Nuevo Insumo"}
                buttonTitle={"Insumos"}
                variant={item?.consumables?.length > 0 ? "list" : "modal"}
                buttonType={"add"}
                lubricationPoint={lubricationPoint}
                propSupplies={neededSuppliesState}
                propDeleteSupply={deleteSupply}
                propSetNeededSupplies={setNeededSupplies}
            />

            <Divider />
            <br />

            <FlexContainer flexDirection="column" gap={"8px"}>
                <FieldSelector
                    fieldProps={{
                            ...formattedUiModel.lubricates,
                            disabled:
                                !isLubrication ||
                                impossibleToLubricate ||
                                !needsLubrication,
                        } as any }
                />
                <FieldSelector
                    fieldProps={{
                            ...formattedUiModel.impossibleToLubricate,
                            disabled: !isLubrication || !needsLubrication,
                        } as any }
                />
                <FieldSelector
                    fieldProps={{
                            ...formattedUiModel.reason,
                            disabled: isReasonRequired
                                ? false
                                : !isLubrication || disableLubricationFields,
                        } as any }
                />
                <FieldSelector
                    fieldProps={{
                            ...formattedUiModel.lubricant,
                            disabled:
                                !isLubrication || disableLubricationFields,
                        } as any }
                />
                <FieldSelector
                    fieldProps={{
                            ...formattedUiModel.quantity,
                            disabled:
                                !isLubrication || disableLubricationFields,
                        } as any }
                />
            </FlexContainer>
        </>
    );
};

export default SurveyUpdateFormFields;
