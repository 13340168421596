import React from 'react';
import { TgdTextField } from '../../../components/fields/TextField/TgdTextField';
import SelectArea from '../../../domains/areas/components/SelectArea';
import SelectColor from '../../../domains/criticalities/components/SelectColor';
import SelectCriticality from '../../../domains/criticalities/components/SelectCriticality';
import SelectCriticalityType from '../../../domains/criticalities/components/SelectCriticalityType';
import SelectLubricantBy from '../../../domains/lubricant/molecules/SelectLubricantBy';
import { SelectOilAnalysis } from '../../../domains/lubricationPoints/components/actions/SelectOilAnalysis';
import SelectSuggestedQuantity from '../../../domains/lubricationPoints/components/actions/SelectSuggestedQuantity';
import SelectOperator from '../../../domains/routes/components/atoms/SelectOperator';
import Selectperiodicity from '../../../domains/routes/components/atoms/SelectPeriodicity';
import SelectSector from '../../../domains/sectors/atom/SelectSector';
import SelectSupplyType from '../../../domains/supply/atom/SelectSuplyType';
import SelectTypes from '../../../domains/types/components/SelectType';
import SelectTypeBy from '../../../domains/types/components/SelectTypeBy';
import { InputComponentTypes, uiModelInterface } from '../models/FormTypes';
import { SelectWithOptions } from '../../../components/autocomplete/SelectWithOptions';
import SelectAddedReason from '../../../domains/added-reason/components/SelectAddedReason';
import SelectSurveyReviews from '../../../domains/surveys/SurveyUpdateForm/components/SelectSurveyReviews';
import SelectTabulatedObservation from '../../../domains/types/components/SelectTabulatedObservation';
import SelectReferrer from '../../../pages/backoffice/LubricationSection/components/SelectReferrer';




interface Props {
    fieldProps:uiModelInterface<any>
}

const FieldSelector:React.FC<Props> = ({fieldProps}) => {
    
    
    
    switch (fieldProps.component) {

        //Generic inputFields
        case InputComponentTypes.text:
            return <TgdTextField {...fieldProps} />  
            
        case InputComponentTypes.date:
            return <TgdTextField {...fieldProps} type='date' />  

        case InputComponentTypes.dateTime:
            return <TgdTextField {...fieldProps} type='datetime-local' />  
                
        case InputComponentTypes.number:
            return <TgdTextField {...fieldProps} type='number' />   
                    
        case InputComponentTypes.description:
            return <TgdTextField {...fieldProps} />  

        case InputComponentTypes.selectWithOptions:
            return <SelectWithOptions {...fieldProps} />
            

        //Lubricants
        case InputComponentTypes.lubricant:
        return<SelectLubricantBy {...fieldProps} {...fieldProps.inputConfig} />
        
        //Sectors
        case InputComponentTypes.sector:
        return<SelectSector {...fieldProps} {...fieldProps.inputConfig} />
        
        //Types
        case InputComponentTypes.types:
        return <SelectTypes {...fieldProps}  /> 

        //Areas
        case InputComponentTypes.area:
        return <SelectArea {...fieldProps} {...fieldProps.inputConfig} />
        
        case InputComponentTypes.typeBy:
        return <SelectTypeBy {...fieldProps} {...fieldProps.inputConfig} />
        
        //Supply
        case InputComponentTypes.supplyType:
        return <SelectSupplyType {...fieldProps} />
        
        //Criticalities
        case InputComponentTypes.criticality:
        return <SelectCriticality {...fieldProps} {...fieldProps.inputConfig} />
        
        case InputComponentTypes.color:
        return <SelectColor {...fieldProps} />
        
        case InputComponentTypes.criticalityType:
        return <SelectCriticalityType {...fieldProps}/>
        
        //Routes
        case InputComponentTypes.operator:
        return <SelectOperator {...fieldProps} {...fieldProps.inputConfig}/>
        
        case InputComponentTypes.periodicity:
        return <Selectperiodicity {...fieldProps} />

        case InputComponentTypes.suggestedQuantity:
        return <SelectSuggestedQuantity {...fieldProps} />

        //Surveys
        case InputComponentTypes.reason:
            return <SelectAddedReason {...fieldProps} {...fieldProps.inputConfig} />

        case InputComponentTypes.review:
            return <SelectSurveyReviews {...fieldProps} {...fieldProps.inputConfig} />
        
        case InputComponentTypes.observation:
            return <SelectTabulatedObservation {...fieldProps} {...fieldProps.inputConfig} />
        
        // Referrer
        case InputComponentTypes.referrer:
            return <SelectReferrer {...fieldProps} {...fieldProps.inputConfig} />
        
        //Not Showing
        case InputComponentTypes.none:
        return null
        
        /* 
        //lubricationPoints
        case InputComponentTypes.element:
        return <SelectElement {...fieldProps} {...fieldProps.inputConfig}/>
        
        case InputComponentTypes.component:
        return <SelectComponent {...fieldProps} {...fieldProps.inputConfig}/>
        
        */

        case InputComponentTypes.oilAnalysis:
        return <SelectOilAnalysis {...fieldProps}/> 


        //Default
        default:
        return null
    }
}

export default React.memo(FieldSelector);
