import React from 'react'
import SimpleAutocompleteSelect from '../../../components/autocomplete/SimpleAutocompleteSelect';
import CUAreaForm from '../CUAreaForm';

import { usePlantAreas } from '../services/service.areas';
import { AreaInterface } from '../models/AreaTypes';
import { useLangLabels } from '../../../shared/lang/services/useLangLabels';
import { useUser } from '../../session/store/sessionStore';
import { CrudFormType } from '../../../shared/form/models/FormTypes';

interface Props{
    onChange:(value:any)=>void
    defaultValue?:string|undefined
    error?:string
    complete?:boolean
    helper?:string
}

const SelectArea:React.FC<Props> = ({ onChange, defaultValue, complete, error, helper}) => {

    const showTitle = 'area'
    const {lang} = useLangLabels()
    const {data:areas} = usePlantAreas()
    const {data:user} = useUser()
    const label = lang.areas.title.singular

    const handleChange = (value:string) => {
        if(complete){
            const resultComplete = areas?.find((a)=>a[showTitle] === value)
            onChange(resultComplete)
        }else{
            const result = value as string 
            onChange(result)
        }
    }

    return (<> 
            <SimpleAutocompleteSelect
                {...{
                    error,
                    onChange:handleChange,
                    defaultValue,
                    label,
                    items:areas || [],
                    showTitle,
                    helper
                }}
            >
                {user?.type !== 'O' && <CUAreaForm actionType={CrudFormType.create}  />}
            </SimpleAutocompleteSelect>
    </>);

}

export default React.memo(SelectArea);
