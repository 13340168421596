import { Badge, Divider, Tab, Tabs, TabsProps } from '@material-ui/core';
import React, { useState } from 'react';
import CustomButton, { CustomIcon, CustomIconType } from '../buttons/CustomButton';
import PageContainer from '../containers/PageContainer';
import TgdPopover from '../popovers/TgdPopover';
import TabPanel from './TabPanel';


function a11yProps(index: any) { 
	return {
		id: `scrollable-auto-tab-${index}`,
		'aria-controls': `scrollable-auto-tabpanel-${index}`,
	};
}

interface Props {
    icon?:CustomIconType,
    popoverTitle?:string,
    items:any[],
    content:(items?:any)=>React.ReactNode,
    labelIterator:string,
    customTabComponent?:React.ReactNode,
    props?:TabsProps
    padding?:string
}


const TgdTabs:React.FC<Props> = ({items,content,labelIterator,customTabComponent,popoverTitle,icon,props,padding}) => {

    const defaultPosition = customTabComponent ? 1 : 0
    const [value, setValue] = useState(defaultPosition)

    const action = (index:number) => 
        items[index] 
            ? items[index].onChange 
                ? items[index].onChange() 
                : undefined 
            : undefined
    
    const handleChange = (value:any) => {
        setValue(value)
        action(value)        
    }

    return(<>

            <Tabs
            {...props}
            value={value}
            onChange={(e,newValue)=>handleChange(newValue)}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            >
                {/* configTab */}
                {customTabComponent &&
                    <Tab 
                    key={'CustomTab'} 
                    icon={
                        <TgdPopover mode='hover' title={popoverTitle || ''} >
                            <CustomButton variant='iconButton' icon={icon ? icon : 'config'} /> 
                        </TgdPopover>
                    } {...a11yProps(0)}
                />}

                {/* Tabs */}
                {items && items.map((item:any,index:number) => 
                    <Tab 
                        key={index} 
                        label={item[labelIterator]} 
                        {...a11yProps(customTabComponent ? index + 1 :index)} 
                        {...{
                            onClickCapture:()=>
                            (!!props && !!props.onClick) 
                                ? props.onClick(item) 
                                : null 
                        }} 
                    />
                )}
            </Tabs>
            <Divider />
            
            {/* configTabPanel */}
            {customTabComponent && 
            <TabPanel key={'CustomTabPanel'} value={value} index={0}>
                <PageContainer padding={padding || '24px 0px'}>
                    {customTabComponent}
                </PageContainer>
            </TabPanel>}

            {/* TabPanels */}
            {items && items.map((item:any,index:number) => 
                <TabPanel key={`${index}`} value={value} index={customTabComponent ? index+1 :index}>
                    <PageContainer padding={padding || '24px 0px'}>
                        {content(item)}
                    </PageContainer>
                </TabPanel>
            )}  
        
    </>
    )
}

export default TgdTabs