import { Paper } from '@material-ui/core'
import FlexContainer from '../../../components/containers/FlexContainer'
import { SurveyInterface } from '../../../domains/surveys/model/SurveyInterface'
import { LubricationPointInterface, lubricationPointName } from '../../../domains/lubricationPoints/model/lubricationPointsInterface'
import { useSurveyWithObservationsGridModel } from '../../../domains/surveys/services/lang/useSurveyWithObservationsGridModel'
import { CircularProgress, Table, TableBody, TableHead } from '@mui/material'
import ExploreSurveyRow from './ExploreSurveyRow'
import { useExploredSurveys } from '../SurveyExploreCache'
import { CustomTableCell } from './CustomTableCell'

interface SurveyListProps {
    surveys:SurveyInterface[]
    lubricationPoint:LubricationPointInterface | null | undefined
}

export const SurveyList:React.FC<SurveyListProps> = ({lubricationPoint, surveys}) => {

    const surveyModel = useSurveyWithObservationsGridModel({
        type:'show',
        options: ['startTime', 'quantity','taskType', 'reason', 'outOfRoute', 'route', 'observations']
    })
    const gridTitle = `RELEVAMIENTOS - ${lubricationPoint ? lubricationPointName(lubricationPoint) : ''}`


    const listIsEmpty = surveys?.length === 0
    const {data:surveysObservations, isLoading} = useExploredSurveys(surveys)

    
    if(isLoading){
        return(
            <Paper >
                <FlexContainer width={'100%'} height={'200px'} align='center' justify='center'>
                    <CircularProgress/>
                </FlexContainer>
            </Paper>
        )
    }

    if(listIsEmpty){
        return (
            <Paper >
                <FlexContainer height={'200px'} justify='center' align='center'>
                    No hay relevamientos de este activo, prueba con otro período
                </FlexContainer>
            </Paper>
        )
    }

    return (
        <Paper style={{
            padding:'24px'
        }}>

                <Table>
                    <TableHead style={{backgroundColor: '#e6e6e6'}}>
                        <CustomTableCell>Dispositivo </CustomTableCell>
                        <CustomTableCell>Fecha de inicio </CustomTableCell>
                        <CustomTableCell>Lubricación </CustomTableCell>
                        <CustomTableCell>Cantidad </CustomTableCell>
                        <CustomTableCell>Motivo agregado </CustomTableCell>
                        <CustomTableCell>Fuera de ruta </CustomTableCell>
                        <CustomTableCell>Observaciones </CustomTableCell>

                    </TableHead>
                    <TableBody>
                        {surveysObservations?.map((survey) => 
                                <ExploreSurveyRow survey={survey} />
                        )}
                    </TableBody>
                </Table>
                
        </Paper>
    )
}
