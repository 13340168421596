import React from 'react'
import CustomButton from './buttons/CustomButton'
import TgdPopover from './popovers/TgdPopover'



const IconStatus:React.FC<{state:boolean | undefined}> = ({state}) => {
    
    return(
        state===undefined ? null :
        <TgdPopover title={`Punto ${state ? 'Activo' : 'Inactivo'}`} mode='hover'>
            <CustomButton 
                variant='iconButton' 
                icon='state' 
                styleProps={{color:state ? 'green' : 'gray'}} 
            />
        </TgdPopover>
    )
}

export default React.memo(IconStatus)