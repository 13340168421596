import { Fab, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import theme from '../../../assets/theme';
import { CustomIcon, CustomIconType } from '../../../components/buttons/CustomButton';
import FlexContainer from '../../../components/containers/FlexContainer';
import HandleUndefined from '../../../components/containers/handleUndefined/HandleUndefined';
import CarrouselModal, { useCarrousellModal } from '../../../components/containers/modal/carrousellModal';
import CarrousellToolbar from '../../../components/containers/toolbar/CarrousellToolbar';
import { useCarrousell } from '../../../components/containers/toolbar/hooks/useCarrousell';
import { Divider } from '../../../components/Divider';
import TgdTextField from '../../../components/fields/TextField/TgdTextField';
import DefaultFormLayout from '../../../components/form/DefaultFormLayout';
import { CrudFormType } from '../../../shared/form/models/FormTypes';
import { errorsLog } from '../../../shared/globals/utils/utils';
import { useLangLabels } from '../../../shared/lang/services/useLangLabels';
import { CollectorInterface } from '../models/CollectorInterfaces';
import { useCreateUpdateCollector } from '../services/collectorsServices';
import CollectorStatus  from './CollectorStatus';


type createUpdateCollectorType ={
    tagFP:string
    mode?:'modal' | 'content'
} & (
    {
        type:CrudFormType.create
        items?:CollectorInterface[]
    } |
    {
        type: CrudFormType.update
        items:CollectorInterface[],
    }
)

const CreateUpdateCollectorForm:React.FC<createUpdateCollectorType> = ({tagFP,type, mode, items}) => {

    const {lang} = useLangLabels() 

    const title = lang.actionTypes[type] + ' ' + lang.collectors.singular
    const icon:CustomIconType = (type === CrudFormType.create) 
    ?   'add'
    :   'edit'

    const CarrousellModalProps = useCarrousellModal<CollectorInterface>({
        title,
        items,
        icon,
        variant: type === CrudFormType.update ? 'iconButton' : undefined,
        popoverTitle:lang.actionTypes[type] + ' ' + lang.collectors.singular
    })

    const {handleCreateUpdateCollector,query:{status,message,error},errors,setValue,handleSubmit,form,reset} = useCreateUpdateCollector({tagFP,type,item:CarrousellModalProps.current,})

    errorsLog(errors)


    const content = <> 
        {type === CrudFormType.create
            ? <TgdTextField
                name='name'
                label={lang.collectors.form.name}
                value={form.name}
                defaultValue={form.name}
                error={errors?.name?.message} 
                onChange={(value: any) => setValue('name',value)}                
                />
            : null
        }
        <TgdTextField
            name='serialNumber'
            label={lang.collectors.form.serialNumber}
            value={form.serialNumber}
            defaultValue={form.serialNumber}
            error={errors?.serialNumber?.message} 
            onChange={(value: any) => setValue('serialNumber',value)}                
        />
        
        {/*  <TgdTextField
            name='collectorCode'
            label={lang.collectors.form.collectorCode}
            value={form.collectorCode}
            defaultValue={form.collectorCode}
            error={errors?.collectorCode?.message} 
            onChange={(value: any) => setValue('collectorCode',value)}                
        /> */}
    </> 
    return(<>
        <CarrouselModal {...{
            ...CarrousellModalProps,
            ...(type === CrudFormType.create 
                ? {handleUndefinedProps:{
                    ...CarrousellModalProps.handleUndefinedProps,
                    isError:true
                }}
            : {})
        }}>
            <DefaultFormLayout
                variant='iconButton'
                mode='content'
                onClose={reset}
                fullwidth 
                onChange={handleSubmit(handleCreateUpdateCollector)} 
                buttonTitle={lang.actionTypes[type]}
                popoverTitle={title} 
                {...{status,message,error,icon}}
                >
                    {type === CrudFormType.update && CarrousellModalProps.current && <>
                        <Typography>{CarrousellModalProps.current.id}</Typography>
                        <Typography>{CarrousellModalProps.current.name}</Typography>
                        <FlexContainer align={'center'}>
                            Estado: <CollectorStatus collector={CarrousellModalProps.current}/>
                        </FlexContainer>
                        <Divider/>
                        <br />
                    </>
                    }
                    {content}
            </DefaultFormLayout>
        </CarrouselModal>

    </>);
}

export default CreateUpdateCollectorForm;
