import { Divider, Grid, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography } from '@material-ui/core'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import React, { useMemo } from 'react'
import theme from '../../../assets/theme'
import { CustomIcon, CustomIconProps, CustomIconType } from '../../../components/buttons/CustomButton'
import ComponentMode from '../../../components/componentMode/ComponentMode'
import FlexContainer from '../../../components/containers/FlexContainer'
import PageContainer from '../../../components/containers/PageContainer'
import EntityProperties from '../../../components/EntityProperties'
import { getGridModelkey, getGridModelValue, GridModelInterface } from '../../../shared/gridModel/gridModelEntity'
import { useLangLabels } from '../../../shared/lang/services/useLangLabels'
import { useLubricationPointsGridModel } from '../../lubricationPoints/lang/useLubricationPointsGridModel'
import { useLubricationPointByTagTGD } from '../../lubricationPoints/services/lubricationPointsAdapters'
import { getSurveyIconProps, SurveyInterface } from '../model/SurveyInterface'
import { useSurveyGridModel } from '../services/lang/useSurveyGridModel'
import { TaskType } from '../tasks/model/TaskInterface'


type SurveyPreviewProps = {
    surveyId?:SurveyInterface['id'],
    item?:SurveyInterface,
} | {
    surveyId?:SurveyInterface['id'],
    item:SurveyInterface,
} |{
    surveyId:SurveyInterface['id'],
    item?:SurveyInterface,
}


const PreviewField:React.FC<{iconProps:CustomIconProps,title:string, label:string | number}> = ({iconProps,label,title}) => {

    const _label = (()=>{
        switch(typeof label){
            case'string': return label.toUpperCase()
            default: return label.toString()
        }
    })()

    const _key = title?.toUpperCase() || 'default'


    return(
        <FlexContainer align={'center'}>
            {iconProps.icon 
                ? <CustomIcon {...iconProps}  />
                : _key
            }
            <Typography >
                {_label}
            </Typography>
        </FlexContainer>
    )
}

const notShow:(keyof SurveyInterface | string)[] = ['tagFP','id','sector','tagTGD','accessible','observationsQuantity','startTime','outOfRoute','measureUnit','equipment','element','component','modified','otnumber','modifiedDate','user','manualRegistration','cloudSynchronized','assetCriticality','loadingDate','endTime','routeDate']
const principalItems:(keyof SurveyInterface | string)[] = ['id','sector','sampleExtraction','lubricant','measureUnit','impossibleToLubricate','temperature','review','route','inaccessible','outOfRoute','needsLubrication','label','startTime']

const divideObjectDataKeys = (items:any[] | undefined,filterArray:string[]) => {
    const rest:any[] = []

    if(!!items){
        const filtered = items.filter(([key,value])=>{
                const isvalidated = filterArray.includes(key) 
            if(isvalidated){
                return true
            }else{
                rest.push([key,value])
            }
        })
        return {
            filtered,
            rest
        }
    }else{
        return{
            filtered:[],
            rest
        }
    }
}

const surveyTaskColor = (taskType?:string):CSSProperties['background'] => {
    switch(taskType){
        case 'LUBRICACION':return theme.palette.primary.main
        case 'LIMPIEZA':return theme.palette.info.light
        case 'REPARACION':return theme.palette.info.main
        case 'CONTROL':return theme.palette.success.main
        default: return ''
    }
}

const TaskItem:React.FC<{item?:SurveyInterface}> = ({item}) => {

    const gridModel = useSurveyGridModel()
    const content  = (() =>{ 
        switch(item?.taskType){
            case 'CONTROL': 
                return(<>
            </>
            )
            case 'LUBRICACION': 
                return(<>

            <FlexContainer >
                <PageContainer padding={'0 '} width={'50%'}>
                    <Typography>
                        Se lubricó: {getGridModelValue('lubricates',item.lubricates,gridModel)} 
                    </Typography>
                    <Typography>
                        motivo: {item.reason} 
                    </Typography>
                </PageContainer>
                <PageContainer padding={'0 '} width={'50%'}>
                    <Typography>
                        lubricante: {item.lubricant}  
                    </Typography>
                    <Typography>
                        cantidad: {item.quantity} {item.measureUnit}
                    </Typography>
                </PageContainer>
            </FlexContainer>
            

            </>)
            case 'LIMPIEZA':return(<></>)
            case 'REPARACION':return(<></>)
            default: return(<></>)
        }
    })()

    return(
        <>
            <FlexContainer width='100%' justify={'space-between'} align='center'>
                <FlexContainer width='100%' justify={'space-between'} background={surveyTaskColor(item?.taskType)} color='#FFF' padding='0 16px'>
                    <Typography variant='h6' >
                        {item?.taskType}
                    </Typography>
                    <Typography variant={'h6'}>
                        id: {item?.id}
                    </Typography>
                </FlexContainer>
            </FlexContainer>
            
            <br />
            
            {content}
            
            <br />
            <Divider/>
            <br />

            <Typography>    
                Tiempo de tarea: {item?.taskTime}  min
            </Typography>
            
            <br />
            <Divider/>
            <br />
            <Typography>
                Consumibles utilizados: {item?.consumables}
            </Typography>
            

        </>
    )
}

const SurveyPreview:React.FC<SurveyPreviewProps> = ({surveyId,item}) => {
    
     const {lang} =  useLangLabels()
    const gridModel = useSurveyGridModel()
    const lubricationPointsGridModel = useLubricationPointsGridModel()
    const {data:lubricationPoint} = useLubricationPointByTagTGD(item?.tagTGD)

    const previewItems = (!!item && Object.entries(item).filter((entry:any)=>!notShow.includes(entry[0]))) || []
    const {filtered,rest} = useMemo(()=>divideObjectDataKeys(previewItems,principalItems),[previewItems])

    console.log(filtered)
        
    return (<>

            <FlexContainer>
                <FlexContainer align={'center'}>
                    <ComponentMode mode='popover' popoverTitle='identificación del equipo' icon='info' variant='iconButton' >
                        <FlexContainer>
                            <strong>{getGridModelkey('id',lubricationPointsGridModel)}:</strong> {lubricationPoint?.id}
                        </FlexContainer>
                        <FlexContainer>
                            {getGridModelkey('tagTGD',lubricationPointsGridModel)}: {lubricationPoint?.tagTGD}
                        </FlexContainer>
                        <FlexContainer>
                            {getGridModelkey('plantTag',lubricationPointsGridModel)}: {lubricationPoint?.plantTag}
                        </FlexContainer>
                        <FlexContainer>
                            {lubricationPoint?.tagTGDBefore &&
                                getGridModelkey('tagTGDBefore',lubricationPointsGridModel)+': '+lubricationPoint?.tagTGDBefore
                            } 
                        </FlexContainer>
                        
                    </ComponentMode>
                    <strong>{lubricationPoint?.equipment}</strong> {`>>`}
                    <strong>{lubricationPoint?.element}</strong> {`>>`}
                    <strong>{lubricationPoint?.component}</strong>
                </FlexContainer>
                
            </FlexContainer>

            <br />
            <Divider/>
            <List>
                <ListItem >
                    <ListItemIcon>
                        <CustomIcon icon='user' />
                    </ListItemIcon>
                    <ListItemText
                        primary={item?.user}
                        secondary={getGridModelValue('manualRegistration',item?.manualRegistration,gridModel)}
                    />
                    <ListItemText
                        primary={item?.route}
                        secondary={getGridModelValue('outOfRoute',item?.outOfRoute,gridModel)}
                    />
                    <ListItemText
                        primary={getGridModelkey('startTime',gridModel)}
                        secondary={getGridModelValue('startTime',item?.startTime,gridModel)}
                    />
                    {/* <ListItemSecondaryAction>
                        <CustomIcon icon={'state'} />
                    </ListItemSecondaryAction> */}
                </ListItem>
                <Divider/>
                <ListItem>
                    <Grid 
                        container
                        justifyContent='space-between'
                    >
                        <Grid md={4} style={{borderRight:'1px solid #DedeDe',boxSizing:'border-box'}}>
                            
                            <Grid>
                                ID: {item?.id}
                            </Grid>
                            <Grid>
                                <PreviewField {...{
                                    title:getGridModelkey('inaccessible',gridModel),
                                    iconProps:getSurveyIconProps('inaccessible',item?.inaccessible),
                                    label:getGridModelValue('inaccessible',item?.inaccessible,gridModel)
                                }}/>
                            </Grid>
                            <Grid>
                                <PreviewField {...{
                                    title:getGridModelkey('sector',gridModel),
                                    iconProps:getSurveyIconProps('sector',item?.sector),
                                    label:getGridModelValue('sector',item?.sector,gridModel)
                                }}/>
                            </Grid>

                            
                            {filtered.map(([key,value])=>
                                <Grid>
                                    { value !== undefined  && value !== ''  && 
                                        getGridModelkey(key,gridModel) +': '+ getGridModelValue(key,item?.[key as keyof SurveyInterface],gridModel)
                                    }
                                </Grid>
                            )}
                            
                        </Grid>

                        <Grid container md={8} alignContent='flex-start' style={{padding:'0 0 0 16px',boxSizing:'border-box'}}>
                            <Grid md={12}>
                                <TaskItem item={item} />
                            </Grid>
                        </Grid>

                    </Grid>
                </ListItem>
            </List>

            <br />
            <Divider/>
            <br />

        </>
    )
}

export default SurveyPreview

