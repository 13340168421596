import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { SIDEBAR_WIDTH, TRANSITION_DURATION } from '../LayoutConstants'
import sidebarImg  from '../../assets/img/sidebar.png'
import { Badge, Divider, Drawer, Hidden, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, makeStyles, Toolbar, Typography } from '@material-ui/core'
import Logo from '../../assets/img/TGD-blanco.png'
import { AccountCircle } from '@material-ui/icons'
import { useSessionContext } from '../../domains/session/store/sessionContext'
import NotificationsNavBar from '../../domains/notifications/components/NotificationsNavBar'
import LogOutButton from './LogOutButton'
import { CustomIcon } from '../../components/buttons/CustomButton'
import SupportComponent from '../../domains/tickets/CreateTicketsPage'
import { ConfigButton } from '../../pages/backoffice/ConfigPage'
import { NavLink } from 'react-router-dom'
import MenuPlantSelector from '../../pages/backoffice/ClientVisor/MenuPlantSelector'
import theme from '../../assets/theme'
import { LubricationPointOutOfRouteSurvey } from '../../domains/surveys/components/LubricationPointSurvey'
import { useNewsByTagFP, useTodayNewsbyTagFP } from '../../domains/news/services/service.news'



const useStyles = makeStyles(theme => ({
  drawerPaper:{
    width: SIDEBAR_WIDTH,
    color:'#f3f3f3',
    border:'none',
    backgroundColor:'#002F6C',
    backgroundImage:'url(' + sidebarImg + ')',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    overflow:'hidden'
  },
  iconDrawer:{
    color:'#f3f3f3',
    pointerEvents: 'none',
    minWidth: '32px'
  },
  colorSecondary:{
    borderColor: '#fff',
    color: 'white',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    ...theme.mixins.toolbar,  
  },
  spacing:{
      margin: '0 24px'
  },
  flexButton:{
    width:'100%',
    backgroundColor:'rgba(255, 255, 255, 0.3)',
    borderRadius:'5px',
    padding:'5px',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    '&:hover':{
        backgroundColor:'rgba(255, 255, 255, 0.6)',
        cursor:'pointer'
    },
  },
  logOut:{
    position:'absolute',
    display:'flex',
    gap:'8px',
    margin:'16px',
    bottom:'16px',
    width:'218px'
  },
}))


const SidebarWrapper =  styled((props:any)=><div {...props}/>)`
    width:${(props)=>props.open === false ? 0 : SIDEBAR_WIDTH}px;
    transition: width ease-in-out ${TRANSITION_DURATION}s;
    height:100vh;
    flex-shrink: 0;
    z-index:0;
`

interface sidebarProps {
  menu:any
  variant?: "permanent" | "temporary" | "persistent" | undefined
  open:boolean
  onClose?:()=>void | null | undefined
  anchor:'left' | 'right'
}


const SidebarToolbar:React.FC<{}> = ({}) => {
  const classes = useStyles()
  const {plants,user} = useSessionContext()

  return(<>
      <div className={classes.toolbar}>
        <Hidden mdUp>
            <ListItem>
                <AccountCircle   />
                {user && <Typography variant="button" component={'span'} noWrap className={classes.spacing} >{user.name} </Typography>}
            </ListItem>
        </Hidden>
        <Hidden mdUp>
          <NotificationsNavBar color={'#fff'}/>
        </Hidden>
        <Hidden smDown>
          <Toolbar>
            <img src={Logo} style={{height:'40px',left:'24px'}}alt=""/>
            <MenuPlantSelector color='default'/>
          </Toolbar>
        </Hidden>
    </div>
  </>)
}

const activeStyles = ({isActive}:{isActive:boolean})=>{
  return isActive
  ?
    {
      gap:'4px',
      padding:'8px 4px',
        backgroundColor: theme.palette.info.main,
        borderRadius:'5px',
        width:'100%',
        lineHeight:'1px'
    }
  :{
    gap:'4px',
    padding:'8px 4px',
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    borderRadius:'5px',
    width:'100%',
    lineHeight:'1px'
  }
} 

const MenuItems:React.FC<{menu:any[]}> = ({children,menu}) => {

  const classes = makeStyles(()=>({
    listItem:{
      padding:'0 16px',
      margin:'8px 0 0',
    },
    iconDrawer:{
      color:'#f3f3f3',
      pointerEvents: 'none',
      minWidth: '32px'

    },
    pointerEvents:{
      pointerEvents: 'none'
    },
    menuButton:{
      gap:'4px',
      padding:'8px 4px',
      backgroundColor: 'rgba(255, 255, 255, 0.3)',
      borderRadius:'5px',
      width:'100%',
      lineHeight:'1px'
    }

  }))()

  

  const [dropState,setDropstate] = useState<any>(null)

  //drop menu
  const drop = (e:any) =>{   
    let id = e.target.id
    let state = !dropState[id] 
    setDropstate({...dropState,[id]:state})  
  }

  useEffect(() =>{
      if(menu){
          menu?.forEach((element:any) => {
              setDropstate((dropState:any) => ({...dropState,[element.id]: false}))
          })
      }
  },[menu])  


  return(
    !!menu
    ? menu.map((item:any, index:any) =>  
      <div key={index} className={classes.listItem} >
          {<div style={{backgroundColor:'rgba(255, 255, 255, 0.15)',borderRadius:'5px'}}>
            {!item.actionMenu &&
                <ListItem 
                  component={NavLink}
                  style={activeStyles}
                  to={item.route.replace(/ /g,'%20')} 
                  button key={index} end
                >
                  
                  <ListItemIcon className={classes.iconDrawer}>
                    <CustomIcon icon={item.icon} />
                  </ListItemIcon>

                  <ListItemText  >
                    <Typography component={'span'}  style={{fontSize:'12px'}} variant="button">
                      {item.name}
                    </Typography>
                  </ListItemText>

                  <Badge style={{marginRight:'12px'}} badgeContent={item.badge} color='primary'>
                      <ListItemSecondaryAction>
                      </ListItemSecondaryAction> 
                  </Badge>
                </ListItem>
            }

            {(item.actionMenu) && (item.actionMenu.length > 0) && 
                <>  
                    <ListItem 
                      component={item.type === 'pbi_report' ? 'div' : NavLink} 
                      onClick={(e:any)=>drop(e)} id={item.id} 
                      className={classes.menuButton} 
                      to={item.route.replace(/ /g,'%20')} 
                      button key={index}
                    >
                        <ListItemIcon className={classes.iconDrawer}><CustomIcon icon={item.icon} /> </ListItemIcon>
                        <ListItemText className={classes.pointerEvents}>
                                <Typography component={'span'} style={{fontSize:'12px'}} variant="button">
                                        {item.name}
                                </Typography>
                        </ListItemText>
                        <ListItemIcon className={classes.pointerEvents} style={{justifyContent:'flex-end'}}>
                            { !!dropState && !dropState[item.id] &&
                              <CustomIcon icon='arrowDown' style={{color:'#FFF'}} />
                            }
                            {!!dropState && dropState[item.id] &&
                              <CustomIcon icon='arrowUp'  style={{color:'#FFF'}}/>
                            }
                        </ListItemIcon>
                    </ListItem>
                    {dropState && dropState[item.id] && //submenuItems
                        <>
                            {item.actionMenu.map((subItem:any,index:number) =>(
                              <div key={index} style={{padding:'8px 8px 0 8px'}}>
                                <ListItem 
                                  component={NavLink} 
                                  to={`${item.route.replace(/ /g,'%20')}/${subItem.route.replace(/ /g,'%20')}`} 
                                  style={activeStyles} 
                                  button end
                                > 
                                  <ListItemIcon className={classes.iconDrawer}>
                                    <CustomIcon icon={item.icon} />
                                  </ListItemIcon>
                                  <ListItemText  >
                                    <Typography style={{fontSize:'12px'}}component={'span'}  variant="button">{subItem.name}</Typography>
                                  </ListItemText>
                                </ListItem>
                              </div>
                            ))}
                            <div style={{width:'100%',height:'8px'}}></div>
                        </>
                    }
                </>
            }
            {children && children}
          </div>}
      </div> 
    )
    : <></>  
  )
}

const Sidebar:React.FC<sidebarProps> = ({open,menu,anchor,variant,onClose}) => {

  const classes = useStyles()

  return (
    <SidebarWrapper open={open} >
        <Drawer
          classes={{
            paper:classes.drawerPaper
          }}
            anchor={anchor}
            variant={variant || 'permanent'}
            open={open}
            onClose={onClose && onClose}
        >
          <SidebarToolbar/>
          <Divider />
          <div style={{maxHeight:'calc(100vh - 150px)',overflowX:'hidden'}}>
            <div style={{width:'100%',height:'8px'}}></div>

            <MenuItems menu={menu} />
            <LubricationPointOutOfRouteSurvey mode='menuItem' />


          </div>

          <div className={classes.logOut}>
              <LogOutButton title={'salir'}/>
              <ConfigButton />
              <SupportComponent/>
          </div>

        </Drawer>
    </SidebarWrapper>
  )
}


export default Sidebar