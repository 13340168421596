import React, { useState } from 'react';
import { Typography, Grid, Divider, List, ListItemAvatar, ListItemText, Avatar, Paper, ListItemSecondaryAction} from '@material-ui/core'
import { useFactoriesByUser } from './services/service.factory';
import CustomButton, { CustomIcon } from './../../components/buttons/CustomButton';
import ListItem from '@material-ui/core/ListItem';
import CreateFactoryPage from './pages/CreateFactoryPage';
import FactoryConfig from './FactoryConfig';
import { useLangLabels } from '../../shared/lang/services/useLangLabels';
import { FactoryInterface } from './model/FactoryInterface';
import PageContainer from '../../components/containers/PageContainer';
import FlexContainer from '../../components/containers/FlexContainer';
 

const FactoryList:React.FC<{}> = ({}) => {

    const {lang} = useLangLabels()
    const {data:factories,} = useFactoriesByUser()
    const [currentFactory,setCurrentFactory] = useState<FactoryInterface | undefined>()

    const handleFactoryConfig = (factory:FactoryInterface) => {
        setCurrentFactory(factory)
    }

    const handleFactoryConfigReset = () => {
        setCurrentFactory(undefined)
    }

    return (
        <Paper>
            <PageContainer>
                    <Grid container>
                        <Grid item xs={6} sm={6}>
                            <Typography variant='h6'>
                                Fábricas
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <FlexContainer justify='flex-end'>
                                <CreateFactoryPage/>
                            </FlexContainer>
                        </Grid>
                    </Grid>
                    <Divider style={{width:'calc(100% - 120px)'}}/>
                    <br />
                    <List>
                        {factories?.map((item:FactoryInterface)=>
                            <ListItem dense key={item.name} >
                                <ListItemAvatar>
                                    <Avatar variant='circular' >
                                        <CustomIcon icon='flag' />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText 
                                    primary={item.name} 
                                    secondary={`${lang.factories.referrer}: ${item.referrer}`} 
                                />
                                <ListItemSecondaryAction>
                                    <CustomButton  
                                        action={()=>handleFactoryConfig(item)}
                                        icon='config' variant='iconButton' 
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                        )}
                </List>

            </PageContainer>

            <FactoryConfig
                factory={currentFactory} 
                onClose={handleFactoryConfigReset} 
            />
        </Paper>
    );
}

export default FactoryList;
