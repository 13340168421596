import { ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core'
import React, { lazy, Suspense, useMemo } from 'react'
import theme from '../../../assets/theme'
import { CustomIcon } from '../../../components/buttons/CustomButton'
import ComponentMode from '../../../components/componentMode/ComponentMode'
import FlexContainer from '../../../components/containers/FlexContainer'
import HandleUndefined from '../../../components/containers/handleUndefined/HandleUndefined'
import { useModal } from '../../../components/containers/modal/hooks/useModal'
import Modal from '../../../components/containers/modal/Modal'
import PageContainer from '../../../components/containers/PageContainer'
import TabContainer from '../../../components/containers/tabContainer/TabContainer'
import CarrousellToolbar from '../../../components/containers/toolbar/CarrousellToolbar'
import { useCarrousell } from '../../../components/containers/toolbar/hooks/useCarrousell'
import SubTitle from '../../../components/Typography/SubTitle'
import Title from '../../../components/Typography/Title'
import { useLangLabels } from '../../../shared/lang/services/useLangLabels'
import { LubricationPointInterface } from '../../lubricationPoints/model/lubricationPointsInterface'

import CriticalityFlags from './CriticalityFlags'
/* import LastSurveysPanel from './LastSurveysPanel'
 */

interface Props{
    items?:LubricationPointInterface[],
    variant:'content' | 'modal' | 'resume',
}






const EquipmentInfo:React.FC<Props> = ({items,variant}) => {

    const modal = useModal(false)
    const itemsCount = items?.length || 0 
    const carrousellProps = useCarrousell(itemsCount)
    const item = useMemo(()=>items && items[carrousellProps.current],[carrousellProps.current, items])
    
    const { lang } = useLangLabels()
    console.log(item)
    
    const info = useMemo(()=>item?.info,[item?.info])

    const plantTag = useMemo(()=>item?.plantTag,[item?.plantTag])
    const tagTGD = useMemo(()=>item?.tagTGD,[item?.tagTGD])
    const tagFP = item?.tagFP!
    const supplyQuantity = useMemo(()=>info?.supplies?.filter((s)=>s !== lang.infoLubricationPoints.withoutSupplies).length
    ,[info?.supplies, lang.infoLubricationPoints.withoutSupplies] )

    const title = useMemo(()=><>
        {`${item?.equipment} - ${item?.element} - `} <strong>{item?.component}</strong>
    </>,[item?.component, item?.element, item?.equipment])
    
    const infoPanel = useMemo(()=> info && item && <>
        <FlexContainer >
            <ListItem >
                <ListItemIcon >
                    <CustomIcon style={{color:info.leak ? theme?.palette?.error?.main : theme?.palette?.success?.main}} icon='leak'/>
                </ListItemIcon>
                <ListItemText  primary={info.leak ? lang.infoLubricationPoints.withLeak : lang.infoLubricationPoints.withoutLeak} />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <div>
                        <Typography style={{paddingLeft:'10px'}} variant='h6'>
                            <strong>
                                {info.lubricantQuantity}
                            </strong>
                        </Typography>
                    </div>
                </ListItemIcon>
                <ListItemText secondary={lang.infoLubricationPoints.lastAgregate} primary={item.measureUnit} />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <CustomIcon style={{color: info.pending? theme?.palette?.error?.main : theme?.palette?.success?.main}} icon='pending' />
                </ListItemIcon>
                <ListItemText  primary={info.pending ? lang.infoLubricationPoints.pending : lang.infoLubricationPoints.notPending }/>
            </ListItem>
        </FlexContainer>
        <FlexContainer >
            <ListItem>
            <ListItemIcon>
                    <CustomIcon icon={info.updatedToday ? 'check' : 'close' }  />
                </ListItemIcon>
                <ListItemText secondary={lang.infoLubricationPoints.intervenedToday} primary={info.updatedToday ? lang.words.yes : lang.words.no} />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <CustomIcon  icon='calendar' />
                </ListItemIcon>
                <ListItemText secondary={lang.infoLubricationPoints.lastInspection} primary={info.lastInspectionDate.substring(0, 10)} />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <CustomIcon style={{color: item.info?.inaccessible ? theme?.palette?.error.main : theme?.palette?.success.main}} icon={item.info?.inaccessible ? 'error' : 'success'} />
                </ListItemIcon>
                <ListItemText primary={item.info?.inaccessible ? lang.infoLubricationPoints.inaccessible : lang.infoLubricationPoints.accesible} />
            </ListItem>
        </FlexContainer>
        <FlexContainer >
            <ListItem>
                <ListItemIcon>
                    <CustomIcon  icon='calendar' />
                </ListItemIcon>
                <ListItemText secondary={lang.infoLubricationPoints.lastLubrication} primary={info.lastLubrication.substring(0, 10)} />
            </ListItem>
            <ListItem>
                <ListItemIcon>      
                    <CustomIcon style={{
                        fill: 'red orange'
                    }} icon='temperature'  />
                </ListItemIcon>
                <ListItemText secondary={lang.infoLubricationPoints.lastTemperature} primary={`${info.temperature}º`} />
            </ListItem>
        
            <ListItem>
                <ListItemIcon>
                    {/* <PendingSuppliesToAssignR lubricationPoint={item} items={items} /> */}
                </ListItemIcon>
                <ListItemText secondary={lang.infoLubricationPoints.missings} primary={supplyQuantity === 0 ? lang.infoLubricationPoints.withoutSupplies : lang.infoLubricationPoints.supplies} />
            </ListItem>
        </FlexContainer>
        <FlexContainer>
            <ListItem>
                <ListItemIcon>      
                <CustomIcon icon='capacity' />
                </ListItemIcon>
                <ListItemText secondary={lang.infoLubricationPoints.capacity} primary={`${item.capacity} ${item.measureUnit}`} />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <CustomIcon icon='user'/>
                </ListItemIcon>
                <ListItemText secondary={lang.infoLubricationPoints.user} primary={`${item.info?.user}`} />
            </ListItem>
            <ListItem>
                {/* empty */}
            </ListItem>

        </FlexContainer>
    </>,[info, item, lang.infoLubricationPoints.accesible, lang.infoLubricationPoints.capacity, lang.infoLubricationPoints.inaccessible, lang.infoLubricationPoints.intervenedToday, lang.infoLubricationPoints.lastAgregate, lang.infoLubricationPoints.lastInspection, lang.infoLubricationPoints.lastLubrication, lang.infoLubricationPoints.lastTemperature, lang.infoLubricationPoints.missings, lang.infoLubricationPoints.notPending, lang.infoLubricationPoints.pending, lang.infoLubricationPoints.supplies, lang.infoLubricationPoints.user, lang.infoLubricationPoints.withLeak, lang.infoLubricationPoints.withoutLeak, lang.infoLubricationPoints.withoutSupplies, lang.words.no, lang.words.yes, supplyQuantity])


    const LastSurveyPanel = lazy(()=>import('./LastSurveysPanel'))

    const tabs = useMemo(()=>[
        {
            
            label:lang.infoLubricationPoints.lastEquipmentState,
            component:infoPanel
        },
        {
            label:lang.infoLubricationPoints.lastSurveys,
            component:item ? <Suspense fallback='loading...'> <LastSurveyPanel tagTGD={item.tagTGD} tagFP={tagFP} /> </Suspense> : <></>
        }
    ],[infoPanel, item, lang.infoLubricationPoints.lastEquipmentState, lang.infoLubricationPoints.lastSurveys])

    const content = <>
        <PageContainer padding={'24px 0px'} width='100%'>
            <FlexContainer justify='space-betwqeen' gap='16px' width='100%' >
                <FlexContainer flexDirection='column' width={'60%'}>
                    <Title>{title}</Title>
                    <SubTitle><strong>TAG PLANTA: </strong> {plantTag}</SubTitle>
                    <SubTitle><strong>TAG TGD: </strong>{tagTGD}</SubTitle>
                </FlexContainer>
                <FlexContainer width={'40%'}>
                        <CriticalityFlags lubricationPoint={item}/>
                </FlexContainer>
            </FlexContainer>

            <TabContainer
                tabs={tabs} 
                labelSelector={'label'} 
                panelSelector={'component'} 
            />

        </PageContainer>
    </>
    const modalComponent = (callback:()=>void) => 
    <ComponentMode {...{
        modal:modal.modal,
        onOpen:callback,
        onClose:modal.onClose,
        key:'info',
        buttonColor:'inherit',
        icon:'more',
        popoverTitle:'informacion del equipo',
        variant:'iconButton'
    }} >
        <CarrousellToolbar {...carrousellProps}>
            {content}
        </CarrousellToolbar>
    </ComponentMode>

    const isError = useMemo(()=>!!items && items.length > 0,[items] ) 

    return(
       <> 
            {variant === 'modal' &&  
                <HandleUndefined isError={isError} callback={modal.onOpen}>
                    {(callback)=>         
                        modalComponent(callback)
                    }
                </HandleUndefined>}

            {variant === 'content' &&
                <FlexContainer background={'#fff'} borderRadius={'4px'} width={'100%'} flexDirection='column'>
                    <CarrousellToolbar {...carrousellProps}>
                        {content}
                    </CarrousellToolbar>
                </FlexContainer>
            }

            {variant === 'resume' &&
                <Typography component={'div'} variant='body2' color='inherit' style={{width:'100%'}}>
                    <FlexContainer align='center' justify='flex-end'>
                        {`${item?.equipment} `}
                        {`| ${item?.element} `}
                        {`| ${item?.component} |`}
                        {modalComponent(modal.onOpen)}
                    </FlexContainer>
                </Typography>
            }

        </>
    )
}

export default React.memo(EquipmentInfo)