import { FiberManualRecord } from "@material-ui/icons"
import { CrudFormType } from "../../../shared/form/models/FormTypes"
import { useLangLabels } from "../../../shared/lang/services/useLangLabels"
import VirtualGrid, { CarrousellActinInterface } from "../../../shared/react-table/VirtualGrid"
import CUCriticalityForm from "../CUCriticalityForm"
import DeleteCriticalityForm from "../DeleteCriticalityForm"
import { useCriticalitiesGridModel } from "../lang/useCriticalitiesGridModel"
import { CriticalityInterface } from "../models/criticalityInterface"
import { usePlantCriticality } from "../services/service.criticality"

interface Props {
    virtualGridWidth:string
}



const ManageCriticityPage:React.FC<Props> = ({virtualGridWidth}) => {

    const {lang} = useLangLabels()
    const {data:criticalities} = usePlantCriticality()
    
    const icon = (item:any) => {
        return <FiberManualRecord style={{ color: `${item.color}`,marginRight:'16px'}} />
    }

    const carrousellComponents:CarrousellActinInterface = [
        {icon:'edit', popoverTitle:lang.crudActions.update, component:(value:CriticalityInterface)=><CUCriticalityForm item={value} actionType={CrudFormType.update} />}
    ]

    return(

        <VirtualGrid {...{
            height:55,
            width:virtualGridWidth,
            title:lang.criticalities.title.plural,
            items:criticalities,
            gridModel:useCriticalitiesGridModel(),
            renderOption:icon,
            carrousellComponents,
            headerActions:(item:any)=><CUCriticalityForm actionType={CrudFormType.create} />,
            itemActions:(item)=><DeleteCriticalityForm item={item} />
        }}/>
    )
}

export default ManageCriticityPage
