import { Badge, CircularProgress, Paper } from "@material-ui/core"
import { FlagOutlined } from "@material-ui/icons"
import { circularProgressClasses } from "@mui/material"
import { useEffect, useMemo, useState } from "react"
import { Route, Routes,  useNavigate } from "react-router-dom"
import FlexContainer from "../../../components/containers/FlexContainer"
import PageContainer from "../../../components/containers/PageContainer"
import PointerContainer from "../../../components/containers/PointerContainer"
import TgdPopover from "../../../components/popovers/TgdPopover"
import CheckList from "../../checklist/CheckList"
import { colectorHomeSupplies, useCheckListController } from "../../checklist/controllers/useCheckListController"
import { PersonInterface } from "../../person/models/PersonInterface"
import { useSessionContext } from "../../session/store/sessionContext"
import { useLubricatorStore } from "../../users/lubricator/lubricatorStore"
import ColectorRoutesPage from "./ColectorRoutesPage"


/* export const useOperatorDailyData = (operator:PersonInterface) => {

    const {data:lubricationPoints} = useLubricationPointsAndInfoByTagFP()
    const {data:dailyOperatorRoutes} = useDailyRoutesByLubricatorNumber(operator)
    const previous = useLubricationPreviousPendingPointsByOperator(operator)

    const lubricationPointsMapped = useMemo(()=>objectMapper(lubricationPoints,'tagTGD'),[lubricationPoints])

    const operatorDailyPoints:LubricationPointInterface[] = lubricationPointsMapped && dailyOperatorRoutes?.map((route:any)=>{
        const lubricationPoints = route.lubricationPoints.map((point:string)=>({
                tagTGD:point,routes:route.routeName
            })
        )
        return lubricationPoints
    }).flat().map((item)=>{
        return lubricationPointsMapped[item.tagTGD]
    })


    const todayPendingLubricattionPoints = operatorDailyPoints?.filter(point=>point.info.pending)

    const previusPendingLubricationPoints = lubricationPointsMapped && previous?.map((item)=>{
        return lubricationPointsMapped[item.tagTGD]
    })

    const allDayPoints = previusPendingLubricationPoints && todayPendingLubricattionPoints && [
        ...previusPendingLubricationPoints,
        ...todayPendingLubricattionPoints,
    ]

    const {data:permissions} = useDailyPermissions(allDayPoints)
    const {data:lubricants} = useDailyLubricants(allDayPoints)
    const {data:tools} = useDailySupplies(allDayPoints,EnumSupplyTypes.tool)
    const {data:consumables} = useDailySupplies(allDayPoints,EnumSupplyTypes.consumable)
    const {data:protections} = useDailySupplies(allDayPoints,EnumSupplyTypes.protection)

   if (!!operator && !!operatorDailyPoints && !!todayPendingLubricattionPoints && !!previusPendingLubricationPoints && !!dailyOperatorRoutes) return {
        operator,
        operatorDailyPoints,
        todayPendingLubricattionPoints,
        previusPendingLubricationPoints,
        dailyOperatorRoutes,
        allDayPoints,
        permissions,
        lubricants,
        tools,
        consumables,
        protections,
    }
    else return undefined
} */

interface Props {
    operator:PersonInterface
    tagFP:string
}

const LubricatorPanel:React.FC<Props> = () => {
    
    const {selectOperator} = useSessionContext()
    const {data,status} = useLubricatorStore()
    const checkListTypes = colectorHomeSupplies()
    const { notification, routes, icons } = useCheckListController()
    const navigate = useNavigate()

    useEffect(()=>{
        if(data?.operator){
            selectOperator(data.operator)
        }
    },[data?.operator])

    return(<>

        {status === 'loading' && <FlexContainer justify={'center'} align={'center'} height='200px' width='100%'>
                <CircularProgress />
            </FlexContainer>}

        {status === 'success'&&
            <FlexContainer gap='24px' flexWrap='wrap' padding='24px 0px' >
                {checkListTypes.map((type,index)=>
                <TgdPopover key={index} mode='hover' title={type}>
                    <PointerContainer key={type}>
                        <Badge  style={{display:'block'}} color="primary" badgeContent={notification(type) || undefined}>
                            <Paper elevation={3}>
                                <FlexContainer onClick={()=>navigate(`${routes[type]}`)} height='50px' width='100px' align={'center'} justify='center'  padding='16px'>
                                    {icons[type]}
                                </FlexContainer>
                            </Paper>
                        </Badge>
                    </PointerContainer>
                </TgdPopover>
                )}
                <TgdPopover mode='hover' title={'RUTAS'}>
                    <PointerContainer >
                        <Badge  style={{display:'block'}} color="primary" badgeContent={data?.dailyRoutes.length}>
                            <Paper elevation={3}>
                                <FlexContainer onClick={()=>navigate(``)} height='50px' width='100px' align={'center'} justify='center'  padding='16px'>
                                    <FlagOutlined/>
                                </FlexContainer>
                            </Paper>
                        </Badge>
                    </PointerContainer>
                </TgdPopover>
        
                {/* <TgdPopover mode='hover' title={'RELEVAR TODO'}>
                    <PointerContainer>
                        <Badge  style={{display:'block'}} color="primary" badgeContent={allDayPendingPoints.length}>
                            <Paper elevation={3}>
                                crear survey
                                <StartRoute />
                            </Paper>
                        </Badge>
                    </PointerContainer>
                </TgdPopover> */}
        
        
            </FlexContainer>
        
        }
    
        <Routes>        
            <Route
                path={`*`}
                element={ <ColectorRoutesPage />}
            />
            {checkListTypes?.map((type)=>
                <Route 
                key={type}
                path={`${routes[type]}`} 
                element ={
                    <div key={type}>
                        <CheckList checkListType={type} />
                    </div>
                }/>
            )}
        
         </Routes>
    

    </>);
}

export default LubricatorPanel;
