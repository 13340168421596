import { CustomIcon } from "../../../components/buttons/CustomButton";
import FlexContainer from "../../../components/containers/FlexContainer";
import Title from "../../../components/Typography/Title";
import { useLeakLevel } from "./hooks/leakLevel";


interface Props{
    pointCapacity:number
    lastlubricantCuantity:number
}

const LeakLevelComponent:React.FC<Props> = ({pointCapacity, lastlubricantCuantity}) => {

    const { description, leakQuantity } = useLeakLevel({lastlubricantCuantity,pointCapacity})
    
    return(
    <FlexContainer gap={'10px'} align='center'>
	    <CustomIcon icon='leak' style={{color:description}} />
		<div style={{color:description}} >
			% { leakQuantity }
		</div>  
	</FlexContainer>
    ); 

}

export default LeakLevelComponent;
