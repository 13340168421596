import React from 'react';
import { CrudFormType } from '../../shared/form/models/FormTypes';

import FieldSelector from '../../shared/form/components/FieldSelector';
import { UpdateArea, CreateArea, DeleteArea, AreasCRUDDependencies } from './services/service.areas';
import { useAreaForm } from './services/useAreaForm';
import { AreaInterface } from './models/AreaTypes';
import { useLangLabels } from '../../shared/lang/services/useLangLabels';
import { CUEntityFormPropsType, uiFormInterface } from '../../shared/form/crud/types/crudTypes';
import { useCreateUpdate } from '../../shared/form/crud/hooks/useCreateUpdate';
import DefaultFormLayout from '../../components/form/DefaultFormLayout';
import { useSessionContext } from '../session/store/sessionContext';

/* type CUSectorFormProps = {
    tagFP:string
    actionType:CrudFormType.create
    item?:AreaInterface
} | {
    tagFP:string
    actionType:CrudFormType.update
    item:AreaInterface
} */

type props=  CUEntityFormPropsType<AreaInterface>

const CUAreaForm: React.FC<props> = ({item, actionType}) => {

    const{ tagFP } =  useSessionContext()
    
    //DB 
    const { action, resetStatus,status, error, message } = useCreateUpdate({
        create: CreateArea,
        update: UpdateArea,
        delete: DeleteArea
    },actionType, AreasCRUDDependencies)

    //UI
    const { lang } = useLangLabels()

    const { uiModel, handleSubmit, reset } = useAreaForm(tagFP, item)

    const ui = (() => {
        const formName = lang.areas.title.plural 
        switch (actionType) {
            case CrudFormType.create:
                const create:uiFormInterface = {
                    icon:'add',
                    title:`${lang.actionTypes.create} ${formName.toLowerCase()}`,
                    variant:'button',
                    mode:'modal'
                }
                
                return create

            case CrudFormType.update:
                const update:uiFormInterface = {
                    icon:'edit',
                    title:`${lang.actionTypes.update} ${formName.toLowerCase()}`,
                    variant:'iconButton',
                    mode:'content'
                }
                return update
        }
    })()

    //Methods
    const submit = (data:AreaInterface) => {
        action(data)
    }

    const handleReset = () => {
        resetStatus()
        reset()
    }

    return(
        <DefaultFormLayout
            mode={ui.mode}
            icon={ui.icon}    
            variant={ui.variant}
            buttonTitle={ui.title}
            popoverTitle={ui.title}
            {...{status,error,message}} 
            onChange={handleSubmit(submit)}  
            onClose={handleReset} 
        >
            {uiModel.map((props,index)=>
                <FieldSelector key={index} fieldProps={props} />
            )}
        </DefaultFormLayout>
    )
};

export default CUAreaForm;