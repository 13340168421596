import { Box, Divider, Grid, Paper, Typography } from "@material-ui/core"
import FiberManualRecord from "@material-ui/icons/FiberManualRecord"
import { useMemo } from "react"
import FlexContainer from "../../../../components/containers/FlexContainer"
import PageContainer from "../../../../components/containers/PageContainer"
import SimpleChip from "../../../../components/SimpleChip"
import { useDate } from "../../../../shared/date/useDate"
import AssignCancelPermissionForm from "../../../permisions/components/AssignCancelPermissionForm"
import AssignCancelRouteForm from "../../../routes/components/AssignCancelRouteForm"
import { EnumSupplyTypes } from "../../../supply/model/SupplyInterface"
import AssignCancelSupplyForm from "../../../supply/molecules/AssignCancelSupplyForm"
import { supplyParser } from "../../../supply/utils/supplyParser"
import { equipmentType, LubricationPointInterface } from "../../model/lubricationPointsInterface"
import { useEquipmentsByTagFP, useEquipmentsTag } from "../../services/lubricationPointsAdapters"
import CreateLubricationPoint from "./CreateLubricationPoint"
import EditEquipmentComponent from "./EditEquipmentComponent"
import ViewLubricationPoint from "./ViewLubricationPoint"
import TgdEquipmentsTable from "../TgdEquipmentsTable"


interface Props{
	item:any
	type:equipmentType

}

const PointProperty:React.FC<{title:string, value:any}> = ({title,value}) => 
	<FlexContainer flexDirection='column' padding='24px 0px'>
		<Typography >
			{title}:
			<br />
			<strong>
				{value}
			</strong>
			<Divider/>
		</Typography>
	</FlexContainer>

const EquipmentFileInfo:React.FC<{item:any}> = ({item})=> {
	return(<>
		<Grid container spacing={3} >
			<Grid item md={12}>
				<Typography variant='h6'>{item?.tagFP ? item?.tagFP.split('|').map((i:string) => `${i} `):''}</Typography>
				<br/>
				<Divider/> 
			</Grid>
			<Grid item md={4}>
				<Box style={{
					backgroundColor:'#f6f6f6',
					maxWidth:'200px',
					maxHeight:'200px',
					minHeight:'150px',
					minWidth:'150px',
					width:'auto',
					height:'100%',
				}}>
						
				</Box>
			</Grid>
			<Grid item md={8} >
				<Grid container spacing={3}>
					<Grid item md={6}>
						Nombre de equipo:
						<Typography variant='body2'><strong>{item?.equipment}</strong></Typography>
						<Divider/>
						<br/>
						Tag de equipo:
						<Typography variant='body2'><strong>{item?.tagTGD}</strong></Typography>
						<Divider/>
					</Grid>
					<Grid item md={6}>
						Tipo:
						<Typography variant='body2'><strong>{item?.type}</strong></Typography>
						<Divider/>
						<br/>
						Sector: 
						<Typography variant='body2'><strong>{item?.sector}</strong></Typography>   
						<Divider/>
					</Grid>
					<Grid item md={6}>
						Tag de planta:
						<Typography variant='body2'><strong>{item?.plantTag}</strong> </Typography>
						<Divider/> 
					</Grid>
					<Grid item md={6}>
						Criticidad del equipo:
						<Typography variant='body2'><strong>{item?.criticality}</strong></Typography>
						<Divider/> 
					</Grid>
				</Grid>
			</Grid>                       
		</Grid>
		<br/>
		<br/>
		<Divider/>
		<br/>
	</>)
}

const LubricationPointFileTemplate :React.FC<Props> = ({item,type}) => {

	const {getTag} = useEquipmentsTag()
	const {nowYear}= useDate()

	const supplies = supplyParser(item.supplies)
	const permissions = item.permissions

	const {data:Equipments} = useEquipmentsByTagFP() 

	const lubricationPoints = useMemo(()=>{
		switch(type){
			case 'equipment':
				return Equipments?.filter(eq => eq.tagTGD.includes(item.tagTGD))

			case 'element':
				return item.lubricationPoints
		}
	},[Equipments, item.lubricationPoints, item.tagTGD, type])

	const elements = useMemo(()=>{

		let flag:string[] = []
		let result:any = {}

		
		lubricationPoints?.forEach((lp:any)=>{
			const element = lp.element
			if(!!flag.includes(element)){
				result[element] = {
					...result[element],
					lubricationPoints:[
						...result[element].lubricationPoints,
						lp
					]
				}
			}
			else{
				flag.push(element)
				result={
					...result,
					[element]:{
						element: element,
						tagFP:lp.tagFP,
						sector:lp.sector,
						plantTag:lp.plantTag,
						type: lp.type,
						criticality:lp.criticality,
						tagTGD:getTag(lp,equipmentType.element),
						equipment:lp.equipment,
						lubricationPoints: [lp]
					}
				}
			}
		})

		return flag.map((key) =>result[key])

	},[getTag, lubricationPoints])

 
    return (
				<Paper elevation={3} style={{width:'700px',height:'min-content' /* 'calc(700px * 1.41)' */}}>
					<PageContainer>
						
						<EquipmentFileInfo item={item} />
						
						{(type === 'equipment') && <>
							<TgdEquipmentsTable
							height='auto'
							title={`Elementos: ${elements?.length}`}
							items={elements || []}
							type={equipmentType.element} 
							itemActions={(i:any) => <>
								<EditEquipmentComponent item= {i} type={equipmentType.element} />
							</>}/>
							<br/>
							<Divider/>
						</>}
						
						
						{((type === 'equipment') || (type==='element')) && <>
							<TgdEquipmentsTable  
								height='auto'
								title={`Componentes: ${lubricationPoints?.length}`}
								items={lubricationPoints || []}
								type={equipmentType.component} 
								headerAction={<CreateLubricationPoint item={item} {...(type === 'element' ? { element:item.element }: null)}/>}
								itemActions={(i:LubricationPointInterface) => <ViewLubricationPoint item= {i} /> }
							/>
							<br/>
							<Divider/>
						</>}
						
						

						<PageContainer>	
							{(type === 'component') && <>
								<Grid container spacing={3}>
								<Grid item md={3}>
									{item?.brand && <>
										Marca: 
										<div>{item?.brand}</div>
										<br/>
									</>}
								</Grid>
								<Grid item md={3}>
									{item?.model && <>
										Modelo: 
										<div>{item?.model}</div>
										<br/>
									</>}
								</Grid>
								<Grid item md={3}>
									{item?.location && <>
										Ubicación: 
										<div>{item?.location}</div>
										<br/>
									</>}
								</Grid>
								<Grid item md={3}>
								
									Análisis de aceite: 
									<div>{(item?.oilAnalysis ? 'SI':'NO')}</div>
									<br/>
									
								</Grid>
							</Grid>
							<br/>
							<Divider/>
							<br/>

								<FlexContainer justify='space-between' width='100%'>
										<PointProperty title='Estado' value={
											<FlexContainer align='center'> 
												<FiberManualRecord style={{color:!!item?.state ? 'green' : 'gray', marginRight:'16px'}} />{item?.state ? 'Activo' : 'dado de baja'}
											</FlexContainer>
										}/>
										{item?.admissionDate && <PointProperty title='Ingreso' value={item?.admissionDate.slice(0,10).split('-').reverse().join('-')}/>} 
										{(parseInt(item?.egressDate?.slice(0,4)) <= nowYear) && <PointProperty title='Egreso' value={item?.egressDate.slice(0,10).split('-').reverse().join('-')}/>}
										{item?.review && <PointProperty title='Revisión' value={item.review}/>}
										{item?.labelId && <PointProperty title='Id etiqueta' value={item.labelId}/>}
								</FlexContainer>

								{item?.lowReason && <PointProperty title='Motivo de baja' value={item.lowReason || 'Nunca dadod e baja'}/>}
						
								<Grid container spacing={0}>
									<Grid item md={9}>
										{!!!item?.function && <PointProperty title='Función' value={item.function || 'sin funcion'}/>}
									</Grid>
								</Grid>
							
								<FlexContainer flexWrap='wrap' width='100%' justify='space-between'>
									{item?.element && <PointProperty title='Elemento' value={item.element}/>}
									{item?.component && <PointProperty title='Componente' value={item?.component}/>}
									{item?.lubricant && <PointProperty title='Lubricante' value={item.lubricant}/>}
									{item?.lubricantType && <PointProperty title='Tipo' value={item.lubricantType}/>}
									{item?.lubricant && <PointProperty title='Capacidad' value={`${item.capacity} ${item.measureUnit}`}/>}
									{item?.suggestedQuantity && <PointProperty title='Cantidad sugerida' value={`${item.suggestedQuantity}`}/>}
								</FlexContainer>

								{!!!item?.observations && <PointProperty title='Observations' value={item.observtions || 'sin observaciones'}/>}
									
								<br/>
								<Divider/>
								<br/>
								{item?.routes && <>
									<FlexContainer align='center' flexWrap='wrap'> 
										{<AssignCancelRouteForm item={item}/>}
										{JSON.parse(item?.routes).map((r:string,index:number) => 
											<SimpleChip key={index} label={r} type={'route'} />
										)}
									</FlexContainer>
								</>}
								<br/>
								<Divider/>
								<br/>
								{item?.supplies && <>
									{/* herramientas */}
									<FlexContainer align='center' flexWrap='wrap'> 											
										<AssignCancelSupplyForm type={EnumSupplyTypes.tool} item={item}/> 
										{supplies.tools.map((t,index)=>
											<SimpleChip key={index} type='supply' label={t} />
										)}											
									</FlexContainer>
									<br/>
									<Divider/>
									<br/>
									{/* consumibles */}
									<FlexContainer align='center' flexWrap='wrap'> 
										<AssignCancelSupplyForm type={EnumSupplyTypes.consumable} item={item}/>
										
										<br />
										{supplies.consumables.map((t,index)=>
											<SimpleChip key={index} type='supply' label={t} />
										)}
									</FlexContainer>
									<br/>
									<Divider/>
									<br/>
									{/* protecciones */}
									<FlexContainer align='center' flexWrap='wrap'> 
										<AssignCancelSupplyForm type={EnumSupplyTypes.protection} item={item}/>
										<br />
										{supplies.securityItems.map((t,index)=>
											<SimpleChip key={index} type='supply' label={t} />
										)}
									</FlexContainer>
									<br/>
									<Divider/>
									<br/>
								</>}
								{item?.permissions && <>
									<FlexContainer align='center' flexWrap='wrap'> 
										<AssignCancelPermissionForm item={item}/>
										{permissions.map((p:string,index:number)=>
											<SimpleChip key={index} type='supply' label={p} />
										)}
									</FlexContainer>
									<br/>
									<Divider/>
									<br/>
								</>}

							
						</>}
						</PageContainer>
					</PageContainer>
				</Paper>
		
    );
}

export default LubricationPointFileTemplate;
