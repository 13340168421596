import { useEffect, useState } from "react"
import { getBrowserLocales } from "../../globals/utils/utils"
import { useQueryPersistanceState } from "../../react-query/hooks/useQueryPersistanceState"
import { LangInterface, LangType } from "./interfaces"






export const useSelectedLanguage= ():[(lang:LangType)=>void, LangInterface] => {

    const [selectedLanguage,setValue] = useQueryPersistanceState('lang',{lang:undefined})

    const setLang = (lang:LangType) =>{
        setValue({
           lang
        })
    } 

    return [setLang,selectedLanguage!]

}