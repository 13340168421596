import React from 'react';
import SimpleAutocompleteSelect from '../../../components/autocomplete/SimpleAutocompleteSelect';
import { CrudFormType } from '../../../shared/form/models/FormTypes';
import { useLangLabels } from '../../../shared/lang/services/useLangLabels';
import { useUser } from '../../session/store/sessionStore';
import CUElementForm from '../CUElementForm';
import {usePlantElements} from '../services/service.elements'

import { ElementInterface } from '../models/ElementInterface';

interface props{
    error?:string | undefined 
    tagFP:string
    onChange:(value:any)=>void
    defaultValue?:string | undefined | null
    complete?:boolean
}

const SelectElement:React.FC<props> = ({error, tagFP, onChange, defaultValue, complete}) => {

    const {lang} = useLangLabels()
    const showTitle = 'element'
    const label = lang.elements.title.singular
    const {data:user} = useUser()
    const {data:elements} = usePlantElements()
     
    const handleChange = (value:string) => {
        if(complete){
            const resultComplete = elements?.find((a)=>a[showTitle] === value) as ElementInterface
            onChange(resultComplete)
        }else{
            const result = value as string 
            onChange(result)
        }
    }
    
   
    return (<>
            <SimpleAutocompleteSelect
                {...{
                    error,
                    label,
                    showTitle,
                    defaultValue,
                    items:elements || [],
                    onChange:handleChange,
                }}
            >
                {user?.type !== 'O' && <CUElementForm actionType={CrudFormType.create} tagFP={tagFP}/>}
            </SimpleAutocompleteSelect>
    </>);

}

export default React.memo(SelectElement);
