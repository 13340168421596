import React from 'react'
import SimpleAutocompleteSelect from '../../../components/autocomplete/SimpleAutocompleteSelect';
import { CrudFormType } from '../../../shared/form/models/FormTypes';
import { useLangLabels } from '../../../shared/lang/services/useLangLabels';
import { useSessionContext } from '../../session/store/sessionContext';
import { useUser } from '../../session/store/sessionStore';
import CUContainerForm from '../CUContainerForm';
import { ContainersInterface } from '../model/ContainerInterface';
import { useAllContainersByTagFP } from '../services/service.containers';


type Props = {
    error?:string
    complete?:boolean
    onChange:(value:any) =>void
    defaultValue?:string
    by?:{
        key:string
        value:string
    }
}

const SelectContainerBy:React.FC<Props> = ({error, complete, onChange, defaultValue ,by}) => {

    const key='name'
    const {lang} = useLangLabels()
    const {currentPlant,user} = useSessionContext()
    const tagFP =  currentPlant?.tagFP!

    const {data:containers} = useAllContainersByTagFP()
    const containersBy = (by ? containers?.filter((l)=>l[by.key as keyof ContainersInterface] === by.value) : containers) || []
    const label = lang.containers.title.singular

    const handleChange = (value:string) => {
        if(complete){
            const con = containersBy.find((l)=>l[key] === value) as ContainersInterface
            onChange(con)
        }else{    
            onChange(value)
        }
    }
    return(
    <SimpleAutocompleteSelect 
    {...{
        error,
        label,
        items:containersBy || [],
        defaultValue,
        onChange:handleChange,
        showTitle:key
    }} >
        {user?.type !== 'O' && <CUContainerForm {...{tagFP,actionType:CrudFormType.create}} />}
    </SimpleAutocompleteSelect>)
}

export default SelectContainerBy