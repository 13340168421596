import { TextField } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import FlexContainer from '../../../../components/containers/FlexContainer';
import DefaultFormLayout from '../../../../components/form/DefaultFormLayout';
import { errorsLog } from '../../../../shared/globals/utils/utils';
import { useStatusProcessor } from '../../../../shared/queries/StatusProcessor';
import SelectCriticality from '../../../criticalities/components/SelectCriticality';
import { CriticalityTypes } from '../../../criticalities/models/criticalityInterface';
import { Plant } from '../../../plants/PlantInterface';
import SelectSector from '../../../sectors/atom/SelectSector';
import { useSessionContext } from '../../../session/store/sessionContext';
import SelectTypeBy from '../../../types/components/SelectTypeBy';
import { enumTgdTypes } from '../../../types/models/types';
import { EquipmentInterface, equipmentType } from '../../model/lubricationPointsInterface';
import { useEquipmentsTag, useUpdateEquipmentNoImpact } from '../../services/lubricationPointsAdapters';

interface Props{
    item:EquipmentInterface
}



const UpdateEquipment:React.FC<Props> = ({item}) => {

    const {token,tagFP} = useSessionContext()
    const {register,setValue,handleSubmit,watch,errors,control} = useForm()
    const query = useUpdateEquipmentNoImpact()
    const {status,message,error} = useStatusProcessor(query)
    const {getTag} = useEquipmentsTag()

    const submit = (data:EquipmentInterface) => {
        query.mutate({
            data:{
                tagFP:item?.tagFP,
                tagTGD:`${getTag(item,equipmentType.equipment)}`,
                sector: data.sector.toUpperCase(),
                plantTag: data.plantTag.toUpperCase(),
                equipment: data.equipment.toUpperCase(),
                type: data.type.toUpperCase(),
                criticality:data.criticality.toUpperCase()
            },
            token
        })
    }

    useEffect(()=>{
        register('criticality',{required:{value:true,message:'Criticidad requerida'}})
    },[register])
   
    errorsLog(errors)

    return (<>

            <DefaultFormLayout 
            onChange={handleSubmit(submit)} 
            status={status} 
            message={message} 
            error={error}
            buttonTitle='editar Equipo' 
            icon='edit' variant='iconButton' popoverTitle='Editar Equipo'>
                
                <TextField
                inputRef={register({
                    required:{value:true,message:'Campo Obligatorio'},
                })} 
                name="plantTag" 
                placeholder='Codigo de planta' 
                defaultValue={item?.plantTag}
                variant='outlined'
                label={errors?.plantTag?.message || 'Codigo de planta'} 
                color={errors?.plantTag?.message  ? 'primary' : 'secondary'}
                size="small"
                />
                

                <TextField
                inputRef={register({
                    required:{value:true,message:'Campo Obligatorio'},
                })} 
                name="equipment" 
                placeholder='Nombre del Equipo' 
                defaultValue={item?.equipment}
                variant='outlined'
                label={errors?.equipment?.message || 'Nombre del Equipo'} 
                color={errors?.equipment?.message ? 'primary' : 'secondary'}
                size="small"
                />

                <Controller
                as={<SelectSector
                    error={errors?.sector?.message } 
                    onChange={(value:string)=>setValue('sector',value)}
                    defaultValue={watch('sector',item?.sector)}
                    />}
                name='sector'
                defaultValue={item?.sector}
                rules={{required:{value:true,message:'Campo Obligatorio'}}}
                control={control}
                />

                <Controller
                as={<SelectTypeBy
                    error={errors?.type?.message}
                    by={{key:'type',value:enumTgdTypes.equipment}}
                    tagFP={tagFP}
                    onChange={(value)=>setValue('type',value)}
                    defaultValue={watch('type')}
                    />}
                name='type'
                defaultValue={item?.type}
                rules={{required:{value:true,message:'Campo Obligatorio'}}}
                control={control}
                />
               
                <SelectCriticality
                    by={{key:'criticalityType', value:CriticalityTypes.equipment}}
                    error={errors?.criticality?.message}
                    color={errors?.criticality?.message ? 'primary' : 'secondary'}
                    onChange={(value)=>setValue('criticality',value)}
                    defaultValue={watch('criticality',item?.criticality)}
                />
               
            </DefaultFormLayout>
 </>);
}

export default UpdateEquipment;
