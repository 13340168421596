import { useMemo } from "react"
import { apiCall, } from "../../../shared/axios/axios-config"
import { useQuery } from "../../../shared/react-query/react-query-conf"
import { useSessionContext } from "../../session/store/sessionContext"
import { getViewDateDay } from "../../../shared/date/utils"
import { useLubricationPointsWithLeakLevel } from "./lubricationPointsAdapters"

import { LubricationPointInterface } from "../model/lubricationPointsInterface"
import { LubricantInterface } from "../../lubricant/model/LubricantInterface"
import { 
    PrivateQueryData, 
    ItemsAndPaginationResponseInterface, 
    PaginationInterface,
    FilterInterface,
} from "../../../shared/globals/utilsGlobalTypes"

// ----------------------------------------------------------------------------------------------------
// Interfaces

type PaginatedLubricationPointsAndInfoInterface<T = any> = PaginationInterface & FilterInterface<T> & {
    tagFP: string
}

// ----------------------------------------------------------------------------------------------------
// Services

export const PaginatedLubricationPointsAndInfo = (
    { data, token }: PrivateQueryData <PaginatedLubricationPointsAndInfoInterface <LubricationPointInterface> >
    ) => {
        return apiCall< ItemsAndPaginationResponseInterface<LubricationPointInterface, PaginatedLubricationPointsAndInfoInterface> >({
            method: "POST",
            url: `/PaginatedLubricationPointsAndInfo`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data
        })
}

const suppliesAdapter = (supplies: any): LubricationPointInterface['supplies'] => {
    return JSON.parse(supplies || '["SIN INSUMOS"]')
}

export const lubricationPointsByTagFPAdapter = (lubricationPoints: LubricationPointInterface[]): LubricationPointInterface[] => {
    const items = lubricationPoints.map((point: LubricationPointInterface) => ({
        id: point.id,
        tagTGD: point.tagTGD,
        sector: point.sector,
        plantTag: point.plantTag,
        tagFP: point.tagFP,
        equipment: point.equipment,
        type: point.type,
        criticality: point.criticality,
        state: point.state,
        egressDate: point.egressDate,
        lowReason: point.lowReason,
        admissionDate: point.admissionDate,
        brand: point.brand,
        capacity: point.capacity,
        component: point.component,
        element: point.element,
        function: point.function,
        labelId: point.labelId,
        location: point.location,
        lubricant: point.lubricant,
        lubricantType: point.lubricantType,
        measureUnit: point.measureUnit,
        model: point.model,
        observations: point.observations,
        oilAnalysis: point.oilAnalysis,
        review: point.review,
        routes: point.routes,
        supplies: suppliesAdapter(point.info?.supplies),
        permissions: point.permissions,
        tagTGDBefore: point.tagTGDBefore,
        info: !point.info ? undefined :  {
            id: point.info.id,
            tagTGD: point.info.tagTGD,
            supplies: suppliesAdapter(point.info?.supplies),
            labelReplacement: point.info.labelReplacement,
            lastLubrication: point.info.lastLubrication,
            lubricantQuantity: point.info.lubricantQuantity,
            leak: point.info.leak,
            pending: point.info.pending,
            updatedToday: point.info.updatedToday,
            user: point.info.user,
            lastSurveyId: point.info.lastSurveyId,
            assetCriticality: point.info.assetCriticality,
            inaccessible: point.info.inaccessible,
            tagFP: point.info.tagFP,
            lastInspectionDate: point.info.lastInspectionDate,
            updateReview: point.info.updateReview,
            temperature: point.info.temperature,
        },
        suggestedQuantity: point.suggestedQuantity || ''
    }))

    return items;
}

// ----------------------------------------------------------------------------------------------------
// Adapters

export const usePaginatedLubricationPointsAndInfo = (
    { pageIndex, pageSize }: { pageIndex: number, pageSize: number },
    { filters, searchValue }: { filters: any, searchValue: string }
    ) => {
    const { currentPlant, token } = useSessionContext()
    const tagFP = currentPlant?.tagFP ?? ""

    const data = {
        tagFP,
        pageIndex,
        pageSize,
        filters,
        searchValue
    }

    return useQuery<ItemsAndPaginationResponseInterface<LubricationPointInterface, PaginatedLubricationPointsAndInfoInterface>>({
        queryKey: ["PaginatedLubricationPointsAndInfo", pageIndex, pageSize, filters, searchValue], 
        queryFn: () => PaginatedLubricationPointsAndInfo({ data, token }),
        enabled: !!tagFP && !!token,
        select: (data) => {
            return {
                items: lubricationPointsByTagFPAdapter(data.items),
                pagination: {
                    ...data.pagination
                }
            }
        },
        staleTime: 8*60*60*1000
    })
}

export const usePaginatedEquipmentsWithCompleteInfo = (
        paginationOptions: { pageIndex: number, pageSize: number },
        filterOptions: { filters: any, searchValue: string },
        selectFn?: (data: LubricationPointInterface[]) => LubricantInterface[], 
    ) => {
    const { data: { items: equipments, pagination } = {} } = usePaginatedLubricationPointsAndInfo(paginationOptions, filterOptions)
    
    const lubricationPointsquery = useLubricationPointsWithLeakLevel(equipments)

    const dataComposition = (i: LubricationPointInterface) => ({
        //fix interface from this source of data
        ...i,
        admissionDate: getViewDateDay(i.admissionDate),
        egressDate: getViewDateDay(i.egressDate),
        info: {
            ...i.info,
            lastInspectionDate: getViewDateDay(i.info?.lastInspectionDate),
            lastLubrication: getViewDateDay(i.info?.lastLubrication),
        }
    })

    const result = useMemo((): any => lubricationPointsquery?.map(dataComposition), [lubricationPointsquery])

    const data = {
        items: selectFn && result ? selectFn(result) : result,
        pagination
    }

    return { data }
} 