import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import AssignCancelPermission from './AssignCancelPermission';
import { usePermisionsByEquipment, usePermissionsByTagFP } from '../services/service.permission';
import ModalButton from '../../../components/buttons/ModalButton';
import { PermissionInterface } from '../model/PermissionInterface';
import { LubricationPointInterface } from '../../lubricationPoints/model/lubricationPointsInterface';
import { useLubricationPointByTagTGD } from '../../lubricationPoints/services/lubricationPointsAdapters';
import TgdTable, { TgdTableInterface } from '../../../components/grids/TgdTable';

interface Props{
    item:LubricationPointInterface
}

const useLubricationPointWithPermissions = (tagTGD:string) => {
    const lubricationPoint = useLubricationPointByTagTGD(tagTGD) 
    const permissions = usePermisionsByEquipment(tagTGD)
    
    const lubricationPointWithPermissions = {
        ...lubricationPoint,
        status:permissions.status,
        data:{
            ...lubricationPoint.data,
            permissions:permissions.data?.map((permission)=>permission.permissionName)
        }
    }

    return lubricationPointWithPermissions

}

const AssignCancelPermissionForm:React.FC<Props> = ({item}) => {

    const {data:permissions,status,error}= usePermissionsByTagFP()
    const {data:LubricationPoint} = useLubricationPointWithPermissions(item.tagTGD)


    const columns:TgdTableInterface[] = [
        {label:'ID',atribute:'id'},
        {label:'Nombre',atribute:'permissionName'},
    ]

    return (
        <ModalButton
            variant='chip'
            popoverTitle='Gestionar permisos del equipo' 
            icon='config' 
            buttonText='Permisos'
            title={`Asignar  permisos al equipo: ${LubricationPoint?.plantTag}`}
        >
            <>
                {error && <><Alert severity='error' > Ha ocurrido un error </Alert><br/></>}
                {status === 'loading' ?<CircularProgress size='small'/>:
                
                    <TgdTable
                    title='Permisos'
                    items={permissions}
                    columns={columns}
                    type={'permission'} 
                    itemActions={(permission:PermissionInterface)=><AssignCancelPermission equipment={LubricationPoint} permission={permission}/>}
                    />
                }
            </>
        </ModalButton>
    ); 
}

export default React.memo(AssignCancelPermissionForm);
