import { useCallback, useMemo } from "react"

export const useDate = () => {
    const date = useMemo(()=>new Date(),[])
    const day = useMemo(()=>date.getDate(),[date])
    const month = useMemo(()=>date.getMonth() + 1,[date])
    const year = useMemo(()=>date.getFullYear(),[date])

    const nowDate = useCallback(() => {
        if(month < 10){
            return `${day}-0${month}-${year}`
        }else{
            return `${day}-${month}-${year}`
        }
    },[day, month, year])

    const isValidDateFormat = (dateString: string) => {
        const fullDateFormats = [
            /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}\+\d{2}:\d{2}$/,    // "2023-07-04T00:00:00.000+00:00"  // DB
            /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/,                // "2023-07-04T00:00:00.000Z"       // ISO
            /^\w{3}, \d{2} \w{3} \d{4} \d{2}:\d{2}:\d{2} GMT$/,             // "Tue, 04 Jul 2023 00:00:00 GMT"  // UTC
            /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/,                        // "2023-07-04T00:00:00"
            /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/,                       // "2023-07-04T00:00:00Z"
            /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\+\d{2}:\d{2}$/,           // "2023-07-04T00:00:00+00:00"
            /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/,                              // "2023-07-04 00:00"
        ];

        const dateFormats = [
            /^\d{4}-\d{2}-\d{2}$/,                                          // "2023-07-04"
            /^\d{4}\/\d{2}\/\d{2}$/,                                        // "2023/07/04"
        ]

        const isDateFormat = dateFormats.some((regex) => regex.test(dateString))
        const isFullDateFormat = fullDateFormats.some((regex) => regex.test(dateString))

        return { isDateFormat, isFullDateFormat }
    }

    const ISOToDateTime = (isoDateTime:string, showSeconds: boolean = true): string => {
        const date = new Date(isoDateTime)
        const localTime = date.toLocaleString(undefined, {
            day: "2-digit", 
            month: "2-digit", 
            year: "numeric", 
            hour: "2-digit", 
            minute: "2-digit",
            second: showSeconds ? "2-digit" : undefined,
        }).split(', ')[1]
        
        const isoDate = date.toISOString().split('T')[0]

        const inputTodayDate = `${isoDate}T${localTime}`
        
        return inputTodayDate
    }

    const fromISOToDate = (iso: string) => {
        const date = new Date(iso);
        const localeDate = date.toLocaleString(undefined, { 
            day: "2-digit", 
            month: "2-digit", 
            year: "numeric", 
        });
        return localeDate;
    }


    const fromISOToDateTime = (iso: string, showSeconds: boolean = true) => {
        const date = new Date(iso)
        return date.toLocaleString(undefined, { 
            day: "2-digit", 
            month: "2-digit", 
            year: "numeric", 
            hour: "2-digit", 
            minute: "2-digit",
            second: showSeconds ? "2-digit" : undefined,
        });
    }

    const fromFormattedDateToDate = (iso: string) => {
        const [year, month, day] = iso.split("-")
        return `${day}/${month}/${year}`;
    }
 
    const isToday = (isoDateTime:string): boolean => {
        const today = new Date().toISOString().split('T')[0]
        const compare = isoDateTime.split('T')[0]

        return today === compare
    } 

    return {
        nowDate,
        nowDay:(day < 10) ? `0${day}` : day,
        nowMonth:(month < 10) ? `0${month}` : month,
        nowYear:year,
        ISOToDateTime,
        fromISOToDate,
        fromISOToDateTime,
        fromFormattedDateToDate,
        isValidDateFormat,
        isToday
    }
}