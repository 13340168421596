import { CrudFormType } from "../../../../shared/form/models/FormTypes"
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels"
import VirtualGrid, { CarrousellActinInterface } from "../../../../shared/react-table/VirtualGrid"
import CUComponentForm from "../../CUComponentForm"
import DeleteComponent from "../../DeleteComponent"
import { useComponentsGridModel } from "../../lang/useComponentsGridModel"
import { ComponentInterface } from "../../models/ComponentInterface"
import { usePlantComponents } from "../../services/service.components"


export interface Props{
    virtualGridWidth:string
}

const ManageComponentPage:React.FC<Props> = ({virtualGridWidth}) => {

    const {lang} = useLangLabels()
    const {data:components} = usePlantComponents()

    const carrousellComponents:CarrousellActinInterface = [
        {icon:'edit', popoverTitle:lang.crudActions.update, component:(item:ComponentInterface)=><CUComponentForm item={item} actionType={CrudFormType.update} />}
    ]

    return(
    
        <VirtualGrid
            {...{
                width:virtualGridWidth,
                title:lang.components.title.plural,
                gridModel:useComponentsGridModel(),
                items:components,
                headerActions:(item:any)=><CUComponentForm  actionType={CrudFormType.create} />,
                carrousellComponents
            }}
        >
            {(items)=>items.length === 1
                ? <DeleteComponent item={items[0]} />
                : <></>
            }   
        </VirtualGrid>
    )
}

export default ManageComponentPage
