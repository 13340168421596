import React from "react"
import { CircularProgress, Divider, Grid, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Paper, Typography } from "@material-ui/core"
import PageContainer from "../../../../components/containers/PageContainer"
import { Outlet} from "react-router-dom"
import FlexContainer from "../../../../components/containers/FlexContainer"
import { CustomIcon } from "../../../../components/buttons/CustomButton"
import PieChart from "../../../../shared/chart.js/charts/PieChart"
import TgdPopover from "../../../../components/popovers/TgdPopover"
import { QueryStatus } from "react-query"
import StatusAndMessage from "../../../../shared/queries/errors/components/StatusAndMessage"
import ReScheduleRoute from "../../../../domains/routes/components/ReScheduleRoute"
import { useSessionContext } from "../../../../domains/session/store/sessionContext"
import { usePlantOperators } from "../../../../domains/person/services/personServices"
import { useRoutesByDate, useRoutesByTagFPAndState } from "../../../../domains/routes/services/service.routes"
import RouterTabs, { useRouteTabProps } from "../../../../components/containers/tabs/RouterTabs"
import FeatureStatusContainer, { useFeatures } from "../../../../shared/config/features/FeatureStatusContainer"
import ReportsPage from "./ReportsPage"
import LubricatorPanel from "../../../../domains/surveys/pages/LubricatorPanel"


const RoutesPieChart:React.FC<{routes:any[]}> = ({routes}) => {

    const todayRoutes = routes.filter(route=>!route.state).map((item,index)=>index).length || 1
    const delayedRoutes = routes.filter(route=>route.state === 'ATRASADA').map((item,index)=>index).length || 1
    const pendingRoutes = routes.filter(route=>route.state === 'INCUMPLIDA' || route.state === 'PROGRAMADA INCUMPLIDA').map((item,index)=>index).length || 1

    const dataset = [{
        label:'Routes',
        data:[ todayRoutes, delayedRoutes, pendingRoutes ],
        backgroundColor:['green','gray','red']
    }]

    return(
        
        <FlexContainer maxWidth='30vh' align='center' margin='auto auto' padding='24px 8px 8px 8px' justify='center'>
            <PieChart
                key={routes.length}
                chartValues={dataset}
                labels={['HOY', 'ATRASADAS', 'PENDIENTES',]}
                title='Resumen de rutas'
            />
        </FlexContainer>
    )
}

const CardTitle:React.FC<{title:string,popoverTitle?:string,status?:QueryStatus,popoverChild?:React.ReactNode}> = ({popoverChild, title,popoverTitle, status}) => {

    return(
        <Typography variant='h6'>
            <FlexContainer justify='space-between' padding={'8px 24px 0px 24px'}>
                {title} 
                <div>
                    <TgdPopover mode='hover' title={popoverTitle || ''} hoverChildren={popoverChild}>
                        {(status && status === 'loading')
                        ? <CircularProgress size='24px' />
                        :<CustomIcon icon='info'/>}
                    </TgdPopover>
                </div>
            </FlexContainer>
            <Divider />
        </Typography>
    )
}

const HomeCard:React.FC<{children:React.ReactNode}> = ({children}) => {
    return(
    <Grid item md={3} lg={3} sm={6} xs={1}>
        <Paper style={{height:'35vh',overflowY:'scroll'}}>
            {children && children}
        </Paper>
    </Grid>
    )
}

const SupervisorHomePage:React.FC<{}> = () => {

    const features = useFeatures()
    const {tagFP,selectOperator} = useSessionContext()
    /* const {setSelectedOperator,state:{status}} =  useLubricatorStoreContext() */
    /* const {data} = usePersistedLubricatorQuery() */
    const {data:routes,status:dayRoutesStatus,error} = useRoutesByDate()
    const {data:routesbytagFPAndstate} = useRoutesByTagFPAndState()
    const {data:operators} = usePlantOperators(tagFP)


    return <>

        <PageContainer padding={'0px'}>
            <Grid container spacing={2}>
                <HomeCard>
                    <RoutesPieChart routes={[
                        ...routes || [],
                        ...routesbytagFPAndstate || []
                    ]} />
                </HomeCard>
                
                <HomeCard>
                    <StatusAndMessage status={dayRoutesStatus} message='' error={error} />
                    <CardTitle status={dayRoutesStatus} title='Rutas de hoy' popoverTitle='Rutasque tocan hoy'/>
                    <List>
                        {routes?.map((route:any,index:number)=>
                            <ListItem key={index}>
                                <ListItemIcon>
                                    <CustomIcon icon='flag' />
                                </ListItemIcon>
                                <ListItemText>{route.route}</ListItemText>
                                <ListItemSecondaryAction>
                                {/*  <ModalButton fullwidth={true} variant='iconButton' icon='more'>
                                    </ModalButton> */}
                                </ListItemSecondaryAction>
                                {/* <Divider/> */}
                            </ListItem>
                        )}
                    </List>
                </HomeCard>

                <HomeCard>
                    <CardTitle 
                    title='Rutas atrasadas' 
                    popoverChild={
                        <Typography 
                        variant='body2' 
                        style={{maxWidth:'250px'}}>
                            Rutas que no hubo posibilidad de hacer, y directamente no se pudieron asignar como pendientes del dia, causalidades: Parada de planta, feriados, etc...
                        </Typography>
                    } />
                    {!routesbytagFPAndstate && <FlexContainer justify={'center'} align={'center'}>
                            <CircularProgress size= '24px' />
                        </FlexContainer> 
                    }
                    <List>
                            {routesbytagFPAndstate?.filter((route)=>route.state === 'ATRASADA').map((route,index)=>
                                <ListItem key={index}>
                                    <ListItemIcon>
                                        <ReScheduleRoute item={route} />
                                    </ListItemIcon>
                                    <ListItemText>{route.routeName}</ListItemText>
                                    <ListItemIcon>
                                        {/* <ModalButton fullwidth={true} variant='iconButton' icon='more'>
                                        </ModalButton> */}
                                    </ListItemIcon>
                                    {/* <Divider/> */}
                                </ListItem>
                            )}
                    </List>
                </HomeCard>

                <HomeCard>
                    <CardTitle 
                    title='Rutas pendientes' 
                    
                    popoverChild={
                        <Typography 
                        variant='body2' 
                        style={{maxWidth:'250px'}}>
                            Rutas que fueron asignadas a un operador como pendientes, y no fueron comenzadas el día que tocaban en ruta
                        </Typography>
                    } />
                    
                    <List>
                        {!routesbytagFPAndstate && <FlexContainer justify={'center'} align={'center'}>
                            <CircularProgress size= '24px' />
                        </FlexContainer>  }
                        {routesbytagFPAndstate?.filter((route)=>((route.state === 'INCUMPLIDA') || (route.state === 'PROGRAMADA INCUMPLIDA') )).map((route)=>
                            <ListItem key={route.routeName} >
                                <ListItemIcon>
                                    <ReScheduleRoute item={route} />
                                </ListItemIcon>
                                <ListItemText>{route.routeName}</ListItemText>
                                <ListItemIcon>
                                    {/* <ModalButton fullwidth={true} variant='iconButton' icon='more'>
                                    </ModalButton> */}
                                </ListItemIcon>
                                {/* <Divider/> */}
                            </ListItem>
                        )}
                    </List>
                </HomeCard>
            </Grid>
        </PageContainer>


        <br />
        <RouterTabs {...{
            ...useRouteTabProps(3,[
                {
                    label:'Reportes',
                    route:'',
                    component: <ReportsPage/>,
                },
                 ...operators ? operators.map((operator)=>({
                    label:operator.name,
                    route:operator.name,
                    component: /* status === 'loading' */ false 
                    ?<FlexContainer justify={'center'} align={'center'} height='200px' width='100%'>
                        <CircularProgress />
                    </FlexContainer>
                    :<FeatureStatusContainer feature={features.supervisorSurvey}>
                        <LubricatorPanel operator={operator} tagFP={tagFP} />,
                    </FeatureStatusContainer>,
                    data:operator
                })) : [],
            ],{
                onClick:selectOperator
            })
        }} />

        <Outlet/>

    </>
}

export default React.memo(SupervisorHomePage)