
import React, { useCallback } from 'react'
import { useState } from 'react';
import { useAssignMultipleRoutesToMultipleEquipments, useRoutesByTagFP } from '../services/service.routes';

import TgdSelectableTable from '../../../components/grids/TgdSelectableTable';
import { Alert } from '@material-ui/lab';
import CustomButton from '../../../components/buttons/CustomButton';
import { useEquipmentsByTagFP } from '../../lubricationPoints/services/lubricationPointsAdapters';
import { useModal } from '../../../components/containers/modal/hooks/useModal';
import ComponentMode from '../../../components/componentMode/ComponentMode';
import { useLubricantsGridModel } from '../../lubricant/lang/useLubricantsGridModel';
import { useLubricationPointsGridModel } from '../../lubricationPoints/lang/useLubricationPointsGridModel';
import { useRoutesGridModel } from '../lang/useRoutesGridModel';
import FlexContainer from '../../../components/containers/FlexContainer';


const AssignCancelMultipleRoutes:React.FC<{}> = () => {

    const [step,setStep] = useState(1)
    const {data:equipments} = useEquipmentsByTagFP()
    const {data:routes} = useRoutesByTagFP()

    const [selectedEquipments, setSelectedEquipments ] = useState([])
    const [selectedRoutes, setSelectedRoutes ] = useState([])

    const { handleAssigCancel,status,reset } = useAssignMultipleRoutesToMultipleEquipments()
    
    const handleAssign = () =>{
        handleAssigCancel(selectedEquipments,selectedRoutes,'A')  
    }
    
    const handleReset = () => {
        reset()
        setSelectedEquipments([])
        setSelectedRoutes([])
        setStep(1)
    }

    const lubricationPointsGridModel = useLubricationPointsGridModel()
    const routesGRidModel = useRoutesGridModel()



    return(
        <ComponentMode icon='map' popoverTitle='Asignar múltiples rutas a múltiples equipos'>

            {status === 'error' && <><Alert severity='error'>Ha ocurrido un error</Alert></>}
            {status === 'success' && <><Alert>Asignaciones realizadas</Alert></>}
            {step === 1 &&
                <TgdSelectableTable
                    title='Seleccionar equipos'
                    items={equipments}
                    gridModel={lubricationPointsGridModel} 
                    headerActions={<CustomButton icon='next'popoverTitle='Próximo paso' {...(selectedEquipments?.length > 0) ? {disabled:false} : {disabled:true}}  action={()=>setStep(2)}/>}
                    handleChange={setSelectedEquipments}
                />
            }
               
            {step === 2 && 
                <TgdSelectableTable
                    title='Rutas a asignar'
                    items={routes}
                    gridModel={routesGRidModel} 
                    headerActions={<FlexContainer>
                    <CustomButton icon='before' popoverTitle='Atras(resetear)' action={handleReset}></CustomButton>
                    <CustomButton icon='save' popoverTitle='Guardar asignaciones' action={handleAssign} {...(selectedRoutes?.length > 0) ? {disabled:false} : {disabled:true}} status={status} ></CustomButton>
                    </FlexContainer>}
                    handleChange={setSelectedRoutes}
                />

            }

        </ComponentMode>)
}
export default React.memo(AssignCancelMultipleRoutes)