
type GenericObject<T> = {
    [K in keyof T]: T[K]
}

export const flattenObjAllLevels = (ob:GenericObject<any>):GenericObject<any> => {
    var toReturn:GenericObject<any> = {};
    
    for (var i in ob) {
        if (!ob.hasOwnProperty(i)) continue;
        
        if ((typeof ob[i]) == 'object') {
            const nextObj = ob[i]
            var flatObject = flattenObjAllLevels(nextObj);
            for (var x in flatObject) {
                if (!flatObject.hasOwnProperty(x)) continue;
                
                toReturn[`${i}.${x}`] = flatObject[x];
            }

        } else {
            toReturn[i] = ob[i];
        }
    }
    return toReturn;
};



export const flattenObj = (item:any,k?:string) =>{
    let flattenKeys:any = {}
    item && Object.entries(item).forEach(([key,value]:[string,any])=>{

        if(Array.isArray(value)){
            return flattenKeys = {
                ...flattenKeys,
                    [key]:value
            }
        }

        if(typeof(value) === 'object'){
            const flatted = flattenObj(value,key)
            flattenKeys={
                ...flattenKeys,
                ...flatted
            }
        }
       

        else{
            return flattenKeys = {
                ...flattenKeys,
                    [`${k ? (k+'.') : ''}${key}`]:value
            }
        }
    })

    return flattenKeys
}

export const groupByKeyOptions = (items:any[]) => {

    let grupedOptions:any = {}
    
    const allkeys = Object.keys(flattenObjAllLevels(items[0]))
    
    for(const key of allkeys){
        grupedOptions[key] = []
    }

    for(const item of items){
        for(const key of allkeys){
            const keys = key.split('.')
            const value = objectFinalValue(item,keys)
            const exist = grupedOptions[key].includes(value)
            if(!exist) grupedOptions[key].push(value)
        }
    }

    return grupedOptions
}



const objectFinalValue = (object:any,levels:string[]):any =>{
    
    const firstLevel = levels[0] 
    const otherLevels = (levels.length > 1) 
        ? levels.filter((value,index)=>index !== 0) 
        : undefined
    
        if(object){
        if(otherLevels){
            return objectFinalValue(object[firstLevel],otherLevels)
        }
        return object[firstLevel]
    }
}


//Search Methods
export const search = (rows:any[],currentSearch:string):any[] => {
    const columns = rows[0] && Object.keys(flattenObj(rows[0]))
    const filterSearch = currentSearch.toString().toLowerCase()

    return rows.filter((row) =>
        columns.some((column:string) =>
            row[column]?.toString().toLowerCase().indexOf(filterSearch) > -1)
        )
}

export const multipleSearch = (rows:any[],filters:any) => {
        
    const filterKeys = filters && Object.keys(filters)

    return rows[0] && rows?.filter((row:any) => {
        const flatRow = flattenObj(row)
        return filterKeys ? filterKeys.every((fk:string) => {
            if(filters[fk] === undefined) return true
            if(flatRow[fk] !== filters[fk]) return false
            if(flatRow[fk] === filters[fk]) return true
        }) : true
    })

}



