import styled from '@emotion/styled';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState, useRef } from 'react';
import theme from '../../../assets/theme';
import { useKeyPress } from '../../../shared/keyPress/useKeyPress';
import CustomButton, { ButtonColorType, ButtonVariant, CustomIconType } from '../../buttons/CustomButton';
import Portal from '../Portal';
import { ModalBackdrop } from './components/ModalBackdrop';
import { ModalDialog } from './components/ModalDialog';
import { useModal } from './hooks/useModal';


const HEIGHT = '70vh'
const WIDTH = '60vw'


export type ModalProps = {
    modal?:boolean,
    color?:ButtonColorType
    onModalChange?:(state:boolean) => void
    onClose?:() => void
    mode?:'modal'
    autowidth?:boolean
    icon?:CustomIconType,
    variant?:ButtonVariant,
    popoverTitle?:string,
    onOpen?:()=>void,
    disabled?:boolean
    children?: any
} 

const Modal:React.FC<ModalProps> = ({mode,autowidth, modal, children, icon, variant, popoverTitle, onClose, onOpen, disabled, color='inherit'}) =>{

    const keyPressed = useKeyPress('Escape')
    const modalRef = useRef();

    const hasCarrousel = children ? children[0]?.props?.current : undefined;


    const scrollToTop = () => {
        modalRef.current?.scrollTo(0, 0);
    }

    useEffect(()=>{
        onClose && onClose()
    },[keyPressed])

    useEffect(() => {
        scrollToTop()
    }, [modal, hasCarrousel])


    return(<>
        {mode === 'modal' ? null : <CustomButton disabled={!!disabled} color={color} action={onOpen} {...{icon,variant,popoverTitle}} />}
        <div>
            {modal ?
                <ModalBackdrop open={modal} onClick={onClose} >   
                    <ModalDialog ref={modalRef} open={modal} onClick={(e:any) => e.stopPropagation()} height={!autowidth && HEIGHT} width={!autowidth && WIDTH}>
                        {children}
                    </ModalDialog>
                </ModalBackdrop>
            : <></>} 
        </div> 
    </>)
}

export default Modal