import { TextField } from "@material-ui/core"
import { FormFields, FormInitialState, FormReducerType, FORM_ACTION_TYPES } from "../models/FormTypes"
import { validate } from "../utils/FormValidation"
import { parseFormFieldsToData } from '../utils/FormValidation';

export const formReducer = <T,>(state:FormInitialState<T>,action:FORM_ACTION_TYPES<T>):FormInitialState<T>  => {
    const { fields ,isValidated} = state

    switch (action?.type) {
        
        case 'SET_VALUE':{
            const {name:key, value} = action.payload
            const field = fields[key]
            
            const newFields = {
                ...fields,
                [key]:{
                    ...field,
                    value,
                }
            } as FormFields<T>
            
            return {
                ...state,
                fields:newFields,
                errors:validate<T>(newFields)
            }

        }

        case 'SET_VALIDATIONS':{
            const {key, validation, value} = action.payload
            const field = fields[key]
            
            const newFields = {
                ...fields,
                [key]:{
                    ...field,
                    validations: {
                        ...field.validations,
                        [validation]: {
                            ...field.validations?.[validation],
                            ...value
                        },
                    },
                }
            } as FormFields<T>
            
            return {
                ...state,
                fields:newFields,
                errors:validate<T>(newFields)
            }
        }

        case 'VALIDATE_ALL':{

            const [success, error] = action.payload
            const err = validate(fields)
            const data = parseFormFieldsToData<T>(fields)
            const calls = 
            error 
            ? {
                callbacks:{
                    callbackSuccess:success,
                    callbackError: error
                }
            }   
            :{  
                ...state.callbacks,
                callbackSuccess:success,
            }

            if(Object.entries(err).length === 0) success(data)
            else{
                error 
                ? error(err)
                : state.callbacks.callbackError(err)
            }

            return {
                ...state,
                errors:err,
                isValidated:true,
                ...calls
            }
        }

        case 'RESET_ALL':{ 
            return action.payload
        }

        case 'CONFIG_CALLBACKS':{ 
            const callbacks = action.payload

            const cals = callbacks[1] 
            ? {
                callbacks:{
                    callbackSuccess:callbacks[0],
                    callbackError: callbacks[1]
                }
            }   
            :{  
                ...state.callbacks,
                callbackSuccess:callbacks[0],
            }

            return {
                ...state,
                ...cals
            }
        }

        default:
            return state
    }
}