import React, { useState } from 'react';
import { Typography } from '@material-ui/core';

import FlexContainer from '../../../components/containers/FlexContainer';
import SelectContainerBy from '../../containers/components/SelectContainerBy';
import TgdRadioButton from '../TgdRadioButton';


function truncNumber (x:number | undefined, positions:number = 0) {
    if(x){
        var s = x.toString()
        var l = s.length
        var decimalLength = s.indexOf('.') + 1
    
        if (l - decimalLength <= positions){
        return x
        }
        // Parte decimal del número
        var isNeg  = x < 0
        var float =  x % 1
        var integer  = isNeg ? Math.ceil(x) : Math.floor(x)
        // Parte decimal como número entero
        // Ejemplo: parte decimal = 0.77
        // decimalFormated = 0.77 * (10^posiciones)
        // si posiciones es 2 ==> 0.77 * 100
        // si posiciones es 3 ==> 0.77 * 1000
        var decimalFormated = Math.floor(
        Math.abs(float) * Math.pow(10, positions)
        )
        // Sustraemos del número original la parte decimal
        // y le sumamos la parte decimal que hemos formateado
        var finalNum = integer + 
        ((decimalFormated / Math.pow(10, positions))*(isNeg ? -1 : 1))
        return finalNum
    }
  }

const LubricantQuantityCalculator:React.FC<{ unit:any, quantity:number,lubricant:string,onChange:(value:number)=>void}> = ({unit,quantity,lubricant,onChange}) => {

    const [selected,setSelected] = useState<string>()
    const [containerCoeficient,setCoeficientContainer] = useState(0)
   
    const [types] = useState<string[]>(['bomb.',unit])

    const computedQuantity = truncNumber((containerCoeficient * quantity),2) || 0

    const handleChange = (item:string) => {
        setSelected(item)
    }

    const handleContainerChange = (value:any) => {
        setCoeficientContainer(value?.coefficient || 0)
    }
    

    return (
        <FlexContainer align='center'>
            {(selected === 'bomb.') && <>
                <SelectContainerBy 
                complete 
                by={{key:'lubricant',value:lubricant}} 
                onChange={handleContainerChange} 
                />
                <Typography style={{width:'100px'}} >
                    {computedQuantity} {unit}
                </Typography>
            </>}
            <TgdRadioButton handleChange={handleChange} defaultOption={unit} items={types}/>
        </FlexContainer>
    );
}

export default React.memo(LubricantQuantityCalculator);
