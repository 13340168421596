import { createStyles, Divider, makeStyles, Paper, Tab, Tabs, Typography } from "@material-ui/core";
import path from "path";
import React, { useEffect } from "react";
import { Navigate, NavLink, Routes, useLocation, useMatch, useNavigate, useResolvedPath } from "react-router-dom";
import { Route } from "react-router-dom";
import { CustomIcon } from "../../components/buttons/CustomButton";
import FlexContainer from "../../components/containers/FlexContainer";
import PageContainer from "../../components/containers/PageContainer";
import RouterTabs, { TabsInterface, useRouteTabProps } from "../../components/containers/tabs/RouterTabs";
import TgdSnackbar from "../../components/snackbar/TgdSnackbar";
import ManageAddedReasonsPage from "../../domains/added-reason/pages/ManageAddedReasonsPage";
import ManageAnomaliesPage from "../../domains/anomalies/pages/ManageAnomaliesPage";
import ManageAreaPage from "../../domains/areas/components/pages/ManageAreaPage";
import ManageCollectorsPage from "../../domains/collectors/components/pages/ManageCollectorsPage";
import ManageComponentPage from "../../domains/components/components/pages/ManageComponentPage";
import ManageContainersPage from "../../domains/containers/pages/ManageContainersPage";
import ManageCriticityPage from "../../domains/criticalities/pages/ManageCriticalityPage";
import ManageElementsPage from "../../domains/elements/pages/ManageElementsPage";
import ManageLubricantsPage from "../../domains/lubricant/pages/ManageLubricantsPage";
import ManageEquipmentsPageR from "../../domains/lubricationPoints/pages/ManageEquipmentsPageR";
import ManagePermissionsPage from "../../domains/permisions/pages/ManagePermissionsPage";
import { Plant } from "../../domains/plants/PlantInterface";
import ManageRoutesPage from "../../domains/routes/pages/ManageRoutesPage";
import ManageSectorPage from "../../domains/sectors/pages/ManageSectorPage";
import { useSessionContext } from "../../domains/session/store/sessionContext";
import { EnumSupplyTypes } from "../../domains/supply/model/SupplyInterface";
import ManageSuppliesPage from "../../domains/supply/pages/ManageSuppliesPage";
import ManageTypesPage from "../../domains/types/pages/ManageTypesPage";
import ManageUsersPage from "../../domains/users/pages/ManageUsersPage";
import { useLayoutStore } from "../../layout/store/layoutStore";
import FeatureStatusContainer, { useFeatures } from "../../shared/config/features/FeatureStatusContainer";
import { USER_ROUTES } from "../../shared/routes/routes";
import { DrawerWidth } from "./ViewAsClient/components/UserDashboard";



const useStyles = makeStyles((theme:any) => createStyles({
    verticalTabsContainer: {
        backgroundColor: theme.palette.background.paper,
        display:'flex',
        gridTemplateColumns:'200px 1fr',
    },
    tabs: {
        //padding:'20px 0px',
        borderRight: `1px solid ${theme.palette.divider}`,
    },
  }));

const a11yProps = (index: any) => {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}


interface TabPanelProps {
    children?: React.ReactNode;
    index?: any;
    value?: any;
  }
  
export const TabPanel:React.FC<TabPanelProps> = ({ children, value, index, ...other }) => {
    return (
        <div 
        {...other} 
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        style={{width:`calc( 100vw - ${DrawerWidth} - 48px)`}}> 
            {value === index && (
                children   
            )}
        </div> 
    );
}

interface Props{
 
}

const PlantManagementPage:React.FC<Props> = ({}) => {

    const {currentPlant} = useSessionContext()
    const plant = currentPlant!
    const features = useFeatures()
    const {layout,handleModal} = useLayoutStore()

    const virtualGridWidth =  `calc( 100vw - ${layout?.sidebarWidth}px - 144px - 124px)`    

    const PlantManagementConfig:TabsInterface[] = [
        {
            label:"Usuarios",
            route:USER_ROUTES.plantManagement.users,
            component:<ManageUsersPage plant={plant}/>
        },
        {
            label:"Colectores",
            route:USER_ROUTES.plantManagement.colectors,
            component: <FeatureStatusContainer feature={features.collectorFeature}>
                <ManageCollectorsPage {...{virtualGridWidth}} />
            </FeatureStatusContainer>
        },
        {
            label:"Tipos",
            route:USER_ROUTES.plantManagement.types,
            component:<ManageTypesPage {...{virtualGridWidth}} />
        },
        {
            label:"Lubricantes",
            route:USER_ROUTES.plantManagement.lubricants,
            component:<ManageLubricantsPage {...{virtualGridWidth}}/>
        },
        {
            label:"Recipientes",
            route:USER_ROUTES.plantManagement.containers,
            component:<ManageContainersPage {...{virtualGridWidth}} />
        },
        {
            label:'Sectores',
            route:USER_ROUTES.plantManagement.sectores,
            component:<ManageSectorPage {...{virtualGridWidth}} />
        },
        {
            label:'Áreas',
            route:USER_ROUTES.plantManagement.areas,
            component:<ManageAreaPage  {...{virtualGridWidth}}/>
        },
        {
            label:'Componentes',
            route:USER_ROUTES.plantManagement.components,
            component:<ManageComponentPage {...{virtualGridWidth}}/>
        },
        {
            label:'Elementos',
            route:USER_ROUTES.plantManagement.elements,
            component:<ManageElementsPage {...{virtualGridWidth}}/>
        },
        {
            label:'Herramientas',
            route:USER_ROUTES.plantManagement.tools,
            component:<ManageSuppliesPage {...{type: EnumSupplyTypes.tool,virtualGridWidth}} />
        },
        {
            label:'Consumibles',
            route:USER_ROUTES.plantManagement.consumables,
            component:<ManageSuppliesPage {...{type: EnumSupplyTypes.consumable,virtualGridWidth}}/>
        },
        {
            label:'Protecciones',
            route:USER_ROUTES.plantManagement.protections,
            component:<ManageSuppliesPage {...{type: EnumSupplyTypes.protection,virtualGridWidth}}/>
        },
        {
            label:'Permisos',
            route:USER_ROUTES.plantManagement.permissions,
            component:<ManagePermissionsPage {...{virtualGridWidth}} />
        },
        {
            label:'Criticidades',
            route:USER_ROUTES.plantManagement.criticalities,
            component:<ManageCriticityPage {...{virtualGridWidth}} />
        },
        {
            label:'Rutas',
            route:USER_ROUTES.plantManagement.routes,
            component:<ManageRoutesPage {...{virtualGridWidth}}/>
        },
        
        {
            label:'Activos',
            route:`${USER_ROUTES.plantManagement.lubricationPoints}/*`,
            component:<ManageEquipmentsPageR {...{virtualGridWidth}}/>
        },
        
        {
            label:'Anomalías',
            route:USER_ROUTES.plantManagement.anomalies,
            component:<ManageAnomaliesPage {...{virtualGridWidth}} />
        },
        {
            label:'Motivos Agregados',
            route:USER_ROUTES.plantManagement.addedReasons,
            component:<ManageAddedReasonsPage {...{virtualGridWidth}}/>
        },
        /*
        {
            label:'Observaciones',
            route:USER_ROUTES.plantManagement.observations,
            component:<ManageObservationsPage {...{virtualGridWidth}}/>
        }¨
        */
    ]

    const tabProps = useRouteTabProps(4,PlantManagementConfig,{
        orientation:'vertical'
    })
    
    return (
        <>
            
            {!!plant && <>
                <TgdSnackbar />
                <Paper elevation={3}>
                    <PageContainer >
                        <Typography variant='h5'><CustomIcon icon='config' style={{margin:'0px 10px -5px 0'}}/>Configuraciones</Typography>
                    </PageContainer>
                    <Divider /> 
                  
                    <RouterTabs {...tabProps} />
                   
                </Paper>
            </>}
        </>
    );
}

export default React.memo(PlantManagementPage);


