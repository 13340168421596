import React, { useEffect, useState } from 'react';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { Box, makeStyles, Typography } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating'
import { ticketType } from '../CreateTicketsPage';

const useStyles = makeStyles({
    'icon-1': { color: 'green' },
    'icon-2': { color: 'orange' },
    'icon-3': { color: 'red' },
    'icon-4': { color: 'black' }
    });
    

interface Props{
    type:ticketType
    title:string
    max:number
    handleState:(type:ticketType,indicator:string)=>void
}
const ColorRating:React.FC<Props> = ({type,title,max,handleState}) => {

    const classes:any = useStyles()

    const [value, setValue] = useState<number>(1)
    const [hover, setHover] = useState(-1)
    const indicator:{label:string}[] = [
        {label:'Baja'},
        {label:'Media'},
        {label:'Alta'},
        {label:'Urgente'},
    ]

    useEffect(()=> {
        handleState(type,indicator[value] ? indicator[value].label:'Sin Seleccionar')
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[value])

   return (

        <Box component="fieldset" mb={3} borderColor="transparent">
        <Typography component="legend">{title}</Typography>
        <Rating
        classes={{
            iconFilled: classes[`icon-${Math.ceil(value)}`],
            iconHover: classes[`icon-${Math.ceil(hover)}`],
        }}
        name={title}
        value={value}
        precision={1}
        max={max}
        onChange={(event, newValue:any) => setValue(newValue)}
        onChangeActive={(event, newHover) => setHover(newHover)}
        icon={<FiberManualRecordIcon fontSize="inherit" />}
        />
        <br/>
        {indicator[value] ? indicator[value].label:'Sin Seleccionar'}
        </Box>
    )
}

export default ColorRating;
