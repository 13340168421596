
import React from 'react'
import { QueryStatus } from "react-query"
import StatusAndMessage from '../../shared/queries/errors/components/StatusAndMessage'
import CustomButton, { ButtonVariant, CustomIconType } from "../buttons/CustomButton"
import ComponentMode from '../componentMode/ComponentMode'
import { ComponentUIModes } from '../componentMode/componentModesInterfaces'
import HandleUndefined from '../containers/handleUndefined/HandleUndefined'
import { useModal } from '../containers/modal/hooks/useModal'
import Modal from '../containers/modal/Modal'
import CarrousellToolbar from '../containers/toolbar/CarrousellToolbar'
import { useCarrousell } from '../containers/toolbar/hooks/useCarrousell'


 interface Props{
     items?:any[]
     onChange:(item?:any)=>void, 
     children:React.ReactNode, 
     status:QueryStatus, 
     error?:any,
     buttonTitle?:string, 
     message?:string | undefined,
     mode?:ComponentUIModes,
     icon?:CustomIconType,
     variant?:ButtonVariant
     popoverTitle?:string
     disabled?:boolean
     fullwidth?:boolean
     onClose?:()=>void
     extraActions?:React.ReactNode
     severity?:any
     timeToReset?: number
     statusVariant?: "indeterminate" | "determinate"
    }



const DefaultFormLayout:React.FC<Props > = ({items, severity, extraActions, onChange,onClose, mode,buttonTitle, icon, variant,  children, status, error, message,popoverTitle,disabled,fullwidth, timeToReset, statusVariant}) => {

    return(
      
        <ComponentMode onClose={onClose} fullwidth={fullwidth} title={buttonTitle} mode={mode} icon={icon} variant={variant} popoverTitle={popoverTitle}>
            <StatusAndMessage status={status} error={error} message={message} reset={onClose} severity={severity} timeToReset={timeToReset} variant={statusVariant}/>
             {children}
             <CustomButton  action={onChange} status={status} title={buttonTitle} />
             {extraActions && extraActions} 
        </ComponentMode>
    )
}

export default React.memo(DefaultFormLayout)