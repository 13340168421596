import React, { useState } from 'react';
//components
import HomeHero from './HomeHero';
import HomeContent from './HomeContent';
import HomeHeroImg from '../../../../assets/img/welcomeHeader.png'
import { CustomUserPbiData } from '../../../../domains/powerBi/models/powerBiInterfaces';

//services


const ClientHome:React.FC<{hide?:{notifications?:boolean},defaultConfig?:CustomUserPbiData}> = ({hide, defaultConfig}) => {
    return (
        <>  
            <HomeHero img={HomeHeroImg}/> 
            <HomeContent hide={hide} defaultConfig={defaultConfig} />
        </>
    )
}

export default ClientHome;
