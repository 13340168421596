import React, { useEffect } from 'react';
import ModalButton from '../../../components/buttons/ModalButton';
import { useForm } from 'react-hook-form';
import { ComponentUIModes } from '../../../components/componentMode/componentModesInterfaces';
import { SubCategoryInterface } from '../sub-categories/subCategoryInterface';
import { useCreateStock } from '../services/service.stock';
import { errorsLog } from '../../../shared/globals/utils/utils';
import { StockInterface } from '../models/StockInterface';
import { Typography } from '@material-ui/core';
import StatusAndMessage from '../../../shared/queries/errors/components/StatusAndMessage';
import FlexContainer from '../../../components/containers/FlexContainer';
import theme from '../../../assets/theme';
import { TgdTextField } from '../../../components/fields/TextField/TgdTextField';
import SelectTypeBy from '../../types/components/SelectTypeBy';
import SelectArea from '../../areas/components/SelectArea';
import SelectSimpleEntity from '../../../components/autocomplete/old/SelectSimpleEntity';
import { enumTgdTypes } from '../../types/models/types';
import CustomButton from '../../../components/buttons/CustomButton';



interface Props{
    mode:ComponentUIModes
    subcategory:SubCategoryInterface
}

const CreateStockForm:React.FC<Props> = ({mode,subcategory}) => {
    
    const tagFP = subcategory.tagFP
    const {createStock, status,message,error} = useCreateStock()
    const {register,setValue,errors,watch,handleSubmit,control,reset} = useForm()
    const title='Nuevo producto de stock'
    
    useEffect(()=>{
        register({name:'id',type:'number',value:0})
        register({name:'tagFP',value:tagFP})
        register({name:'categoryArea',type:'string',value:'LUBRICACION'})
        register({name:'category',value:subcategory.category})
        register({name:'subcategory',value:subcategory.subcategory})
        
        register({name:'measureUnit',type:'string',value:''}, {required:{value:true,message:'requerido'}})
        register({name:'brand',type:'string',value:''}, {required:{value:true,message:'requerida'}})
        register({name:'product',type:'string',value:''}, {required:{value:true,message:'requerido'}})
        register({name:'minimumRequired',type:'string',value:''}, {required:{value:true,message:'requerido'}})
        register({name:'actualStock',type:'string',value:''}, {required:{value:true,message:'requerido'}})
        register({name:'providerArea',type:'string',value:''}, {required:{value:true,message:'requerida'}})
        register({name:'complex',type:'string',value:''}, {required:{value:true,message:'requerido'}})
    })

    errorsLog(errors)

    const submit = (item:StockInterface) => {
        createStock(item)
    }

    const content=<> 
        <Typography variant='h6'>{mode === 'content' && title}</Typography>
        <StatusAndMessage status={status} message={message} error={error} />
        <FlexContainer padding={`${theme.spacing(3)}px 0px`} flexDirection={'column'}>
            
                <TgdTextField
                onChange={(value) => setValue('product',value)}
                value={watch('product','')}
                name='product'
                label={'Nombre del producto'}
                error={errors?.product?.message}
                />

                <TgdTextField
                onChange={(value) => setValue('brand',value)}
                value={watch('brand','')}
                name='brand'
                label={'Marca'}
                error={errors?.brand?.message }
                />
                
                <TgdTextField
                onChange={(value) => setValue('actualStock',parseInt(value))}
                value={watch('actualStock','')}
                name='actualStock'
                label={`Stock actual`}
                error={errors?.actualStock?.message}
                />

                <TgdTextField
                onChange={(value) => setValue('minimumRequired',parseInt(value))}
                value={watch('minimumRequired','')}
                name='minimumRequired'
                label={`Cantidad mínima`}
                error={errors?.minimumRequired?.message}
                />

                    <SelectTypeBy
                        error={errors?.measureUnit?.message}
                        by={{key:'type',value:enumTgdTypes.unity}}
                        tagFP={tagFP}
                        onChange={(value)=>setValue('measureUnit',value)}
                        defaultValue={watch('measureUnit')}
                    />
                  
                <SelectArea
                error={errors?.providerArea?.message}
                tagFP={tagFP}
                onChange={(value)=>setValue('providerArea',value)}
                defaultValue={watch('providerArea')}
                />

               <SelectSimpleEntity
                    error={errors?.complex?.message}
                    complete
                    onChange={(value:any) => setValue('complex',value.value)}
                    label={'Abastecimiento'}
                    entityList={[{label:'COMPLEJO',value:true},{label:'SIMPLE',value:false}]}
                    showTitle={'label'}
                    color={errors?.complex?.message ? 'primary' : 'secondary'}
               />

                <CustomButton status={status} action={handleSubmit(submit)}>Crear</CustomButton>
            
        </FlexContainer>
    </>
    
    return (<>

        {mode === 'content' && content}
    
        {mode === 'modal' && <ModalButton icon='add' title={title} >
            {content}
        </ModalButton>}
    </>);
}

export default React.memo(CreateStockForm);
