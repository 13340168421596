import React from "react";
import PageContainer from "../../../../components/containers/PageContainer";
import VirtualGrid, {
  CarrousellActinInterface,
} from "../../../../shared/react-table/VirtualGrid";
import { LubricantInterface } from "../../../../domains/lubricant/model/LubricantInterface";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import CreateUpdateIncomeForm from "./CreateUpdateIncomeForm";
import { CrudFormType } from "../../../../shared/form/models/FormTypes";
import { useGetIncome } from "../services/IncomeService";
import { useIncomeGridModel } from "../lang/useIncomeGridModel";
import DeleteIncomeForm from "./DeleteIncomeForm";

interface LubricantIncomeProps {
  item?: LubricantInterface;
  lubricant?: string;
}

const LubricantIncome = ({ item, lubricant }: LubricantIncomeProps) => {
  const { lang } = useLangLabels();
  const { data: incomes } = useGetIncome(item?.lubricant);

  const carrousellActions: CarrousellActinInterface = [
    {
      icon: "edit",
      popoverTitle: lang.messages.latestIncome,
      component: (item) => (
        <CreateUpdateIncomeForm
          actionType={CrudFormType.update}
          lubricant={lubricant}
          item={item}
        />
      ),
    },
  ];

  return (
    <PageContainer>
      <VirtualGrid
        {...{
          key: item?.id,
          height: lubricant ? 10 : undefined,
          contained: false,
          width: "100%",
          title: lang.messages.latestIncome,
          documentExportTitle: "Ultimos Ingresos",
          items: incomes || [],
          gridModel: useIncomeGridModel(),
          entityName: lang.incomeForm.plural,
          carrousellComponents: lubricant ? undefined : carrousellActions,
          headerActions: (item: any) => (
            <CreateUpdateIncomeForm
              lubricant={lubricant}
              actionType={CrudFormType.create}
            />
          ),
          itemActions: (item) => (
            <>
              <DeleteIncomeForm key={1} item={item} />
              {lubricant ? (
                <CreateUpdateIncomeForm
                  key={2}
                  actionType={CrudFormType.update}
                  lubricant={lubricant}
                  item={item}
                  mode={"modal"}
                />
              ) : undefined}
            </>
          ),
        }}
      />
    </PageContainer>
  );
};

export default React.memo(LubricantIncome);
