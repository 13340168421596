
/** 
 * @ISOString date on IsoString new Date().toISOString() 
 * if is not pased will calculate actual date
 */

export const getTodayDay = (ISOString?:string) => {
    const now = new Date()
    const date = ISOString || now.toISOString()
    return date.split('').splice(0,10).join('') 
}

export const getTodayDateTime = (ISOString?:string) => {
    const date = ISOString ? new Date(ISOString) : new Date()
    const day = date.toISOString().split('').splice(0,10).join('') 
    const time = date.toISOString().split('').splice(11,8).join('') 
    const miliseconds =  date.getTime()
    return {date:day,time,miliseconds}
}