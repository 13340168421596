import React, { useMemo } from 'react'
import { CustomIconType } from '../../components/buttons/CustomButton'
import ComponentMode from '../../components/componentMode/ComponentMode'
import HandleUndefined from '../../components/containers/handleUndefined/HandleUndefined'
import { useModal } from '../../components/containers/modal/hooks/useModal'
import Modal from '../../components/containers/modal/Modal'
import PageContainer from '../../components/containers/PageContainer'
import CarrousellToolbar from '../../components/containers/toolbar/CarrousellToolbar'
import { useCarrousell } from '../../components/containers/toolbar/hooks/useCarrousell'
import { useLangLabels } from '../../shared/lang/services/useLangLabels'
import VirtualGrid from '../../shared/react-table/VirtualGrid'
import SurveyPreview from '../surveys/components/SurveyPreview'
import { useObservationsGridModel } from './lang/useObservationsGridModel'
import { useObservationByIdentifier } from './services/observationServices'



interface Props {
    items?:any[],
    mode?:'content',
    queryType:'lubricationPoint' | 'survey',
}

const icon:CustomIconType = 'observations'



const CustomObservationsList:React.FC<Props> = React.memo(({items,mode,queryType}) => {
    
    const modalProps = useModal(false)
    const carrouselProps = useCarrousell(items?.length || 0)

    const allItems = useMemo(()=>items || [],[items])
    const currentItem = useMemo(()=>allItems.length > 0 ? allItems[carrouselProps.current] : undefined,[allItems, carrouselProps])!

    const {lang} = useLangLabels()
    const gridModel = useObservationsGridModel()
    
    const observations = useObservationByIdentifier(queryType,currentItem)

    const _observations = useMemo(()=>observations.data,[observations])
    
    const itemIdentifier = useMemo(()=>
        (queryType === 'survey' && currentItem?.id) || 
        (queryType === 'survey' && currentItem?.plantTag) ||
        (queryType === 'lubricationPoint' && `${currentItem?.equipment}>>${currentItem?.element}>>${currentItem?.component}`)
    ,[currentItem?.component, currentItem?.element, currentItem?.equipment, currentItem?.id, currentItem?.plantTag, queryType])
 
    const title = useMemo(()=>
        (queryType === 'survey' && lang.messages.surveyObservations) || 
        (queryType === 'lubricationPoint' && `${lang.messages.lubricationPointObsevetions}: ${itemIdentifier}`) || ''
    ,[itemIdentifier, lang.messages.lubricationPointObsevetions, lang.messages.surveyObservations, queryType])

   

    const content =
    <PageContainer>
        {queryType === 'survey' &&
            <SurveyPreview item={currentItem} />
        }
        <VirtualGrid
        {...{
            key:currentItem?.tagTGD,
            contained:false,
            width:'100%',
            height:9,
            title:'Ultimas Observaciones',
            documentExportTitle:'Prueba',
            items:_observations || [],
            gridModel:gridModel,
            //itemActions:(items:any)=><>
            //{/* {items && items.length > 0 && <VirtualGridObservations items={items} mode='modal' queryType='survey'/>} */}
            //</>,
            ///* renderOption:(item: any) => <LeakLevelComponent lastlubricantCuantity={item.info.lubricantQuantity} pointCapacity={item.capacity}/>  */
        }}
        ></VirtualGrid>
    </PageContainer>

    
    const _mode = mode || 'modal'

    return(<>
        {_mode === 'modal' && 
            <HandleUndefined isError={!!items && items.length > 0} callback={modalProps.onOpen}>
                {(callback)=>
                <Modal modal={modalProps.modal} onOpen={callback} onClose={modalProps.onClose} icon={icon} variant='iconButton' popoverTitle={`${title} de este item`} >
                    <CarrousellToolbar {...carrouselProps} />
                    {content} 
                </Modal>}
            </HandleUndefined>
        }

        {_mode === 'content' &&
            content
        }
    </>)
})

export default React.memo(CustomObservationsList)