import { AppBar, Box, createStyles, makeStyles, Toolbar, Typography } from "@material-ui/core"
import React from "react"
import CustomButton from "../../../../components/buttons/CustomButton"
import FlexContainer from "../../../../components/containers/FlexContainer"
import FullscreenSurface from "../../../../components/containers/modal/FullscreenSurface"
import { useModal } from "../../../../components/containers/modal/hooks/useModal"
import { equipmentType, LubricationPointInterface } from "../../model/lubricationPointsInterface"
import UpdateLubricationPointR from "./UpdateLubricationPointR"
import EquipmentFileTemplate from './EquipmentFileTemplate'

const useStyles = makeStyles((theme) => createStyles({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
	},
	root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
  }))

interface Props{
    item:LubricationPointInterface
}
const ViewLubricationPoint:React.FC<Props> = ({item}) => {
    const classes = useStyles()  
    const {modal,handleModal} = useModal()


    return (
        <>
            <CustomButton variant='iconButton' icon='view' action={handleModal} popoverTitle='Ver'/>

            <FullscreenSurface  modal={modal}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <CustomButton
                            icon='close'
                            color="inherit"
                            action={handleModal}
                            variant='iconButton'
                        />
                        <Typography variant="h6" className={classes.title}>
                            Ver componente
                        </Typography>
                    </Toolbar>
                </AppBar>
                <br/>
                <FlexContainer justify='center' >
                    <EquipmentFileTemplate item={item} type={equipmentType.component}/>
                    <Box>
                        <UpdateLubricationPointR item={item} />
                    </Box>
                </FlexContainer>
                
            </FullscreenSurface>
        </>
    );
}

export default React.memo(ViewLubricationPoint);
