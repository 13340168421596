import { useMemo, useState } from "react"

import { dynamicFilter } from "../domain/dynamicfilterEntity"

import { PeriodChange, periodFilter, usePeriodForm } from "./usePeriodForm"
import { resetFilter } from './../domain/dynamicFilterModel';
import { PeriodInterface } from "../../../../shared/reports/model/ReportInterface";
import { valueFromFlatObjectkey } from "../../../../shared/globals/utils/valueFromFlatObjectkey";
import { groupByKeyOptions } from "../../../../shared/globals/utils/dataFiltering";
import { GridModelInterface } from "../../../../shared/gridModel/gridModelEntity";
import useBasicLocalStorage from "../../../../shared/localStorage/useBasicLocalStorage";


export type FilterType = 'andFilterType' | 'orFilterType'

const translateBoolean = (value: any) => {
    if (typeof value === "boolean") {
        if (value === true) return 1
        else return 0
    }

    return value
}

export const translateValue = (value:any) => {
    switch (value) {
        
        case 'SI':
            return true
        
        case 'NO':
            return false

        case 'ACTIVO':
            return true

        case 'INACTIVO':
            return false

        case 'REALIZADA':
            return true

        case 'SIN REALIZAR':
            return false

        case 'ACCESIBLE':
            return true

        case 'INACCESIBLE':
            return false

        case 'TODOS':
            return undefined

        case 'TODO':
            return undefined

        case 'CON PERDIDA':
            return true

        case 'SIN PERDIDA':
            return false

        case 'CARGA MANUAL':
            return true

        case 'COLECTOR':
            return false

        case "IMPOSIBLE DE LUBRICAR":
            return true
        
        case "NORMAL":
            return false

        case 'FUERA DE RUTA':
            return true

        case 'EN RUTA':
            return false
    
        default:
            return value
    }
}


export interface DynamicFiltersProps {
    searchValue:string
    onSearchValueChange: (inputValue: string) => void
    setSearchValue:(item:string)=>void
    selectedEntitiestoFilter:any,
    handlePeriod:(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
    periodSubmit:()=>void
    handleFiltersChange:(key:string,value:string,index:number,type:FilterType)=>void
    errors?:any
    periodFilterEnabled:boolean
    handleResetFilters:()=>void
    defaultPeriodValues:periodFilter
    handleResetPeriod:()=>void
    addEntityToFilters:(item:any,callback:()=>void)=>void
    handleDeleteFilter?:(entity:filterLabel,filtervalue:any)=>void
    filtered:any[]
    existAndFilter:boolean
    filters:{[key:string]:any} | undefined
    columnLabels:{
        key: string;
        id: number;
        label: string;
        options: {
            name: any;
            value: any;
        }[];
    }[]
    notSelectedColumnLabels:{
        key: string;
        label: any;
        options: any;
    }[]
}

export interface filterLabel{
    key: string;
    id: number;
    label: string;
    type:FilterType
    options: {
        name: any;
        value: any;
    }[];
    selectedValue: any
}


export const useDynamicFilter = (
    items:any[], 
    gridModel?:GridModelInterface,
    defaultPeriodValues?:PeriodInterface, 
    onPeriodSubmit?:PeriodChange, 
    filterOptions?: any,
    entityName?: string
    ): DynamicFiltersProps => {
    const {periodValues, errors, handlePeriod, periodSubmit, handleReset:handleResetPeriod } = usePeriodForm(defaultPeriodValues,onPeriodSubmit)
    const [filters, setFilters] = useState<dynamicFilter | undefined >() // Probablemente se pueda prescindir de este estado en un futuro
    const [andFilters, setAndFilters] =  useState<dynamicFilter | undefined >()
    const { onFiltersChange, onDeleteFilter, onResetFilters, onSearchValueChange } = filterOptions || {}

    const existAndFilter = useMemo(()=>{
        return !!andFilters && Object.keys(andFilters).length > 0
    },[andFilters]) 

    const [searchValue,setSearchValue] = useState<string>('')
    
    const storeValue = entityName ? true : false
    const [selectedEntitiestoFilter, setSelectedEntitiestoFilter] = useBasicLocalStorage<filterLabel[] | []>(`${entityName?.toLowerCase()}-row-filters`, [], storeValue)
    const periodFilterEnabled = !!onPeriodSubmit
    
    const filtered = useMemo(() => {
        const result = items.filter((item) => {
            let matchesFilters = true;
            for (const entity of selectedEntitiestoFilter) {
                const filterKey = entity.key;
                const filterValue = entity.selectedValue;
                const translatedValue = translateValue(filterValue);
                const itemValue = valueFromFlatObjectkey(item, filterKey);
        
                if (translatedValue !== undefined) {
                    matchesFilters = translatedValue.toString().toUpperCase() === itemValue?.toString().toUpperCase();
                    if (!matchesFilters) break; // Importante: Si un filtro no coincide, sal del bucle
                }
            }
            return matchesFilters;
        });


        if (searchValue) {
            const keys = Object.keys(result[0] || [])
            
            const searchBoxResults = result.filter((item: any) => {
                let exist = false as (boolean | undefined)
                
                keys.forEach((key) => {     
                    if (exist === true) return
                    
                    if (typeof item[key] === 'number') {
                        exist = item[key].toString().indexOf(searchValue.toUpperCase()) === -1 ? false : true
                    }
                    
                    if (typeof item[key] === 'string') {
                        exist = item[key].toUpperCase().indexOf(searchValue.toUpperCase()) === -1 ? false : true
                    }
                })

                return exist
            })

            return searchBoxResults
        } else {
            return result
        }
    }, [filters, items, searchValue, andFilters])



    //Methods
    const handleResetFilters = (isLastFilter = false) => {
        setSelectedEntitiestoFilter([])
        setFilters(resetFilter())
        setAndFilters(resetFilter())
        onResetFilters?.()

        if (!isLastFilter) {
            setSearchValue('')
            onSearchValueChange("")
        }
    }

    const handleFiltersChange = (field: string, value: any, index: number, type: FilterType) => {
        const translatedValue = translateBoolean(translateValue(value))

        setFilters({
            ...filters,
            [field]:[value]
        })
        setAndFilters({
            ...andFilters,
            [field]:[value]
        })
        setSelectedEntitiestoFilter(selectedEntitiestoFilter.map((item) => {
            if(item.key === field) {
                return {
                    ...item,
                    selectedValue: value
                } 
            } else {
                return item;
            }
        }))
        
        onFiltersChange?.(field, translatedValue)
    }

    const handleDeleteFilter = (filter:filterLabel, filterValue:any) => {
        const fieldToDelete = filter.key
        const isLastFilter = selectedEntitiestoFilter.length <= 1
        
        if (isLastFilter) {
            handleResetFilters(isLastFilter)
        } else {
            const validItems = { ...andFilters }
            delete validItems[fieldToDelete]

            setAndFilters(validItems)
            setFilters(validItems)
            onDeleteFilter?.(fieldToDelete)
            
            setSelectedEntitiestoFilter(() => {
                return selectedEntitiestoFilter.filter((item) => {
                    return item.id !== filter.id
                })
            })
        }
    }

    const addEntityToFilters = (item:any,callback:()=>void) => {
            setSelectedEntitiestoFilter([
                ...selectedEntitiestoFilter,
                item
            ])
            
            callback()
    }

    const columnLabels = useMemo(() => {
        if (gridModel) {
            return Object.entries<any[]>(groupByKeyOptions(items))
            //filter gridModel inexistance of attributes
            .filter(([key]) => !!gridModel[key]?.label)
            .map(([key,values], index) => {
                const nestedKeys = key.split(".")
                const lastIndexKey = nestedKeys.length - 1
                const lastKey = nestedKeys[lastIndexKey]

                return {
                    key: lastKey,
                    id: index,
                    label: gridModel[key]?.label || key,
                    options: (gridModel[key]?.options || 
                            values.map(value => ({
                                name:value?.toString(),
                                value:value?.toString()
                            }))
                        )
                    }
            })
        } else { 
            return Object.entries<any[]>(groupByKeyOptions(items))
            .map(([key,values], index) => {
                const nestedKeys = key.split(".")
                const lastIndexKey = nestedKeys.length - 1
                const lastKey = nestedKeys[lastIndexKey]
                
                return {
                    key: lastKey,
                    id: index,
                    label: key,
                    options: values.map(value => ({
                        name:value?.toString(),
                        value:value?.toString()
                    }))
                }
            })
            .filter((item) => !(item.label === ''))
    }}
    , [gridModel, items])

    const notSelectedColumnLabels = useMemo(() => {
        const selectedEntitiesKeys = selectedEntitiestoFilter?.map((entity) => entity.key)
        
        return columnLabels.filter((entity) => {
            const field = entity.key
            return !selectedEntitiesKeys.includes(field)
        })

    }, [columnLabels, filters, selectedEntitiestoFilter])


    return {
        filters,
        existAndFilter,
        filtered,
        periodFilterEnabled,
        errors,
        handlePeriod,
        periodSubmit,
        handleResetPeriod,
        defaultPeriodValues:periodValues,
        setSearchValue,
        selectedEntitiestoFilter,
        handleFiltersChange,
        handleResetFilters,
        addEntityToFilters,
        columnLabels,
        handleDeleteFilter,
        notSelectedColumnLabels,
        searchValue,
        onSearchValueChange
    }
}