import React, { useEffect, useState } from 'react';
import {  List, Divider, TextField } from '@material-ui/core';
import { useDate } from '../../../shared/date/useDate';
import { LubricationPointInterface } from '../../lubricationPoints/model/lubricationPointsInterface';
import { SurveyTasksValues, TaskType } from '../tasks/model/TaskInterface';
import { useLubricationPointSurveyController } from '../services/controllers/useLubricationPointSurveyController';
import { useForm } from 'react-hook-form';
import { errorsLog } from '../../../shared/globals/utils/utils';
import { useSetStartedRoute } from '../../routes/services/service.routes';
import { suppliesToString } from '../../supply/utils/supplyToString';
import { ObservationInterface } from '../../observations/models/ObservationTypes';
import CustomButton, { CustomIcon } from '../../../components/buttons/CustomButton';
import FlexContainer from '../../../components/containers/FlexContainer';
import DoNotDoButton from '../../../components/buttons/DoNotDoButton';
import ClickableForm from '../../../components/form/ClickableForm';
import { TypeFormRegex } from '../../../shared/regExp/regExp';
import AddObservation from '../../observations/components/AddObservation';
import TgdFab from '../../../components/buttons/TgdFab';
import {  useLubricatorStore } from '../../users/lubricator/lubricatorStore';
import { QueryStatus } from 'react-query';
import TgdTextField from '../../../components/fields/TextField/TgdTextField';

const SurveyInspection:React.FC<{item:LubricationPointInterface, onFinish:()=>void,taskType:TaskType,outOfRoute:boolean,statusController:(status:QueryStatus)=>void}> = ({outOfRoute,taskType,item,onFinish,statusController}) => {

    //SURVEY DATA
    const {nowDay,nowMonth,nowYear, ISOToDateTime, isToday} = useDate()
    
    const { survey, backTaskStep, setInaccessible, nextTaskStep, setNewComponent, setNeedsLubrication, setLeak, setLabelReplacement, setTemperature, createSurvey, updateEquipmentInfo, createObservation, setStartTime } = useLubricationPointSurveyController(item,outOfRoute)

    const inaccessible= survey?.inspection?.inaccessible
    const newComponent = survey?.inspection?.newComponent
    const needsLubrication = survey?.inspection?.needsLubrication
    const leak = survey?.inspection?.leak
    const labelReplacement = survey?.inspection?.labelReplacement
    const temperature = survey?.inspection?.temperature
    const anomalies = survey?.anomalies || []
    const observations = survey?.observations || []
    const supplies = survey?.neededSupplies || []
    const currentEquipmentinfoSupplies = item.info?.supplies?.filter(s=> s !== 'SIN INSUMOS') || []
    const startTime = survey?.inspection.startTime
    
    const {finishSurvey} = useLubricatorStore()

    //APLICATION STATE
    const [step,setStep] = useState<any>({})
    const {register,handleSubmit,errors} = useForm()

    useEffect(()=>{
        register({name:'newComponent',value:newComponent})
        register({name:'needsLubrication',value:needsLubrication},{
            required:{
                value:(needsLubrication === undefined),
                message:'Requerido'
            }
        })
        register({name:'leak',value:leak})
        register({name:'labelReplacement', value:labelReplacement})
        register({name:'temperature', value:temperature})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[newComponent, needsLubrication, leak, labelReplacement, temperature])

    errorsLog(errors)    

    //ACTIONS
    const {startRoute} = useSetStartedRoute()


    //APLICATION STATE ACTIONS
    const handleFinish = () => {
        if(survey?.inspection.inaccessible){
            return
        }else{
            onFinish()
        }
    }

    //COMPONENT METHODS
    const handleAccesibility = (accesibility:boolean) => {
        //SetAtom inaccesible to proced on survey process
        setInaccessible(accesibility)
        //startRoute to set pending equipments and started routes 
        if(item.routeName){
            startRoute({
                date:`${nowYear}-${nowMonth}-${nowDay}`,
                tagFP:item.tagFP,
                route:item.routeName
            })
        }
    }

    const submit = () =>{
       nextTaskStep(taskType)
    }

    const handleCustomDate = (selectedDateTime: string): void => {
        const selectedDate = new Date(selectedDateTime)
        const selectedMiliseconds = selectedDate.getTime()
        const nowMiliseconds = new Date().getTime()

        if(selectedMiliseconds > nowMiliseconds){
            return alert('no estan permitidas las fechas futuras')
        }
        const selectedIsoDate = selectedDate.toISOString()
        setStartTime(selectedIsoDate)
    }

    const handleInaccesiblePoint = () =>{
        statusController('loading')
        createSurvey({
            taskTime:'0',
            taskDone:false,
            impossibleToLubricate:false,
            needsLubrication:false,
            inaccessible:true,
            lubricates:false,
            lubricantType:item.lubricantType,
            lubricant:item.lubricant,
            taskType:SurveyTasksValues.lubrication,
            sampleExtraction:false,
            startTime:startTime,
            endTime: isToday(startTime!) ? new Date().toISOString() : startTime,
            tagTGD:item.tagTGD,
            equipment:item.equipment,
            sector:item.sector,
            measureUnit:item.measureUnit,
            tagFP:item.tagFP,
            review:item.review,
            criticality:`${item.criticality}`,
            manualRegistration:true,             
            outOfRoute:outOfRoute || false,
            route:outOfRoute ? '' : item?.routeName!,
            routeDate:new Date().toISOString(),
            quantity:0, 
            reason:'', 
            cleaning:false, 
            consumables:JSON.stringify(suppliesToString(supplies))
        } as any).then((data)=>{
            createObservation([
                ...anomalies.map((o:ObservationInterface)=>({...o,surveyId:data.id})),
                ...observations.map((o:ObservationInterface)=>({...o,surveyId:data.id})),
            ]).then(()=>{
                updateEquipmentInfo({
                    ...item.info,
                    ...survey?.inspection,
                    //Data To Modify
                    tagTGD:data.tagTGD,
                    tagFP:data.tagFP,
                    temperature:data.temperature,
                    lastSurveyId:data.id,
                    updatedToday:true, 
                    user:data.user,
                    lastInspectionDate: startTime!,
                    inaccessible:data.inaccessible,
                    labelReplacement:!item?.info?.labelReplacement,
                    // updateReview:!item?.info?.updateReview, 
    
                    //Inmutable data
                    supplies:currentEquipmentinfoSupplies.length === 0 ? ["SIN INSUMOS"] : currentEquipmentinfoSupplies,
                    lubricantQuantity:item?.info?.lubricantQuantity!,
                    leak:item?.info?.leak!,
                    assetCriticality:item?.info?.assetCriticality!,
                    lastLubrication:item?.info?.lastLubrication!,
    
                }).then(async (res)=>{
                    //await list updates to see reflected changes
                    finishSurvey(()=>{
                        statusController('success')
                        nextTaskStep(taskType)
                        handleFinish()
                    })
                })
            })
        }).catch((err)=> console.log(err))  
    }

    errorsLog(errors)
    


    return(<>

        {<div>
            {outOfRoute 
                ?   <CustomButton
                    action={()=>{
                        backTaskStep(taskType)    
                    }} 
                    icon='before' 
                    variant='iconButton' 
                    title='Atras' />
            
                : inaccessible !== undefined &&
                    <CustomButton
                    action={()=>{
                        backTaskStep(taskType)    
                    }} 
                    icon='before' 
                    variant='iconButton' 
                    title='Atras' />

            }

        </div>}
        
        {(inaccessible === undefined) && <>
            <FlexContainer justify='center' align='center' height='100%' width='100%' padding={'50px 0px 0px'}>
                    <DoNotDoButton 
                        action={()=>handleAccesibility(true)} 
                        icon='close' 
                        title='Inaccesible'
                        />
                    <DoNotDoButton 
                        action={()=>handleAccesibility(false)} 
                        icon='check' 
                        title='Accesible'
                        />
            </FlexContainer>
            <FlexContainer justify='center' align='center' padding={'50px 0px 0px'} height='100%' width='100%' >
                <FlexContainer width={'308px'}>
                    <TgdTextField
                        label='Fecha de inicio'
                        onChange={handleCustomDate}
                        value={ISOToDateTime(startTime!)}
                        type={'datetime-local'}
                        autoFocus={false}
                        onKeyDown={(e: any) => e.preventDefault()}
                        style={{
                            color: "#bfbfbf",
                        }}
                    />
                </FlexContainer>
            </FlexContainer>
        </>}

        {(inaccessible === false) && <>
            <List> 
                <ClickableForm
                    title={'Cambio de Componente'}
                    value={newComponent}
                    handleValue={(item)=>{
                        setNewComponent(item)
                    }}
                //validationError={errors.newComponent}
                />
                <ClickableForm 
                    title={'¿Necesita lubricante?'}
                    value={needsLubrication}
                    handleValue={(item)=>{
                        setNeedsLubrication(item)
                    }}
                    validationError={errors.needsLubrication}
                />
                <ClickableForm 
                    title={'¿Se identifica Pérdida?'}
                    value={leak}
                    handleValue={(item)=>{
                        setLeak(item)
                    }}
                    validationError={errors.leak}
                />

                <ClickableForm 
                    title={'Reemplazar Etiqueta'}
                    value={labelReplacement}
                    handleValue={(item)=>{
                        setLabelReplacement(item)
                    }}
                //validationError={errors.needsLubrication}
                />
                <br/>

                <FlexContainer padding='0px 16px'justify ='space-between' >
                    <TextField
                        name='temperature'
                        value={temperature}
                        inputRef={register({
                            pattern:{
                                value:TypeFormRegex.float,
                                message:'Numeros/Decimales con punto'
                            }
                        })}
                        variant='outlined'
                        size='small'
                        color={errors.temperature?.message ? 'primary' :'secondary'}
                        label={errors.temperature?.message || 'Temperatura en Cº'}
                        onChange={(e)=>setTemperature(e.target.value)}
                    />
                </FlexContainer>
                <br/>
            </List>
            <Divider/>    
            <br/>
            <FlexContainer  width='100%' align='center' justify='flex-end'>
                {(!step?.anomaly && (anomalies?.length === 0)) &&
                    <CustomButton icon='close' title='Sin anomalía' action={()=>setStep({anomaly:'Sin Anomalías'})}/>
                }
                <AddObservation
                    outOfRoute={outOfRoute}
                   /*  handleDelete={handleDeleteObservationAnomaly}
                    onChange={setAnomalies}
                    defaultState={anomalies} */
                    taskType = {SurveyTasksValues.lubrication}
                    lubricationPoint={item}
                    type='ANOMALIA' 
                    buttonTitle='Anomalías'
                    formTitle='Nueva anomalía'
                    variant={((step?.anomaly) || (anomalies && anomalies.length > 0)) ? 'list' : 'modal'} 
                    listTitle={(step?.anomaly) || 'Anomalías'}
                    buttonType='add'
                />
            </FlexContainer>
                
            <TgdFab
                disabled={!(step?.anomaly || (anomalies?.length !== 0))}
                bottom={3}
                right={4.5}
                color='primary' 
                onClick={handleSubmit(submit)}
                children={<CustomIcon icon={'next'}/>}
            />
                
        </>}


        {(inaccessible === true) && <>
            <AddObservation
                outOfRoute={outOfRoute}
                taskType = {SurveyTasksValues.lubrication}
                type='INACCESIBLE' 
                lubricationPoint={item}
                listTitle='Observaciones'
                variant='content' 
                formTitle='¿Porqué el equipo estaba inaccesible?'
                buttonType='add'
            />
            
            <TgdFab
                disabled={!(observations && observations.length > 0)}
                bottom={4}
                right={4}
                color='primary' 
                onClick={handleInaccesiblePoint}
                children={<CustomIcon icon={'next'}/>}
            />
            
        </>}
    </>)
}

export default React.memo(SurveyInspection);
