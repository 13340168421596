import { TextField, Typography } from "@material-ui/core"
import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import CustomButton, { ButtonVariant } from "../../../../components/buttons/CustomButton"
import ComponentMode from "../../../../components/componentMode/ComponentMode"
import { ComponentUIModes } from "../../../../components/componentMode/componentModesInterfaces"
import FlexContainer from "../../../../components/containers/FlexContainer"
import { errorsLog } from "../../../../shared/globals/utils/utils"
import StatusAndMessage from "../../../../shared/queries/errors/components/StatusAndMessage"
import { useSessionContext } from "../../../session/store/sessionContext"
import SelectCategory from "../../categories/components/SelectCategory"
import { StockInterface } from "../../models/StockInterface"
import SelectStockProduct from "../../components/SelectStockProduct"
import SelectSubcategoryByCategory from "../../sub-categories/components/SelectSubcategoryByCategory"
import { useCreatePurchaseRequest } from "../service.purchaseRequests"
import theme from "../../../../assets/theme"


interface Props{
    mode:ComponentUIModes
    stock?:StockInterface
    variant?:ButtonVariant
}

const CreatePurchaseRequestForm:React.FC<Props> = ({mode,stock,variant}) => {

    const {currentPlant,user} = useSessionContext()
    const tagFP = currentPlant?.tagFP! 
    const {createPurchaseRequest,status,message,error} = useCreatePurchaseRequest()

    const {register,setValue,errors,watch,handleSubmit} = useForm()
    const title='Nueva Solicitud de compra'
    
    useEffect(()=>{
        //PresetedValues
        register({name:'id',type:'number',value:0})
        register({name:'tagFP',value:tagFP})
        register({name:'categoryArea',type:'string',value:'LUBRICACION'})
        register({name:'state',value:'PENDIENTE'},{required:{value:true,message:'Campo requerido'}})
        register({name:'user',value:user?.email},{required:{value:true,message:'Campo requerido'}})
        register({name:'receivedAmount',type:'number',value:0})

        //inputs
        register({name:'category',value:stock?.category || ''},{required:{value:true,message:'Campo requerido'}})
        register({name:'subcategory',value:stock?.subcategory || ''},{required:{value:true,message:'Campo requerido'}})
        register({name:'brand',type:'string',value:stock?.brand || ''}, {required:{value:true,message:'Campo requerido'}})
        register({name:'measureUnit',type:'string',value:stock?.measureUnit || ''}, {required:{value:true,message:'Campo requerido'}})
        register({name:'product',type:'string',value:stock?.product ||''}, {required:{value:true,message:'Campo requerido'}})
        register({name:'requestedAmount',type:'number',value:0},{required:{value:true,message:'Campo requerido'}})
        register({name:'observations',value:''})
        
        register({name:'providerArea',value:stock?.providerArea || ''},{required:{value:true,message:'Campo requerido'}})
        register({name:'complex',value:stock?.complex || ''})
    
    },[stock])


    const handleProductSelect = (item:StockInterface) =>{
        /* console.log(item) */
        setValue('complex',item.complex)
        setValue('product',item.product)
        setValue('brand',item.brand)
        setValue('measureUnit',item.measureUnit)
        setValue('providerArea',item.providerArea)
    }

    const submit = (item:any) => {
        createPurchaseRequest({
            ...item,
            subcategory:stock?.subcategory || item.subcategory.subcategory
        })
    }

    errorsLog(errors)

    const content=<> 
        <Typography variant='h6'>{mode === 'content' && title}</Typography>
        <StatusAndMessage status={status} message={message} error={error} />
        <FlexContainer padding={`${theme.spacing(3)}px 0px`} flexDirection={'column'}>

               {!stock && <>
                    <SelectCategory
                        handleSelect={(value) => setValue('category',value)}
                        errors={errors.category?.message}
                    />

                    <SelectSubcategoryByCategory
                        parentCategory={watch('category')}
                        errors={errors.subcategory?.message}
                        handleSelect={(value) => setValue('subcategory',value)}
                    />
                
                    <SelectStockProduct
                        subcategory={watch('subcategory')}
                        error={errors?.product?.message}
                        handleSelect={(value)=>handleProductSelect(value)}
                    />
                </>}

                <TextField
                inputRef={register()}
                name='requestedAmount'
                size='small'
                variant='outlined'
                label={errors?.requestedQuantity?.message || 'Cantidad pedida' }
                color={errors?.requestedQuantity?.message ? 'primary' : 'secondary'}
                />

                <TextField
                inputRef={register()}
                name='observations'
                size='small'
                variant='outlined'
                label={errors?.observations?.message || 'Observación'}
                color={errors?.observations?.message ? 'primary' : 'secondary'}
                />

                <CustomButton status={status} action={handleSubmit(submit)}>Crear</CustomButton>
            
        </FlexContainer>
    </>
    
    return (<>

        <ComponentMode mode={mode} variant={variant || 'button'} icon='add' title={title}>
            {content}
        </ComponentMode>
    </>);
}

export default React.memo(CreatePurchaseRequestForm);
