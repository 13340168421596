import { LinearProgress } from '@material-ui/core';
import { Alert, Color } from '@material-ui/lab';
import React, { useEffect, useMemo, useState } from 'react';
import { QueryStatus } from 'react-query';
import CustomButton from '../../../../components/buttons/CustomButton';
import EaseInOut from '../../../../components/containers/animation/EaseInOut';


interface Props{
    status:QueryStatus
    message:string | undefined
    error:any
    reset?:()=>void
    severity?:Color
    timeToReset?: number
    variant?: "indeterminate" | "determinate"
}



export const TimedProgress:React.FC<{time?:number, variant?: "indeterminate" | "determinate"}> = React.memo(({time, variant}) => {


    const [progress,setProgress] = useState(0)
    /* const coeficient = time ? 100/time : 0 */

    useEffect(()=>{
        const pgress = setInterval(()=>{
            setProgress((p) => (p >= 100 ? 0 : p + 10))
        },200)

        return ()=>clearInterval(pgress)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return(
        <LinearProgress color='secondary' variant={variant ?? "determinate"} value={variant === "determinate" ? undefined : progress} />
    )
})

const StatusAndMessage:React.FC<Props> = ({status,message,error,reset,severity,timeToReset,variant}) => {

    const isTriggered = useMemo(()=>(status === 'success' ) && reset, [reset, status] )
    const time = timeToReset ?? 2000 

    useEffect(() => {
        if(isTriggered){
            const timeOut = setTimeout(()=>{
                reset && reset()
            },time)
            return () => clearTimeout(timeOut)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isTriggered]); 


    const action = !!reset ? <CustomButton icon={'close'} variant='iconButton' action={reset}></CustomButton> : null

    return (<>
           
            {error && 
                <EaseInOut>
                    <Alert          
                        action={action}
                        severity={severity || 'error'} 
                        onClose={reset}>
                            Ha ocurrido un problema. {error} 
                    </Alert>
                </EaseInOut>
            }

                {message && 
                <EaseInOut>
                    <Alert 
                        action={action}
                        severity={severity || (status === 'error' ? 'warning' : 'success')} 
                        onClose={reset}>
                            {message}<br/> 
                            {isTriggered &&  <TimedProgress time={time} variant={variant} />}
                        
                    </Alert>
                </EaseInOut>}

                {(error || message) && <br/> }  
        
    </>);
}

export default React.memo(StatusAndMessage);
