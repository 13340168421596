import { Button, Chip, CircularProgress, IconButton, Fab } from '@material-ui/core';
import React, { CSSProperties } from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import FilterListIcon from '@material-ui/icons/FilterList';
import EventIcon from '@material-ui/icons/Event';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ListAltIcon from '@material-ui/icons/ListAlt';
import SaveIcon from '@material-ui/icons/Save';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import BuildIcon from '@material-ui/icons/Build';
import DeleteIcon from '@material-ui/icons/Delete';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import MapIcon from '@material-ui/icons/Map';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import CreateIcon from '@material-ui/icons/Create';
import CropFreeOutlinedIcon from '@material-ui/icons/CropFreeOutlined';
import SpeakerNotesOutlinedIcon from '@material-ui/icons/SpeakerNotesOutlined';
import ToggleOffOutlinedIcon from '@material-ui/icons/ToggleOffOutlined';
import PowerSettingsNewOutlinedIcon from '@material-ui/icons/PowerSettingsNewOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PowerIcon from '@material-ui/icons/Power';
import VisibilityIcon from '@material-ui/icons/Visibility';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import FlagOutlinedIcon from '@material-ui/icons/FlagOutlined';
import LabelIcon from '@material-ui/icons/Label';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import SendIcon from '@material-ui/icons/Send';
import LanguageIcon from '@material-ui/icons/Language';
import WifiIcon from '@material-ui/icons/Wifi';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Add, AddCircle, BubbleChart, BugReport, Business, BusinessCenter, Check, CodeSharp, ErrorOutlined, ErrorOutlineRounded, FlagOutlined, Home, HomeOutlined, LabelImportantOutlined, LocationSearching, MoreHoriz, NewReleases, Opacity } from '@material-ui/icons';
import BlurOnIcon from '@material-ui/icons/BlurOn';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import CachedIcon from '@material-ui/icons/Cached';
import RefreshIcon from '@material-ui/icons/Refresh';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import WidgetsOutlinedIcon from '@material-ui/icons/WidgetsOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import TodayIcon from '@material-ui/icons/Today';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SyncIcon from '@material-ui/icons/Sync';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import MobileFriendlyIcon from '@material-ui/icons/MobileFriendly';
import PhonelinkEraseIcon from '@material-ui/icons/PhonelinkErase';
import TgdPopover from '../popovers/TgdPopover';
import theme from '../../assets/theme';
import Close from '@material-ui/icons/Close';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import InventoryIcon from '../../assets/icons/InventoryIcon';
import HelmetIcon from '../../assets/icons/HelmetIcon';
import PermissionsIcon from '../../assets/icons/PermissionsIcon';
import OilCanIcon from '../../assets/icons/OilCanIcon';
import CleaningIcon from '../../assets/icons/CleaningIcon';
import OutOfRouteIcon from '../../assets/icons/OutOfRouteIcon';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import UpdateIcon from '@material-ui/icons/Update';
import ExitToApp from '@material-ui/icons/ExitToApp';
import LabIcon from '../../assets/icons/LabIcon';
import RoomIcon from '@material-ui/icons/Room';
import QRCode from 'react-qr-code';

export type ButtonVariant = 'button' | 'iconButton' | 'chip' | 'fab'
export type CustomIconType = 'add' 
| 'qr'
| 'updateSync'
| 'circleOutlined'
| 'routes'
| 'points'
| 'search'
| 'news'
| 'home'
| 'person'
| 'search'
| 'nextState'
| 'outOfRouteWhite' 
| 'resync' 
| 'cancelOutlined' 
| 'refresh' 
| 'add-circle' 
| 'leak'
| 'lab' 
| 'cleaning' 
| 'pending' 
| 'checkedCircle' 
| 'temperature' 
| 'arrowDown' 
| 'arrowUp' 
| 'oilCan' 
| 'helmet' 
| 'permission' 
| 'inventory' 
| 'wifi' 
| 'language' 
| 'send' 
| 'list' 
| 'email' 
| 'label' 
| 'flag' 
| 'info' 
| 'view' 
| 'power' 
| 'survey' 
| 'more' 
| 'surveyPlay' 
| 'state' 
| 'observations' 
| 'toggleOff' 
| 'map' 
| 'tool' 
| 'off' 
| 'columns' 
| 'delete' 
| 'close' 
| 'edit' 
| 'download'
| 'filter' 
| 'calendar' 
| 'error' 
| 'success' 
| 'config' 
| 'stock'
| 'save' 
| 'next' 
| 'before' 
| 'check' 
| 'addList'
| 'capacity'
| 'date'
| 'user'
| 'sync'
| 'smartPhone'
| 'desktop'
| 'readyToSync'
| 'notReadyToSync'
| 'more-horizontal'
| 'bubble_chart'
| 'code'
| 'business'
| 'new-release'
| 'logout'
| 'pin'

export interface CustomIconProps { 
    icon:CustomIconType | undefined
    style?:CSSProperties
    onClick?:(e:any)=>void
}


export const CustomIcon:React.FC<CustomIconProps> = ({style, icon, onClick}) => {

    const componentStyle = {
        margin:'4px',
        ...style
    }
    const iconProps = {
        onClick,
    }
    
    return(<>
        {(icon === 'add' ) && <Add {...iconProps}  style={ componentStyle} />}
        {(icon === 'add-circle' ) && <AddCircle {...iconProps} style={ componentStyle} />}
        {(icon === 'edit' ) && <CreateIcon {...iconProps} style={ componentStyle} />}
        {(icon === 'delete' ) && <DeleteIcon {...iconProps} style={ componentStyle} /> }
        {(icon === 'close' ) && <Close {...iconProps} style={ componentStyle} > close</Close>}
        {(icon === 'config' ) && <SettingsOutlinedIcon {...iconProps} style={ componentStyle} /> }
        {(icon === 'check' ) && <Check {...iconProps} style={ componentStyle}/>}
        {(icon === 'download') && <GetAppIcon {...iconProps} style={ componentStyle} />}  
        {(icon === 'filter') && <FilterListIcon {...iconProps} style={ componentStyle} />}  
        {(icon === 'calendar') && <EventIcon {...iconProps} style={ componentStyle} />}  
        {(icon === 'error') && <ErrorOutlineIcon {...iconProps} style={ componentStyle} />}  
        {(icon === 'success') && <CheckCircleOutlineIcon {...iconProps} style={ componentStyle} />}  
        {(icon === 'stock') && <WidgetsOutlinedIcon {...iconProps} style={ componentStyle} />}  
        {(icon === 'save') && <SaveIcon {...iconProps} style={ componentStyle} />}  
        {(icon === 'next') && <NavigateNextIcon {...iconProps} style={ componentStyle} />}  
        {(icon === 'before') && <NavigateBeforeIcon {...iconProps} style={ componentStyle} />}  
        {(icon === 'addList') && <PlaylistAddIcon {...iconProps} style={ componentStyle} />}  
        {(icon === 'map') && <MapIcon {...iconProps} style={ componentStyle} />}  
        {(icon === 'tool') && <BuildIcon {...iconProps} style={ componentStyle} />}  
        {(icon === 'columns') && <ViewWeekIcon {...iconProps} style={ componentStyle} />}  
        {(icon === 'observations') && <SpeakerNotesOutlinedIcon {...iconProps} style={ componentStyle} />}  
        {(icon === 'survey') && <CropFreeOutlinedIcon {...iconProps} style={ componentStyle} />}  
        {(icon === 'toggleOff') && <ToggleOffOutlinedIcon {...iconProps} style={componentStyle} />}  
        {(icon === 'off') && <PowerSettingsNewOutlinedIcon {...iconProps} style={componentStyle} />}  
        {(icon === 'state') && <FiberManualRecordIcon {...iconProps} style={componentStyle} />}  
        {(icon === 'surveyPlay') && <PlayCircleFilledWhiteIcon {...iconProps} style={componentStyle} />}  
        {(icon === 'more') && <MoreVertIcon {...iconProps} style={componentStyle} />}  
        {(icon === 'checkedCircle') && <CheckCircleOutlineIcon {...iconProps} style={componentStyle} />}  
        {(icon === 'power') && <PowerIcon {...iconProps} style={componentStyle} />}  
        {(icon === 'view') && <VisibilityIcon {...iconProps} style={componentStyle} />}  
        {(icon === 'info') && <InfoOutlinedIcon {...iconProps} style={componentStyle} />}  
        {(icon === 'flag') && <FlagOutlinedIcon {...iconProps} style={componentStyle} />}  
        {(icon === 'label') && <LabelIcon {...iconProps} style={componentStyle} />} 
        {(icon === 'email') && <MailOutlineIcon {...iconProps} style={componentStyle} />} 
        {(icon === 'send') && <SendIcon {...iconProps} style={componentStyle} />} 
        {(icon === 'language') && <LanguageIcon {...iconProps} style={componentStyle} />} 
        {(icon === 'list') && <ListAltIcon {...iconProps} style={componentStyle} />} 
        {(icon === 'wifi') && <WifiIcon {...iconProps} style={componentStyle} />} 
        {(icon === 'inventory') && <InventoryIcon {...iconProps} style={componentStyle} />} 
        {(icon === 'helmet') && <HelmetIcon {...iconProps} style={componentStyle} />} 
        {(icon === 'permission') && <PermissionsIcon {...iconProps} style={componentStyle} />} 
        {(icon === 'oilCan') && <OilCanIcon {...iconProps} style={componentStyle} />} 
        {(icon === 'arrowUp') && <ExpandLessIcon {...iconProps} style={componentStyle} />} 
        {(icon === 'arrowDown') && <ExpandMoreIcon {...iconProps} style={componentStyle} />} 
        {(icon === 'leak') && <Opacity {...iconProps} style={componentStyle} />} 
        {(icon === 'temperature') && <BlurOnIcon {...iconProps} style={componentStyle} />} 
        {(icon === 'pending') && <HourglassEmptyIcon {...iconProps} style={componentStyle} />} 

        {(icon === 'lab') && <LabIcon {...iconProps} style={componentStyle} />}
        {/* {(icon === 'lab') && <BugReport {...iconProps} style={componentStyle} />} */}
        {(icon === 'cleaning') && <CleaningIcon {...iconProps} style={componentStyle} />} 
        {(icon === 'cleaning') && <OutOfRouteIcon {...iconProps} style={{...componentStyle,fill:'#fff'}}/>} 
        {(icon === 'resync') && <CachedIcon {...iconProps} style={componentStyle} />} 
        {(icon === 'refresh') && <RefreshIcon {...iconProps} style={componentStyle} />} 
        {(icon === 'cancelOutlined') && <CancelOutlinedIcon {...iconProps} style={componentStyle} />} 
        {(icon === 'outOfRouteWhite') && <OutOfRouteIcon {...iconProps} style={componentStyle} />} 
        {(icon === 'nextState') && <LabelImportantOutlined {...iconProps} style={componentStyle} />} 
        {/* {(icon === 'search') && <GridSearchIcon {...iconProps} style={componentStyle} />} */} 
        {(icon === 'capacity') && <ZoomOutMapIcon {...iconProps} style={componentStyle} />} 
        {(icon === 'date') && <TodayIcon {...iconProps} style={componentStyle} />} 
        {(icon === 'user') && <AccountCircleIcon {...iconProps} style={componentStyle} />} 
        {(icon === 'sync') && <SyncIcon {...iconProps} style={componentStyle} />} 
        {(icon === 'smartPhone') && <PhoneIphoneIcon {...iconProps} style={componentStyle} />} 
        {(icon === 'desktop') && <DesktopWindowsIcon {...iconProps} style={componentStyle} />} 
        {(icon === 'readyToSync') && <MobileFriendlyIcon {...iconProps} style={componentStyle} />} 
        {(icon === 'notReadyToSync') && <PhonelinkEraseIcon {...iconProps} style={componentStyle} />} 
        {(icon === 'person') && <PersonIcon {...iconProps} style={componentStyle} />} 
        {(icon === 'home') && <HomeOutlined {...iconProps} style={componentStyle} />} 
        {(icon === 'news') && <ErrorOutlineRounded {...iconProps} style={componentStyle} />} 
        {(icon === 'search') && <SearchIcon {...iconProps} style={componentStyle} />} 
        {(icon === 'points') && <LocationSearching {...iconProps} style={componentStyle} />} 
        {(icon === 'routes') && <FlagOutlined {...iconProps} style={componentStyle} />} 
        {(icon === 'bubble_chart') && <BubbleChart {...iconProps} style={componentStyle} />} 
        {(icon === 'circleOutlined') && <RadioButtonUncheckedIcon {...iconProps} style={componentStyle} />} 
        {(icon === 'updateSync') && <UpdateIcon {...iconProps} style={componentStyle} />} 
        {(icon === 'code') && <CodeSharp {...iconProps} style={componentStyle} />} 
        {(icon === 'business') && <Business {...iconProps} style={componentStyle} />} 
        {(icon === 'new-release') && <NewReleases {...iconProps} style={componentStyle} />} 
        {(icon === 'logout') && <ExitToApp {...iconProps} style={componentStyle} />} 
        {(icon === 'pin') && <RoomIcon {...iconProps} style={componentStyle} />} 
        {(icon === 'qr') && <QRCode size={15} value={''}  style={{
            ...componentStyle,
            marginBottom:'8px',
        }}/>} 

        

    </>)
}

export type ButtonColorType = 'primary' | 'secondary' | 'inherit' | undefined

interface Props{
    color?:ButtonColorType 
    variant?:ButtonVariant
    action?:(item?:any)=>void
    title?:string
    type?:'submit'
    icon?:CustomIconType
    status?: 'error' |'loading' | 'success' | 'idle'
    disabled?:boolean
    popoverTitle?:string
    styleProps?:CSSProperties
    outlined?:boolean,
    right?:string
    bottom?:string
    top?:string
    left?:string
}


const CustomButton:React.FC<Props> = ({action, title, type, children, icon, status, disabled, variant, color, popoverTitle, styleProps, outlined, right, bottom, top, left}) => {

    const defaultVariant:ButtonVariant = variant || 'button'

    const style:CSSProperties | undefined = status 
        ?   (status === 'loading'

                ?   { backgroundColor:theme?.palette?.info?.main }

                :   (status === 'success'

                        ? { backgroundColor:theme?.palette?.success?.dark }

                        :undefined
                    )

            )
        :   undefined


    const iconStatus = status 
    ?   (((status !== 'error' && status !== 'success' ))
            ? icon
            : status
        )
    :   icon


    const buttonContent =
    <>
        {icon && <CustomIcon icon={iconStatus} /> }
        {title && title} {children && children}
    </>

    const buttonStatus =
    <>
        {
        status 
            ?   ((status === 'loading') 
                    ?   <CircularProgress color='inherit' size='20px' />
                    :   buttonContent
                ) 
            :   buttonContent
        }


    </>

    const fabVariant =  <> 
        {defaultVariant === 'fab' &&
            <Fab 
            color={(color || 'primary')} 
            onClick={action} 
            style={{
                top,
                right,
                bottom,
                left
            }}
            
            >
                <CustomIcon icon={icon}/>
            </Fab>
        }
    </>

    const chipVariant = <>
        {defaultVariant==='chip' &&
            <Chip disabled={disabled} color={(color==='inherit') ? 'default' : (color || 'default')} size='small' icon={<CustomIcon icon={icon}/>} label={title || children || ''} onClick={action} />
        }
    </>

    const IconVariant = <>
        {defaultVariant === 'iconButton' && 
            <IconButton color={color} type={type} style={styleProps} size='small' onClick={action} disabled={ disabled }>
                { status === 'loading' ? <CircularProgress color='inherit' size='20px'/> : <CustomIcon icon={icon} /> }
            </IconButton>
        }
    </>

    const ButtonVariant =<>
        {defaultVariant === 'button' && 
            <Button size='small' color={status ==='error' ? ('primary') : (color ? color :'secondary')} style={{...style,...styleProps}} type={type} variant={outlined ? 'outlined' : 'contained'}  onClick={action} disabled={disabled}>
                {buttonStatus}
            </Button>        
        }
    </>
    
    return (<>     

        {popoverTitle 
        ?   <TgdPopover mode='hover' title={popoverTitle}>
                {IconVariant}
                {ButtonVariant}
                {chipVariant}
                {fabVariant}
            </TgdPopover>

        :   <>
                {IconVariant}
                {ButtonVariant}
                {chipVariant}
                {fabVariant}
            </>
        }

    </>);
}

export default CustomButton;
