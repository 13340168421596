import React from 'react'
import { Route, Routes } from 'react-router-dom';
import PageContainer from '../../../../components/containers/PageContainer';
import { PersonInterface } from '../../../../domains/person/models/PersonInterface';
import { Plant } from '../../../../domains/plants/PlantInterface';
import Reports from '../../../../domains/powerBi/components/Reports';
import { CustomUserPbiData } from '../../../../domains/powerBi/models/powerBiInterfaces';
import User from '../../../../domains/users/UserInterface';
import { USER_ROUTES } from '../../../../shared/routes/routes';
import ClientHome from '../../ClientVisor/home/ClientHome';
import PlantSelector from '../../ClientVisor/PlantSelector';
import UserPanel from '../../UserPanel';

import UserDashboard from './UserDashboard';


interface Props {
    user?:User,
    userRole:string,
    currentPbiGroupName:string,
    selectedPlant:Plant,
    baseUrl?:string
}

const ClientContent:React.FC<Props> = ({user,currentPbiGroupName,userRole,selectedPlant,baseUrl}) => {

    const userType = 'C'
    const customConfig:CustomUserPbiData = {defaultUser:userType, name:currentPbiGroupName}

    return(<>
        <UserDashboard
            {...{
                userType,
                currentPbiGroupName,
                userRole
            }} 
        >
            <Routes>
                <Route path='' element={<ClientHome defaultConfig={customConfig}/>}/>
                <Route path='*' element={<Reports/>} />
            </Routes>
        </UserDashboard>
  </>)
}

export default ClientContent