import React,{useEffect} from 'react';
import { CircularProgress, IconButton, } from '@material-ui/core';
import { useDeleteComponentVerification } from './services/service.components';
import { CustomIcon } from '../../components/buttons/CustomButton';
import TgdPopover from '../../components/popovers/TgdPopover';

import { useSnackbar } from '../../components/snackbar/hooks/useSnackbar';
import { ComponentInterface } from './models/ComponentInterface';
import { useStatusProcessor } from '../../shared/queries/StatusProcessor';
import { useClickPosition } from '../../shared/globals/hooks/useClickPosition';

interface Props{
    item:ComponentInterface   
}

const DeleteComponent:React.FC<Props> = ({item}) => {

    const {status:deleteStatus,validate,errors, query} = useDeleteComponentVerification()
    const { status, message,  } = useStatusProcessor(query)

    const {position,getClickPosition} = useClickPosition()
    const { setData } = useSnackbar()

    useEffect(()=>{

        if(deleteStatus === 'error'){
            const componentsError = (
                (errors?.equipments?.length 
                    ? (errors.equipments.length > 1 
                        ? `${errors.equipments.length} Equipos` 
                        : `${errors.equipments.length} Equipo`) 
                    : '')
            )
            setData({
                modal:true,
                errors:`Este componente no se puede eliminar porque está siendo usado en: ${componentsError}` ,
                severity:'warning',
                position:position
            })
        }
        
        if(status === 'success'){
            setData({
                modal:true,
                errors:message,
                severity:'success',
                position
            })
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[deleteStatus,status,errors])

    return (
        (status === 'loading')  
        ? <CircularProgress size='24px' /> 
        : <TgdPopover mode='hover' title='Eliminar componente'>
            <IconButton  size='small' onClick={(e:any)=>getClickPosition(e,()=>validate(item))}>
                <CustomIcon icon='delete'/>
            </IconButton>
        </TgdPopover>
    );
}

export default React.memo(DeleteComponent);
