import styled from "@emotion/styled"
import React from "react"
import FlexContainer from "../../../components/containers/FlexContainer"

export const useRenderOptionColumn = (headerName,renderOption)=>{

    if(renderOption){
      return (hooks) => {
        hooks.visibleColumns.push(columns => [
          // Let's make a column
          {
            id: 'renderOptions',
            Header:headerName,
            Cell: ({row}) => (
              <FlexContainer align={'center'} gap={'0px'}>
                  {renderOption && renderOption(row.original)}
              </FlexContainer>
            ),
            width:230
            
          },
          ...columns,
        ])
      }
    }else{
      return ()=>{}
    }
  }