import React from 'react'
import { CustomIconType } from '../../../components/buttons/CustomButton'
import ModalButton from '../../../components/buttons/ModalButton'
import { useLangLabels } from '../../../shared/lang/services/useLangLabels'

import VirtualGrid from '../../../shared/react-table/VirtualGrid'
import { LubricationPointInterface } from '../../lubricationPoints/model/lubricationPointsInterface'
import CustomObservationsList from '../../observations/CustomObservationsList'
import { useSurveyGridModel } from '../services/lang/useSurveyGridModel'
import { useSurveysByLubricationPoint } from '../services/surveyServices'

interface Props{
    mode:'modal' | 'content'
    lubricationPoint:LubricationPointInterface
}

const EquipmentSurveysList:React.FC<Props> = ({mode,lubricationPoint})=> {

    const {lang} = useLangLabels()
    const entity = useSurveysByLubricationPoint(lubricationPoint.tagTGD).data
    const gridModel = useSurveyGridModel()
    
    const title =  lang.messages.lastTenSurveys
    const icon:CustomIconType = 'survey'

    const content = 
    <VirtualGrid
        items={entity}
        gridModel={gridModel}
        title={`${title} del equipo ${lubricationPoint?.plantTag}`}
        bulkActions={(items:any[])=> <CustomObservationsList queryType='survey' items={items} />}
        documentExportTitle={`Reporte_${title}_${lubricationPoint?.plantTag}`}
        /* renderOptions={item.renderOption} */
    />

    return(<>
        {mode === 'modal' && 
            <ModalButton fullwidth={true} icon={icon} variant='iconButton' popoverTitle={`${title} de este punto de lubricación`} >
                {content}
            </ModalButton>
        }

        {mode === 'content' &&
            content
        }
        
    </>)
}

export default React.memo(EquipmentSurveysList)