import { TextField } from "@material-ui/core"
import React, { useMemo } from "react"
import { Controller, useForm } from "react-hook-form"
import SelectSimpleEntity from "../../../../components/autocomplete/old/SelectSimpleEntity"
import { ButtonVariant } from "../../../../components/buttons/CustomButton"
import FlexContainer from "../../../../components/containers/FlexContainer"
import DefaultFormLayout from "../../../../components/form/DefaultFormLayout"
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels"
import { EquipmentFormRegex } from "../../../../shared/regExp/regExp"
import { useGetAllAuxByPlantData } from "../../../allAux/services/allAuxServices"
import SelectComponent from "../../../components/components/SelectComponent"
import SelectElement from "../../../elements/molecules/SelectElement"
import SelectLubricant from "../../../lubricant/molecules/SelectLubricant"
import SelectTypeBy from "../../../types/components/SelectTypeBy"
import { enumTgdTypes } from "../../../types/models/types"
import { EquipmentInterface, equipmentCreateSuggestedQuantityFactory, equipmentType, LubricationPointInterface } from "../../model/lubricationPointsInterface"
import { lubricationPointVerificationArray, useCreateLubricationPoint, useEquipmentsTag } from "../../services/lubricationPointsAdapters"
import SelectSugestedQuantity from "./SelectSuggestedQuantity"
import { SelectOilAnalysis } from "./SelectOilAnalysis"
import FeatureStatusContainer from "../../../../shared/config/features/FeatureStatusContainer"


interface Props{
    item:EquipmentInterface
    element?:string
    variant?:ButtonVariant
}


const CreateLubricationPoint:React.FC<Props> = ({item,variant,element}) => {

    const {lang} = useLangLabels()
    const _tagFP = useMemo(()=>item.tagFP,[item.tagFP])
    const {data:lubricants} = useGetAllAuxByPlantData('lubricants')

    
    const {register,setValue,handleSubmit,watch,errors,control} = useForm()
    
    const selectedLubricants = lubricants?.find((l:any) => l.lubricant === watch().lubricant)
        
    const {createLubricationPoint, status, message, error } = useCreateLubricationPoint()
    const {getTag} = useEquipmentsTag()

    const submit = (data:LubricationPointInterface) => {
        createLubricationPoint({
            tagTGD:`${getTag(item,equipmentType.equipment)}`,
            tagFP:item.tagFP,
            sector: item.sector,
            plantTag: item.plantTag,
            equipment: item.equipment,
            type: item.type,
            criticality:item.criticality,
            brand: data.brand,
            model:  data.model,
            capacity: data.capacity,
            component: data.component,
            element: element || data.element,
            function: data.function || lang.stringDefaultValues.notSpecified,
            location: data.location,
            lubricant: data.lubricant,
            lubricantType: selectedLubricants?.type!,
            measureUnit: data.measureUnit,
            observations: data.observations || lang.stringDefaultValues.notSpecified,
            oilAnalysis: !!(data.oilAnalysis === 'SI'),
            review: 1,
            suggestedQuantity:data.suggestedQuantity
        })
    }

    console.log(errors)
    
    return (<>

         <DefaultFormLayout variant={variant} status={status} message={message} error={error} onChange={handleSubmit(submit)} buttonTitle='Agregar'>             

               <TextField
                   inputRef={register({
                       required:{value:true,message:'requerida'},
                   })} 
                   value={watch('brand',lang.stringDefaultValues.notSpecified)}
                   name="brand"
                   variant='outlined'
                   label={`Marca del componente ${errors?.brand?.message || ''}`} 
                   color={errors?.brand?.message  ? 'primary' : 'secondary'}
                   size="small" 
                   />

               <TextField
                   inputRef={register({
                       required:{value:true,message:'requerido'},
                   })} 
                   value={watch('model',lang.stringDefaultValues.notSpecified)}
                   name="model" 
                   variant='outlined'
                   label={`Modelo del componente ${errors?.model?.message || ''}` } 
                   color={errors?.model?.message ? 'primary' : 'secondary'}
                   size="small"
                   />

               <TextField
                    inputRef={register()}
                    name="function" 
                    value= {watch('function',lang.stringDefaultValues.notSpecified)}
                    variant='outlined'
                    label={'Función del componente'} 
                    color={errors?.function?.message ? 'primary' : 'secondary'}
                    size="small"
                />

               <TextField
                    inputRef={register()} 
                    value= {watch('location',lang.stringDefaultValues.notSpecified)}
                    name="location" 
                    variant='outlined'
                    label={'Ubicación del componente'} 
                    color={'secondary'}
                    size="small"
                />

               <TextField
                   inputRef={register()} 
                   name="observations"
                   value= {watch('observations',lang.stringDefaultValues.withoutObservations)}
                   variant='outlined'
                   label={'Observaciones'} 
                   color={'secondary'}
                   size="small"
                   />

                {!!!element &&
                    <Controller
                        as={<SelectElement
                            tagFP={_tagFP}
                            error={errors?.element?.message}
                            onChange={(value)=>setValue('element',value)}
                            defaultValue={watch('element')}
                            />}
                        name='element'
                        rules={{
                            required:{value:true, message:'requerido'} 
                        }}
                        control={control}
                    />
                }
              <Controller
                   as={<SelectComponent
                       tagFP={_tagFP}
                       error={errors?.component?.message}
                       onChange={(value:string)=> setValue('component',value)}
                       defaultValue={watch('component')}
                   />}
                   name='component'
                   rules={{
                       required:{value:true,message:'obligatorio'},
                       validate:{exist: () => lubricationPointVerificationArray(item.lubricationPoints || [],watch('element'),watch('component'))}
                   }}
                   control={control}
               />
               
              <Controller
                   as={<SelectLubricant
                       tagFP={_tagFP}
                       error={errors?.lubricant?.message}
                       onChange={(value:string)=>setValue('lubricant',value)}
                       defaultValue={watch('lubricant')}
                       />}
                   name='lubricant'
                   rules={{required:{value:true,message:'Campo Obligatorio'}}}
                   control={control}
               />

               <TextField
                   inputRef={register({
                       required:{value:true,message:'Campo Obligatorio'},
                       pattern:{value:EquipmentFormRegex.float, message:'El número no es válido'},
                   })} 
                   name="capacity" 
                   placeholder='Capacidad de lubricante' 
                   variant='outlined'
                   label={errors?.capacity?.message || 'Capacidad de lubricante'} 
                   color={errors?.capacity?.message ? 'primary' : 'secondary'}
                   size="small"
                   />                

               <Controller
                   as={<SelectTypeBy
                    key={selectedLubricants?.measureUnit}
                    error={errors?.measureUnit?.message}
                    by={{key:'type',value:enumTgdTypes.unity}}
                    tagFP={_tagFP}
                    disabled={true}
                    onChange={(value)=>setValue('measureUnit',value)}
                    defaultValue={selectedLubricants?.measureUnit!}
                    />}
                   name='measureUnit'
                   rules={{required:{value:true,message:'Campo Obligatorio'}}}
                   control={control}
               />

               

               <Controller
                   as={
                       <SelectOilAnalysis
                           error={errors?.oilAnalysis?.message}
                           onChange={setValue}
                           value={watch('oilAnalysis')}
                       />
                   }
                   name='oilAnalysis'
                   rules={{required:{value:true,message:'obligatorio'}}}
                   control={control}
               />

            
               <Controller
                   as={
                       <SelectSugestedQuantity
                            defaultValue={equipmentCreateSuggestedQuantityFactory(watch('measureUnit'))}
                            onChange={(value)=>setValue('suggestedQuantity',value)}
                        />
                   }
                   name='suggestedQuantity'
                   rules={{required:{value:true,message:'obligatorio'}}}
                   control={control}
               />
            </DefaultFormLayout> 
    </>);
}

export default React.memo(CreateLubricationPoint);
