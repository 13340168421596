import { useMemo, useReducer } from "react";
import { formReducer } from "./reducers/FormReducer";
import { InitialState, FormDataResult, FormReducerType, FormField, FormConfigCallbacks, FormErrors, FormValidations } from "./models/FormTypes";

//Utils


export const useForm = <T,>(initialFields:InitialState<T>) => {
    
    //initialState
    const _initialState = useMemo(()=>({
        ...initialFields,
        errors:{},
        isValidated:false,
        callbacks:{
            callbackSuccess:(formData:FormDataResult<T>)=>console.log('SUCCESS',formData),
            callbackError:(formError:FormErrors<T>)=>console.log('ERROR',formError)
        }
    }),[initialFields])
    
    //state
    const [state, dispatch] = useReducer<FormReducerType<T>>(formReducer, _initialState)
    const {fields, errors, isValidated } = state
    
    //computed data
    const completefields = useMemo(()=>{
        const err = errors as any
        let newFields = fields
        for(const f in fields){
            newFields ={
                ...newFields,
                [f]:{
                    ...fields[f],
                    error: err[f]
                },
            }
        }
        return newFields
    },[errors, fields])

    const dataModel = useMemo(()=>{
        let data = {} as T
        for(let d in fields){
            data = {
                ...data,
                [d]:fields[d].value
            }
        }
        return data
    },[errors, fields])

    const uiModel = useMemo(()=>{
        const elementsData = Object.entries(fields) as [ keyof T, FormField<keyof T>][]
        const err = errors as any

        
        return elementsData.map(([key,value]) => {
            switch (err ) {
                case {}:
                   return{
                    key,
                    name:key,
                    onChange:(value:FormField<T>) => {
                        setValue(key,value)
                    },
                    defaultValue:value.value,
                    ...value
                }

            default:
                return {
                    key,
                    name:key,
                    onChange:(value:FormField<T>) => {
                        setValue(key,value)
                    },
                    defaultValue:value.value,
                    ...value,
                    error:err[key]
                }
            }
        })
    },[errors, fields])

    
    //ACTION CREATORS
    const setValue = (name:keyof T ,value:any) => {
        dispatch({
            type:'SET_VALUE',
            payload:{
                name,
                value,
            }
        })
    }

    const setValidations = (key: keyof T, validation: keyof FormValidations, value: any) => {
        dispatch({
            type:'SET_VALIDATIONS',
            payload:{
                key,
                validation,
                value
            }
        })
    }

    const validateAll = (callbacks:FormConfigCallbacks<T>) => {
        dispatch({
            type:'VALIDATE_ALL',
            payload:callbacks
        })
    }

    const reset = () => {
       dispatch({
           type:'RESET_ALL',
           payload:_initialState
       })
    }
    
    const nextItem = () => {
       dispatch({
           type:'NEXT'
        })
    }

    const prevItem = () => {
       dispatch({
           type:'PREV'
        })
    }

    const handleSubmit = (onSuccess:FormConfigCallbacks<T>[0],onError?:FormConfigCallbacks<T>[1]) => { 
        return ()=> {
            if(isValidated){
                Object.entries(errors).length === 0 
                ? onSuccess(dataModel)
                : onError && onError(errors)
            }else{
                //Validate data
                validateAll([onSuccess, onError])
            }
        }
    }

    return ({
        fields:completefields,
        errors,
        dataModel,
        uiModel,
        setValue,
        setValidations,
        handleSubmit,
        reset,
        nextItem,
        prevItem
    });
}


