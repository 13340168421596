import React from 'react';
import { CrudFormType } from '../../../shared/form/models/FormTypes';
import { useLangLabels } from '../../../shared/lang/services/useLangLabels';
import VirtualGrid from '../../../shared/react-table/VirtualGrid';
import { Plant } from '../../plants/PlantInterface';
import { useSessionContext } from '../../session/store/sessionContext';
import CUtypeForm from '../CUtypeForm';
import DeleteTypeForm from '../DeleteTypeForm';
import { useTypesByPlant } from '../services/service.types';
import { useTypeGridModel } from '../services/useTypesGridModel';



interface Props{
    virtualGridWidth:string
}

const ManageTypesPage:React.FC<Props> = ({virtualGridWidth}) => {

    const {tagFP} = useSessionContext()
    const {lang} = useLangLabels()
    const { data:types, isLoading } = useTypesByPlant(tagFP)
    const gridModel = useTypeGridModel()

    return(<>

        <VirtualGrid
            width={virtualGridWidth}
            title={lang.types.plural}
            items={types}
            gridModel={gridModel}
            carrousellComponents={[
                {icon:'edit',popoverTitle:lang.crudActions.update ,component:(item)=><CUtypeForm  {...{item,tagFP}} actionType={CrudFormType.update} />}
            ]}
            headerActions={(item:any)=>
                <CUtypeForm {...{tagFP:tagFP}} item={item} actionType={CrudFormType.create} />
            }
        >
            {(items)=><>
                {items?.length === 1 &&
                    <DeleteTypeForm item={items[0]} />
                }
            </>}
        </VirtualGrid>
    
    </>
    )
}

export default React.memo(ManageTypesPage);
