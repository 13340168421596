import React, { useState } from 'react'
import { Badge, TextField, Typography } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import CustomButton from '../../buttons/CustomButton'
import { DynamicFiltersProps, filterLabel, FilterType } from './services/useDynamicFilter'
import SimpleAutocompleteSelect from '../../autocomplete/SimpleAutocompleteSelect'
import { useLangLabels } from '../../../shared/lang/services/useLangLabels'
import { useAutocompleteStyles } from '../../autocomplete/useAutocompleteStyle'
import { useModal } from '../../containers/modal/hooks/useModal'
import PageContainer from '../../containers/PageContainer'
import FlexContainer from '../../containers/FlexContainer'
import ComponentMode from '../../componentMode/ComponentMode'
import { v4 as uuidv4 } from 'uuid';
import { useQueryPersistanceState } from '../../../shared/react-query/hooks/useQueryPersistanceState'

const FilterItemOption:React.FC<{
    entity:filterLabel,index:number, 
    handleChange:(key:string,value:any,index:number)=>void, 
    handleNewFilterModal:()=>void, 
    handleDelete?:(entity:filterLabel,filterValue:any)=>void,
    selectedValue: any
}> = React.memo(({ index, entity, handleChange, handleDelete, handleNewFilterModal, selectedValue }) => {

    //const cacheKey = `filter-${entity.key}`

    const [filterValue,setFiltervalue] = useState(selectedValue)

    const handlefilter = (key:string,value:any) => {
        setFiltervalue(value)
        handleChange(key,value,index)
    }

    const filterDelete = () => {
        filterValue &&
        handleDelete && handleDelete(entity,filterValue)
        setFiltervalue(undefined)
    }

    return(
            <SimpleAutocompleteSelect
                openOnFocus={filterValue ? false : true}
                autoFocus={filterValue ? false : true}
                label={entity.key}
                items={entity.options}
                onChange={(value:any)=>handlefilter(`${entity.key}`,value)}
                showTitle={'name'}
                defaultValue={filterValue}
            >

                {
                    handleDelete && filterValue 
                    ?<CustomButton 
                        variant='iconButton' 
                        icon='delete' 
                        action={filterDelete}
                    />
                    :null
                }

            </SimpleAutocompleteSelect>
    )
})

const DynamicFilter:React.FC<DynamicFiltersProps > = ({filters, notSelectedColumnLabels, periodFilterEnabled, existAndFilter ,searchValue, handleResetFilters, handleDeleteFilter, setSearchValue, selectedEntitiestoFilter, handleFiltersChange, errors, handlePeriod, defaultPeriodValues, addEntityToFilters,  handleResetPeriod, periodSubmit, onSearchValueChange }) => {

    const {lang} = useLangLabels()
    const classes = useAutocompleteStyles({width:'150px'})
    const {modal,handleModal} = useModal()
    const [filterType, setFilterType] = useState<FilterType>('orFilterType') 
    
    const filterQuantityArray = [selectedEntitiestoFilter.length,searchValue ? 1 : 0, defaultPeriodValues.secondDate || defaultPeriodValues.firstDate ? 1 : 0]
    const filterQuantity = filterQuantityArray.reduce((total, valor)=> total + valor ,0) 

    const title = lang.filters.title

    const handleResetFilterType = ()=>{
        setFilterType('orFilterType')
    }

    //delay search trigger
    const handleSearchboxChange = (value:string)=>{
        setSearchValue(value)
        onSearchValueChange(value)
    }

    //handleFilterValue
    const _handleChange = (key: string, value: string, index: number, ) =>{
        handleFiltersChange(key, value, index, filterType)
    }

    const _handleResetFilters = () => {
        handleResetFilters()
        handleResetFilterType()
    }

    const _handleDeleteFilter = (entity: filterLabel, filtervalue: any) => {
        handleDeleteFilter && handleDeleteFilter(entity,filtervalue)
        handleResetFilterType()
    }

    const forms = <PageContainer padding='0px'>
        <br />
        <TextField
            fullWidth
            size='small'
            variant='outlined'
            label={lang.filters.searchByTerm}
            type='search'
            value={searchValue}
            onChange={(e:any) => handleSearchboxChange(e.target.value)}
        />
        <br />
        <br />
        <FlexContainer flexDirection='column'>
                {
                    selectedEntitiestoFilter?.map((entity:filterLabel,index:number)=>                                
                        <FilterItemOption
                            {...{
                                key:entity.id,
                                index,
                                entity,
                                handleChange:_handleChange,
                                handleDelete:_handleDeleteFilter,
                                handleNewFilterModal:handleModal,
                                selectedValue: entity.selectedValue
                            }}
                        />
                    )
                }

                {periodFilterEnabled && 
                    <FlexContainer style={{margin: "12px 0", display: "flex", justifyContent: "space-between"}}>
                        <FlexContainer>
                            <TextField
                                focused={errors?.firstDate}
                                error={errors?.firstDate}
                                InputLabelProps={{ shrink: true }}
                                placeholder={defaultPeriodValues.firstDate || ''}
                                size='small'
                                variant='outlined'
                                name='firstDate'
                                label={errors?.firstDate?.message || lang.filters.from}
                                value={defaultPeriodValues.firstDate}
                                type='date'
                                onChange={handlePeriod}
                            />

                            <TextField
                                focused={errors?.secondDate}
                                error={errors?.secondDate}
                                InputLabelProps={{ shrink: true }}
                                placeholder={defaultPeriodValues.secondDate || ''}
                                size='small'
                                variant='outlined'
                                name='secondDate'
                                label={errors?.secondDate?.message || lang.filters.to}
                                defaultValue={defaultPeriodValues.secondDate}
                                value={defaultPeriodValues.secondDate}
                                type='date'
                                onChange={handlePeriod}
                            />
                        </FlexContainer>
                        <FlexContainer>
                            <CustomButton icon='search' variant='iconButton' action={periodSubmit} popoverTitle={lang.filters.queryDateRange} />
                            {
                                periodFilterEnabled 
                                    && <CustomButton icon='delete' variant='iconButton' action={handleResetPeriod} popoverTitle={lang.filters.resetDateRange} />
                            }
                        </FlexContainer>
                    </FlexContainer>
                }
            
                {/* manage filters actions*/}
                {modal 
                    ?  <FlexContainer>
                        <Autocomplete
                            openOnFocus
                            color='Primary'
                            size='small'
                            options={notSelectedColumnLabels}
                            onChange={(e,value)=>{
                                addEntityToFilters({
                                    ...value,
                                    id:uuidv4(),
                                    type:filterType
                                },handleModal)
                            }}
                            getOptionLabel={(option:any) => option.label}
                            style={{ width: 150 }}
                            classes={{
                                option:classes.option
                            }}
                            renderInput={(params) => <TextField autoFocus {...params} label={lang.filters.addFilter} variant="outlined" />}
                        /> 
                        <CustomButton 
                            popoverTitle={lang.filters.deleteFilters} 
                            action={_handleResetFilters} 
                            icon='delete'
                        />
                    </FlexContainer>
                    
                    : <FlexContainer> 
                        <CustomButton 
                            popoverTitle={lang.filters.addFilter} 
                            icon='add' action={handleModal}
                        /> 
                        
                        {(selectedEntitiestoFilter.length > 0) && 
                            <CustomButton 
                                popoverTitle={lang.filters.deleteFilters} 
                                action={_handleResetFilters} 
                                icon='delete'
                            />
                        }

                    </FlexContainer> 
                }

        </FlexContainer>
    </PageContainer>

    return (
        <Badge badgeContent={filterQuantity} color='primary' >
            <ComponentMode mode='popover' icon='filter' popoverTitle={title} >
                <PageContainer width='450px' padding='0px'>
                    <Typography variant='h6'>
                        {title}
                    </Typography>
                    {forms}                       
                </PageContainer>
            </ComponentMode>
        </Badge>
    )
}

export default DynamicFilter