import React from 'react'
import { Autocomplete, FilterOptionsState } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { useSimpleAutocompleteProps } from "./useSimpleAutocompleteProps";
import { CustomIcon, CustomIconType } from '../buttons/CustomButton';
import FlexContainer from '../containers/FlexContainer';


interface Props {
    previus?:React.ReactNode
    items:any[],
    error?:string,
    label:string,
    defaultValue?:any,//last change optional
    renderOption?:(option:any)=>any
    onChange:(value?:any)=>void,
    extendInput?:boolean
    showTitle:string
    icon?:CustomIconType
    iconColor?:string
    disabled?:boolean
    helper?:string
    openOnFocus?:boolean
    autoFocus?:boolean
    children?:React.ReactNode
    filterOptions?:(options: any[], state: FilterOptionsState<any>) => any[]
    clearable?:boolean
    freeSolo?: boolean
    autoSelect?: boolean
}

const SimpleAutocompleteSelect:React.FC<Props> = ({previus, autoFocus, openOnFocus,filterOptions, helper, children, items, error, label, defaultValue, onChange, showTitle, icon, iconColor, extendInput, disabled, renderOption, clearable, freeSolo, autoSelect }) =>{
    
    const [ableToSetDefaultValue,setAbleToSetDefaultValue] = useState(true)
    const [extendedValue,setExtendedValue] = useState<string | null>(null)
    const [inputValue, setInputValue] = useState<string | null>(null)
    const [value, setValue] = useState<any | null >(null);

    const autocomplete = useSimpleAutocompleteProps<any>({
        items,
        value,
        error,
        label,
        helper,
        autoFocus
    })
    
    const handleReset = () => {
        setValue(null)
        setExtendedValue(null)
        setInputValue(null)
    }
    
    const handleInputChange = (e:any) => {
        const inputValue = e?.target?.value || null
        if(value){
            if(extendInput){
                if(inputValue){
                    const sizetoSplit:number = value[showTitle]?.split('').length
                    const inputValueSplitted:string[] = inputValue?.split('') || []
                    const result = inputValueSplitted?.slice(sizetoSplit)?.join('')
                    setExtendedValue(result)
                }
            }   
        }else{
            if(extendInput){
                setExtendedValue(inputValue)
                setAbleToSetDefaultValue(false)
            }
        }
    }

    const handleCustomInputChange = (e: any) => {
        const targetValue = e?.target?.value || null
        if(value){
            if(autoSelect){
                if(targetValue){
                    const sizetoSplit:number = value[showTitle]?.split('').length
                    const inputValueSplitted:string[] = targetValue?.split('') || []
                    const result = inputValueSplitted?.slice(sizetoSplit)?.join('')
                    setInputValue(result)
                }
            }   
        }else{
            if(autoSelect){
                setInputValue(targetValue)
                setAbleToSetDefaultValue(false)
            }
        }
    }
    
    const handleAutocompleteChange = (value:any) => {
        setValue(value)
        
        if(!value && clearable){
            onChange(value)
        }
    }

    const handleChange = () => {
        if(value){
            onChange(value[showTitle])
            // setAbleToSetDefaultValue(false)
        }
    }

    const handleDefaultValue = () => {
        if(ableToSetDefaultValue) {
            const findItem = items?.find((c:any)=>{
                return (c[showTitle] === defaultValue) || null
            })
            if(findItem){
                setValue(findItem)
            }else{
                setValue({
                    [showTitle]:`${defaultValue || ''}${extendedValue ? ' - ' + extendedValue : ''}` 
                })
            }
        }
    }
    
    const onBlur = () => {
        if(extendInput){
            if(!!value && !!extendedValue){
                setValue({
                    ...value,
                    [showTitle]:`${value[showTitle]}${extendedValue ? ' - ' + extendedValue : ''}` 
                })
            }
            else if (!!!value && !!extendedValue){
                setValue({
                    ...value,
                    [showTitle]:extendedValue
                })
            }
        }

        if (autoSelect) {
            if (!!value) {
                setValue({
                    ...value,
                    [showTitle]: `${value[showTitle]}${inputValue ? inputValue : ''}`
                })
            } else if (!!!value && !!inputValue){
                setValue({
                    ...value,
                    [showTitle]: inputValue
                })
            }
        }
    }
    
    useEffect(()=>{
        handleChange()
        if(extendedValue){
            setExtendedValue(null)
        }
        if (inputValue){
            setInputValue(null)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[value])

    useEffect(()=>{
        if(defaultValue === '' ||
        defaultValue === null ||
        /*!defaultValue ( this line broke default value identification ) || */ 
        defaultValue === 0  || 
        defaultValue === undefined){
            handleReset()
        }else{
            handleDefaultValue()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[defaultValue])

    return (
        <FlexContainer style={{width:"100%"}} >
            {previus && previus}
            <Autocomplete
                //tryal prop
                filterOptions={filterOptions}
                //tested props
                openOnFocus={openOnFocus}
                {...autocomplete}
                onChange={(e,value)=>handleAutocompleteChange(value)}
                getOptionLabel={option=>option[showTitle] || ""}
                onBlur={onBlur}
                renderOption={renderOption ? renderOption : (option)=>icon ? <CustomIcon icon={icon} style={{color:iconColor || option[showTitle]}} /> : option[showTitle]}
                {...extendInput 
                    ? {onInputChange:handleInputChange} 
                    : null }
                
                disabled={disabled}
                freeSolo={freeSolo}
                autoSelect={autoSelect}
                {...autoSelect 
                    ? {onInputChange:handleCustomInputChange} 
                    : null }
            />
            {children && children}
        </FlexContainer>
    );
}

export default React.memo(SimpleAutocompleteSelect)