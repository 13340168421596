import { translateValue } from "../../../components/filters/DynamicFilter/services/useDynamicFilter"
import { apiCall, mutateApiCall, } from "../../../shared/axios/axios-config"
import { PeriodInterface } from "../../../shared/date/usePeriod"
import { getViewDateDay } from "../../../shared/date/utils"
import { PrivateQueryData, PrivateQueryDataTagFPInterface, PrivateQueryTagFPInterface } from "../../../shared/globals/utilsGlobalTypes"
import { useStatusProcessor } from "../../../shared/queries/StatusProcessor"
import { queryClient, useMutation, useQuery } from "../../../shared/react-query/react-query-conf"
import { updateDependencies } from "../../../shared/react-query/utils/updateDependencies"
import { useSessionContext } from "../../session/store/sessionContext"
import { useToken, useUser } from "../../session/store/sessionStore"
import { SurveyInterface, UpdateSurveyInterface } from "../model/SurveyInterface"
import { SurveyTasksValues } from "../tasks/model/TaskInterface"

export const SurveysByTagFP = ({tagFP,token}:PrivateQueryTagFPInterface) => {
    return apiCall({
        method: "get",
        url:`/SurveysByTagFP`,
        headers:{
            Authorization:`Bearer ${token}`,
            tagFP
        }
    })
}
export const SurveysByEquipment = ({data,token}:PrivateQueryData) => {
    return apiCall({
        method: "post",
        url:`/SurveysByLubricationPoint`,
        data,
        headers:{
            Authorization:`Bearer ${token}`,
        }
    })
}

export const SurveysByPeriodAndTagFP = ({data,token}:PrivateQueryData) => {
    return apiCall({
        method: "post",
        //url:`${URL}/SurveyLubricationPointByPeriodAndTagFP`,
        url:`/SurveyLubricationPointByPeriodAndTagFP2`,
        data,
        headers:{
            Authorization:`Bearer ${token}`,
        }
    })
}

export const LubricationPointsAndLastsSurveys = ({data,tagFP,token}:PrivateQueryDataTagFPInterface) => {
    return apiCall({
        method: "post",
        url:`/LubricationPointsAndLastsSurveys`,
        data,
        headers:{
            Authorization: `Bearer ${token}`,
            tagFP
        }
    })
}

export const CreateSurvey = ({data,token}:PrivateQueryData) => {
    return apiCall({
        method: "post",
        url:`/CreateSurvey`,
        data,
        headers:{
            Authorization:`Bearer ${token}`
        }
    })
}


/* export const useLubricationPointsLastSurveys = () => {

    const {token,tagFP} = useSessionContext()
    const {data:DailyOperatorLubricationPoints} = useDailyLubricationPointsWithInfo()

    return useQuery({
        queryKey:'LubricationPointsAndLastsSurveys',
        queryFn:()=>LubricationPointsAndLastsSurveys({data:DailyOperatorLubricationPoints,tagFP,token}),
        enabled:!!DailyOperatorLubricationPoints && !!tagFP,
    })

}
 */
export const useCreateSurvey = () => {

    const token = useToken()
    const {data:user} = useUser()
    const query = useMutation(CreateSurvey,{
        onSuccess:()=>{
            //queryClient.invalidateQueries('LubricationPointsWithInfo')
        },
    })
    
    const  createSurvey = (data: SurveyInterface) => {
        return query.mutateAsync({
            data:{
                ...data,
                user:user?.email,
                lubricantType: data.taskType === SurveyTasksValues.lubrication ? data.lubricantType : undefined,
                lubricant: data.taskType === SurveyTasksValues.lubrication ? data.lubricant : undefined,
                quantity: data.taskType === SurveyTasksValues.lubrication ? data.quantity : undefined,
                reason: data.taskType === SurveyTasksValues.lubrication ? data.reason : undefined
            },
            token
        })
    }

    const status = useStatusProcessor(query)

    return {
        createSurvey,
        query:{
            ...query,
            ...status
        }
    }
} 


export const useSurveysByPeriodAndTagFP = (period:PeriodInterface | undefined) => {

    const {token,tagFP} = useSessionContext()

    return useQuery<SurveyInterface[]>({
        queryKey:['SurveysByPeriodAndTagFP',period],
        queryFn:()=>SurveysByPeriodAndTagFP({data:{...period,tagFP},token}),
        enabled:!!tagFP && !!token && !!period?.firstDate && !!period.secondDate,
        select:(data)=>{
            return data?.map((i)=>({
                    ...i,
                    routeDate:getViewDateDay(i.routeDate),
                    modifiedDate:getViewDateDay(i.modifiedDate),
                }))
        },
        staleTime:20000,
    })
 
}

export const useSurveysByTagFP = () => {
    const {token,tagFP} = useSessionContext()
    return useQuery<SurveyInterface[]>({
        queryKey:'SurveysByTagFP',
        queryFn:()=>SurveysByTagFP({token,tagFP}),
        enabled:!!token && !!tagFP,
        staleTime:600000,
    })
}

export const useSurveysByLubricationPoint = (tagTGD?:string) =>{
    const {token} = useSessionContext()
    return useQuery<SurveyInterface[]>({
        queryKey:['SurveysByLubricationPoint', tagTGD],
        queryFn:()=>SurveysByEquipment({token,data:{tagTGD}}),
        enabled:!!token && !!tagTGD,
        staleTime:0,
        /*
        select:(data)=>{
            return data?.map((survey)=>({
                ...survey,
                loadingDate:getViewDateDayHour(survey.loadingDate)
            }))
        }
        */
    })
}

export const UpdateSurvey = ({data, token}: PrivateQueryData<UpdateSurveyInterface>) => {
    return mutateApiCall({
        method: "POST",
        url: "/UpdateSurveys",
        headers: {
            Authorization: `Bearer ${token}`
        },
        data
    })
}

export const useUpdateSurvey = () => {
    const token = useToken()

    const updateSurveyQuery = useMutation(UpdateSurvey, {
        onSuccess: () => updateDependencies([
            "LubricationPointsWithInfo", 
            "PaginatedSurveyLubricationPointByPeriod",
            "SurveysByLubricationPoint",
            "PaginatedLubricationPointsAndInfo"
        ])
    })

    const status = useStatusProcessor(updateSurveyQuery)

    const updateSurvey = (data: UpdateSurveyInterface) => {
        return updateSurveyQuery.mutate({
            // Formatting dates to convert them to UTC
            data: {
                ...data,
                startTime: new Date(data?.startTime).toISOString(),
                endTime: new Date(data?.endTime).toISOString(),
                needsLubrication: translateValue(data?.needsLubrication),
                lubricates: translateValue(data?.lubricates),
                impossibleToLubricate: translateValue(data?.impossibleToLubricate),
                taskDone: translateValue(data?.taskDone)
            },
            token
        })
    }

    return {
        updateSurvey,
        ...updateSurveyQuery,
        ...status,
        resetStatus: status.reset
    }
}
