import styled from "@emotion/styled"
import React from "react"
import theme from "../../../assets/theme"
import { scrollbarWidth } from "../utils/scrollbarWidth"

export const CELL_HEIGHT = 35
export const SCROLLBAR_SIZE = scrollbarWidth()
export const CELL_WIDTH = 150
export const ROWS_CUANTITY = 16

const HOVER_COLOR = theme.palette.background.default
const TABLE_LINES_COLOR = '#E6E6E6'
const SELECTED_ROWS_COLOR = '#E8E8E8'

export const TableContainer = React.memo(styled('div')`
  .table {
    display: inline-block;
    border-spacing: 0;
    border: 1px solid  ${TABLE_LINES_COLOR};
    border-radious:20px;
    width: ${props=> props.width || 'calc(100vw - 230px - 48px - 24px - 48px - 8px)' }; 
    height:${''/* props => (props.height || CELL_HEIGHT) * ROWS_CUANTITY */} min-content;
    /* .header {
      cursor:none;
      background:${HOVER_COLOR};
    } */
    overflow-x:scroll;
    .tr, .tr-selected {
        cursor:pointer;
        background:white;
        :hover{
            background:${HOVER_COLOR};
        } 
    }
    .tr-selected{
      background:${SELECTED_ROWS_COLOR};
    }
    .th{
      cursor:default;
    }
    .th,
    .td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid ${TABLE_LINES_COLOR};
      border-right: 1px solid  ${TABLE_LINES_COLOR};
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      :last-child {
        border-right: 1px solid  ${TABLE_LINES_COLOR};
      }
    }
    .th{
      font-weight: bolder;
    }
    .sort {
      cursor:pointer;
    }
    .th {
      background:${HOVER_COLOR};
    }

    .resizer {
        right: 0;
        /* background: blue; */
        width: 10px;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 1;
        ${'' /* prevents from scrolling while dragging on touch devices */}
        touch-action :none;

        &.isResizing {
          /* background: red; */
        }
      }
  }
`)
