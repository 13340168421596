import React, { useMemo } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';

import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';



const Transition = React.forwardRef(function Transition(
	props: TransitionProps & { children?: React.ReactElement },
	ref: React.Ref<unknown>,
	) {
		return <Slide direction="up" ref={ref} {...props} />;
	}
);



interface Props{
	modal:boolean
	children?:React.ReactNode
}

const FullScreenSurface:React.FC<Props> = ({modal,children}) => {

	const props = useMemo(()=>({
		open:modal,
		transitionComponent:Transition,
		style:{zIndex:1000}
	}),[modal])

	return (
		<>
		<Dialog fullScreen {...props} >
			{children && children}
		</Dialog>
		</>
	);
}


export default React.memo(FullScreenSurface)