import React, { useState } from 'react'
import CustomButton, { CustomIcon } from '../../components/buttons/CustomButton'
import TextField from '../../components/fields/TextField/TextField'
import Form from '../../components/form/Form'
import { htmlAdmin, htmlClient } from '../../domains/emails/emailTemplates/htmlClientDemo'
import { demoFactory } from '../../domains/demo/demoFactory'
import { DemoInterface } from '../../domains/demo/demoInterface'
import { useLangLabels } from '../../shared/lang/services/useLangLabels'
import { phone } from '../../shared/regExp/regExp'
import { getDeviceType, getUserAgentData } from '../../shared/user-agent/service.DetectUserDevice'
import LoginFormContainer from './components/LoginFormContainer'
import FlexContainer from '../../components/containers/FlexContainer'
import { Alert } from '@material-ui/lab'
import { Chip } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { USER_ROUTES } from '../../shared/routes/routes'
import { useCreateDemoRequests } from '../../domains/demo/services/demoServices'


const  DemoPage:React.FC<{}> =() => {
  
  const {lang} = useLangLabels()
  const info = getUserAgentData()
  const deviceType = getDeviceType()

  const {mutate,data,reset} = useCreateDemoRequests()
  const [form,setForm] = useState<DemoInterface>(demoFactory())
  
  const handleInput = (e:any) =>{
    setForm({
      ...form,
      [e.target.name]:e.target.value
    })
  }

  function handleSubmit() {
    mutate(demoFactory({
      applicantsEmail:form.applicantsEmail,
      applicantsName:form.applicantsName,
      applicantsTelephone:form.applicantsTelephone,
      applicantsCompany:form.applicantsCompany,
      htmlClient:htmlClient(form) || '',
      htmlAdmin:htmlAdmin(form) || '',
      latitude:`${ sessionStorage.getItem('latitud') ? sessionStorage.getItem('latitud') : 'No se aprobó' }`,
      longitude:`${ sessionStorage.getItem('longitud') ? sessionStorage.getItem('longitud') : 'No se aprobó' }`,
      operativeSystem:info ? info.OSName : 'no se ha detectado un sistema operativo',
      device:deviceType,
      browser:info ? info.browserName : 'no se ha detectado un navegador'
    }))
}

const showPosition:PositionCallback = (position) => {
    let data:any = {
        latitud : position.coords.latitude, 
        longitud : position.coords.longitude
    }
    sessionStorage.setItem('latitud',data.latitud)
    sessionStorage.setItem('longitud',data.longitud)
    handleSubmit()
}

const sendWithLocation = async() => {
    navigator.geolocation.getCurrentPosition(showPosition,()=>handleSubmit())
}

  return (
    <LoginFormContainer >
      {data.status === 'idle'&&
        <Form onSubmit={sendWithLocation}>

        <TextField 
          name='applicantsName'
          type='text'
          placeholder='nombre'
          minLength={8}
          onChange={handleInput}
          required
        />

        <TextField
          name='applicantsTelephone'
          type='tel'
          match={phone}
          minLength={10}
          placeholder='Teléfono'
          onChange={handleInput}
          required
        />

        <TextField
          name='applicantsCompany'
          type='text'
          placeholder='Companía'
          minLength={5}
          onChange={handleInput}
          required
        />

        <TextField
          name='applicantsEmail'
          type='email'
          placeholder='Email'
          onChange={handleInput}
          required
        />

        <CustomButton color='primary' status={data.status} type='submit' icon='send'>


        </CustomButton>
      </Form>
      }

      <br />
      <FlexContainer align={'center'} justify='center'>
        <Chip
          onDelete={()=>(true)}
          deleteIcon={<CustomIcon icon='before'/>}
          size="small"
          label={lang.actionTypes.login}
          clickable
          color="secondary"
          component={Link}
          to={`/${USER_ROUTES.login}`}
        />
      </FlexContainer>

      <FlexContainer padding='8px 16px'  /* width={'calc(100% - 32px)'} */>
          {(data.status === 'error' || data.status === 'success') ? 
              <Alert style={{width:'100%'}} severity={data.status} onClose={reset}>
                  {data.message}
              </Alert> 
          : null}
      </FlexContainer>
    </LoginFormContainer>
  )
}

export default DemoPage