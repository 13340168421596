import React, { useState,useMemo, useEffect } from 'react';
import {  useStockAreaCategorySubcategory } from '../services/service.stock';
import CreateStockForm from './CreateStockForm';
import UpdateStockForm from './UpdateStockForm';
import NewStockQuantityForm from './NewStockQuantityForm';

import HiddenStockForm from './HiddenStockForm';
import CustomButton from '../../../components/buttons/CustomButton';
import { useStockGridModel } from '../lang/useStockGridModel';
import { SubCategoryInterface } from '../sub-categories/subCategoryInterface';
import VirtualGrid from '../../../shared/react-table/VirtualGrid';
import { capitalize } from '../../../shared/globals/utils/capitalize';
import UpdateSubCategoryForm from '../sub-categories/components/UpdateSubCategoryForm';
import CreatePurchaseRequestForm from '../purchase-requests/components/CreatePurchaseRequestForm';

interface Props{
    subcategory:SubCategoryInterface
}
const SubcategoryStockGrid:React.FC<Props> = ({subcategory}) => {

    const [tab,setTab] = useState(false)
    
    const {data:categoryStocks} = useStockAreaCategorySubcategory(
        useMemo(()=>({
            categoryArea:subcategory.categoryArea,
            category:subcategory.category,
            subcategory:subcategory.subcategory,
            tagFP:subcategory.tagFP
        }),[subcategory])
    )
    
    const subCategoryStocksNotHidden = useMemo(()=>categoryStocks?.filter((item)=>item.hidden === false) || [],[categoryStocks])
    const subCategoryStocksHidden = useMemo(()=>categoryStocks?.filter((item)=>item.hidden === true) || [],[categoryStocks])
    

    const ShowArchived = <CustomButton 
        popoverTitle={tab ? 'Ver items' :'Ver archivados' }
        icon={tab ? 'before' : 'delete'}
        action={()=>setTab(!tab)} 
        />

    const data = useMemo(()=>
        tab ? subCategoryStocksHidden : subCategoryStocksNotHidden
    ,[subCategoryStocksHidden, subCategoryStocksNotHidden, tab])

    const gridModel = useStockGridModel()

    return (
        <>
        <VirtualGrid
        width='100%'
        gridModel={gridModel}
        items={data}
        headerActions={()=><>
            <UpdateSubCategoryForm defaultValue={subcategory} mode='modal'/>
            <CreateStockForm mode='modal' subcategory={subcategory}/>
            {ShowArchived}
        </>}
        bulkActions={(items)=><UpdateStockForm items={items}/>}
        renderOption={(item)=><>
            <NewStockQuantityForm key={item.id} item={item} />
            <CreatePurchaseRequestForm mode='popover' variant='iconButton' stock={item}/>
            <HiddenStockForm item={item} />
        </>}
        title={ tab ? 'Archivado' :`${capitalize(subcategory.subcategory)} en stock`}
    />
    
    </>
    
        /* <DynamicGrid
            gridModel={useStockGridModel()}
            height='50vh'
            items={tab ? subCategoryStocksHidden : subCategoryStocksNotHidden}
            loading={categoryStocks ? false : true}
            title={ tab ? 'Archivado' :`${capitalize(subcategory.subcategory)} en stock`}
            headerActions={()=><>
                <UpdateSubCategoryForm defaultValue={subcategory} mode='modal'/>
                <CreateStockForm mode='modal' subcategory={subcategory}/>
                {ShowArchived}
            </>}
            itemActions={(item:StockInterface)=><>
                <UpdateStockForm defaultValue={item}/> 
                <NewStockQuantityForm key={item.id} item={item} />
                <CreatePurchaseRequestForm mode='popover' variant='iconButton' stock={item}/>
                <HiddenStockForm item={item} />
            </>}
        /> */

    );
}

export default React.memo(SubcategoryStockGrid);
