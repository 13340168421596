export const getLocalDateFromUTC = (dateString: string) => {
    const date = new Date(dateString);
    const millisecondOffset = date.getTimezoneOffset() * 60 * 1000;
    const adjustedTime = date.getTime() - millisecondOffset;
    const localDate = new Date(adjustedTime).toISOString();

    return localDate;
};

export const getViewDateDay = (date:string | undefined)=>{
    //Date UTC 
    if(date === undefined) return 'date is undefined'
    return date.split('').splice(0,10).join('')
}

export const getViewDateHour = (date:string | undefined)=>{
    //Date UTC
    if(date === undefined) return 'date is undefined'
    return date.split('').splice(11,5).join('')
}

export const getViewDateDayHour = (date:string | undefined) =>{
    //Date UTC
    if(date === undefined) return 'date is undefined'
    const dateDay = getViewDateDay(date)
    const dateHour = getViewDateHour(date)
    return `${dateDay} ${dateHour}`
}

export const getIsWeekend = (date:string)=>{
    const day = new Date(date).getDay()
    if (day === 5) return true
    if (day === 6) return true
}

export const calcDate = (date:any) => {
    let date2 = new Date(date);
    let date1 = new Date()
    let substract = date2.getTime() - date1.getTime()
    if((Math.round(substract/ (1000*60*60*24))) === -1) return 0
    return(Math.round(substract/ (1000*60*60*24)))
}

export const javaDateParse = (javaDate:string) => {
    const result = javaDate.split(' ')
    const jsDate = `${result[0]} ${result[1]} ${result[2]} ${result[5]} ${result[3]} GMT+0000 (GMT)`
    return new Date(jsDate).toISOString()
}


