import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import LoginPage from './pages/login/LoginPage';
import { useSessionContext } from './domains/session/store/sessionContext';
import DemoPage from './pages/login/DemoPage';
import ConfigPage from './pages/backoffice/ConfigPage';
import { USER_ROUTES } from './shared/routes/routes';
import NewsPage from './pages/backoffice/NewsPage';
import StockPage from './pages/backoffice/stock/StockPage';
import { useTGDsignature } from './shared/config/utils/useTGDsignature';
import LoadingNecesaryData from './pages/backoffice/LoadingNecesaryData';
import UserPanel from './pages/backoffice/UserPanel';
import PlantSelector from './pages/backoffice/ClientVisor/PlantSelector';
import Reports from './domains/powerBi/components/Reports';
import ClientHome from './pages/backoffice/ClientVisor/home/ClientHome';
import DemoUsersPage from './pages/backoffice/DemoUsersPage';
import ViewPlantAsClientPage from './pages/backoffice/ViewAsClient/ViewPlantAsClientPage';
import PlantManagement from './pages/backoffice/PlantManagement';
import LubricatorHomePage from './pages/backoffice/lubricator/LubricatorHomePage';
import DevTools from './domains/devtools/pages/DevToolsPage';
import DiscoverPage from './pages/backoffice/DiscoverPage';
import HomePage from './pages/backoffice/super-admin/HomePage';
import AddUser from './pages/backoffice/AddUser';
import SupervisorHomePage from './pages/backoffice/supervisor/home/SupervisorHomePage';
import { SESSION_DURATION } from './domains/session/application/sessionUseCases';
import FactoryList from './domains/factories/FactoryList';
import { ExplorePage } from './pages/explore/ExplorePage';
import LubricationSectionPage from './pages/backoffice/LubricationSection/LubricationSectionPage';


const PrivateRoutes:React.FC<{}> = () => {

	const {token,setLastInteraction} = useSessionContext() 

	  React.useEffect(() => {
		window.addEventListener('focus', ()=>{
			setLastInteraction()
		});

		const interval = setInterval(()=>{
			setLastInteraction()
		},SESSION_DURATION + 1000)
	
		return () => {
			clearInterval(interval)
			window.removeEventListener('focus', ()=>{
				setLastInteraction()
			});
		};
	  }, []);

	return (
		<div onClick={setLastInteraction}>
			{!!token && token !== '' 
				? <Outlet/>
				: <Navigate to='/login' />
			}
		</div>
	)
}

const PublicRoutes:React.FC<{}> = () => {
  return (
    <Outlet/>
  )
}

const App:React.FC<{}> = () => {

	useTGDsignature()
	const {person,currentPbigroupName,user,isMultiplePlantUser} = useSessionContext()

/* 	useProcessedData()
 */
  return (
	<Routes>
		{/* PUBLIC */}
		<Route path={''} element={<PublicRoutes/>}>
			<Route path={''} element={<Navigate to={`/${USER_ROUTES.login}`}/>} />
			<Route path={USER_ROUTES.login} >
				<Route path={''} element={<LoginPage/>}/>
				<Route path={USER_ROUTES.demoRequest} element={<DemoPage/>}/>
				<Route path={'*'} element={<Navigate to={`/${USER_ROUTES.login}`}/>} />
			</Route>
			<Route path={'*'} element={<Navigate to={`/${USER_ROUTES.login}`}/>} />
		</Route>

		{/* PRIVATE */}
		<Route path={USER_ROUTES.home} element={<PrivateRoutes/>}>
			
			<Route path={''} element={<LoadingNecesaryData/>}/>

			{/* SUPERVISOR */}
			<Route path={`${USER_ROUTES.supervisor}`} element={<UserPanel/>}>
				<Route path={''} element={<SupervisorHomePage/>}/>
				<Route path={USER_ROUTES.news} element={<NewsPage/>} />
				<Route path={`${USER_ROUTES.lubricationSection.index}/*`} element={<LubricationSectionPage />}/>
				<Route path={`${USER_ROUTES.stock}/*`} element={<StockPage/>} /> 
				<Route path={`${USER_ROUTES.plantManagement.index}/*`} element={<PlantManagement />}/>
				<Route path={`${USER_ROUTES.clientVisor}/*`} element={<ViewPlantAsClientPage/>}/>
				<Route path={`${USER_ROUTES.explore}/*`} element={<ExplorePage/>}/>
				<Route path={USER_ROUTES.config} element={<ConfigPage/>}/>
				<Route path={'*'} element={<SupervisorHomePage/>}/>
			</Route>

			{/* OPERATOR */}
			<Route path={`${USER_ROUTES.operator}`} element={<UserPanel/>}>
				<Route path={''} element={<LubricatorHomePage/>}/>
				<Route path={`${USER_ROUTES.explore}/*`} element={<ExplorePage/>}/>
				<Route path={USER_ROUTES.news} element={<NewsPage/>} />
				<Route path={USER_ROUTES.config} element={<ConfigPage/>}/>
				<Route path={'*'} element={<LubricatorHomePage/>}/>
			</Route>

			{/* CLIENT */}
			<Route path={USER_ROUTES.client}>

				<Route path={currentPbigroupName} element={isMultiplePlantUser ? <PlantSelector/> : <UserPanel/>}  >
					<Route path='' element={<ClientHome/>}/>
					<Route path='*' element={<Reports/>} />
					<Route path={USER_ROUTES.config} element={<ConfigPage/>}/>
					{user && (user?.email === 'd.lavallen@tgdsa.com') &&
						<Route path={`${USER_ROUTES.demoUsers}/*`} element={<DemoUsersPage/>} />
					}
				</Route>

				<Route path='' element={<Navigate to={currentPbigroupName}/>} />
				<Route path='*' element={<Navigate to={currentPbigroupName}/>} />
			</Route>


			{/* ADMIN CUENTAS */}
			<Route path={USER_ROUTES.accountManager} element={<UserPanel/>}>
				<Route path={''} element={<HomePage />}/>
				<Route path={`${USER_ROUTES.factories}`} element={<FactoryList/>} />
				<Route path={`${USER_ROUTES.addUser}/*`} element={<AddUser />} />
				<Route path={`${USER_ROUTES.discover}/*`} element={<DiscoverPage />} />
				<Route path={`${USER_ROUTES.clientVisor}/*`} element={<ViewPlantAsClientPage/>}/>
				<Route path={'*'} element={<HomePage />}/>
				<Route path={USER_ROUTES.config} element={<ConfigPage/>}/>
			</Route>

			{/* ADMIN */}
			<Route path={USER_ROUTES.administrator} element={<UserPanel/>}>
				<Route path={''} element={<HomePage />}/>
				<Route path={`${USER_ROUTES.factories}`} element={<FactoryList/>} />
				<Route path={`${USER_ROUTES.addUser}/*`} element={<AddUser />} />
				<Route path={`${USER_ROUTES.discover}/*`} element={<DiscoverPage />} />
				<Route path={`${USER_ROUTES.clientVisor}/*`} element={<ViewPlantAsClientPage/>}/>
				<Route path={'*'} element={<HomePage />}/>
				<Route path={USER_ROUTES.config} element={<ConfigPage/>}/>
			</Route>


			{/* SUPER ADMIN */}
			<Route path={USER_ROUTES.superAdmin} element={<UserPanel/>}>
				<Route path={''} element={<HomePage />}/>
				<Route path={`${USER_ROUTES.factories}`} element={<FactoryList/>} />
				<Route path={`${USER_ROUTES.addUser}/*`} element={<AddUser />} />
				<Route path={`${USER_ROUTES.discover}/*`} element={<DiscoverPage />} />
				<Route path={USER_ROUTES.devTools} element={<DevTools/>} />
				<Route path={`${USER_ROUTES.clientVisor}/*`} element={<ViewPlantAsClientPage/>}/>
				<Route path={'*'} element={<HomePage />}/>
				<Route path={USER_ROUTES.config} element={<ConfigPage/>}/>
			</Route>
			
			{person 
				? <Route path={'*'} element={<Navigate to={`/${USER_ROUTES.home}/${person?.role.replace(/ /g,'-')}`}/>} />
				: <Route path={'*'} element={<Navigate to={`/${USER_ROUTES.login}`}/>} />
			}
			
		</Route>
	</Routes>
			
  );
}

export default App;
