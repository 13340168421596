import React from 'react';
import CustomButton from '../../../components/buttons/CustomButton';
import { useModal } from '../../../components/containers/modal/hooks/useModal';
import TgdModal from '../../../components/containers/modal/TgdModal';
import { useCarrousell } from '../../../components/containers/toolbar/hooks/useCarrousell';
import SidebarSimpleMenuButton from '../../../layout/components/SidebarSimpleMenuButton';
import { useLocalStorage } from '../../../shared/localStorage/useLocalStorage';
import VirtualGrid from '../../../shared/react-table/VirtualGrid';
import { useLubricationPointsGridModel } from '../../lubricationPoints/lang/useLubricationPointsGridModel';
import { LubricationPointInterface } from '../../lubricationPoints/model/lubricationPointsInterface';
import { useEquipmentswithCompleteInfo } from '../../lubricationPoints/services/lubricationPointsAdapters';
import { surveyTasks } from '../services/controllers/surveyController';
import SurveyModal from '../SurveyModal';
import CreateSurveyForm from './CreateSurveyForm';



type Props =  {
    items: LubricationPointInterface[] /* LubricationPointInterface */
    outOfRoute:boolean
    mode?:'content'
    exitButton?:React.ReactNode 
}


const LubricationPointSurvey:React.FC<Props> = ({items, exitButton, outOfRoute, mode}) => {

    const {current,handleNext,handlePrev,canNext,canPrev} = useCarrousell(items?.length || 0)
    const lubricationPoint = items && items[current]

    const [modal,setModal] = useLocalStorage('surveyModal',false)

    const handleModal = () => {
        setModal(!modal)
    }

    const _outOfRoute = lubricationPoint?.info.pending ? outOfRoute : true

    const popoverTitle = `Relevar ${outOfRoute ? 'fuera de ruta' : 'en ruta'}`
    const button =  <CustomButton action={handleModal} color='secondary' popoverTitle={popoverTitle} icon='surveyPlay' variant='iconButton' />

    const surveyModal = 
    <SurveyModal
        open={!!modal}
        handleClose={handleModal}
        canNext={canNext}
        canPrev={canPrev}
        handleNext={handleNext}
        handlePrev={handlePrev}
        currentLubricationPoint={lubricationPoint}
        tasks={surveyTasks}
        outOfRoute={_outOfRoute}
        exitButton={exitButton && exitButton}
        > 
            <CreateSurveyForm
                item={lubricationPoint!} 
                tasks={surveyTasks} 
                outOfRoute={_outOfRoute}
                onFinish={()=>{}} 
            />
    </SurveyModal>

    return (<>
        { !mode  && button }
        {surveyModal}
      
</>);
}

export const LubricationPointOutOfRouteSurvey:React.FC<{}> = () =>{

    const modalProps = useModal(false)
    const LubricationPoints = useEquipmentswithCompleteInfo((data)=>data.filter(item=>item.state === true) as any)
    const gridModel = useLubricationPointsGridModel()

    return(
        <>
            <SidebarSimpleMenuButton 
                onClick={modalProps.onOpen} 
                ownerService={'TGD'} 
                allowedUserTypes={['O']}
                title='fuera de ruta' 
                icon='outOfRouteWhite'
            />
        
            <TgdModal 
                open={modalProps.modal}
                handleClose={modalProps.onClose}
            >
                <VirtualGrid {...{
                    height:13,
                    documentExport:false,
                    documentSendEmail:false,
                    title:'Todos los puntos activos',
                    items:LubricationPoints,
                    gridModel,
                    bulkActions:(items:LubricationPointInterface[]) => 
                        <LubricationPointSurvey {...{outOfRoute:true,items}} /> ,
                }}/>
            </TgdModal >
        </>
    )
} 

export default React.memo(LubricationPointSurvey);
