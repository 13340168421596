import React, { useState } from "react"
import { CELL_HEIGHT } from "../components/TableContainer"

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref)  => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef 

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} style={{
          margin:'auto',
          width:`${CELL_HEIGHT/2}px`,
          height:`${CELL_HEIGHT/2}px`,
          cursor:'pointer',
        }} />
      </>
    )
  }
)

export const useCheckboxColumn = (hooks) => {

    hooks.visibleColumns.push(columns => [
      // Let's make a column for selection
      {
        id: 'selection',
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div>
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          </div>
        ),
        
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: ({row}) => (
          <div>
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
          </div>
        ),
        width:CELL_HEIGHT,
      },
      ...columns,
    ])
  }