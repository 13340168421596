import styled from "@emotion/styled"
import { Hidden } from "@material-ui/core"
import theme from "../assets/theme"
import { SIDEBAR_WIDTH } from "./LayoutConstants"
import NavBar from "./components/NavBar"
import Sidebar from "./components/Sidebar"
import { useLayoutStore } from "./store/layoutStore"
import PageContainer from "../components/containers/PageContainer"
import React from "react"
import { useMenu } from "./menu/useMenu"


const LayoutWrapper = styled('div')`
    display: flex;
    height: 100vh;
`

const HeaderSpacer = styled('div')`
    display: block;
    width:100%;
    max-height:65px;
    min-height:65px;
` 

const MainContent = styled('main')`
    display:flex;
    width:calc( 100vw - ${SIDEBAR_WIDTH}px );
    height:100vh;
    flex-grow:1;
    flex-direction:column;
    padding:0px;
    background-color:${theme.palette?.background?.default};
    min-height: 100vh;
    z-index:1;
    overflow-y:scroll;
`

const Layout:React.FC<{}> = ({children}) => {

    const {layout,handleModal} = useLayoutStore()
    const menu = useMenu()
    return (
        <LayoutWrapper >
            
            <Hidden smDown>
                <NavBar
                    open={!!layout?.open}
                    action={handleModal}
                />
                <Sidebar
                menu={menu}
                    variant="permanent"
                    open={!!layout?.open}
                    anchor='left'
                />
            </Hidden>

            <Hidden mdUp>
                <NavBar 
                    device='mobile'
                    open={!!layout?.open}
                    action={handleModal}
                />
                <Sidebar
                menu={menu}
                    variant="temporary"
                    open={!!layout?.open}
                    onClose={handleModal}
                    anchor='left'
                />
            </Hidden>

            {/* CONTENIDO */}
            <MainContent>
                    <HeaderSpacer/>
                    <PageContainer>
                        {children}
                    </PageContainer>
            </MainContent>
            
        </LayoutWrapper>
            
        )
    }

export default Layout
