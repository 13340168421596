import { Badge, Chip, Divider, Tab, Tabs } from '@material-ui/core'
import { normalize } from 'path';
import React, { useMemo } from 'react'
import { useLocation, NavLink, Route, Navigate, Routes, useNavigate } from 'react-router-dom';
import { CustomIcon, CustomIconType } from '../../buttons/CustomButton';
import FlexContainer from '../FlexContainer';
import PageContainer from '../PageContainer';


const a11yProps = (index: any) => {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export type TabsInterface<T=any> = {
    label: string;
    route:string;
    notification?:number
    icon?:CustomIconType 
    component: React.ReactNode;
    data?:T
}

interface RouterTabOptions {
    orientation?: 'vertical' | 'horizontal' | undefined
    variant?:'chip' | undefined
    onClick?:(data:any)=>void
}

interface RouterTabProps {
    value:number,
    tabs:TabsInterface[],
    orientation?: 'vertical' | 'horizontal' | undefined
    routes:string[],
    variant?: 'chip',
    onClick?:(data:any)=>void
    style?:{}
}

export const useRouteTabProps  = <T,>(currentLevel:number,tabsConfig:TabsInterface<T>[],options?:RouterTabOptions):RouterTabProps  => {

    const path = useLocation().pathname.split('/')[currentLevel]?.toLowerCase().split('/')[0] || ''
    const routes =  tabsConfig.map((p)=>
        p.route.normalize('NFD')
        .replace(/[\u0300-\u036f]/g,"")
        .replace(/ /g,'-')
        .toLowerCase()?.split('/')[0]
    )
   
    const value = routes.indexOf(path)

    return {
        value,
        tabs:tabsConfig,
        routes,
        ...options
    }

}



const RouterTabs:React.FC<RouterTabProps> = ({value,tabs,routes,variant,orientation,onClick,style}) => {
   
    const navigate = useNavigate()
    const isVertical = orientation === 'vertical'

    const handleClick = (data:{pathname:string,state:any}) => {
        onClick && onClick(data.state)
        navigate(data)
    }

    const chipVariant = 
    <FlexContainer padding={'24px 0px'} gap='16px' flexWrap="wrap" >
        {tabs.map(({label,icon,data},index)=>
            <Chip
                key={index}
                label={label}
                clickable
                icon={<CustomIcon icon={icon}/>} 
                variant={'outlined'} 
                color={value === index ? 'secondary' : 'default' }
                onClick={()=> handleClick({pathname:routes[index],state:data})}
            />
        )}
    </FlexContainer>

    const tabVariant = <>
        <Tabs
            style={{overflow:'unset'}}
            orientation={orientation}
            value={value}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            {...(!isVertical ? { scrollButtons:"auto" } : {})}
            >

            {tabs.map((item,index:number) => 
                <Tab  
                    onClick={()=>handleClick({pathname:routes[index],state:item.data})} 
                    key={index} 
                    label={item.notification ? `${item.notification}/${item?.label}` : item.label } 
                    {...a11yProps(index)} 
                />
            )}
        </Tabs>
        <Divider style={{height:'auto'}} orientation={orientation} />
    </>
    const content = <>
        {variant === 'chip' && chipVariant}
        {!variant && tabVariant}
        
        <PageContainer width='100%' padding='0' style={style}>
            <Routes>
                {tabs.map((tab,index:number) =>
                    <Route path={`${routes[index]}/*`} key={index} element={tab.component}/>
                )}
                <Route path='*' element={<Navigate to={routes[0]}/>}/>
            </Routes>
        </PageContainer>
    </>

return (

        isVertical
        ? <FlexContainer gap='0'>
            {content}
        </FlexContainer>
        :content
    )
}


export default RouterTabs
 