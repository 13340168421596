import { FormDataResult, FormFields,  } from "../models/FormTypes"

export const parseFormFieldsToData =<T,> (formFields:FormFields<T>):FormDataResult<T> => {
    let result = {} as FormDataResult<T>
 
    for(let f in formFields){
        result = {
            ...result,
            [f]:formFields[f].value
        }
    }
 
    return result
 }

export const validate = <T,>(fields:FormFields<T>) => {

    let errors = {}

    for(const f in fields){
        const field = fields[f]
        const value = field.value
        const requiredMessage = field.validations?.required?.message || ''
        const patternMessage = field.validations?.pattern?.message || ''
        //initValues
        let result = fields

        //Required validation
        if(field.validations?.required?.value ){
            if(!!!value || value === [] || value === {} || value === '' ){
                //updating keyField  
                result = {
                    ...result,
                    [f]:{
                        ...result[f],
                        error:requiredMessage
                    }
                }
                
                //Updating global errors object
                errors = {
                    ...errors,
                    [f]:requiredMessage
                }
            }
        }

        //pattern validation
        if(field.validations?.pattern && typeof value === 'string'){
            if(!value.match(field.validations.pattern.value)){
                //updating keyField  
                result = {
                    ...result,
                    [f]:{
                        ...result[f],
                        error:patternMessage
                    }
                }
                
                //Updating global errors object
                errors = {
                    ...errors,
                    [f]:patternMessage
                }
            }

        }

        //max validation
        if(field.validations?.max){
            if((typeof value === 'string' && value.length > field.validations.max.value) 
            || (typeof value === 'number' && value > field.validations.max.value) ){
                //updating keyField  
                result = {
                    ...result,
                    [f]:{
                        ...result[f],
                        error:field.validations.max.message
                    }
                }
                
                //Updating global errors object
                errors = {
                    ...errors,
                    [f]:field.validations.max.message
                }
            }

        }

        //Min validations
        if(field.validations?.min){
            if((typeof value === 'string' && value.length < field.validations.min.value)
            || (typeof value === 'number' && value < field.validations.min.value) ){
                //updating keyField  
                result = {
                    ...result,
                    [f]:{
                        ...result[f],
                        error:field.validations.min.message
                    }
                }
                
                //Updating global errors object
                errors = {
                    ...errors,
                    [f]:field.validations.min.message
                }
            }

        }

        //CustomValidation
        if(field.validations?.validate){
            const validationValue = field.validations.validate(field.value)
            if(validationValue !== true){
                //updating keyField  
                result = {
                    ...result,
                    [f]:{
                        ...result[f],
                        error:validationValue
                    }
                }
                
                //Updating global errors object
                errors = {
                    ...errors,
                    [f]:validationValue
                }
            }
        } 
    }

    return errors
}