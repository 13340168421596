import { CircularProgress, ListItemText } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { QueryStatus } from 'react-query'
import theme from '../../../assets/theme'
import CustomButton, { ButtonColorType, CustomIconType } from '../../../components/buttons/CustomButton'
import FlexContainer from '../../../components/containers/FlexContainer'
import { useSnackbar } from '../../../components/snackbar/hooks/useSnackbar'
import { useClickPosition } from '../../../shared/globals/hooks/useClickPosition'
import { queryClient } from '../../../shared/react-query/react-query-conf'
import { UpdateLubricationPoint } from '../../lubricationPoints/services/lubricationPointsService'
import { useSessionContext } from '../../session/store/sessionContext'
import { InfoLubricationPointInterface } from '../model/InfoLubricationPointInterface'
import { UpdateReviewState } from '../service/infoLubricationPointsService'
import EquipmentInfo from './EquipmentInfo'

interface CustomStatusButtonProps {
    action?:()=>void
    status?:QueryStatus
    message?:string,
    error?:string,
    icon?:CustomIconType,
    popoverTitle?:string,
    color?:ButtonColorType
}
const CustomStatusButton:React.FC<CustomStatusButtonProps> = React.memo(({action,status,message,error,icon,popoverTitle,color}) => {

    const { position, getClickPosition } = useClickPosition()
    const { setData } = useSnackbar()

    useEffect(() => {
            if(status === 'success'){
                setData({
                    modal:true,
                    errors:message,
                    severity:status,
                    position
                })
            }
            if(status === 'error'){
                setData({
                    modal:true,
                    errors:message,
                    severity:'warning',
                    position
                })
            }

    }, [status]);

    const handleAction = (e:any)=>getClickPosition(e,() => {
        action && action()
    })

    const button = (() => {
        switch(status){
            case 'error':
                return <CustomButton variant='iconButton' icon='cancelOutlined' popoverTitle={message || error} styleProps={{color:theme.palette.error.main}}/>
            case 'success':
                return <CustomButton variant='iconButton' icon='checkedCircle' popoverTitle={message} styleProps={{color:theme.palette.success.main}} />
            case 'loading':
                return <CircularProgress size={'24px'} />
            default:
                return <CustomButton {...{
                    variant:'iconButton',
                    icon,
                    popoverTitle,
                    action:handleAction,
                    color
                }} />
        }
    })()

    return(<>
        {button}
    </>)
})

interface changeRevisionList {
    item:InfoLubricationPointInterface,
    mappedItems:any,
}

const ChangeViewRequestListItem:React.FC<changeRevisionList > = ({mappedItems,item}) => {

    const {token,tagFP} = useSessionContext()

    /* const updateReviewQuery = useUpdateEquipmentNextRevision() 
    const cancellQuery = useUpdateInfoEquipmentReview()

    const handleNextRevision = (item:InfoLubricationPointInterface) => {
        updateReviewQuery.updateEquipmentNextRevision(mappedItems[item.tagTGD])
    }
    const handleCancelRequest = (item:InfoLubricationPointInterface) => {
        cancellQuery.cancelReviewState(mappedItems[item.tagTGD])
    }

    useEffect(()=>{
        updateReviewQuery.reset()
        cancellQuery.reset()
    },[item]) */


    const [updateState, setUpdateState] = useState({
        status:'idle' as QueryStatus,
        message:''
    })

    const [cancellState, setCancellState] = useState({
        status:'idle' as QueryStatus,
        message:''
    })

    const handleClick = async (payload:{item:InfoLubricationPointInterface,type:'update' | 'cancel' })=>{

        const point = mappedItems[item.tagTGD]
        switch(payload.type){
            case 'cancel':
                setCancellState({
                    status:'loading',
                    message:''
                })

                const queryCancel = await UpdateReviewState({
                    token,
                    data:{
                        tagTGD:item.tagTGD,
                        tagFP:item.tagFP,
                        updateReview:false
                    }
                })

                if(queryCancel.error){
                    setCancellState({
                        status:'error',
                        message:queryCancel.error
                    })
                }else{
                    setCancellState({
                        status:'success',
                        message:queryCancel.success
                    })
                }

                queryClient.invalidateQueries('EquipmentsInfoByTagFP')
                
                break
            case 'update':
                setUpdateState({
                    status:'loading',
                    message:''
                })

                const queryUpdate = await UpdateLubricationPoint({
                    token,
                    data:{
                        tagFP:point.tagFP,
                        brand:point.brand,
                        model:point.model,
                        element:point.element,
                        component:point.component,
                        review:`${parseInt(point.review) + 1}`,
                        observations: point.observations,
                        lubricantType:point.lubricantType,
                        lubricant:point.lubricant,
                        capacity:point.capacity,
                        measureUnit:point.measureUnit,
                        oilAnalysis:point.oilAnalysis,
                        tagTGD:point.tagTGD
                    }
                })
                if(queryUpdate.error){
                    setUpdateState({
                        status:'error',
                        message:queryUpdate.error
                    })
                }else{
                    setUpdateState({
                        status:'success',
                        message:queryUpdate.success
                    })
                }

                queryClient.invalidateQueries('EquipmentsInfoByTagFP')
                queryClient.invalidateQueries('LubricationPointsAndInfoByTagFP')

                break
        }
    }

    return (
            <FlexContainer height='50px' align={'center'} justify='space-between' >

                <FlexContainer gap={'24px'} align='center'>
                    
                    <EquipmentInfo variant='modal' items={[mappedItems[item.tagTGD]]} />
                   
                    <ListItemText secondary='punto de lubricación'> 
                        {mappedItems[item.tagTGD].equipment} {'>> '}  
                        {mappedItems[item.tagTGD].element} {'>> '}
                        {mappedItems[item.tagTGD].component}   
                    </ListItemText>
                </FlexContainer>
                
                
                    <FlexContainer>

                        {cancellState.status === 'idle' && <CustomStatusButton 
                            {...{
                                status:updateState.status,
                                message:updateState.message
                            }} 
                            color='primary'
                            icon='nextState' 
                            action={()=>handleClick({item,type:'update'})} 
                            popoverTitle='Cambiar a la siguiente revision'
                        />}

                        {updateState.status === 'idle' && <CustomStatusButton 
                            {...{
                                status:cancellState.status,
                                message:cancellState.message
                            }} 
                            icon='close' 
                            action={()=>handleClick({item,type:'cancel'})} 
                            popoverTitle='rechazar solicitud'
                        />}

                    </FlexContainer>
                

            </FlexContainer>
        )
}

export default React.memo(ChangeViewRequestListItem)