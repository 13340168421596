import React from 'react'
import FlexContainer from '../../../components/containers/FlexContainer'
import { ListItem, ListItemIcon, Paper, Table, TableBody, TableHead } from '@material-ui/core'
import { LubricationPointInterface } from '../../../domains/lubricationPoints/model/lubricationPointsInterface'
import { CustomTableCell } from './CustomTableCell'
import CriticalityFlags from '../../../domains/infoLubricationPoints/components/CriticalityFlags'
import CustomButton, { CustomIcon } from '../../../components/buttons/CustomButton'
import TgdPopover from '../../../components/popovers/TgdPopover'
import { ListItemText, Tooltip } from '@mui/material'
import theme from '../../../assets/theme'
import { useLangLabels } from '../../../shared/lang/services/useLangLabels'

interface EquipmentDetailProps {
  lubricationPoint: LubricationPointInterface | null | undefined
}

export const EquipmentDetail:React.FC<EquipmentDetailProps> = ({lubricationPoint}) => {

  const {lang} = useLangLabels()
  const headStyling = {backgroundColor: '#e6e6e6'}
  
  return (
    <FlexContainer width={'100%'} justify='center' align='center' >
      <FlexContainer flexDirection='column' gap={'0px'} width={'100%'} justify='center' align='center' padding='16px'>

        <FlexContainer padding={'0px 0px 8px'}>
          <ListItem>
                <ListItemIcon>
                    <CustomIcon style={{color: lubricationPoint?.info?.inaccessible ? theme?.palette?.error.main : theme?.palette?.success.main}} icon={lubricationPoint?.info?.inaccessible ? 'error' : 'success'} />
                </ListItemIcon>
                <ListItemText primary={lubricationPoint?.info?.inaccessible ? lang.infoLubricationPoints.inaccessible : lang.infoLubricationPoints.accesible} />
            </ListItem>
          <ListItem>
            {lubricationPoint && 
              <CriticalityFlags
                mode='icons' 
                lubricationPoint={lubricationPoint} 
                fontSize={12}
              />
            }
          </ListItem>
          <ListItem>
            <ListItemIcon>
            <CustomIcon style={{color: lubricationPoint?.info?.labelReplacement ? theme?.palette?.error.main : theme?.palette?.success.main}} icon={lubricationPoint?.info?.labelReplacement ? 'error' : 'success'} />
            </ListItemIcon>
            <ListItemText primary={lubricationPoint?.info?.labelReplacement ? 'Necesita cambio de etiqueta' : 'Etiqueta en condiciones'} >

            </ListItemText>
          </ListItem>
        </FlexContainer>

        <Table>
          <TableHead style={headStyling}>
            <CustomTableCell >
              Equipo
            </CustomTableCell>
            <CustomTableCell >
              Elemento
            </CustomTableCell>
            <CustomTableCell >
              Componente
            </CustomTableCell>
            <CustomTableCell >
              Marca
            </CustomTableCell>
            <CustomTableCell >
              Capacidad
            </CustomTableCell>
            <CustomTableCell>
              Funcion
            </CustomTableCell>
            <CustomTableCell>
              Etiqueta
            </CustomTableCell>
            <CustomTableCell>
              Ubicación
            </CustomTableCell>
          </TableHead>
          <TableBody>
            <CustomTableCell>
                {lubricationPoint?.equipment} 
            </CustomTableCell>
            <CustomTableCell>
                {lubricationPoint?.element} 
            </CustomTableCell>
            <CustomTableCell>
                {lubricationPoint?.component} 
            </CustomTableCell>
            <CustomTableCell>
                {lubricationPoint?.brand} 
            </CustomTableCell>
            <CustomTableCell>
                {lubricationPoint?.capacity}  {lubricationPoint?.measureUnit} 
            </CustomTableCell>
            <CustomTableCell>
              {lubricationPoint?.function} 
            </CustomTableCell>
            <CustomTableCell>
              {lubricationPoint?.labelId} 
            </CustomTableCell>
            <CustomTableCell>
                {lubricationPoint?.location} 
              </CustomTableCell>
          </TableBody>
        </Table>
        <Table>
          <TableHead style={headStyling}>
            <CustomTableCell>
              Lubricante
            </CustomTableCell>
            <CustomTableCell>
              Tipo de lubricante
            </CustomTableCell>
            <CustomTableCell >
              Modelo
            </CustomTableCell>
            <CustomTableCell >
              Observaciones
            </CustomTableCell>
            <CustomTableCell >
              Analisis de aceite
            </CustomTableCell>
            <CustomTableCell >
              Revision
            </CustomTableCell>
            <CustomTableCell >
              Rutas
            </CustomTableCell>
            <CustomTableCell >
              Cantidad sugerida
            </CustomTableCell>
          </TableHead>
          <TableBody>
            <CustomTableCell>
              {lubricationPoint?.lubricant} 
            </CustomTableCell>
            <CustomTableCell>
              {lubricationPoint?.lubricantType} {lubricationPoint?.measureUnit}
            </CustomTableCell>
            <CustomTableCell>
                {lubricationPoint?.model} 
            </CustomTableCell>
            <CustomTableCell>
                {lubricationPoint?.observations}
            </CustomTableCell>
            <CustomTableCell>
                {lubricationPoint?.oilAnalysis}
            </CustomTableCell>
            <CustomTableCell>
                {lubricationPoint?.review}
            </CustomTableCell>
            <CustomTableCell>
                {lubricationPoint?.routes}
            </CustomTableCell>
            <CustomTableCell>
              {lubricationPoint?.suggestedQuantity}              
              </CustomTableCell>
          </TableBody>
        </Table>

      </FlexContainer>
    </FlexContainer>
  )
}
