import React from 'react';
import { CircularProgress, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDeleteRouteVerification } from './services/service.routes'
import { useEffect } from 'react';
import TgdPopover from '../../components/popovers/TgdPopover';

import { useSnackbar } from '../../components/snackbar/hooks/useSnackbar';
import CustomButton from '../../components/buttons/CustomButton';
import FlexContainer from '../../components/containers/FlexContainer';
import { useStatusProcessor } from '../../shared/queries/StatusProcessor';
import { useClickPosition } from '../../shared/globals/hooks/useClickPosition';
import { RouteInterface } from './services/RoutesInterface';




interface Props{
    item:RouteInterface
}
const DeleteRoute:React.FC<Props> = ({item}) => {

    const {errors,status:deleteStatus,validate,query } = useDeleteRouteVerification()
    const {status,message} = useStatusProcessor(query)

    const {position,getClickPosition} = useClickPosition()
    const { setData } = useSnackbar()

    useEffect(()=>{
        if(deleteStatus === 'error'){
            const componentsError = (
                (errors?.equipments?.length 
                    ? (errors.equipments.length > 1 
                        ? `${errors.equipments.length} Equipos` 
                        : `${errors.equipments.length} Equipo`) 
                    : '')
            )
            setData({
                modal:true,
                errors:`Esta Ruta no se puede eliminar porque está siendo usada en: ${componentsError}` ,
                severity:'warning',
                position:position
            })
        }
        if(status === 'success'){
            setData({
                modal:true,
                errors:message ,
                severity:'success',
                position:position
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[status,deleteStatus,errors])
    
   

    return (
        deleteStatus === 'loading' || status === 'loading'
        ? <FlexContainer justify={'center'} align='center'><CircularProgress size='24px' /></FlexContainer>
        : <CustomButton 
            status={status}
            icon='delete' 
            variant='iconButton' 
            action={(e)=>getClickPosition(e,()=>validate(item))}
            popoverTitle='Eliminar ruta'
        />
    
    );
}

export default React.memo(DeleteRoute);