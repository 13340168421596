import React from 'react';
import { AppBar, createStyles, IconButton, makeStyles, Toolbar, Typography } from '@material-ui/core';
import { equipmentType } from '../../model/lubricationPointsInterface';
import FullscreenSurface from '../../../../components/containers/modal/FullscreenSurface';
import CustomButton from '../../../../components/buttons/CustomButton';
import { useModal } from '../../../../components/containers/modal/hooks/useModal';
import { useEquipmentsTag } from '../../services/lubricationPointsAdapters';
import FlexContainer from '../../../../components/containers/FlexContainer';
import EquipmentFileTemplate from './EquipmentFileTemplate'
import UpdateEquipment from './UpdateEquipment';
import DesactivateActivateEquipment from './DesactivateActivateEquipment';

const useStyles = makeStyles((theme) => createStyles({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
	},
	root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
  }))

interface Props{
    item:any
    type:equipmentType.element | equipmentType.equipment
}
const EditEquipmentComponent:React.FC<Props> = ({item,type}) => {

    const classes = useStyles()
    const {modal,handleModal } = useModal()
    const {getTag}= useEquipmentsTag()
    
    return (
        <>
            <CustomButton action={handleModal} variant='iconButton' icon='edit' />

            <FullscreenSurface  modal={modal}  >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <CustomButton
                            icon='close'
                            color='inherit'
                            variant='iconButton'
                            action={handleModal}
                        />
                        
                        <Typography variant="h6" className={classes.title}>
                            Editar Equipo
                        </Typography>
                       </Toolbar>
                </AppBar>
                <br/>
                <FlexContainer justify='center' gap='16px' >
                    <EquipmentFileTemplate item={item} type={type}/>
                    <FlexContainer flexDirection='column'>
                        <UpdateEquipment item={item} />
                        <DesactivateActivateEquipment variant='fab' type={equipmentType.equipment} tagTGD={getTag(item,equipmentType.equipment)} item={item}/>
                    </FlexContainer>
                </FlexContainer>
            </FullscreenSurface>
        </>
    );
}

export default EditEquipmentComponent
