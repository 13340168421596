import { createStyles, ListItem, makeStyles, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';
import { useForm } from 'react-hook-form';
import CustomButton from '../../../components/buttons/CustomButton';
import FlexContainer from '../../../components/containers/FlexContainer';
import { PersonsFormRegex } from '../../../shared/regExp/regExp';
import { useToken } from '../../session/store/sessionStore';
import { PersonInterface } from '../models/PersonInterface';
import { useAssigneLubricatorNumber } from '../services/personServices';


const useStyles =  makeStyles((theme) => createStyles({
    formGrid:{
        display:'flex',
        flexDirection:'column'
    },
    input: {
        width:'300px,'
    },
}));


const AssignOperatorNumber:React.FC<{person:PersonInterface,tagFP:string}> = ({person,tagFP}) => {

    const classes = useStyles()
    const token = useToken()
    const {register,watch,errors,handleSubmit:handleLubricatorNumber} = useForm()
    const {mutate:updatePerson,status:updatePersonStatus,data} = useAssigneLubricatorNumber()

    const muatatePerson = () =>{
        updatePerson({
            data:{
                email:person.email,
                tagFP:tagFP,
                lubricatorNumber:watch('lubricatorNumber')
            },
            token:token!
        })
    }


    return (
        <ListItem>
            <form onSubmit={handleLubricatorNumber(muatatePerson)}>
            {(data?.data && data.data === 'Ya existe') && (updatePersonStatus==='success')  ? <><Alert severity="warning">No puede asignar este numero porque otro operador ya lo tiene asignado</Alert><br/></> : null}
                <FlexContainer align='center' gap='16px'>
                    <TextField
                        inputRef={register({
                            required:{value:true,message:'Requerido'},
                            pattern:{value:PersonsFormRegex.integer,message:'Ingresa solo un numero'},
                        })}
                        className={classes.input}
                        size='small'
                        variant='outlined'
                        name={'lubricatorNumber'}
                        defaultValue={watch('lubricatorNumber',person?.lubricatorNumber)}
                        label={errors?.lubricatorNumber?.message || 'Numero de Lubricador'}
                        color={errors?.lubricatorNumber?.message ? 'primary' : 'secondary'}
                    />
                    <CustomButton icon='save' status={updatePersonStatus} color='secondary' type='submit' ></CustomButton>
                </FlexContainer>
            </form>
        </ListItem>
    );
}

export default AssignOperatorNumber;
