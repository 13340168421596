import React from 'react';
import {  useRoutesByTagFP } from '../services/service.routes';
import DeleteRoute from '../DeleteRoute'
import AssignCancelMultipleRoutes from '../components/AssignCancelMultipleRoutes';
import CancelRouteAllLubricationPoints from '../components/CancelRouteAllLubricationPoints';
import CreateRouteForm from '../CreateRouteForm';
import { useRoutesGridModel } from '../lang/useRoutesGridModel';
import VirtualGrid from '../../../shared/react-table/VirtualGrid';
import { useLangLabels } from '../../../shared/lang/services/useLangLabels';


interface Props{
    virtualGridWidth:string
}


const ManageRoutesPage:React.FC<Props> = ({virtualGridWidth}) => {

    const {lang} = useLangLabels()
    const { data:routes } = useRoutesByTagFP()

    return (

        <VirtualGrid {...{
            width:virtualGridWidth,
            title:lang.routes.plural,
            gridModel:useRoutesGridModel(),
            items:routes,
            headerActions:(item:any)=><>
                <AssignCancelMultipleRoutes />
                <CreateRouteForm />
            </>,
            itemActions:(item)=><DeleteRoute  item={item}/>
            //<CancelRouteAllLubricationPoints item={item} />
        }}/>
    );
}

export default React.memo(ManageRoutesPage);
