import React from 'react';
import { CrudFormType } from '../../../shared/form/models/FormTypes';
import { useLangLabels } from '../../../shared/lang/services/useLangLabels';
import VirtualGrid, { CarrousellActinInterface } from '../../../shared/react-table/VirtualGrid';
import { Plant } from '../../plants/PlantInterface';
import CUContainerForm from '../CUContainerForm';
import DeleteContainerForm from '../DeleteContainerForm';
import { useContainersGridModel } from '../lang/useContainersGridModel';
import { ContainersInterface } from '../model/ContainerInterface';

import { useAllContainersByTagFP, useDeleteContainer } from '../services/service.containers';


interface Props {
    virtualGridWidth:string
} 


const ManageContainersPage:React.FC<Props> = ({virtualGridWidth}) => {
    

    const {lang} = useLangLabels()
    const {data:containers} = useAllContainersByTagFP()
    
    const carrousellComponents:CarrousellActinInterface = [
        {icon:'edit', popoverTitle:lang.crudActions.update,component:(item)=><CUContainerForm actionType={CrudFormType.update} item={item} />}
    ]

    return (
        <VirtualGrid
            {...{
                title:lang.containers.title.plural,
                width:virtualGridWidth,
                items:containers,
                gridModel:useContainersGridModel(),
                headerActions:()=><>
                    <CUContainerForm  actionType={CrudFormType.create} />
                </>,
                carrousellComponents
            }}
        >
            {(items)=> 
                items.length === 1 
                    ?<DeleteContainerForm typeLabel={lang.containers.title.singular} item={items[0]} />
                    :<></>
                }

        </VirtualGrid>
    );
}

export default ManageContainersPage
