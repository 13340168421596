import React, { ComponentType, ReactNode } from "react"
import { FixedSizeList, ListChildComponentProps } from "react-window"

interface VirtualizedListPRops{
    height:string | number, 
    width:string | number, 
    itemCount:number, 
    itemSize:number, 
    data?:any[]
    renderRow:ComponentType<ListChildComponentProps<any>> & ReactNode
}

export const VirtualizedList: React.FC<VirtualizedListPRops> = React.memo(({data,renderRow, height, itemCount, itemSize, width }) =>{
    return (
    <FixedSizeList {...{height, itemCount, itemSize , width, itemData:data}} >
        {renderRow}
    </FixedSizeList>)
})