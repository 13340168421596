import { InputComponentTypes } from "../../../shared/form/models/FormTypes";
import { useForm } from "../../../shared/form/useForm";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import { AnomalyInterface } from "../models/anomaliesTypes";


export const useAnomalyForm = (tagFP:string,item?:AnomalyInterface) => {

    const {lang} = useLangLabels()
    return useForm<AnomalyInterface>({fields:{
        id:{
            label:lang.globalentityValues.id,
            placeholder:lang.globalentityValues.id,
            component:InputComponentTypes.none,
            value: item?.id || 0
        },
        anomaly:{
            label:lang.anomalies.anomalyName,
            placeholder:lang.anomalies.anomalyName,
            value:item?.anomaly || '',
            component:InputComponentTypes.text,
            validations:{
                required:{ value:true, message: lang.validations.messages.required }
            }
        },
        tagFP:{
            label:lang.globalentityValues.tagFP,
            placeholder:lang.globalentityValues.tagFP,
            value:tagFP,
            component:InputComponentTypes.none
        }
    }})
}