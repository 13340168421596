import { Divider, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { QueryStatus } from 'react-query';
import CustomButton, { CustomIcon } from '../../../components/buttons/CustomButton';
import DoNotDoButton from '../../../components/buttons/DoNotDoButton';
import TgdFab from '../../../components/buttons/TgdFab';
import FlexContainer from '../../../components/containers/FlexContainer';
import PageContainer from '../../../components/containers/PageContainer';
import { useDate } from '../../../shared/date/useDate';
import { bakupAddSurveyWithObservations } from '../../../shared/dexie-js/backup/backupRepository';
import { errorsLog } from '../../../shared/globals/utils/utils';
import { LubricationPointInterface } from '../../lubricationPoints/model/lubricationPointsInterface';
import AddObservation from '../../observations/components/AddObservation';
import { ObservationInterface } from '../../observations/models/ObservationTypes';
import AddSupply from '../../supply/molecules/AddSupply';
import { suppliesToString } from '../../supply/utils/supplyToString';
import { useLubricationPointSurveyController } from '../services/controllers/useLubricationPointSurveyController';
import { TaskType } from '../tasks/model/TaskInterface';

const SurveyRepairment:React.FC<{item:LubricationPointInterface,taskType:TaskType,statusController:(status:QueryStatus)=>void, outOfRoute:boolean}> = ({outOfRoute, statusController,taskType,item}) => {
    
    const {register, handleSubmit, errors} = useForm()
    const  {isToday} = useDate()
    //SURVEY DATA
    const { survey,setTaskDone, setTaskTime, createSurvey, createObservation, nextTaskStep, backTaskStep, updateEquipmentInfo } = useLubricationPointSurveyController(item,outOfRoute)
    const observations = survey?.observations  || []   
    const taskDone = survey?.repairment.taskDone
    const taskTime = survey?.repairment.taskTime || 0
    const supplies= survey?.neededSupplies || []

    /* const infoSupplies = supplyStringToInfoSupplyString([...(new Set([
        ...currentEquipmentinfoSupplies,
        ...suppliesToString(supplies)
    ]))]) */

    //COMPONENT STATE
    const [step]  = useState<any>()
    
    //COMPONENT METHODS
    useEffect(()=> {
        register({name:'taskDone',value:taskDone})
        register({name:'taskTime',value:taskTime},{
            required:{value:true,message:'debe introducir un tiempo de tarea'},
            validate:value=>value > 0 || 'debe introducir un tiempo de tarea'
        })
    },[taskType, taskDone, register, taskTime])
   

    const submit = (data?:any) => {
        statusController('loading')
        const { surveyDone: lubricationSurveyDone, ...restLubrication } = survey?.lubrication || {};
        const { surveyDone: repairmentSurveyDone, ...restRepairment } = survey?.repairment || {};
        const newSurvey = {
            ...survey?.inspection,
            ...restLubrication,
            ...restRepairment,
            //TASK DATA
            taskType:taskType,
            taskDone:!!taskDone,
            taskTime:`${taskTime}`,
            endTime:isToday(survey?.inspection.startTime!) ? new Date().toISOString() : survey?.inspection.startTime!, 
            //LUBRICATION DATA
            lubricates:survey?.lubrication.lubricates,
            sampleExtraction:false,
            consumables:JSON.stringify(suppliesToString(supplies))
        } as any

        const newObservations = (id:number) =>{
            return [
                ...(observations.map((o:ObservationInterface)=>({...o,surveyId:id}))),
            ]
        }
        
        //save  backUp
        bakupAddSurveyWithObservations(newSurvey,newObservations)

        createSurvey(newSurvey)
        .then((data)=>{
            if (survey) {
                survey.repairment.surveyDone = true; 
            }
            createObservation(newObservations(data.id)).then((obs)=>{
                if(obs.error) statusController('error')
                else updateEquipmentInfo({
                    ...item.info,
                    lastInspectionDate: survey?.inspection.startTime!,
                    lastLubrication: !!survey?.lubrication.lubricates ? survey?.inspection.startTime! : item?.info?.lastLubrication!,
                    updateReview:survey?.inspection.updateReview === false ? item.info?.updateReview : survey?.inspection.updateReview!,
                    labelReplacement:survey?.inspection.labelReplacement === false ? item.info?.labelReplacement : survey?.inspection.labelReplacement!,
                    lastSurveyId: survey?.lastSurveyId ?? item.info?.lastSurveyId, 
                    lubricantQuantity: survey?.lubrication.quantity ?? item.info?.lubricantQuantity,
                } as any).then(async(update)=>{
                    if(update.error) statusController('error')
                    else{
                        statusController('success') 
                        nextTaskStep(taskType)
                    }
                })
            })
        })
        .catch((e)=>{
            statusController('error')
            console.log(e)
        })
    }
    
    errorsLog(errors)


    return(<>

            {outOfRoute && /* BACK BUTTON */
                <div>
                    <CustomButton 
                        action={()=>backTaskStep(taskType)} 
                        icon='before' 
                        variant='iconButton' 
                        title='Atras' 
                    />
                </div>
            }
        
            { taskDone === undefined &&
                <FlexContainer justify='center' align='center' gap='24px' height='100%' width='100%'>
                    <DoNotDoButton action={submit} icon='close' title='Sin Reparación'/>
                    <DoNotDoButton action={()=>setTaskDone(taskType,true)} icon='check' title='Reparación'/>
                </FlexContainer>
            }

            {!outOfRoute && taskDone === true &&
                <div>
                    <CustomButton
                        action={()=>setTaskDone(taskType,false)} 
                        icon='cancelOutlined' 
                        variant='iconButton'
                        popoverTitle='Cancelar reparación' 
                    />
                </div>

            }
            
            <>
                {taskDone && <>
                    <PageContainer>
                        <TextField
                        size='small'
                        variant='outlined'
                        style={{width:'100%'}}
                        type='number'
                        name={'taskTime'}
                        onChange={(event)=>{
                            setTaskTime(taskType,JSON.parse(event.target.value))
                        }}
                        label={(`Tiempo de reparación ${errors?.taskTime?.message || ''}` || 'Tiempo de Reparación')}
                        color={errors?.taskTime?.message ? 'primary' : 'secondary'}
                        helperText='En minutos'
                        inputRef={register({
                            required:{
                                value:!!taskDone,
                                message:'Requerido'
                            }
                        })}
                        />
                    </PageContainer>
                    <br/>
                    <Divider/>

                        <AddObservation
                            outOfRoute={outOfRoute}
                            taskType = {taskType}
                            lubricationPoint={item}
                            type='OBSERVACION' 
                            buttonTitle='observaciones'
                            formTitle='Nueva observación'
                            variant={(survey?.observations && survey.observations.length > 0 ) ? 'list' : 'form'} 
                            listTitle={(step?.observation) || 'Observaciones'}
                            buttonType='add'
                        />

                        <AddSupply
                            outOfRoute={outOfRoute}
                            listTitle='Insumos' 
                            formTitle={'Nuevo insumo'}
                            buttonTitle={'Insumos'}
                            variant={'list'}
                            buttonType={'add'} 
                            lubricationPoint={item}
                        />
                    
                        <TgdFab
                            disabled={(survey?.observations && survey.observations.length > 0 ) ? false : true}
                            bottom={4}
                            right={4}
                            color='primary' 
                            onClick={handleSubmit(submit)}
                            children={<CustomIcon icon={'next'}/>}
                        />
                    
                </>}
            </>
    </>)
}

export default React.memo(SurveyRepairment)