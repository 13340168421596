import React from 'react';
import { DrawerWidth } from '../../../../pages/backoffice/ViewAsClient/components/UserDashboard';
import { CrudFormType } from '../../../../shared/form/models/FormTypes';
import { useLangLabels } from '../../../../shared/lang/services/useLangLabels';
import VirtualGrid from '../../../../shared/react-table/VirtualGrid';
import { Plant } from '../../../plants/PlantInterface';
import { useSessionContext } from '../../../session/store/sessionContext';
import { useColectorGridModel } from '../../lang/useColectorGridModel';
import { useCollectorsByTagFP } from '../../services/collectorsServices';
import ActivateDesactivateCollector from '../ActivateDesactivateCollector';
import CollectorQrCode from '../CollectorQrCode';
import CollectorStatus from '../CollectorStatus';
import CreateUpdateCollectorForm from '../CreateUpdateCollectorForm';
import ViewQrCode from '../ViewQrCode';


interface Props{
    virtualGridWidth:string
}

const ManageCollectorsPage:React.FC<Props> = ({virtualGridWidth}) => {

    const {tagFP} = useSessionContext()
    const {lang} = useLangLabels()
    const {data:collectors} = useCollectorsByTagFP(tagFP)
    
    return(
        
        <VirtualGrid
            width={virtualGridWidth}
            title={lang.collectors.plural}
            gridModel={useColectorGridModel()}
            items={collectors}
            headerActions={(item:any)=> <> 
                <CreateUpdateCollectorForm tagFP={tagFP} type={CrudFormType.create}/>
            </>}
            renderOption={(item)=><>
                <CollectorStatus collector={item}/>
                <ViewQrCode {...{item}} />
            </> }
        >
            {(items:any)=> <>
                <CreateUpdateCollectorForm tagFP={tagFP} type={CrudFormType.update} {...{items}}/>
                <ActivateDesactivateCollector tagFP={tagFP} {...{items}}/> 
            </>}
        </VirtualGrid>

    )
    
}

export default ManageCollectorsPage
