import { useLangLabels } from "../../../shared/lang/services/useLangLabels"
import DeleteElementForm from "../DeleteElementForm"
import { ElementInterface } from "../models/ElementInterface"
import { usePlantElements } from "../services/service.elements"
import VirtualGrid,{CarrousellActinInterface} from "../../../shared/react-table/VirtualGrid"
import CUElementForm from "../CUElementForm"
import { CrudFormType } from "../../../shared/form/models/FormTypes"
import { useElementGridModel } from "../lang/useElementsGridModel"



interface Props {
    virtualGridWidth:string
}



const ManageElementsPage:React.FC<Props>  = ({virtualGridWidth}) => {
    
    const {data:elements} = usePlantElements()
    const {lang} = useLangLabels()

    const carrousellActions:CarrousellActinInterface = [
        {icon:'edit',popoverTitle:lang.crudActions.update ,component:(item:ElementInterface)=><CUElementForm   actionType={CrudFormType.update} item={item}/>}
    ]
    
    return(    
        <VirtualGrid
            {...{
                width:virtualGridWidth,
                title:lang.elements.title.plural,
                gridModel:useElementGridModel(),
                carrousellComponents:carrousellActions,
                items:elements ,
                headerActions:(item:any)=><CUElementForm  actionType={CrudFormType.create} />,        
            }}
        >
            {(items:ElementInterface[] | [])=>items.length === 1
                ? <DeleteElementForm item={items[0]} />
                : <></>
            }
        </VirtualGrid>
    )
}

export default ManageElementsPage
