import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, TextField, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useCreateRoute } from './services/service.routes';
import ModalButton from '../../components/buttons/ModalButton';
import CustomButton from '../../components/buttons/CustomButton';
import SelectOperatorNumber from './components/atoms/SelectOperator';
import Selectperiodicity from './components/atoms/SelectPeriodicity';
import { Alert } from '@material-ui/lab';
import { useRouteExistance } from './services/useRouteExistance';
import { useSessionContext } from '../session/store/sessionContext';
import { useStatusProcessor } from '../../shared/queries/StatusProcessor';
import { RouteInterface } from './services/RoutesInterface';
import { errorsLog } from '../../shared/globals/utils/utils';
import UserRoleManager from '../users/UserRoleManager';
import StatusAndMessage from '../../shared/queries/errors/components/StatusAndMessage';
import FlexContainer from '../../components/containers/FlexContainer';


const useStyles =  makeStyles((theme) => createStyles({
    formGrid:{
        display:'flex',
        flexDirection:'column'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

interface Props{
} 


const CreateRouteForm:React.FC<Props> = () => {

    const {token,tagFP} = useSessionContext()
    const classes=useStyles()
    const query = useCreateRoute()
    const { status, message, error,reset:statusProcesorReset } = useStatusProcessor(query)
    const today = new Date().toISOString().slice(0,10)
    
    const {register,setValue,watch,handleSubmit,errors} = useForm()

    const routeExist = useRouteExistance(watch('route'),tagFP)
    const [errorExist, seterrorExist] = useState(''); 

    const handleReset = () => {
        statusProcesorReset()
        setValue('periodicity','')
        setValue('route','')
        setValue('operator','')
        setValue('startDate','')
        seterrorExist('')
    }

    const submit = (route:RouteInterface) => {
        query.mutate({
            data:{
                operator:route.operator,
                periodicity:route.periodicity,
                route:route.route,
                routeName:`${route.operator}-${route.periodicity}-${route.route}`,
                startDate:`${route.startDate}T12:00:00.000Z`,
                tagFP:route.tagFP,
            },
            token
        })
    }


    useEffect(() => {
        register('tagFP')
        register('operator',{
            required:{value:true,message:'requerido'}
        })
        register({name:'periodicity',type:'number'},{
            required:{value:true, message:'requerido'} 
        })
        setValue('tagFP',tagFP)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[tagFP])

    errorsLog(errors)

    useEffect(() => {
        if(routeExist){
            //setAutomatic periodicity and date from equal route that alredy exist
            setValue('periodicity',routeExist.compatibleRoute.periodicity)
            setValue('startDate',routeExist.compatibleRoute.startDate.slice(0,10)) 
            seterrorExist(routeExist.message)
        } 
        else{
            //reset
            setValue('periodicity','')
            setValue('startDate','')
            seterrorExist('')
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[routeExist])

    return (
        <>
            <UserRoleManager allowedUserTypes={['SA','A','AC']}>
                    
                <ModalButton icon='add' variant='button' title='Nueva ruta' popoverTitle='Nueva ruta'>

                    {!!errorExist && <Alert onClose={handleReset} severity='warning'>{errorExist}</Alert>}
                    <br />
                    <form className={classes.formGrid} onSubmit={handleSubmit(submit)}>

                        <StatusAndMessage status={status} message={message} error={error} reset={handleReset} /> 
                        
                        <FlexContainer>

                            <SelectOperatorNumber
                            defaultValue={ !routeExist?.canCreate ? false : routeExist?.compatibleRoute.operator}
                            disabled={(!routeExist?.canCreate ? false : routeExist ? !routeExist?.canCreate : false) || watch('route') === ''}
                            error={errors?.operator?.message}
                            tagFP={tagFP}
                            complete
                            onChange={(value:any)=>setValue('operator',value?.lubricatorNumber)}
                            />

                            <Selectperiodicity
                            defaultValue={routeExist?.compatibleRoute.periodicity}
                            disabled={!!routeExist || watch('route') === ''}
                            error={errors?.periodicity?.message}
                            onChange={(value:any)=>setValue('periodicity',value)}
                            />
                            
                            <TextField 
                            name='route'  
                            type='number' 
                            inputRef={register({
                                required:{value:true,message:'Campo requerido'},
                                maxLength:{value:4,message:'Muy Largo'},     
                            })} 
                            placeholder='Nombre de la Ruta' 
                            variant='outlined'
                            label={errors?.route?.message || 'Nombre de la Ruta'} 
                            color={errors?.route?.message ? 'primary' : 'secondary'}
                            size="small"
                            />

                        </FlexContainer>
                        <br/>

                        <TextField 
                            InputProps={{
                                readOnly: !!routeExist,
                            }}
                            name='startDate'  
                            type='date' 
                            variant='outlined'
                            inputRef={register({
                                required:{value:true,message:'Campo requerido'},
                                validate:(value)=>{
                                    if(routeExist){
                                        return true
                                    }
                                    return value >= today ? true : 'no puede ser anterior a hoy'
                                }
                            })}
                            color={errors?.startDate?.message ? 'primary' : 'secondary'}
                            size="small"
                        />
                        
                        <br/>

                        {status === 'loading' && <Typography color='error' >Calculando las fechas anuales...</Typography>}
                        <br/>
                        <CustomButton disabled={routeExist ? !routeExist?.canCreate : false} status={status} type='submit'> Crear</CustomButton>
                    </form>
                </ModalButton>
            </UserRoleManager>
        </>
    );
}

export default CreateRouteForm;
