import styled from "@emotion/styled";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import theme from "../../../../assets/theme";


interface Props{
    open?:boolean
    height:CSSProperties['height']
    width:CSSProperties['width']
}

export const ModalDialog = styled<any>('div')`
    top:0;
    left:0px;
    display:${(props)=>props.open ? 'unset' : 'none'};
    z-index:5000;
    background:${theme.palette.background.paper};
    /* border-radius:8px; */
    position:relative;
    box-shadow: rgba(0, 0, 0, 0.8) 0px 7px 29px 0px;
    overflow-y:scroll;
    height:${({height}) =>height};
    width:${({width}) =>width};
    transition: ease-in;
    cursor:default;
`