import React from 'react';
import SelectSimpleEntity from '../../../components/autocomplete/old/SelectSimpleEntity';
import { CrudFormType } from '../../../shared/form/models/FormTypes';
import { Plant } from '../../plants/PlantInterface';
import { useUser } from '../../session/store/sessionStore';
import CULubricantForm from '../CULubricantForm';
import { LubricantInterface } from '../model/LubricantInterface';
import { useAllLubricantsByPlant } from '../services/service.lubricants';
;

interface props{
    tagFP?:string
    plant?:Plant | undefined
    onChange:(value:string) => void
    defaultValue?:string | undefined 
    dimentionType?:string
    color?:'primary'|'secondary'
    error?:string
    lubricantType?:string
}

const SelectLubricant:React.FC<props> = ({ plant, onChange, defaultValue, color, error, tagFP,lubricantType }) => {

    const {data:lubricants} =  useAllLubricantsByPlant()
    const _lubricants = lubricantType 
    ? lubricants?.filter((l:LubricantInterface)=>l.type === lubricantType)
    : lubricants

    const {data:user} = useUser()
    
    return (<>
            <SelectSimpleEntity
                error={error}
                label={ 'Lubricante'}
                onChange={(value:string)=>onChange(value)}
                entityList={_lubricants}
                showTitle={'lubricant'}
                defaultValue={defaultValue}
            >
                {user && (user?.type === 'O' ? null : <CULubricantForm tagFP={plant?.tagFP!} actionType={CrudFormType.create} />)}
            </SelectSimpleEntity>

    </>);

}

export default React.memo(SelectLubricant);
