import React from 'react';
import { GridModelInterface } from '../../shared/gridModel/gridModelEntity';
import VirtualGrid from '../../shared/react-table/VirtualGrid';

interface TgdSelectabelTableInterface {
    items:any,
    headerActions:React.ReactNode,
    title:string,
    handleChange:(item:any)=>void,
    gridModel:GridModelInterface
}

const TgdSelectableTable:React.FC<TgdSelectabelTableInterface> = ({gridModel, items,headerActions,title,handleChange}) => {    

    return (
        <VirtualGrid
            contained={false}
            gridModel={gridModel}
            title={title}
            items={items}
            onSelectionModelChange={handleChange}
            headerActions={(item:any)=><>{headerActions && headerActions}</>}
        />
    );
}

export default React.memo(TgdSelectableTable);
