import styled from "@emotion/styled";

export const ModalBackdrop = styled<any>('div')`
    z-index:${props =>props.zIndex ? props.zIndex : 1101};
    position:absolute;
    display:${(props)=>props.open ? 'flex' : 'none'};
    display:${(props)=>props.open ? '1' : '0'};
    justify-content:center;
    align-items:center;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
    align-items: center;
    justify-content: center;
    transition: ease-in;
    :hover{
        cursor:pointer;
    }
`