import { TextField, Typography } from "@material-ui/core"
import React, { useEffect, useMemo } from "react"
import { useForm } from "react-hook-form"
import SimpleAutocompleteSelect from "../../../../components/autocomplete/SimpleAutocompleteSelect"
import FlexContainer from "../../../../components/containers/FlexContainer"
import FeatureStatusContainer, { useFeatures } from "../../../../shared/config/features/FeatureStatusContainer"
import { CrudFormType } from "../../../../shared/form/models/FormTypes"
import { FormRegExp } from "../../../../shared/regExp/regExp"
import { useGetAllAuxByPlantData } from "../../../allAux/services/allAuxServices"
import CUtypeForm from "../../../types/CUtypeForm"
import { enumTgdTypes } from "../../../types/models/types"
import { LubricationPointInterface } from "../../model/lubricationPointsInterface"

interface selectSugestedQuantity {
    quantity:string,
    meassureUnit:LubricationPointInterface['suggestedQuantity']
}

interface SuggestedQuantityParserResult {
    parsed: string | selectSugestedQuantity,
    original: string | selectSugestedQuantity;
}

const suggestedQuantityParser = (suggestedQuantity:selectSugestedQuantity | LubricationPointInterface['suggestedQuantity']):SuggestedQuantityParserResult => {
    
    const parsed = (() =>{
        if(typeof suggestedQuantity === 'string'){
            if(suggestedQuantity.length > 0) {
                const properties = suggestedQuantity.split(' ') 
                return {
                    quantity:properties[0],
                    meassureUnit:properties[1]
                }
            }
            else return {
                quantity:'',
                meassureUnit:''
            }
        }
        else{
            return `${suggestedQuantity?.quantity || ''} ${suggestedQuantity?.meassureUnit || ''}`
        }
    })() 
    
    return {
        parsed,
        original:suggestedQuantity
    }
}


interface SelectSugestedQuantityProps {
    onChange:(value:LubricationPointInterface['suggestedQuantity'])=>void,
    defaultValue?:LubricationPointInterface['suggestedQuantity'],
    children?:React.ReactNode
}

const SelectSuggestedQuantity:React.FC<SelectSugestedQuantityProps> = ({children,onChange,defaultValue}) => {

    const defaultSugestedCuantity = useMemo(()=>suggestedQuantityParser(defaultValue || '').parsed as selectSugestedQuantity,[defaultValue])
    const {data:types} = useGetAllAuxByPlantData('types') 
    const measureUnits = types?.filter((t)=>t.type === 'UNIDAD')

    const {register, watch, handleSubmit, errors, setValue} = useForm<selectSugestedQuantity>()

    useEffect(()=>{
        register({name:'quantity',type:'number'},{
            required:{
                value:true,
                message:'cantidad requerida'
            },
            pattern:{
                value:FormRegExp.number,
                message:'solo números'
            },
            
        })
        register('meassureUnit',{
            required:{
                value:true,
                message:'unidad de medida requerida'
            }
        })
        setValue('quantity',defaultSugestedCuantity.quantity)
        setValue('meassureUnit',defaultSugestedCuantity.meassureUnit)
    },[defaultSugestedCuantity])

    const handleValue = (key:keyof selectSugestedQuantity,value:any) => {
        setValue(key,value)
    }

    const handleChange = handleSubmit((data) => {
        const valueToElevate = suggestedQuantityParser(data).parsed as string
        onChange(valueToElevate)
    })

    useEffect(()=>{
        handleChange()
    },[watch('quantity'), watch('meassureUnit')])

    

    return(
        <FeatureStatusContainer feature={useFeatures().sugestedQuantity} hidden >
            <FlexContainer flexDirection="column" width={'100%'}>
                <FlexContainer width={'100%'} key={defaultSugestedCuantity.quantity + defaultSugestedCuantity.meassureUnit }>
                    <TextField fullWidth
                        /* autoFocus */
                        name="quantity" 
                        placeholder='Cantidad' 
                        defaultValue={defaultSugestedCuantity.quantity}
                        type='number'
                        variant='outlined'
                        label={errors?.quantity?.message || 'Cantidad'} 
                        color={errors?.quantity?.message  ? 'primary' : 'secondary'}
                        size="small"
                        onChange={(e)=>handleValue('quantity',e.target.value)}
                    />
                    <SimpleAutocompleteSelect {...{
                        label:'Unidad de medida',
                        disabled: true,
                        error: errors?.meassureUnit?.message ? 'requerido' : undefined ,
                        items:measureUnits || [],
                        defaultValue: defaultSugestedCuantity.meassureUnit,
                        showTitle:'name',
                        onChange:value => handleValue('meassureUnit',value)
                    }}/>

                    <CUtypeForm actionType={CrudFormType.create} defaultType={enumTgdTypes.unity} />

                </FlexContainer>

                <Typography variant="body2">
                    Este valor le indicará al lubricador cual es la cantidad sugerida de aplicacion de lubricante en este punto
                </Typography>

                {children && children}

            </FlexContainer>       
        </FeatureStatusContainer>
    )
}

export default React.memo(SelectSuggestedQuantity)