import React from 'react'
import theme from '../../../assets/theme'
import { CustomIcon } from '../../../components/buttons/CustomButton'
import { CollectorInterface } from '../models/CollectorInterfaces'

const CollectorStatus:React.FC<{collector:CollectorInterface}> = ({collector}) => {
  return (
    <CustomIcon icon={'state'} style={{color:collector.state ? theme.palette.success.main : theme.palette.grey[500]}} /> 
  )
}

export default CollectorStatus
