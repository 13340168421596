import React, { useCallback, useEffect } from 'react'
import { AppBar, CircularProgress, ListItemAvatar, Toolbar, Typography, Divider, ListItemText, Grid, Badge, ListItem, Snackbar, Popover } from '@material-ui/core'
import theme from '../../../assets/theme'
import { useLangLabels } from '../../../shared/lang/services/useLangLabels'
import { useModal } from '../../../components/containers/modal/hooks/useModal'
import FeatureStatusContainer, { useFeatures } from '../../../shared/config/features/FeatureStatusContainer'
import { useSessionContext } from '../../session/store/sessionContext'
import { useCollectorsByTagFP } from '../../collectors/services/collectorsServices'
import OnlineOfflineMode from '../../../shared/config/offline Mode/OnlineOfflineMode'
import AllowUsers from '../../../shared/config/UserPermissions/AllowedUser'
import CollectorDBSnackbarStatus from './CollectorDBSnackbarStatus'
import ComponentMode from '../../../components/componentMode/ComponentMode'
import CustomButton, { CustomIcon } from '../../../components/buttons/CustomButton'
import PageContainer from '../../../components/containers/PageContainer'
import TgdPopover from '../../../components/popovers/TgdPopover'
import PointerContainer from '../../../components/containers/PointerContainer'
import StatusAndMessage from '../../../shared/queries/errors/components/StatusAndMessage'
import FlexContainer from '../../../components/containers/FlexContainer'
import SyncBoxIcon from './SyncBoxIconV2'
import { invalidateCollectorsData, useCollectorSyncStatus, useReadyForCollectorSyncQuery, useSetSyncBoxDailySync, useSyncCollectorDatabases } from '../services/sincBoxServices'
import { Alert } from '@material-ui/lab'
import TabContainer from '../../../components/containers/tabContainer/TabContainer'
import { CollectorInterface } from '../../collectors/models/CollectorInterfaces'
import { CollectorsSync, ip } from '../model/CollectorsSyncByTagFPType'
import CollectorQrCode from '../../collectors/components/CollectorQrCode'





interface MakeAvailableDataPanelProps{
    //tagFP:string
}

export const syncPanelDrawerWidth = 40

const syncBoxStatusColor = (colectorSyncsStatus:boolean | null | undefined) => {
    switch (colectorSyncsStatus) {
        case true:
            return theme.palette.success.light
    
        case false:
            return theme.palette.warning.light
        
        case null:
            return theme.palette.error.light

        case undefined:
            return theme.palette.grey[400]
    }
}

const colectorStatusColor = (colectorSyncsStatus:boolean | null) => {
    switch (colectorSyncsStatus) {
        case true:
            return theme?.palette.success.light
    
        case false:
            return theme.palette.error.light
        
        case null:
            return theme.palette.error.light
            
    }
}



const DeviceSyncPanel:React.FC<MakeAvailableDataPanelProps> = ({}) => {

    const {tagFP,currentPlant} = useSessionContext()
    const {lang} = useLangLabels()
    const {modal,handleModal} = useModal()
    const features = useFeatures()

    const {data:Collectors} = useCollectorsByTagFP(tagFP)
    const { isLoading, updateSyncDataBase, updateSyncDataBaseStatus,updateResult,updateTime} = useSetSyncBoxDailySync()

    const {status:snackbarStatus, message:snackbarMessage, refetch} = useReadyForCollectorSyncQuery()

    const {SyncStatusViewMessage, collectorsSyncStatus, leftCollectorsSync, pendingColectorsSync, AllcolectorsSyncData, collectorSyncDate, AllPendingSyncs } = useCollectorSyncStatus(currentPlant?.tagFP)


    const tabItems = Collectors && AllcolectorsSyncData?.map((collectorsync)=>{
        /* console.log(colectorsync) */
        return {
            colectorData:{
                ...collectorsync,
                ...Collectors.find((col)=> col.collectorCode === collectorsync.collectorCode)
            },
            name:collectorsync.collector 
        }
    })


    const _colectorSyncsStatus = collectorsSyncStatus()
    const _leftColectorsSync = leftCollectorsSync()
    const _syncBoxStatusColor = syncBoxStatusColor(_colectorSyncsStatus)
    const _pendingColectorsSync = pendingColectorsSync() || []
    const badgeVisibility = AllPendingSyncs && AllPendingSyncs?.length > 0
        ? false
        : true

    const {syncCollectorsData, status, message, error} = useSyncCollectorDatabases(tagFP)

    const syncInfo =  SyncStatusViewMessage(message,AllPendingSyncs)

    useEffect(()=>{
        if(modal){
            invalidateCollectorsData()
        }
    },[modal])

    return(
        <OnlineOfflineMode reverse hidden>
            <AllowUsers allowedUserTypes={['S','O']} >
                <FeatureStatusContainer hidden feature={features.collectorFeature}>
                    <CollectorDBSnackbarStatus {...{
                        isLoading,
                        status:snackbarStatus,
                        message:snackbarMessage
                    }} />
                    <Badge invisible={badgeVisibility} variant='dot' color='primary' >
                        {!currentPlant?.tagFP || isLoading 
                            ? <CircularProgress size='20px' />
                            : <ComponentMode
                                {...{
                                    mode:'drawer',
                                    icon:'sync',
                                    variant:'iconButton',
                                    popoverTitle:lang.collectorSync.title,
                                    drawerProps:{
                                        open:modal,
                                        onClose:handleModal,
                                        anchor:'right',
                                        styles:{
                                            drawerWidth:`${syncPanelDrawerWidth}vw`,
                                            color:'#000000',
                                        }
                                    }
                                }}
                            >
                                <AppBar {...{ position:'relative',  }}>
                                    <Toolbar>
                                        <CustomButton
                                            action={handleModal} 
                                            icon={'close'} 
                                            variant='iconButton' 
                                            color='inherit' 
                                        />
                                        <Typography variant='h6'>
                                            {lang.collectorSync.title}
                                        </Typography>
                                    </Toolbar>
                                </AppBar>
                
                                <PageContainer>

                                    <CollectorDBSnackbarStatus {...{
                                        mode:'alert',
                                        isLoading,
                                        status:snackbarStatus,
                                        message:snackbarMessage
                                    }} />
                                    
                                    <TgdPopover
                                    title='' 
                                    mode='hover' 
                                    hoverChildren={
                                        syncInfo ?
                                            <PageContainer width='350px' padding='0px'>
                                                {Object.entries(lang.collectorSync.errors).map(([key,value],index)=>
                                                    <ListItem key={index}>
                                                        <ListItemText>
                                                            <Typography variant='h6'>
                                                                <strong>
                                                                    {value} <br />
                                                                </strong>
                                                            </Typography>
                                                            <Typography>
                                                                {lang.collectorSync.helpers[key as SyncFileErrors.expired | SyncFileErrors.failed]}
                                                            </Typography>
                                                        </ListItemText>
                                                    </ListItem>
                                                )}
                                            </PageContainer>
                                            : status === 'error' && 
                                            <PageContainer width='350px' padding='0px'>
                                                    <ListItem >
                                                        <ListItemText>
                                                            <Typography variant='h6'>
                                                                <strong>
                                                                    Error <br />
                                                                </strong>
                                                            </Typography>
                                                            <Typography>
                                                                {lang.collectorSync.helpers.unknown}
                                                            </Typography>
                                                        </ListItemText>
                                                    </ListItem>
                                            </PageContainer>
                                    }>
                                        <PointerContainer>
                                            <StatusAndMessage {...{
                                                status:syncInfo ? 'error' : status,
                                                error:'',
                                                message:syncInfo || message 
                                            }} />
                                        </PointerContainer>
                                    </TgdPopover>
                                    {/* Update ustatus */}
                                    {['success','error'].includes(updateSyncDataBaseStatus) &&
                                        <PageContainer padding={'24px 0'}>
                                            <StatusAndMessage {...{
                                                status:updateSyncDataBaseStatus === 'success' ? 'success' : 'error',
                                                error:'',
                                                message:updateResult && `los datos de Syncbox han sido actualizados: ${updateTime}`  
                                            }} />
                                        </PageContainer>            
                                    }
                                    <FlexContainer align='center' justify='center'>
                                        <SyncBoxIcon
                                            pendingSyncs={AllPendingSyncs || []}
                                            colectorSyncsStatus={_colectorSyncsStatus}
                                            leftColectorsSync={_leftColectorsSync}
                                            syncBoxStatusColor={_syncBoxStatusColor}
                                            pendincColectorsSync={_pendingColectorsSync}
                                            actionStatus={status}
                                            actionUpdateStatus={updateSyncDataBaseStatus}
                                            handleUpdateSyncClick={ updateSyncDataBase }
                                            handleSyncClick = { syncCollectorsData }
                                        />
                                    </FlexContainer>
                                </PageContainer>
                                
                                <TabContainer
                                    tabs={tabItems || []}
                                    labelSelector='name'
                                    panelSelector='panel'
                                >
                                    {(item)=>{
                                            const desactivatedCollector = !item?.colectorData.state
                                            return(
                                                <PageContainer>
                                                {desactivatedCollector
                                                ? <Alert severity='warning'>{lang.collectors.desactivatedColectorWarning}</Alert>
                                                : <Grid container alignItems="center">
                                                        <FlexContainer padding='0px 24px'>
                                                            <CollectorQrCode 
                                                                colector={item?.colectorData}
                                                                size={110}
                                                            />
                                                        </FlexContainer>
                                                        <Divider orientation="vertical" flexItem />
                                                        <FlexContainer flexDirection='column' padding='0px 24px' >
                                                            <ListItemAvatar>
                                                                <CustomIcon icon='state' style={{color:colectorStatusColor(item?.colectorData?.sync)}} />
                                                            </ListItemAvatar>
                                                            <ListItemText 
                                                            primary={collectorSyncDate(item?.colectorData.collectorCode)}
                                                            secondary={'última sincronización'}
                                                            />
                                                        </FlexContainer>
                                                    </Grid>
                                                }

                                                </PageContainer>
                                            )
                                        }
                                    }
                                </TabContainer>
                            </ComponentMode>
                        }

                    </Badge>
                </FeatureStatusContainer>
            </AllowUsers>
       </OnlineOfflineMode>
    )
}

export default DeviceSyncPanel