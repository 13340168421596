import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useUpdateStock } from '../services/service.stock';
import { StockInterface } from '../models/StockInterface';
import ComponentMode from '../../../components/componentMode/ComponentMode';
import StatusAndMessage from '../../../shared/queries/errors/components/StatusAndMessage';
import FlexContainer from '../../../components/containers/FlexContainer';
import { TextField } from '@material-ui/core';
import { TypeFormRegex } from '../../../shared/regExp/regExp';
import CustomButton from '../../../components/buttons/CustomButton';

interface Props{
    item:StockInterface
}

const NewStockQuantityForm:React.FC<Props> = ({item}) => {
    
    const  { register, setValue, errors, watch, handleSubmit, reset } = useForm()
    const  {updateStock,status,error,message} = useUpdateStock() 

    const title = 'Gestionar el stock'
    
    useEffect(()=>{
        if(item){
            Object.entries(item).map(([key,value]:[string,any])=>{
                register({
                    name:key,
                    value
                },{
                    required:{
                        value:!!value,
                        message:'Campo requerido'
                    }
                })
            })
        }
    },[item, register])


    const submit = (data:any) => {
        console.log(data)
        updateStock(data)
    }
    
    return (
        <>
        <ComponentMode key={item.id} variant='iconButton' mode='popover' title={title} icon='stock'>
            <StatusAndMessage status={status} message={message} error={error} />
            <FlexContainer flexDirection={'column'}>
                    <TextField
                        inputRef={register({
                            pattern:{
                                value:TypeFormRegex.integer,
                                message:'Solo numeros'
                            }
                        })}
                        name='actualStock'
                        size='small'
                        variant='outlined'
                        defaultValue={watch('actualStock')}
                        label={errors?.actualStock?.message || 'Cantidad actual'}
                        color={errors?.actualStock?.message ? 'primary' : 'secondary'}
                    />

                    <CustomButton status={status} action={handleSubmit(submit)} >Guardar</CustomButton>
            </FlexContainer>
        </ComponentMode>
        
        </>
    );
}

export default NewStockQuantityForm;
