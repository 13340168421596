import { Grid, List, ListItem, ListItemText, Typography } from "@material-ui/core";
import { getGridModelValue } from "../../../../shared/gridModel/gridModelEntity";
import FlexContainer from "../../../../components/containers/FlexContainer";
import { surveyTaskColor } from "../utils/surveyTaskColor";
import { InfoSurveyInterface, SurveyInterface } from "../../model/SurveyInterface";
import { useSurveyGridModel } from "../../services/lang/useSurveyGridModel";

const TaskInfo = ({item}: {item: SurveyInterface & InfoSurveyInterface}) => {
    const surveyGridModel = useSurveyGridModel()

    return (
        <List>
            <ListItem>
                <Grid container justifyContent="space-between">
                    <Grid
                        md={4}
                        style={{
                            borderRight: "1px solid #DedeDe",
                            boxSizing: "border-box",
                        }}
                    >
                        <ListItemText
                            primary={item?.route}
                            secondary={getGridModelValue(
                                "outOfRoute",
                                item?.outOfRoute,
                                surveyGridModel
                            )}
                        />
                    </Grid>
                    <Grid
                        container
                        md={8}
                        alignContent="flex-start"
                        style={{
                            padding: "0 0 0 16px",
                            boxSizing: "border-box",
                        }}
                    >
                        <Grid md={12}>
                            <FlexContainer
                                width="100%"
                                justify={"space-between"}
                                align="center"
                            >
                                <FlexContainer
                                    width="100%"
                                    justify={"space-between"}
                                    background={surveyTaskColor(item?.taskType)}
                                    color="#FFF"
                                    padding="0 16px"
                                >
                                    <Typography variant="h6">
                                        {item?.taskType}
                                    </Typography>
                                    <Typography variant={"h6"}>
                                        Id: {item?.id}
                                    </Typography>
                                </FlexContainer>
                            </FlexContainer>
                        </Grid>
                    </Grid>
                </Grid>
            </ListItem>
        </List>
    );
};

export default TaskInfo;
