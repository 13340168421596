import React from 'react';
import DefaultFormLayout from '../../components/form/DefaultFormLayout';
import FieldSelector from '../../shared/form/components/FieldSelector';
import { useCreateUpdate } from '../../shared/form/crud/hooks/useCreateUpdate';
import { CUEntityFormPropsType, uiFormInterface } from '../../shared/form/crud/types/crudTypes';
import { CrudFormType } from '../../shared/form/models/FormTypes';
import { useLangLabels } from '../../shared/lang/services/useLangLabels';
import { useSessionContext } from '../session/store/sessionContext';
import { CriticalityInterface } from './models/criticalityInterface';

import { CreateCriticality, criticalityCRUDDependencies, DeleteCriticality, UpdateCriticality } from './services/service.criticality';
import { useCriticalityForm } from './services/useCriticalityForm';




type props= CUEntityFormPropsType<CriticalityInterface>


const CUCriticalityForm:React.FC<props> = ({actionType, item}) => {

    const {tagFP} = useSessionContext()
    //DB 
    const  { action, resetStatus,status, error, message } = useCreateUpdate({
        create: CreateCriticality,
        update: UpdateCriticality,
        delete: DeleteCriticality
    },actionType, criticalityCRUDDependencies)

    //UI
    const { lang } = useLangLabels()
    
    const { uiModel, handleSubmit, reset } = useCriticalityForm(tagFP,item)
    const ui = (() => {
        const formName = lang.criticalities.title.singular
        switch (actionType) {
            case CrudFormType.create:
                const create:uiFormInterface = {
                    icon:'add',
                    title:`${lang.actionTypes.create} ${formName}`,
                    variant:'button',
                    mode:'modal'
                }
                return create

            case CrudFormType.update:
                const update:uiFormInterface = {
                    icon:'edit',
                    title:`${lang.actionTypes.update} ${formName}`,
                    variant:'iconButton',
                    mode:'content'
                }
                return update
        }
    })()
   
  
    //Methods
    const submit = (data:CriticalityInterface) => {
        action(data)
    }

    const handleReset = () => {
        resetStatus()
        reset()
    }


    return(
        <DefaultFormLayout
        mode={ui.mode}
        icon={ui.icon}    
        variant={ui.variant}
        buttonTitle={ui.title}
        popoverTitle={ui.title}
        {...{status,error,message}} 
        onChange={handleSubmit(submit)}  
        onClose={handleReset} 
        >
            {uiModel.map((props,index)=>
                <FieldSelector key={props.name} fieldProps={props} />
            )}
        </DefaultFormLayout>
    )

}

export default React.memo( CUCriticalityForm)
