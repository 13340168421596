import { Alert } from "@material-ui/lab"
import React, { useState } from "react"
import CustomButton from "../../../components/buttons/CustomButton"
import ModalButton from "../../../components/buttons/ModalButton"
import FlexContainer from "../../../components/containers/FlexContainer"
import TgdSelectableTable from "../../../components/grids/TgdSelectableTable"
import { capitalize } from "../../../shared/globals/utils/capitalize"
import { useLubricationPointsGridModel } from "../../lubricationPoints/lang/useLubricationPointsGridModel"
import { useEquipmentsByTagFP } from "../../lubricationPoints/services/lubricationPointsAdapters"
import { useSuppliesGridModel } from "../lang/useSuppliesGridModel"
import { EnumSupplyTypes } from "../model/SupplyInterface"
import { useAssignMultipleSuppliesToMultipleEquipments, useSuppliesByType } from "../services/service.supplies"



const AssignCancelMultipleSupplies:React.FC<{type:EnumSupplyTypes}> = ({type}) => {

    const [step,setStep] = useState(1)
    const {data:equipments} = useEquipmentsByTagFP()
    const {data:supplies} = useSuppliesByType(type)
    const [selectedEquipments, setSelectedEquipments ] = useState([])
    const [selectedSupplies, setSelectedSupplies ] = useState([])

    const { handleAssigCancel,status,reset } = useAssignMultipleSuppliesToMultipleEquipments()

    /* const equipmentColumns:GridColumns = [
        {headerName:'Tag TGD',field:'tagTGD',width:170},
        {headerName:'Sector',field:'sector',width:170},
        {headerName:'Tag Planta',field:'plantTag',width:170},
        {headerName:'Equipo',field:'equipment',width:170},
        {headerName:'Tipo',field:'type',width:170},
        {headerName:'Criticidad',field:'criticality',width:170},
    ]
    
    const suppliesColumns:GridColumns = [
        {headerName:'ID',field:'id'},
        {headerName:'Herramienta',field:'supply'},
        {headerName:'Descripción',field:'description',width:300},
        {headerName:'Tipo',field:'type',width:300},
    ] */
    
    const handleAssign = () =>{
        handleAssigCancel(selectedEquipments,selectedSupplies,'A')
    }
    
  const handleReset = () => {
    reset()
    setSelectedEquipments([])
    setSelectedSupplies([])
    setStep(1)
  }

    const handleClose = () => {
        handleReset()
    }

    const title = (()=>{
        switch(type){
            case EnumSupplyTypes.consumable: return 'consumibles'
            case EnumSupplyTypes.tool:return 'herramientas'
            case EnumSupplyTypes.protection:return 'protecciones'
        }
    })()

    const equipmentGridModel = useLubricationPointsGridModel()
    const supplyGridModel = useSuppliesGridModel(type)

    return(
        <ModalButton icon='addList' title={`Asignar ${title} a equipos`} onClick={reset} onClose={handleClose}>
            {status === 'error' && <><Alert severity='error'>Ha ocurrido un error</Alert></>}
            {status === 'success' && <><Alert>Asignaciones realizadas</Alert></>}
            {step === 1 &&
                <TgdSelectableTable
                    title='Seleccionar equipos a asignar'
                    items={equipments}
                    gridModel={equipmentGridModel} 
                    headerActions={
                        <CustomButton icon='next' popoverTitle='Próximo paso' {...(selectedEquipments?.length > 0) ? {disabled:false} : {disabled:true}} action={()=>setStep(2)} />
                    }
                    handleChange={(items)=>setSelectedEquipments(items)}
                /> 
            }
            
            {step === 2 && 
                <TgdSelectableTable
                    title={`${capitalize(title)} a asignar`}
                    items={supplies} 
                    gridModel={supplyGridModel}
                    headerActions={<FlexContainer>
                        <CustomButton icon='before' popoverTitle='Atras(resetear)' action={handleReset}/>
                        <CustomButton icon='save' popoverTitle='Guardar asiganciones' status={status} {...(selectedSupplies?.length > 0) ? {disabled:false} : {disabled:true}} action={handleAssign} />
                    </FlexContainer>}
                    handleChange={(items)=>setSelectedSupplies(items)}
                />
            }
        </ModalButton>  

    )
}
export default React.memo(AssignCancelMultipleSupplies)