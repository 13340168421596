import { useEffect, useMemo, useState } from "react";
import { flattenObjAllLevels } from "../../../shared/globals/utils/dataFiltering";
import { GridModelInterface } from "../../../shared/gridModel/gridModelEntity";
import useBasicLocalStorage from "../../../shared/localStorage/useBasicLocalStorage";


interface hiddenColumn {
  [key: string]: boolean;
}

export const useFilterColumns = (
  itemColumnModel: { [key: string]: any },
  gridModel: GridModelInterface,
  entityName?: string
) => {
  const storeValue = entityName ? true : false
  const [hiddenColumns, setHiddenColumns] = useBasicLocalStorage<hiddenColumn>(`${entityName?.toLowerCase()}-column-filters`, {}, storeValue)
  const [allHidden, setAllHidden] = useState(false);
  
  const flattenColumnObject = useMemo(() => {
    return flattenObjAllLevels(itemColumnModel)
  }, [itemColumnModel]);

  const ColumnKeys = useMemo(() => { 
    return Object.keys(flattenColumnObject)
  }, [flattenColumnObject]);

  const visibleColumns = useMemo(() => {
    return ColumnKeys?.filter((i) => {
      // Filter columns
      return hiddenColumns[i] ? false : true;
    });
  }, [ColumnKeys, hiddenColumns]);

  // Keys not mutables
  const keys = useMemo(() => {
    const result = ColumnKeys.filter((key) => {
      return gridModel ? gridModel[key] : true;
    });

    return result;
  }, [ColumnKeys, gridModel]);


  const handleHiddenColumn = (key: string) => {
    setHiddenColumns((old) => {
      return {
        ...old,
        [key]: !old[key],
      };
    });
  };

  const handleActivateDesactivateAllColumns = () => {
    setHiddenColumns((old) => {
      const updatedHiddenColumns = { ...old };
      keys.forEach((key: string) => {
        updatedHiddenColumns[key] = !allHidden;
      });
      setAllHidden(!allHidden);
      return updatedHiddenColumns;
    });
  };

  return {
    hiddenColumns,
    visibleColumns,
    handleHiddenColumn,
    handleActivateDesactivateAllColumns,
    labels: keys,
  };
};
