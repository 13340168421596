
import { InputComponentTypes } from '../../../shared/form/models/FormTypes';
import { useForm } from '../../../shared/form/useForm';
import { useLangLabels } from '../../../shared/lang/services/useLangLabels';
import { ElementInterface } from '../models/ElementInterface';

export const useElementForm = (tagFP:string, item?:ElementInterface) =>{
    const {lang} = useLangLabels()
    return useForm<ElementInterface>({fields:{
        id:{
            label:lang.globalentityValues.id,
            placeholder:lang.globalentityValues.id,
            value:item?.id || 0,
            component:InputComponentTypes.none,
        },
        element:{
            label:lang.elements.form.label.element,
            placeholder:lang.elements.form.label.element,
            value:item?.element || '',
            component:InputComponentTypes.text,
            validations:{
                required:{value:true,message:lang.validations.messages.required},
                min:{value:2,message:lang.validations.messages.minLength},
                max:{value:50,message:lang.validations.messages.maxLength},
            }
        },
        code:{
            label:lang.elements.form.label.code,
            placeholder:lang.elements.form.label.code,
            value:item?.code || '',
            component:InputComponentTypes.none,
            validations:{
                required:{value:item ? true : false,message:lang.validations.messages.required},
            }
        },
        tagFP:{
            label:lang.globalentityValues.tagFP,
            placeholder:lang.globalentityValues.tagFP,
            value:tagFP || 0,
            component:InputComponentTypes.none,
        },
    }})
}