import React, { CSSProperties } from 'react';
import CustomButton, { ButtonColorType, ButtonVariant, CustomIconType } from '../buttons/CustomButton';
import ModalButton from '../buttons/ModalButton';
import Drawer from '../containers/drawer/Drawer';
import { cssDrawerAtributesInterface, DrawerPropsAnchordTypes } from '../containers/drawer/types/TgdDrawerTypes';
import FlexContainer from '../containers/FlexContainer';
import { useModal } from '../containers/modal/hooks/useModal';
import Modal from '../containers/modal/Modal';
import PageContainer from '../containers/PageContainer';
import CarrousellToolbar from '../containers/toolbar/CarrousellToolbar';
import { useCarrousell } from '../containers/toolbar/hooks/useCarrousell';
import { usePopoverEvent } from '../popovers/hooks/usePopoverEvent';
import TgdPopover from '../popovers/TgdPopover';
import { ComponentUIModes } from './componentModesInterfaces';


 
type ComponentModeInterface = {
    children?:React.ReactNode,
    mode?:ComponentUIModes,
    items?:any[] 
    icon?:CustomIconType, 
    title?:string, 
    buttonTittle?:string
    variant?:ButtonVariant
    popoverTitle?:string
    buttonStyle?:CSSProperties
    buttonColor?:ButtonColorType
    disabled?:boolean,
    fullwidth?:boolean
    onOpen?:()=>void
    onClose?:()=>void
    open?:boolean
    width?:string,
    modalTitle?:boolean
    drawerProps?:{
        styles?: cssDrawerAtributesInterface
        anchor?:DrawerPropsAnchordTypes,
        open?:boolean,
        onClose?:()=>void,
        disableEscapeKeyDown?:boolean
    }
}

const ComponentMode:React.FC<ComponentModeInterface> = ({ items, modalTitle,onOpen, disabled, buttonColor, buttonTittle, buttonStyle, children, mode, icon, title, variant, onClose, popoverTitle, fullwidth, width, drawerProps}) => {
    
    const componentMode = mode || 'modal'
    const {handleClosePopover,setPopoverTarget,currentTarget,id} = usePopoverEvent()
    const modalProps = useModal()
    
    return(<>

        {componentMode === 'content' && 
            <PageContainer>
                <FlexContainer flexDirection='column'>
                    {children}
                </FlexContainer>
            </PageContainer>
        }

        {componentMode === 'popover' && <>
            <TgdPopover mode='hover' title={title || popoverTitle || ''} width={width} >
                <CustomButton
                title={title || ''}
                color={buttonColor}
                styleProps={{...buttonStyle}}
                icon={icon}
                variant={variant}
                aria-describedby={id} 
                action={(e)=>setPopoverTarget(e)}
                />
            </TgdPopover>
            <TgdPopover 
            width={width}
            handleClose={handleClosePopover}
            currentTargetEvent={currentTarget} 
            title={title || ''}
            children={children}
            typography={{
                variant:'h6'
            }}/>
        </>}

        {componentMode === 'modal' &&
        <ModalButton modalTitle={modalTitle} onClose={onClose} fullwidth={fullwidth} disabled={disabled || false} icon={icon || 'add'} buttonText={buttonTittle} title={title} popoverTitle={popoverTitle}  variant={variant} color={buttonColor} >
            <FlexContainer flexDirection='column'>
                {children}
            </FlexContainer>
        </ModalButton>}

        {componentMode === 'drawer' && <>
                <Drawer
                    {...{
                        ...drawerProps,
                        icon,
                        popoverTitle,
                        variant,
                        color:buttonColor,
                    }}
                >
                    {children}
                </Drawer>
            </>
        }

        {componentMode === 'carrouselModal' &&
            <Modal {...{
                ...modalProps,
                onOpen,
                icon,
                popoverTitle,
            }}>
                {{children}}
            </Modal>
        }
        
    </>)
}

export default React.memo(ComponentMode)