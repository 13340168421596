import { useCallback } from "react"
import theme from "../../../../assets/theme"
import { objectMapper } from "../../../../shared/globals/utils/objectMapper"
import { useLocalStorage } from "../../../../shared/localStorage/useLocalStorage"
import { LubricationPointInterface } from "../../../lubricationPoints/model/lubricationPointsInterface"
import { useSessionContext } from "../../../session/store/sessionContext"
import { useLubricatorStore } from "../../../users/lubricator/lubricatorStore"
import { SurveyTasksValues, TaskType } from "../../tasks/model/TaskInterface"



type SurveyTasks = {
    type:TaskType,
    color:string
}
interface SurveyStateInterface {
    modal:boolean
    tasks:SurveyTasks[],
    backlogStep:number
    currentPoint:LubricationPointInterface | undefined,
    dailyPoints:any[]
}


export const surveyTasks:SurveyTasks[] = [
    {
        type:SurveyTasksValues.intervention,
        color:theme.palette.secondary.main
    },
    {
        type:SurveyTasksValues.inspection,
        color:theme.palette.secondary.main
    },
    {
        type:SurveyTasksValues.lubrication,
        color:theme.palette.primary.main
    },
    {
        type:SurveyTasksValues.cleaning,
        color:theme.palette.info.light
    },
    {
        type:SurveyTasksValues.repairment,
        color:theme.palette.info.main
    },
    {
        type:SurveyTasksValues.puntuation,
        color:theme.palette.success.main
    }
]

export const useSurveyController = () => {

/*     const dailyPendingPoints = usePendingPoints()
    const lasDelayedPendingPoints = useDailyPreviousPendingPointsWithInfo()
    const totalDailyPoints = 
        (dailyPendingPoints && lasDelayedPendingPoints)
            ? [...dailyPendingPoints, ...lasDelayedPendingPoints] 
            : undefined

            */
           
    /* const {state:{allDayPendingPoints,allDayPoints}} = useLubricatorStoreContext() */
    const {data} = useLubricatorStore()
    
    //config supervisor current lubricator data or default behavior for lubricators
    const {selectedOperator,user} = useSessionContext()
    
    const totalDailyPointsTagsTGD = data?.allDayPoints?.map((item)=>item.tagTGD) 
    const todayDailyPointsMap = objectMapper(data?.allDayPoints,'tagTGD')

    const defaultUserEmail = user?.type === 'S' ? selectedOperator?.email  : undefined
    const [ survey, setSurvey ] = useLocalStorage<SurveyStateInterface>(`surveystate`,{
            modal:false,
            tasks:surveyTasks,
            backlogStep:0,
            currentPoint:data?.allDayPendingPoints && data?.allDayPendingPoints[0] ,
            dailyPoints:totalDailyPointsTagsTGD || []
    },{
        enabled:(!!user?.email && !!data?.allDayPendingPoints && !!data?.allDayPoints && !!totalDailyPointsTagsTGD),
        unique:true,
        defaultUserEmail
    })


    const setBacklogStep = useCallback((value:number) => {
        setSurvey({
            ...survey,
            dailyPoints:value
        })
    },[survey,setSurvey])

    const nextPrevBacklogStep = useCallback((action:'PREV' | 'NEXT')=>{

        const backlogStep = survey 
            ?(action ==='NEXT')
                ? survey.backlogStep + 1
                : survey.backlogStep - 1
            :0

        const currentPointTagTGD = survey?.dailyPoints[backlogStep]
        const currentPoint = todayDailyPointsMap ? todayDailyPointsMap[currentPointTagTGD] : undefined
        
        setSurvey({
            ...survey,
            backlogStep,
            currentPoint
        })
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[survey,setBacklogStep])

    
    const finishEquipment = () => {
        const currentPoint = survey?.currentPoint?.tagTGD
        /* console.log(currentPoint) */

        const newLubricationPointsBacklog = survey?.dailyPoints.filter((point)=>point !== currentPoint) || []
        const backlogStep = survey?.backlogStep || 0

        const newSurvey = {
            ...survey,
            currentPoint:todayDailyPointsMap[newLubricationPointsBacklog[backlogStep]],
            dailyPoints:newLubricationPointsBacklog
        }

        setSurvey({
            ...newSurvey,
        })

    }

    const handleModal = () => {
        setSurvey({
            ...survey,
            modal:!survey?.modal
        })
    }




    return{
        surveyState:survey,
        nextPrevBacklogStep,
        finishEquipment,
        handleModal,
        dailyPoints:data?.allDayPendingPoints,
        modal:!!survey?.modal,
        surveyTasks
    }

}

