import { CSSProperties } from "@material-ui/core/styles/withStyles"
import theme from "../../../../assets/theme"

export const surveyTaskColor = (taskType?: string): CSSProperties['background'] => {
  switch (taskType) {
      case 'LUBRICACION': 
          return theme.palette.primary.main

      case 'LIMPIEZA': 
          return theme.palette.info.light

      case 'REPARACION': 
          return theme.palette.info.main

      case 'CONTROL': 
          return theme.palette.success.main

      default: 
          return ''
  }
}