import { Checkbox, createStyles, Grid, ListItem, ListItemIcon, makeStyles, TextField, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useState } from "react";
import { useForm } from "react-hook-form";
import CustomButton, { CustomIcon } from "../../../components/buttons/CustomButton";
import OnlineOfflineMode from "../../../shared/config/offline Mode/OnlineOfflineMode";
import SelectCompanyService from "../../cluster-companies/selectCompanyService";
import { useToken } from "../../session/store/sessionStore";
import { PersonInterface } from "../models/PersonInterface";
import { useUpdatePerson } from "../services/personServices";
import AssignOperatorNumber from "./AssignOperatorNumber";



const useStyles =  makeStyles((theme) => createStyles({
    formGrid:{
        display:'flex',
        flexDirection:'column'
    },
    input: {
        width:'300px,'
    },
}));
interface Props{
    person:PersonInterface
    tagFP?:string
}



const UpdatePersonForm:React.FC<Props> = ({person,tagFP}) =>{

    const token = useToken()
    const classes = useStyles()
    const {mutate,status:PasswordStatus} = useUpdatePerson()
    const {register,watch,errors,handleSubmit} = useForm()
    const [showPassword,setShowPassword] = useState(true)

    const submit = () => {
        mutate({
            data:{
                email:person.email,
                password:watch('password')
            },
            token:token!
        })
    }

    return(
    <OnlineOfflineMode>
        <div style={{padding:'24px',marginBottom:'16px', border:'solid 1px #d3d3d3', /* width: '100%' */}}>
            {(PasswordStatus === 'success') ? <><Alert severity="success">Usuario Actualizado Correctamente</Alert><br/></> : null}
        
            <br/>
            <Grid container wrap={'wrap'} spacing={3} style={{width:'100%'}}>

                <Grid item md={6}>
                    <ListItem>
                        <ListItemIcon>
                            <CustomIcon icon='person'/>
                        </ListItemIcon>
                        <Typography >{person && person.name}</Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CustomIcon icon='email' />
                        </ListItemIcon>
                        <Typography variant={'button'}>{person && person.email}</Typography>
                    </ListItem>
                    <br/>
                    <br/>
                    {tagFP && person.role === 'Operario' && <AssignOperatorNumber person={person} tagFP={tagFP}/>}
                    <SelectCompanyService person={person}/>
                </Grid>

                <Grid item md={6}>
                    <ListItem>
                        <form   onSubmit={handleSubmit(submit)}>
                                <TextField
                                    type={showPassword ? 'password' : 'text'}
                                    inputRef={register({
                                        required:{value:true,message:'Requerido'},
                                        min:{value:6, message:'Por lo menos 6 carácteres'}
                                    })}
                                    className={classes.input}
                                    variant='outlined'
                                    size='small'
                                    name="password"
                                    onFocus={()=>sessionStorage.setItem('passwordConfirmation',watch('password'))}
                                    label={errors?.password?.message ||"Nueva Contraseña"}
                                    color={errors?.password ? 'primary': 'secondary'}
                                />
                                <br/>
                                <br/>
                                <TextField
                                    type={showPassword ? 'password' : 'text'}
                                    inputRef={register({
                                        required:{value:true,message:'Requerido'},
                                        validate:{
                                            equal:()=>(watch('newPassword') === watch('password'))
                                        }
                                    })}
                                    className={classes.input}
                                    variant='outlined'
                                    size='small'
                                    label={errors?.newPassword ? 'No coinciden' : 'Repite la Contraseña'}
                                    color={errors?.newPassword ? 'primary': 'secondary'}
                                    name="newPassword"
                                />
                                <br/>
                                <br/>
                                <Checkbox
                                    color='secondary'
                                    checked={showPassword}
                                    onChange={()=>setShowPassword(!showPassword)}
                                />

                                <CustomButton status={PasswordStatus} type='submit'  color='secondary'> Guardar </CustomButton>
                        </form>
                    </ListItem>
                </Grid>
                
            
            </Grid>
        </div>
    </OnlineOfflineMode>
    )
}

export default UpdatePersonForm
