import React from 'react';
import { TextField } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { Alert } from '@material-ui/lab';
import { useEffect } from 'react';
import CustomButton, { ButtonVariant } from '../../../../components/buttons/CustomButton';
import { useStatusProcessor } from '../../../../shared/queries/StatusProcessor';
import { useClickPosition } from '../../../../shared/globals/hooks/useClickPosition';
import { equipmentType, LubricationPointInterface } from '../../model/lubricationPointsInterface';
import { useUpdateLubricationPointState } from '../../services/lubricationPointsAdapters';
import ModalButton from '../../../../components/buttons/ModalButton';
import FlexContainer from '../../../../components/containers/FlexContainer';
import { useSnackbar } from '../../../../components/snackbar/hooks/useSnackbar';



interface Props{
    item:LubricationPointInterface
    tagTGD:string
    type:equipmentType
    variant?:ButtonVariant
}
const DesactivateActivateEquipment:React.FC<Props>= ({item,tagTGD,type,variant}) => {

    const { activateLubricationPoint, desactivateLubricationPoint, queryData} = useUpdateLubricationPointState()
    const {status:queryStatus,message,error} = useStatusProcessor(queryData)
    const {position,getClickPosition} = useClickPosition()

    const {setData} = useSnackbar()
    const {register,handleSubmit,errors}= useForm()

    const title =` ${type==='component' ? 'componente' :''} ${type==='equipment' ? 'equipo':''} ${type==='element' ? 'elemento':''}`


    const handleActivate = (e:any) => {
        if(item?.tagTGD){
            getClickPosition(e,()=>{
                if(type === 'component'){
                    activateLubricationPoint({lowReason:'',tagTGD:item.tagTGD})
                }else{
                     if(window.confirm('estás activando todos los puntos de lubricacion que este equipo pueda tener desactivados, ¿estás seguro?')){
                        activateLubricationPoint({lowReason:'',tagTGD:item.tagTGD})
                     }
                }
            })
        }
    }
    
    const equipmentType = () => {
        switch (type) {
            case 'equipment':
                return 'Usted está dando de baja un equipo por lo tanto todos sus elementos y puntos de lubricación tambien se darán de baja'
            case 'element':
                return 'Usted está dando de baja un elemento por lo tanto todos sus puntos de lubricación tambien se darán de baja'
            case 'component':
                return 'Usted está dando de baja este componente ¿está seguro de ello?'
        }
    }

    const submit = (form:{lowReason:string})=> {
        const info = equipmentType()
        const alert = window.confirm(`${info}`)
        if(alert){
            desactivateLubricationPoint({
                tagFP:item.tagFP,
                tagTGD,
                lowReason:form.lowReason
            })
        }
    }    


    useEffect(()=>{
        if(position.positionX !=='' || position.positionY !== ''){

            if(queryData.status === 'error'){
                setData({ 
                    modal:true, 
                    errors:`hubo un problema al intentar activar el ${title}`, 
                    severity:'error', 
                    position: position
                })
            }
            if(queryData.status === 'success'){
                /* console.log(queryStatus,message) */
                setData({ 
                    modal:true, 
                    errors:message, 
                    severity: 'success' , 
                    position: position
                })
            }
        }
    },[queryData.status,queryStatus,message])



    const activate = <CustomButton
        status={queryStatus}
        popoverTitle={`Activar Nuevamente este ${title}`}
        icon={'power'} 
        variant={variant || 'iconButton'}
        action={(e)=>handleActivate(e)} 
    />

    const desactivate = 
    <ModalButton 
        icon='off' 
        popoverTitle={`Desactivar ${title}`} 
        variant={variant || 'iconButton'}
        title={`Dar de baja el ${title}`}  
    >
        {queryData.status === 'error' && <Alert severity='error'>Ha ocurrido un error</Alert>}
        {queryData.status=== 'success' && <Alert severity='success'>Se ha dado de baja el equipo</Alert>}
       
        <FlexContainer flexDirection='column'>
            <br/>
            <TextField 
            inputRef={register({
                required:{value:true,message:'Campo obligatorio'}
            })}
            name='lowReason'
            placeholder='Motivo de baja' 
            defaultValue={item?.lowReason}
            variant='outlined'
            label={errors?.lowReason?.message || 'Motivo de baja'} 
            color={errors?.lowReason?.message  ? 'primary' : 'secondary'}
            size="small"
            /> 
            <CustomButton status={queryData.status} action={handleSubmit(submit)}> Dar la baja </CustomButton>
        </FlexContainer> 

    </ModalButton>


    return (<>
            {type === 'component'
                ? item.state !== true
                    ? activate
                    : desactivate
                :<>
                    {activate}
                    {desactivate}
                </>
            }
        </>
    );
}

export default React.memo(DesactivateActivateEquipment);
