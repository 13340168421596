import { usePagination, PaginationOptions } from "../../hooks/usePagination";
import CustomButton from "../../../../components/buttons/CustomButton";
import FlexContainer from "../../../../components/containers/FlexContainer";
import { MenuItem, Select, TextField, Typography } from "@material-ui/core";

interface PaginationProps {
    paginationOptions: PaginationOptions,
    totalItems: number,
    selectedData: any
}

const Pagination = ({ paginationOptions, totalItems: count, selectedData }: PaginationProps) => {
    const { 
        pageIndex, 
        totalItems,
        pageSize,
        totalPages, 
        canPreviousPage, 
        canNextPage, 
        previousPage, 
        nextPage, 
        handlePageIndexChange,
        handlePageSizeChange
    } = usePagination(paginationOptions, count);

    return (
        <>
            { 
            totalPages 
                ? 
                <FlexContainer flexWrap="wrap" gap="48px">
                    <FlexContainer align="center">
                        Rows per page: {"  "}
                        <Select
                            defaultValue={pageSize}
                            value={pageSize}
                            onChange={(e) => handlePageSizeChange(e.target.value as number)}
                            color="secondary"
                            label="Per page"
                            style={{
                                width: "64px"
                            }}
                        >
                            <MenuItem value={200}>200</MenuItem>
                            <MenuItem value={500}>500</MenuItem>
                            <MenuItem value={1000}>1000</MenuItem>
                            <MenuItem value={2000}>2000</MenuItem>
                        </Select>
                    </FlexContainer>
                    <FlexContainer align="center">
                        { `${selectedData?.length}-${pageSize + " "} of ${" " + totalItems}` }
                    </FlexContainer>
                    <FlexContainer align="center">
                        <Typography variant="body1"> Page </Typography>
                        <TextField
                            size='small'
                            color="secondary"
                            variant='standard'
                            type="number"
                            value={pageIndex + 1}
                            onChange={(e) => handlePageIndexChange(parseInt(e.target.value) - 1)}
                            style={{
                                width: "56px",
                            }}
                        />
                        <Typography variant="body1"> {`of ${totalPages}`} </Typography>
                    </FlexContainer>
                    <FlexContainer align="center" gap="8px">
                        <CustomButton
                        variant="chip"
                        color="inherit"
                        action={previousPage}
                        disabled={!canPreviousPage}
                        >
                            {"<"}
                        </CustomButton>
                        <CustomButton
                        variant="chip"
                        color="inherit"
                        action={nextPage}
                        disabled={!canNextPage}
                        >
                            {">"}
                        </CustomButton>
                    </FlexContainer>
                </FlexContainer>

                : <></>
            }
        </>
    );
};

export default Pagination;
