import React, {  memo, useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';

import CustomButton from '../../components/buttons/CustomButton';
import { LubricationPointInterface } from '../lubricationPoints/model/lubricationPointsInterface';
import FlexContainer from '../../components/containers/FlexContainer';
import EquipmentInfo from '../infoLubricationPoints/components/EquipmentInfo';
import { useLubricationPointSurveyController } from './services/controllers/useLubricationPointSurveyController';
import TgdModal from '../../components/containers/modal/TgdModal';
import SurveyTasks from './components/SurveyTasks';
import { Alert } from '@material-ui/lab';

interface SurveyHeaderProps {
    children:React.ReactNode
    canNext?:boolean
    canPrev?:boolean
    handleNext?:()=>void
    handlePrev?:()=>void
    currentItem?:LubricationPointInterface
    background:string
    exitButton?:React.ReactNode
}

const SurveyHeader:React.FC<SurveyHeaderProps> = ({children, exitButton, canNext, canPrev, handleNext, handlePrev, currentItem, background}) => {
    return(
        <FlexContainer
        background={background} 
        color='white'
        align='center'
        justify='space-between'
        padding='8px 24px'>
            {exitButton && exitButton}
            {handlePrev && canPrev !== undefined &&
            <CustomButton /* outlined */ 
                variant='iconButton' 
                styleProps={{color:!canPrev ? '#FFFFFF50' :'#fff',borderColor:!canPrev ? '#FFFFFF50': '#FFF'}}  
                disabled={!canPrev} 
                icon='before' action={handlePrev}
            />}
            <FlexContainer width='100%' align='center'>
                <FlexContainer width='100%'  justify='space-between'>
                    {children && children}
                </FlexContainer>
                <EquipmentInfo variant='resume' items={currentItem && [currentItem] }/>
            </FlexContainer>
            {handleNext && canNext !== undefined &&
            <CustomButton /* outlined */ 
                variant='iconButton'  
                styleProps={{color:!canNext ? '#FFFFFF50' :'#fff',borderColor:!canNext ? '#FFFFFF50': '#FFF'}} 
                disabled={!canNext} 
                icon='next' 
                action={handleNext}
                />}
        </FlexContainer>
    )
}

interface Props{
    open:boolean
    handleClose:()=>void
    tasks:any
    currentLubricationPoint?:LubricationPointInterface
/*     prevNextButton?:React.ReactNode | undefined*/
    children?:React.ReactNode
    outOfRoute:boolean
    exitButton?:React.ReactNode
    canNext?:boolean
    canPrev?:boolean
    handleNext?:()=>void
    handlePrev?:()=>void
}

const SurveyModal:React.FC<Props> = ({children, open, handleClose, exitButton, tasks, currentLubricationPoint, outOfRoute, canNext, canPrev, handleNext, handlePrev}) => {
    
    
    const {survey} = useLubricationPointSurveyController(currentLubricationPoint,outOfRoute)
    const background = tasks && survey && tasks[survey.taskStep]?.color
    const currentTask = tasks && survey && tasks[survey.taskStep]?.type
    
    const wasInspectedToday = (()=>{
        const todayDate = new Date().toISOString().split('T')[0]
        const lastInspectionDate = currentLubricationPoint?.info.lastInspectionDate.split('T')[0]
        if(todayDate == lastInspectionDate && currentLubricationPoint?.info.pending === false) return true
        else{
            return false
        }
    })() 
    
    const [wasInspectedTodayState, setAsOutOfRoute] = useState<boolean>(wasInspectedToday)
    
    useEffect(()=>{
        setAsOutOfRoute(wasInspectedToday)
    },[currentLubricationPoint])

    return (
        <TgdModal
            open={open } 
            handleClose={handleClose} 
            fullWidth={true} 
            defaultButton={<></>}
            header={<>
                <SurveyHeader {...{canNext, canPrev, handleNext, handlePrev, currentItem:currentLubricationPoint,background,exitButton}} >
                    <Typography color='inherit' variant='h6'>{currentTask}</Typography>
                </SurveyHeader>
                {wasInspectedTodayState
        
                ?   <FlexContainer  height={'60vh'} justify='center' align={'center'} width='100%' padding='24px'>
                        <Alert severity='warning' icon={
                            <CustomButton 
                                variant='iconButton' 
                                icon='surveyPlay' 
                                color='secondary' 
                                action={()=>setAsOutOfRoute(false)} 
                                popoverTitle='Relevar fuera de ruta' 
                            />
                        }>
                            <FlexContainer justify='center' width='100%'>
                                Este punto ya fue relevado hoy. 
                                <br />
                                ¿Desea relevarlo otra vez fuera de ruta?
                            </FlexContainer>
                        </Alert>
                    </FlexContainer>

                :   <>
                        {outOfRoute &&
                            <FlexContainer
                            /* flexDirection={'column'} */
                            align='center'
                            background={'black'}
                            color='white'
                            padding='4px 24px'
                            height='3vh'
                            >
                                <Typography variant='body2'>(FUERA DE RUTA)</Typography>
                            </FlexContainer>
                        }

                        <SurveyTasks outOfRoute={outOfRoute} lubricationPoint={currentLubricationPoint}/>
                    </> 
                }
        </>}>

        {(survey?.taskStep !== 0 || outOfRoute === false) && ((!wasInspectedToday) || (wasInspectedToday && outOfRoute)) &&
            <FlexContainer height='60vh'>
                {children && children}
            </FlexContainer>
        }
        
        </TgdModal>

    );
}

export default memo(SurveyModal);
