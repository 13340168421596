import React, { useEffect, useState } from 'react';
import { TextField, IconButton, InputAdornment, Typography } from '@material-ui/core';
import TgdPopover from '../../../components/popovers/TgdPopover';
import EventIcon from '@material-ui/icons/Event';
import CustomButton from '../../../components/buttons/CustomButton';
import { useForm } from 'react-hook-form';
import { useNearlyRouteDate, useSetNewScheduleDate } from '../services/service.routes';
import FastForwardOutlinedIcon from '@material-ui/icons/FastForwardOutlined';
import { RouteInterface } from '../services/RoutesInterface';
import theme from '../../../assets/theme';
import { usePopoverEvent } from '../../../components/popovers/hooks/usePopoverEvent';
import { dateOperation } from '../../../shared/globals/utils/utils';



type RouteMode = 'anticipate' | 'reSchedule'

const routeRescheduleRouteProps= (item:RouteInterface) => {

    var color:string | undefined = undefined
    var mode:RouteMode | undefined = undefined
    var popoverTitle:string | undefined = undefined
    switch (item?.state) {
        case 'ATRASADA': 

            mode ='reSchedule'
            color = theme.palette.grey[500]
            popoverTitle = 'Ruta atrasada'
            break;

        case 'PROGRAMADA':
            mode = 'reSchedule'
            color = theme.palette.success.dark
            popoverTitle = 'Ruta Programada'
            break;

        case 'INCUMPLIDA':
            mode = 'reSchedule'
            color = theme.palette.primary.main
            popoverTitle = 'Ruta que el lubricador no realizó'
            break;

        case 'PROGRAMADA INCUMPLIDA':    
            mode = 'reSchedule'
            color = theme.palette.info.main
            popoverTitle = 'Ruta Programada que el lubricador no realizó'
            break;
    
        default:
            mode ='anticipate'
            color =theme.palette.secondary.main 
            popoverTitle ='Adelantar ruta'
            break;
    }

    return {
        color,
        mode,
        popoverTitle
    }
    
}



interface Props {
    item:RouteInterface | any
}

const ReScheduleRoute:React.FC<Props> = ({item}) => {

    const {mode,color,popoverTitle} = routeRescheduleRouteProps(item)
    const {id,currentTarget,setPopoverTarget,handleClosePopover} = usePopoverEvent()
    const { register, errors, handleSubmit, watch, setValue, setError,clearErrors } = useForm()
    const {scheduleRoute,status} = useSetNewScheduleDate()
    const {getNearlyDate} = useNearlyRouteDate()

    const [nextRouteDate, setNextrouteDate] = useState<string>('');
    const route = item.routeName 
    const routePeriodicity = item.periodicity || ''
    
    const today = new Date().toISOString().slice(0,-14)

    useEffect(()=>{
        if(item){
            register({name:'route',value:item.routeName})
            register({name:'tagFP',value:item.tagFP})
            register({name:'scheduledDate',value:item.scheduledDate})
        } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[item])


    const forbiddenRescheduleRange =  Math.round(parseInt(routePeriodicity) * 0.3)
    const pickedDate = watch().newScheduledDate
    const maxDateToSchedule = dateOperation('minus','days',nextRouteDate,forbiddenRescheduleRange)?.toISOString().slice(0,10)
    const [message, setMessage] = useState<string | undefined>(undefined); 

    //Effect on date selection
    useEffect(()=>{
        switch (mode) {
            case 'anticipate' :
                if(currentTarget){
                    getNearlyDate(route.split('-')[2],{
                        onSuccess:(nearlyDate:any)=>{
                            setNextrouteDate(nearlyDate)
                            setValue('scheduledDate',nearlyDate)
                        }
                    })
                }
                break;
            
            case 'reSchedule' :
                if(currentTarget){
                    getNearlyDate(route.split('-')[2],{
                        onSuccess:(date:any)=>{
                            setNextrouteDate(date)
                        }
                    })
                }
                break;
           
        }
    },[currentTarget])


    useEffect(()=>{
        if(maxDateToSchedule && pickedDate){
            /* 
            //antes no podia programar o adelantar rutas para el dia
            //denagate reprogram today
                const todayValidation = pickedDate <= today
                if(today >= pickedDate) setError('newScheduledDate',{message:'Debe ser mayor a hoy'}) 
                if(!(today >= pickedDate)) clearErrors('newScheduledDate') 
            */
            const todayValidation = pickedDate < today
            if(today > pickedDate) setError('newScheduledDate',{message:'No puede ser anterior a hoy'})
            if(!(today >= pickedDate)) clearErrors('newScheduledDate') 


            if(!(pickedDate < maxDateToSchedule) && !todayValidation ) {

                 //denegate reprogram on nextrouteDate or >
                if((pickedDate >= nextRouteDate)) {
                    setError('newScheduledDate',{message:'Inválida'})
                }
           
                // anticipate an route instead reprogram - set message to show
                if(pickedDate < nextRouteDate) {
                    clearErrors('newScheduledDate')
                    setValue('scheduledDate',nextRouteDate)
                    setMessage('Muy proxima a ruta - Esta ruta será adelantada')
                }
            }

            if((pickedDate < maxDateToSchedule)) setMessage(undefined)
        }
        /* else clearErrors('newScheduledDate') */
    },[today,nextRouteDate,pickedDate,maxDateToSchedule])


    const submit = (data:any) => {
        scheduleRoute(data)
    }

    const selectDate = 
    <TextField 
    type='date'
    color= {errors?.newScheduledDate?.message ? 'primary' :'secondary'}
    label= {errors?.newScheduledDate?.message || 'fecha'}
    name='newScheduledDate'
    inputRef={register({
        required:{value:true,message:'Requiere una fecha'},
    })}
    InputProps={{
        startAdornment:(
            <InputAdornment position="start">
                <EventIcon color='secondary'/>
            </InputAdornment>
        ),
    }}/>




    if(item.periodicity === '1')  return null
    return (<>
    <TgdPopover  mode='hover' title={popoverTitle || 'Ruta Atrasada '}>
        <IconButton size='small' onClick={(e)=>setPopoverTarget(e)} color='primary' >
            {(mode && (mode === 'anticipate'))
            ?   <FastForwardOutlinedIcon {...(color ? {style:{color:color}} : null)} aria-describedby={id}/>
            :   <EventIcon {...(color ? {style:{color:color}} : null)} aria-describedby={id} />
            }
        </IconButton>
    </TgdPopover>

    <TgdPopover 
        handleClose={handleClosePopover}
        currentTargetEvent={currentTarget} 
        title={`${mode && mode === 'anticipate' ? 'Adelantar' : 'Reprogramar'} ruta ${route}`}
        typography={{
            variant:'h6'
            }}
        >
            <br/>
            <>
                <Typography variant='body1' color='secondary'>Esta ruta caerá: {nextRouteDate}</Typography>
                {message && <Typography variant='body1' color='primary'>{message}</Typography>}
                <br/>
                {selectDate}
            </>
            <br/>
            <br/>

        <CustomButton 
            disabled = {errors?.newScheduledDate}
            action={handleSubmit(submit)} 
            icon='calendar' 
            status={status} >
              {status !== 'success' && status !== 'loading' && status !== 'error' && 'programar'}
        </CustomButton>
    </TgdPopover>
    </>);
}

export default React.memo(ReScheduleRoute);
