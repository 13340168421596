import React, { useMemo } from 'react';
import { IconButton, Paper } from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RouterTabs, { TabsInterface, useRouteTabProps } from '../../../components/containers/tabs/RouterTabs';
import { USER_ROUTES } from '../../../shared/routes/routes';
import FlexContainer from '../../../components/containers/FlexContainer';
import LubricationPointsList from '../../lubricationPoints/components/operator/LubricationPointsList';
import LubricationPointSurvey from '../components/LubricationPointSurvey';
import EquipmentInfo from '../../infoLubricationPoints/components/EquipmentInfo';
import ColectorList from '../ColectorList';
import VirtualGrid from '../../../shared/react-table/VirtualGrid';
import LeakLevelComponent from '../../infoLubricationPoints/components/LeakLevelComponent';
import { useLubricatorStore } from '../../users/lubricator/lubricatorStore';
import { useLubricationPointsGridModel } from '../../lubricationPoints/lang/useLubricationPointsGridModel';
import { CustomIcon } from '../../../components/buttons/CustomButton';
import { LubricationPointInterface } from '../../lubricationPoints/model/lubricationPointsInterface';
import theme from '../../../assets/theme';
import { useSessionContext } from '../../session/store/sessionContext';


const CountPendingEquipments:React.FC<{item:any}> = ({item}) => {
    const equipmentsDone = item.lubricationPoints?.filter((point:LubricationPointInterface) => point.info.pending === false)
    
    const totalEquipments = (item.lubricationPoints?.length  > 9 
            ? item.lubricationPoints?.length
            : `0${item.lubricationPoints?.length}` 
        ) 
    
    return  <>
        {`${equipmentsDone.length || 0}/${totalEquipments}`}
    </>
}

const ColectorRoutesPage:React.FC<{}> = () => {
    
    const {user} = useSessionContext()
    const {data} = useLubricatorStore()
    const gridModel = useLubricationPointsGridModel()

    const tabsMenuConfig:TabsInterface[]= useMemo(()=>[
        {
            label:'Rutas',
            route:``,
            component:'',
            data:{
                items:data?.dailyRoutes,
                primaryText:['routeName'],
                secondaryText:['state'],
                renderOption:(item:any)=><FlexContainer align='center'>
                    <LubricationPointsList type ='modal' item={item}/>
                    <IconButton size={'small'} color={'secondary'}  edge="end" aria-label="Estado del equipo">
                        {item.state !== 'INICIADA' ? <RadioButtonUncheckedIcon/> : <CheckCircleOutlineIcon style={{color:'green'}}/>}
                    </IconButton>
                    <CountPendingEquipments item={item} />
                </FlexContainer>,
                badgeConbtent:undefined,
            }
        },
        {
            route:`${USER_ROUTES.delayedPoints}`,
            label:`Puntos Atrasados`,
            component:'',
            notification:data?.previusPendingLubricationPoints.length,
            data:{
                title:`Puntos Atrasados  ${data?.previusPendingLubricationPoints.filter(point => point.info.pending === false).length}/${data?.previusPendingLubricationPoints.length}`,
                items:data?.previusPendingLubricationPoints,
                primaryText:['tagTGD','component', 'sector','lubricant'],
                secondaryText:['plantTag','element','Sector en planta','lubricantType'],
                badgeContent:data?.previusPendingLubricationPoints?.length || 0,
                bulkActions:(items:LubricationPointInterface[])=><>
                    <LubricationPointSurvey outOfRoute={false} items={items} />
                </>,
                renderOption: (item:any)=><FlexContainer align={'center'}>
                    <EquipmentInfo variant='modal' items={[item]}/>
                    <LeakLevelComponent lastlubricantCuantity={item.info.lubricantQuantity} pointCapacity={item.capacity}/>
                    {item.info?.pending &&  !item.info?.inaccesible && 
                        <CustomIcon icon='circleOutlined' style={{color:theme.palette.secondary.main}} />
                    }
                    {item.info?.pending 
                        ?item.info?.inaccessible && <CustomIcon icon='cancelOutlined' style={{margin:'0px 0px 0px 6px ',color:'red'}}/>
                        :<CustomIcon icon='checkedCircle' style={{margin:'0px 0px 0px 6px ',color:'green'}} />
                    }
                    {/* <IconButton size={'small'} color={'secondary'}  edge="end" aria-label="Estado del equipo">
                    {item.info?.pending ? (item.info?.inaccessible ? <HighlightOffIcon style={{color:'red'}}/> : null ) :  <CheckCircleOutlineIcon  style={{color:'green'}}/> }
                    </IconButton> */}
                </FlexContainer>,
            }
        },
        {
            route:`hoy`,
            label:`Puntos de hoy`,
            component:'',
            notification:data?.dailyPoints.length,
            data:{
                title:`Puntos de hoy  ${data?.dailyPoints.filter(point => point.info.pending === false).length}/${data?.dailyPoints.length}`,
                items:data?.dailyPoints,
                primaryText:['tagTGD','component', 'sector','lubricant'],
                secondaryText:['plantTag','element','Sector en planta','lubricantType'],
                renderOption: (item:any)=><FlexContainer align={'center'}>
                    <EquipmentInfo variant='modal' items={[item]}/>
                    <LeakLevelComponent lastlubricantCuantity={item.info.lubricantQuantity} pointCapacity={item.capacity}/>
                    {item.info?.pending &&  !item.info?.inaccesible && 
                        <CustomIcon icon='circleOutlined' style={{color:theme.palette.secondary.main}} />
                    }
                    {item.info?.pending 
                        ?item.info?.inaccessible && <CustomIcon icon='cancelOutlined' style={{color:'red'}}/>
                        :<CustomIcon icon='checkedCircle' style={{color:'green'}} />
                    }
                    
                </FlexContainer>,
                bulkActions:(items:LubricationPointInterface[])=><>
                    <LubricationPointSurvey outOfRoute={false} items={items} />
                </>,
                badgeContent:undefined

            }
            
        },
        
    ].map((tab,index)=>{
        return{
            ...tab,
            component:<>
                <Paper style={{minHeight:'600px'}}>
                    {tab.route === '' 
                        ?   <ColectorList
                                items={tab.data.items}
                                primaryText={tab.data.primaryText }
                                secondaryText={tab.data.secondaryText}
                                buttonActions={tab.data.renderOption}
                            />

                        :   <VirtualGrid {...{
                            documentExport:user?.type === 'O' ? false : true,
                            documentSendEmail:user?.type === 'O' ? false : true,
                            title:tab.data.title!,
                            items:tab.data.items,
                            gridModel,
                            renderOption:tab.data.renderOption,
                            bulkActions:tab.data.bulkActions
                            //excludeColumns:fieldRestrictionByUser
                        }}/>
                    }
                </Paper>
            </>
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [data])

    const urlDimention = (()=>{
        if(user?.type === 'S')  return 4 
        if(user?.type === 'O')  return 3 
        if(user?.type === 'A')  return 0 
        if(user?.type === 'SA') return 0 
        if(user?.type === 'AC') return 0 
        else return 0
    })()

    return (<>
        <RouterTabs {...useRouteTabProps(urlDimention,tabsMenuConfig)} />
    </> 
   );
}

export default React.memo(ColectorRoutesPage);
