import { useMemo } from "react";
import { GridModelInterface } from "../../../shared/gridModel/gridModelEntity";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";

export const useRoutesGridModel = () => {

    const {lang} = useLangLabels()
    const gridModelRoutes:GridModelInterface = useMemo(()=>({
        /* renderOption: {label:'Acciones'}, */
        id: {label:lang.globalentityValues.id},
        routeName: {label:lang.routes.routeName},
        route: {label: lang.routes.route},
        operator: {label:lang.routes.operator},
        periodicity: {label:lang.routes.periodicity},
        tagFP: {label:lang.globalentityValues.tagFP},
        startDate: {label:lang.routes.startDate},
        state: {label:lang.routes.state},
        scheduledDate: {label:lang.routes.scheduledDate},
        newScheduledDate: {label:lang.routes.newScheduledDate},
        special:{
            label:lang.routes.special,
            options:[
                {name:lang.words.yes.toUpperCase(),value:true},
                {name:lang.words.no.toUpperCase(),value:false},
                {name:lang.words.all.toUpperCase(),value:undefined}
            ]
        }
    }),[lang])

    return gridModelRoutes
}