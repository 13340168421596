import { IconButton } from '@material-ui/core'
import { CheckCircleOutlineOutlined, RadioButtonUnchecked } from '@material-ui/icons'
import React from 'react'
import FlexContainer from '../../components/containers/FlexContainer'
import LubricationPointsList from '../lubricationPoints/components/operator/LubricationPointsList'



const CheckedButton:React.FC<{condition:boolean}> = ({condition}) =>( 
    condition 
    ?   <CheckCircleOutlineOutlined style={{color:'green'}}/> 
    :   <RadioButtonUnchecked/>
)

const CheckeUncheckItem:React.FC<{onClick?:(item:any)=>void,item:any, picked:any[],iterator:string}> = ({ iterator, picked, onClick, item }) => {

    const handleCheck = (item:any) => {
        onClick && onClick(item)
    }

    const title:string = (
        (item.lubricant ? `Puntos de lubricacion que utilizan ${item.lubricant.type} ${item.lubricant.lubricant}` : '') ||
        (item.supply ? `Puntos de lubricacion que utilizan ${item.supply.supply}` : '') ||
        (item.routeName ? `Puntos de lubricacion de la ruta ${item.routeName}` : '') ||
        (item.permission ? `Puntos de lubricacion que utilizan este permiso` : '')
    )
 
    return(
        <FlexContainer align='center'>
            <LubricationPointsList type ='modal' item={item} title={title}/>
            <IconButton onClick={()=>handleCheck(item[iterator].id)} edge="end" aria-label="delete">
                <CheckedButton condition={picked.includes(item[iterator].id)}/>
            </IconButton>
        </FlexContainer>
    )

}

export default React.memo(CheckeUncheckItem)