import { Divider, Typography } from '@material-ui/core'
import React from 'react'
import ModalButton from '../../../../components/buttons/ModalButton'
import { ComponentUIModes } from '../../../../components/componentMode/componentModesInterfaces'
import PageContainer from '../../../../components/containers/PageContainer'
import TgdTabs from '../../../../components/tabs/TgdTabs'
import TgdVerticalTabs from '../../../../components/tabs/TgdVerticalTabs'
import CreateCategoryForm from '../../../../domains/stock/categories/components/CreateCategoryForm'
import UpdateCategoryForm from '../../../../domains/stock/categories/components/UpdateCategoryForm'
import { useCategoriesAndSubcategoriesByTagFP } from '../../../../domains/stock/categories/service.category'
import SubcategoryStockGrid from '../../../../domains/stock/components/SubcategoryStockGrid'
import CreateSubCategoryForm from '../../../../domains/stock/sub-categories/components/CreateSubCategoryForm'
import { useLangLabels } from '../../../../shared/lang/services/useLangLabels'

const configParentCategory = (category:any,mode:ComponentUIModes) => {
        
    const content=<PageContainer>
        <Typography variant='body2'> Editar </Typography>
        <Typography variant='h6'> {category.category} </Typography>
        <Divider/>
        <UpdateCategoryForm defaultValue={category} mode='content'
        />
        <Typography variant='body2'>Agregar</Typography>
        <Typography variant='h6'>Subcategorias</Typography>
        <Divider/>
        <CreateSubCategoryForm
            parentCategory={category.category}
            mode='content'
        />
    </PageContainer>

    return(
        <>

    {mode === 'content' &&
        content
    }
        
    {mode === 'modal'  &&
    <ModalButton icon='config'>
        {content}
    </ModalButton>}
</>
    )
}


const StockCategoriesPage:React.FC<{}> = ({}) => {

    const {data:categories} = useCategoriesAndSubcategoriesByTagFP()


  return (
    <TgdVerticalTabs
        items={categories || []}
        labelIterator='category'
        icon='add'
        popoverTitle='Nueva categoria padre' 
        customTabComponent={<CreateCategoryForm mode='content'/>}
        content={(category) => <>
            {(category.subcategories.length > 0)
            ?   <TgdTabs
                popoverTitle={`Configurar categoria padre`}
                icon='config'
                customTabComponent={ configParentCategory(category,'content') }
                labelIterator='subcategory'
                items={category.subcategories}
                content={(subcategory)=><>
                    <SubcategoryStockGrid subcategory={subcategory} />
                    </>}
                />
            :   configParentCategory(category,'content')        
        }</>}
    />
  )
}

export default StockCategoriesPage
