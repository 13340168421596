import React, { Dispatch, SetStateAction } from "react"
import { ButtonVariant, CustomIconType } from "../../buttons/CustomButton"
import HandleUndefined, { HandleUndefinedProps } from "../handleUndefined/HandleUndefined"
import CarrousellToolbar, { CarrousellProps } from "../toolbar/CarrousellToolbar"
import { useCarrousell } from "../toolbar/hooks/useCarrousell"
import { useModal } from "./hooks/useModal"
import Modal, { ModalProps } from "./Modal"


interface CarrousellModalProps <T=any> {
    items?:any[],
    current:T,
    handleUndefinedProps:HandleUndefinedProps
    carrousellProps:CarrousellProps
    modalProps:ModalProps
    
} 

interface useCarrouselOptions {
    items?:any[],
    icon:CustomIconType,
    variant?:ButtonVariant,
    popoverTitle:string,
    title:string
}


export const useCarrousellModal = <T,>(options:useCarrouselOptions):CarrousellModalProps<T> => {
    const modalProps = useModal()
    const itemCount = options.items?.length || 0
    const carrousellProps = useCarrousell(itemCount)
    const current = options.items && options.items[carrousellProps.current]

    const CarrousellModalProps = {
        ...options,
        current,
        items:options.items,
        handleUndefinedProps:{
            isError:itemCount > 0,
            callback:modalProps.onOpen,
        },
        carrousellProps:{
            ...carrousellProps,
            title:options.title
        },
        modalProps:{
            ...modalProps,
            icon:options.icon,
            variant:options.variant,
            popoverTitle:options.popoverTitle
        }
    }

    return CarrousellModalProps
       
    
}

export const CarrouselModal:React.FC<CarrousellModalProps> = (options) => {
    return(
        <HandleUndefined isError={options.handleUndefinedProps.isError} callback={options.handleUndefinedProps.callback}>
            {(callback) =>
                <Modal {...{
                    ...options.modalProps,
                    onOpen:callback
                }}>
                    <CarrousellToolbar {...options.carrousellProps} />
                    {options.children && options.children}
                </Modal>
            }
        </HandleUndefined>
    )
}



export default CarrouselModal