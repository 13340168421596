import { TextField, Typography } from "@material-ui/core"
import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import theme from "../../../../assets/theme"
import CustomButton from "../../../../components/buttons/CustomButton"
import ComponentMode from "../../../../components/componentMode/ComponentMode"
import { ComponentUIModes } from "../../../../components/componentMode/componentModesInterfaces"
import FlexContainer from "../../../../components/containers/FlexContainer"
import StatusAndMessage from "../../../../shared/queries/errors/components/StatusAndMessage"
import { useStatusProcessor } from "../../../../shared/queries/StatusProcessor"
import { useSessionContext } from "../../../session/store/sessionContext"
import { CategoryInterface } from "../CategoriesIinterface"
import { useCreateCategory } from "../service.category"



interface Props{
    mode:ComponentUIModes
}

const CreateCategoryForm:React.FC<Props> = ({mode}) => {
    
    const {tagFP} = useSessionContext() 
    const {createCategory,reset:ClearStatus, status,message,error} = useCreateCategory()
    const {register,setValue,errors,watch,handleSubmit,reset} = useForm()
    const title='Agregar nueva categoría padre'
    
    useEffect(()=>{
        register({name:'id',type:'number',value:0})
        register({name:'category',value:''},{required:{value:true,message:'Campo requerido'}})
        register({name:'tagFP',value:tagFP})
        register({name:'categoryArea',type:'string',value:'LUBRICACION'})
    })

    useEffect(()=>{
        if(status === 'success'){
            setValue('category','')
        }
    },[status])

    const submit = (item:CategoryInterface) => {
        createCategory(item)
    }
   
    
    return (
        <ComponentMode mode={mode} title={title}>
            <Typography variant='h6'>{mode === 'content' && title}</Typography>
            <StatusAndMessage status={status} message={message} error={error} reset={ClearStatus} />
            <FlexContainer padding={`${theme?.spacing(3)}px 0px`} flexDirection={'column'} width='30%'>
                <TextField
                inputRef={register()}
                name='category'
                size='small'
                variant='outlined'
                label={errors?.category?.message || 'Nombre de la categoria'}
                color={errors?.category?.message ? 'primary' : 'secondary'}
                />
                <CustomButton status={status} action={handleSubmit(submit)}>Crear</CustomButton>
            </FlexContainer>
        </ComponentMode>
    );
}

export default React.memo(CreateCategoryForm);
