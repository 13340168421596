import React from 'react';

import { usePlantSectors } from '../services/service.sectors';

import CUSectorForm from './../CUSectorForm';

import VirtualGrid, { CarrousellActinInterface } from '../../../shared/react-table/VirtualGrid';
import { Plant } from '../../plants/PlantInterface';
import { useLangLabels } from '../../../shared/lang/services/useLangLabels';
import { CrudFormType } from '../../../shared/form/models/FormTypes';
import { useSectorsGridModel } from '../lang/useSectorsGRidModel';
import DeleteSectorFrom from '../DeleteSectorFrom';

interface Props {
    virtualGridWidth:string
}



const ManageSectorPage:React.FC<Props> = ({virtualGridWidth}) => {

    const {lang} = useLangLabels()
    const {data:sectors} = usePlantSectors()

    const carrousellActions:CarrousellActinInterface = [
        {icon:'edit', popoverTitle:lang.crudActions.update, component:(item)=><CUSectorForm  actionType={CrudFormType.update} item={item} /> }
    ]

    return(

        <VirtualGrid
            {...{
                title:lang.sectors.title.plural,
                width:virtualGridWidth,
                items:sectors,
                carrousellComponents:carrousellActions,
                gridModel:useSectorsGridModel(),
                headerActions:(item:any)=> <CUSectorForm actionType={CrudFormType.create}/>
            }}
        >
            {(items)=> items.length === 1
                ? <DeleteSectorFrom item={items[0]} />
                :<></>
            }
        </VirtualGrid>
    )
}

export default ManageSectorPage
