import FlexContainer from "../../../../components/containers/FlexContainer";
import ComponentMode from "../../../../components/componentMode/ComponentMode";
import { getGridModelkey } from "../../../../shared/gridModel/gridModelEntity";
import { useLubricationPointsGridModel } from "../../../lubricationPoints/lang/useLubricationPointsGridModel";
import { Typography } from "@material-ui/core";
import { InfoSurveyInterface, SurveyInterface } from "../../model/SurveyInterface";
import { useLubricationPointByTagTGD } from "../../../lubricationPoints/services/lubricationPointsAdapters";

const LubricationPointInfo = ({ survey }: {survey: SurveyInterface & InfoSurveyInterface}) => {
    const { data: lubricationPoint = {} } = useLubricationPointByTagTGD(survey?.tagTGD)
    const lubricationPointsGridModel = useLubricationPointsGridModel();

    
    return (
        <FlexContainer justify="space-between">
            <FlexContainer align={"center"}>
                <ComponentMode
                    mode="popover"
                    popoverTitle="identificación del equipo"
                    icon="info"
                    variant="iconButton"
                >
                    <FlexContainer> 
                        <strong> {getGridModelkey("id", lubricationPointsGridModel)}: </strong>
                        {" "}
                        {lubricationPoint?.id}
                    </FlexContainer>
                    <FlexContainer> 
                        <strong> {getGridModelkey("tagTGD", lubricationPointsGridModel)}: </strong>
                        {" "}
                        {lubricationPoint?.tagTGD}
                    </FlexContainer>
                    <FlexContainer> 
                        <strong> {getGridModelkey("tagFP", lubricationPointsGridModel)}: </strong>
                        {" "}
                        {lubricationPoint?.tagFP}
                    </FlexContainer>
                    <FlexContainer> 
                        <strong> {getGridModelkey("tagTGDBefore", lubricationPointsGridModel)}: </strong>
                        {" "}
                        {lubricationPoint?.tagTGDBefore}
                    </FlexContainer>
                </ComponentMode>
                <strong>{lubricationPoint?.equipment}</strong> {`>>`}
                <strong>{lubricationPoint?.element}</strong> {`>>`}
                <strong>{lubricationPoint?.component}</strong>
            </FlexContainer>
            <FlexContainer align={"center"}>
                <FlexContainer
                    width="100%"
                    justify={"space-between"}
                    color={survey?.inaccessible ? "red" : "green"}
                    padding="0 16px"
                >
                    <Typography variant="h6">
                            {survey?.inaccessible ? "INACCESIBLE" : "ACCESIBLE"}
                    </Typography>
                </FlexContainer>
            </FlexContainer>
        </FlexContainer>
    );
};

export default LubricationPointInfo;
