import React,{useEffect, useState} from 'react';
import { AppBar, Typography, Toolbar, Stepper, Step, StepLabel, StepContent, Chip } from '@material-ui/core';
import CustomButton, { ButtonVariant, CustomIcon, CustomIconType } from '../../../components/buttons/CustomButton';
import { ComponentUIModes } from '../../../components/componentMode/componentModesInterfaces';
import { useLangLabels } from '../../../shared/lang/services/useLangLabels';
import { FactoryInterface } from '../model/FactoryInterface';
import { Plant } from '../../plants/PlantInterface';
import { PersonInterface } from '../../person/models/PersonInterface';
import OnlineOfflineMode from '../../../shared/config/offline Mode/OnlineOfflineMode';
import ComponentMode from '../../../components/componentMode/ComponentMode';
import { useModal } from '../../../components/containers/modal/hooks/useModal';
import theme from '../../../assets/theme';
import { useCarrousell } from '../../../components/containers/toolbar/hooks/useCarrousell';
import CreateFactoryForm from '../CreateFactoryForm';
import CreatePlantForm from '../../plants/CreatePlantForm';
import AddPersonForm from '../../person/components/AddPersonForm';


interface Props{
    color?:string
    handleFactory?:(item:any)=>void
    mode?:ComponentUIModes
    icon?:CustomIconType,
    variant?:ButtonVariant
}


const useCreateFactorySteps = () =>{

    const {lang} = useLangLabels()

    const [factory,setFactory] = useState<FactoryInterface | undefined>()
    const [plants,setPlant] = useState<Plant[] | undefined >()
    const [clients,setClient] = useState<PersonInterface[] | undefined>()

    const handlePlant = (plant?:Plant) => {
        if(plant){
            plants
            ? setPlant([...plants,plant])
            : setPlant([plant])
        }else{
            setPlant(undefined)
        }
    }

    const handleClient = (client?:PersonInterface) => {
        if(client){
            clients
            ? setClient([...clients,client])
            : setClient([client])
        }else{
            setClient(undefined)
        }
    }

    const steps = [
        {
            id:1,
            label:`${lang.actionTypes.create} ${lang.factories.singular.toLowerCase()}`, 
            component:(props?:any) => <CreateFactoryForm handleFactory={setFactory} />
        },
        {
            id:2,
            label:`${lang.actionTypes.create} ${lang.plants.plural.toLowerCase()}`,
            component:(props?:any) =>factory 
            ? <>
                { plants?.map((p)=>
                    <Chip 
                    style={{
                        marginRight:'12px'
                    }}
                        label={p.name}
                    />
                )}
             
                <CreatePlantForm factory={factory} handlePlant={handlePlant} />
            </>
            : <> </>
        },
        {
            id:3,
            label:`${lang.actionTypes.create} ${lang.persons.clients}`,
            component:(props?:any) =>factory 
            ? <>
                {clients?.map((c)=>
                    <Chip 
                    style={{
                        marginRight:'12px'
                    }}
                        icon = {<CustomIcon icon='user'/>}
                        label={c.email}
                    />
                )}
                
                <AddPersonForm tagF={factory.tagF} role='Cliente' handlePerson={handleClient} /> 
            </>
            :<></>
        },

    ]
    
    const carrousell = useCarrousell(steps.length)
    
    const handleReset = () => {
        carrousell.handleReset()
        setFactory(undefined)
    }

    useEffect(()=>{
        if(factory){
            carrousell.handleNext()
        }
    },[factory])

    
    return {
        steps,
        ...carrousell,
        isFactoryCreated:!!factory?.name,
        isPlantCreated:!!plants,
        isClientCreated:!!clients,
        createdFactory:factory,
        createdPlants:plants,
        createdClients:clients,
        handleReset
    }
}

const CreateFactoryPage:React.FC<Props> = ({ color, handleFactory, mode, icon, variant }) => {
 
    const {lang} = useLangLabels()
    const popoverTitle = `${lang.actionTypes.create} ${lang.factories.singular.toLowerCase()}`
    const {steps,current,isFactoryCreated,isPlantCreated,isClientCreated,handleNext,handleReset,createdFactory} = useCreateFactorySteps()

    const nextStep = (disabled:boolean)=> steps[current + 1]?.label
        ? <CustomButton disabled={disabled} action={handleNext} icon={'next'} >{steps[current + 1]?.label}</CustomButton>
        : <CustomButton disabled={disabled} action={handleReset} icon={'next'} >Cargar otra fábrica</CustomButton>

    const {modal,handleModal} = useModal()

        return (
            <OnlineOfflineMode>
                <ComponentMode 
                    mode={'drawer'} 
                    popoverTitle={popoverTitle} 
                    icon={icon} 
                    variant={variant} 
                    drawerProps={{
                        open:modal,
                        onClose:handleModal,
                        styles:{
                            drawerWidth:'50vw'
                        },
                        anchor:'right',
                        disableEscapeKeyDown:true
                    }} 
                >
                    <AppBar position='relative' style={{
                        backgroundColor:isFactoryCreated ? theme.palette.primary.main :theme.palette.grey[500]
                    }} >
                        <Toolbar>
                            <Typography variant='h6' >
                                {createdFactory?.name || `${lang.actionTypes.create} ${lang.factories.singular.toLowerCase()}`}
                            </Typography>
                        </Toolbar>
                    </AppBar>

                <Stepper activeStep={current} orientation="vertical">
                    {steps.map(({label,component})=>
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                            <StepContent>
                                {component()}
                                {current === 0 && nextStep(!isFactoryCreated)}
                                {current === 1 && nextStep(!isPlantCreated)}
                                {current === 2 && nextStep(!isClientCreated)}
                            </StepContent>
                        </Step>
                    )}
                </Stepper>

            </ComponentMode>
        </OnlineOfflineMode>
    );
    
}

export default React.memo(CreateFactoryPage);
