import { TableRow, TextField, Typography } from '@material-ui/core'
import React from 'react'
import { SurveysWithObservations } from '../SurveyExploreService'
import { ObservationInterface } from '../../../domains/observations/models/ObservationTypes'
import { useDate } from '../../../shared/date/useDate'
import { TaskType } from '../../../domains/surveys/tasks/model/TaskInterface'
import { CustomTableCell } from './CustomTableCell'
import { CustomIcon } from '../../../components/buttons/CustomButton'
import theme from '../../../assets/theme'

const ExploreSurveyRow:React.FC<{survey:SurveysWithObservations}> = ({survey}) => {
  const {fromISOToDateTime} = useDate()

  const emptyValue = (value:any) => {
    return !!value ? value : '-'
  }

  const showObservations = (observations:ObservationInterface[]) =>{    
    if (observations.length === 0) return emptyValue(false)
    return observations?.map((obs) => (
      <>
        - { obs.anomaly !== '[]' ? `${obs.anomaly} | ` : '' } {obs.observation} 
        <br />
      </>
    ))
  }

  const showAnomalies = (observations:ObservationInterface[]) =>{    
    if (observations.length === 0) return emptyValue(false)
    return observations?.map((obs)=><>
      {obs.anomaly} <br />
    </>)
  }

  const lubricationColumnLogic = () => {
    if(survey.needsLubrication && survey.lubricates) return 'Lubricado'
    if(survey.needsLubrication && !survey.lubricates) return 'Faltó lubricar'
    if(!survey.needsLubrication && !survey.lubricates && !survey.inaccessible) return 'Controlado'
    if(survey.inaccessible && !survey.needsLubrication && !survey.lubricates) return 'No controlado'
    if(!survey.needsLubrication && survey.lubricates) return 'Inconsistencia en el sistema, se agrego lubricante cuando no era necesario'
  } 

  const outOfRouteColor = (color:string) => survey.outOfRoute ? 'black' : color
  const phoneIconColor = survey.inaccessible ? theme.palette.error.main : outOfRouteColor(theme.palette.success.main)
  const desktopIconColor = survey.inaccessible ?theme.palette.error.main : outOfRouteColor(theme.palette.success.main)

  return (
    <TableRow>
        <CustomTableCell>
          {survey.manualRegistration
            ? <CustomIcon icon='desktop' style={{color: desktopIconColor}} />
            : <>
                <CustomIcon icon='smartPhone' style={{color: phoneIconColor}}/>
                {!survey.inaccessible &&  <CustomIcon icon='qr' style={{color: theme.palette.success.main}} /> }
            </>
          }
        </CustomTableCell>
        <CustomTableCell>
          {emptyValue(fromISOToDateTime(survey.startTime))}
        </CustomTableCell>
        <CustomTableCell>
          {lubricationColumnLogic()}
        </CustomTableCell>
        <CustomTableCell >
          {emptyValue(!!survey.quantity && `${survey.quantity} ${survey.measureUnit}`)} 
        </CustomTableCell>
        <CustomTableCell>
          {emptyValue(survey.reason)}
        </CustomTableCell>
        <CustomTableCell>
          {emptyValue(survey.outOfRoute
            ? 'Fuera de ruta'
            : 'En ruta'
          )}
        </CustomTableCell>
        <CustomTableCell>
          {showObservations(survey.observations)}
        </CustomTableCell>

    </TableRow> 
  )
}

export default ExploreSurveyRow