import { useEffect } from "react";
import { Divider } from "@material-ui/core";
import FlexContainer from "../../../components/containers/FlexContainer";
import DefaultFormLayout from "../../../components/form/DefaultFormLayout";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import {
    InfoSurveyInterface,
    SurveyInterface,
    UpdateSurveyInterface,
} from "../model/SurveyInterface";
import { useUpdateSurvey } from "../services/surveyServices";
import { useSurveyUpdateForm } from "./hooks/useSurveyUpdateForm";
import { useObservationByIdentifier } from "../../observations/services/observationServices";
import SurveyObservations from "../../observations/pages/SurveyObservations";
import LubricationPointInfo from "./components/LubricationPointInfo";
import TaskInfo from "./components/TaskInfo";
import SurveyUpdateFormFields from "./components/SurveyUpdateFormFields";

const SurveyUpdateForm = ({ item }: { item: SurveyInterface & InfoSurveyInterface }) => {
    const { lang } = useLangLabels();

    // Form
    const { formattedUiModel, setValue, setValidations, handleSubmit, reset } = useSurveyUpdateForm(item);
    const formName = lang.surveys.title.singular;

    // Observations
    const { data: observations = {} } = useObservationByIdentifier("survey", item);

    // Update
    const { updateSurvey, resetStatus, status, error, message } = useUpdateSurvey();

    // Methods
    const onSubmit = (data: UpdateSurveyInterface) => {
        updateSurvey(data);
    };

    const handleReset = () => {
        resetStatus();
        reset();
    };

    // console.log(formattedUiModel);

    return (
        <DefaultFormLayout
            mode="content"
            icon="edit"
            variant="iconButton"
            buttonTitle={`${lang.actionTypes.update} ${formName.toLowerCase()}`}
            popoverTitle={`${
                lang.actionTypes.update
            } ${formName.toLowerCase()}`}
            {...{ status, error, message }}
            onChange={handleSubmit(onSubmit)}
            onClose={handleReset}
            timeToReset={6500}
            statusVariant={"indeterminate"}
        >
            <FlexContainer flexDirection="column" gap={"0px"}>
                <LubricationPointInfo survey={item} />
                <br />
                <Divider />
                <TaskInfo item={item} />
                <Divider />
                <br />
                <SurveyUpdateFormFields
                    formattedUiModel={formattedUiModel}
                    item={item}
                    setValue={setValue}
                    setValidations={setValidations}
                />
                <br />
                <Divider />
                <br />
                <SurveyObservations
                    observations={observations}
                    virtualGridHeight={5}
                    contained={false}
                    survey={item}
                />
            </FlexContainer>
        </DefaultFormLayout>
    );
};

export default SurveyUpdateForm;
