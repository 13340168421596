import React from 'react';

import FieldSelector from '../../shared/form/components/FieldSelector';
import { AddedReasonCRUDDependencies, CreateAddedReason, DeleteAddedReason, UpdateAddedReason } from './services/service.addedReasons';
import { AddedReasonInterface } from './models/AdedReasonTypes';
import { useAddedReasonForm } from './services/useAddedReasonForm';
import { CUEntityFormPropsType, uiFormInterface } from '../../shared/form/crud/types/crudTypes';
import { useCreateUpdate } from '../../shared/form/crud/hooks/useCreateUpdate';
import { useLangLabels } from '../../shared/lang/services/useLangLabels';
import { useSessionContext } from '../session/store/sessionContext';
import { CrudFormType } from '../../shared/form/models/FormTypes';
import DefaultFormLayout from '../../components/form/DefaultFormLayout';


type props = CUEntityFormPropsType<AddedReasonInterface>


const CUAddedReasonForm: React.FC<props> = ({item, actionType}) => {

    const {tagFP} = useSessionContext()
    //DB 
    const { action, resetStatus,status, error, message } = useCreateUpdate({
        create: CreateAddedReason,
        update: UpdateAddedReason,
        delete: DeleteAddedReason
    },actionType, AddedReasonCRUDDependencies)
    
    
    //UI
    const { lang } = useLangLabels()

    const { uiModel, handleSubmit, reset } = useAddedReasonForm(tagFP, item)

    const ui = (() => {
        const formName = lang.addedReasons.plural 
        switch (actionType) {
            case CrudFormType.create:
                const create:uiFormInterface = {
                    icon:'add',
                    title:`${lang.actionTypes.create} ${formName.toLowerCase()}`,
                    variant:'button',
                    mode:'modal'
                }
                
                return create

            case CrudFormType.update:
                const update:uiFormInterface = {
                    icon:'edit',
                    title:`${lang.actionTypes.update} ${formName.toLowerCase()}`,
                    variant:'iconButton',
                    mode:'content'
                }
                return update
        }
    })()

    //Methods
    const submit = (data:AddedReasonInterface) => {
        action(data)
    }

    const handleReset = () => {
        resetStatus()
        reset()
    }

    return(
        <DefaultFormLayout
            mode={ui.mode}
            icon={ui.icon}
            variant={ui.variant}
            buttonTitle={ui.title}
            popoverTitle={ui.title}
            {...{status,error,message}} 
            onChange={handleSubmit(submit)}  
            onClose={handleReset} 
        >
            {uiModel.map((props,index)=>
                <FieldSelector key={index} fieldProps={props} />
            )}
        </DefaultFormLayout>
    )
};

export default CUAddedReasonForm;