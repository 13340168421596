import { useMemo } from "react";
import { GridModelInterface } from "../../../shared/gridModel/gridModelEntity";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";

export const useObservationsGridModel = () => {
    const {lang} = useLangLabels()
    const gridModelObservations:GridModelInterface = useMemo(()=>({
        "id":{label:lang.globalentityValues.id},
        "tagFP":{label:lang.globalentityValues.tagFP},
        "tagTGD":{label:lang.globalentityValues.tagTGD},
        "anomaly":{label:lang.anomalies.singular},
        "observation":{label:lang.observations.singular},
        "criticality":{label:lang.criticalities.title.singular},
        "area":{label:lang.areas.title.singular},
        "surveyId":{label:lang.observations.surveyId},
        "equipment":{label:lang.observations.equipment},
        //"modified":{
        //    label:lang.observations.modified,
        //    options:[
        //        {name:lang.words.yes.toUpperCase(),value:true},
        //        {name:lang.words.no.toUpperCase(),value:false},
        //        {name:lang.words.all.toUpperCase(),value:undefined},
        //    ]
        //},
        //"modifiedDate":{label:lang.observations.observationDate},
        "observationDate":{label:lang.observations.observationDate},
        "OTnumber":{label:lang.observations.OTnumber},
        "state":{label:lang.observations.state},
        "taskId":{label:lang.observations.taskId},
        "taskRequestId":{label:lang.observations.taskRequestId},
        "taskType":{label:lang.observations.taskType}, 
        "type":{label:lang.observations.type},
        "element":{label:lang.observations.element},
        "component":{label:lang.observations.component},
        "user": {label: lang.observations.user}
    }),[lang])

    return gridModelObservations
}