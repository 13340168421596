import { useSessionContext } from "./sessionContext"

export const useToken = () => {
    const {token} = useSessionContext()
    return token
}

export const usePlants = () => {
    const {plants} = useSessionContext()
    return plants
}

export const usePerson = () => {
    const {person} = useSessionContext()
    return {
        data:person
    }
}

export const useUser = () => {
    const { user } = useSessionContext()
    return {
        data:user
    }
}
