import React from 'react'
import { CrudFormType } from '../../../shared/form/models/FormTypes'
import { useLangLabels } from '../../../shared/lang/services/useLangLabels'
import { CarrousellActionsProps } from '../../../shared/react-table/CarrouselActions'
import VirtualGrid, { CarrousellActinInterface } from '../../../shared/react-table/VirtualGrid'
import { Plant } from '../../plants/PlantInterface'
import { useSessionContext } from '../../session/store/sessionContext'
import CULubricantForm from '../CULubricantForm'
import DeleteLubricantform from '../DeleteLubricantform'
import { useLubricantsGridModel } from '../lang/useLubricantsGridModel'
import { useAllLubricantsByPlant } from '../services/service.lubricants'


interface props{
    virtualGridWidth:string
}


const ManageLubricantsPage:React.FC<props> = ({virtualGridWidth}) => {

    const {tagFP} = useSessionContext()
    const {lang} = useLangLabels()
    const {data:lubricants} = useAllLubricantsByPlant() 

    const carrousellActions:CarrousellActinInterface = [
        {icon:'edit', popoverTitle:lang.crudActions.update, component:(item)=> <CULubricantForm item={item} tagFP={tagFP} actionType={CrudFormType.update}/>}
    ]    

    return(

        <VirtualGrid 
            width={virtualGridWidth}
            gridModel={useLubricantsGridModel()}
            entityName={lang.lubricantForm.plural}
            items={lubricants || []}
            title={lang.lubricantForm.plural}
            carrousellComponents={carrousellActions}
            headerActions={(item:any)=>
                <CULubricantForm tagFP={tagFP} actionType={CrudFormType.create}/>
            }
            itemActions={(item)=><DeleteLubricantform item={item} />}
        />
        
    )

}
export default React.memo(ManageLubricantsPage)