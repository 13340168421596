import { useState } from "react";
import { MapInterface } from "../../globals/utilsGlobalTypes";

export interface PaginationState {
    pageIndex: number;
    pageSize: number;
}

export interface PaginationStateProps {
    pageIndex?: number;
    pageSize?: number;
}

export interface PaginationOptions {
    pageIndex: number;
    pageSize: number;
    onPaginationChange: (paginationState: PaginationStateProps) => void;
}

export interface PaginationResult {
    pageIndex: number;
    totalItems: number;
    pageSize: number;
    totalPages: number;
    canPreviousPage: boolean;
    canNextPage: boolean;
    previousPage: () => void;
    nextPage: () => void;
    handlePageIndexChange: (value: number) => void,
    handlePageSizeChange: (value: number) => void,
    onPaginationChange: (paginationState: PaginationStateProps) => void;
}

interface PaginationStateResult {
    paginationOptions: PaginationOptions;
}

export const usePagination = (
    { pageIndex, pageSize, onPaginationChange }: PaginationOptions,
    totalItems: number
): PaginationResult => {
    const totalPages = Math.ceil(totalItems / pageSize);
    const canPreviousPage = pageIndex > 0;
    const canNextPage = pageIndex < totalPages - 1;

    const previousPage = () => {
        if (canPreviousPage) {
        onPaginationChange({
            pageIndex: pageIndex - 1,
        });
        }
    };

    const nextPage = () => {
        if (canNextPage) {
        onPaginationChange({
            pageIndex: pageIndex + 1,
        });
        }
    };

    const handlePageIndexChange = (value: number) => {
        if (value < totalPages && value >= 0) {
        onPaginationChange({
            pageIndex: value,
        });
        }
    };

    const handlePageSizeChange = (value: number) => {
        onPaginationChange({
        pageIndex: 0,
        pageSize: value,
        });
    };

    return {
        pageIndex,
        totalItems,
        pageSize,
        totalPages,
        canPreviousPage,
        canNextPage,
        previousPage,
        nextPage,
        handlePageIndexChange,
        handlePageSizeChange,
        onPaginationChange,
    };
};

export const usePaginationState = (
    initialState: PaginationState = { pageIndex: 0, pageSize: 500 }
    ): PaginationStateResult => {
    const [{ pageIndex, pageSize }, setPagination] = useState({
        ...initialState,
    });

    const paginationOptions = {
        pageIndex,
        pageSize,
        onPaginationChange: (paginationState: {
            pageIndex?: number;
            pageSize?: number;
        }) => {
            setPagination((prevState) => ({
                ...prevState,
                ...paginationState,
            }));
        }
    };

    return { paginationOptions };
};




interface FilterOptions{
    filters: MapInterface,
    onFiltersChange: (columnName: string, newValue: string) => void
    onResetFilters: () => void
    onDeleteFilter: (fieldToDelete: string) => void
    searchValue: string
    onSearchValueChange: (inputValue: string) => void
}

interface FilterStateResult {
    filterOptions: FilterOptions;
}

export const useFilterState = ({onPaginationChange}: PaginationOptions): FilterStateResult => {
    const[ filters, setFilters ] = useState<MapInterface>({})
    const[ searchValue, setSearchValue ] = useState("")

    const onFiltersChange = (columnName: string, newValue: string) => {
        setFilters((prevState) => ({
            ...prevState,
            [columnName]: newValue
        }))
        onPaginationChange({ pageIndex: 0 })
    }

    const onResetFilters = () => {
        setFilters({})
        onPaginationChange({ pageIndex: 0 })
    }

    const onDeleteFilter = (fieldToDelete: string) => {
        const validItems = { ...filters }
        delete validItems[fieldToDelete]

        setFilters(validItems)
        onPaginationChange({ pageIndex: 0 })
    }

    const onSearchValueChange = (inputValue: string) => {
        setSearchValue(inputValue)
        onPaginationChange({ pageIndex: 0 })
    }

    
    const filterOptions = {
        filters,
        onFiltersChange,
        onResetFilters,
        onDeleteFilter,
        searchValue,
        onSearchValueChange
    }

    return { filterOptions };
}
