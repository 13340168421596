import { useCallback, useState } from "react"

export const useModal = (init?:boolean) => {

    const [modal,setModal] = useState(init || false)

    const onClose = useCallback(() => {
        setModal(false)
    },[])

    const onOpen = useCallback(() => {
        setModal(true)
    },[])

    const handleModal = () => {
        setModal(!modal)
    }

    return {modal,onClose,setModal,onOpen,handleModal}
}