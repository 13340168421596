import React, { useState } from 'react';
//material
import { Icon, IconButton, Paper, Typography, useTheme } from '@material-ui/core';
import AddPersonForm from './AddPersonForm';
import { useSupervisorsByReferer } from '../services/personServices';
import { PersonInterface } from '../models/PersonInterface';
import CustomButton from '../../../components/buttons/CustomButton';
import OnlineOfflineMode from '../../../shared/config/offline Mode/OnlineOfflineMode';
import SidebarContainer from '../../../components/containers/SidebarContainer';
import EntityList from '../../../components/grids/EntityList';
import PageContainer from '../../../components/containers/PageContainer';
import UpdatePersonForm from './UpdatePersonForm';
import FlexContainer from '../../../components/containers/FlexContainer';
import TgdModal from '../../../components/containers/modal/TgdModal';
import DuoContainer from '../../../components/containers/DuoContainer';
import AssignCancelPlantsRefactor from '../../plants/AssignCancelPlantsRefactor';
import { useLangLabels } from '../../../shared/lang/services/useLangLabels';



const AddSupervisorrRefactor:React.FC = () => {
    const theme = useTheme()
    const {lang} = useLangLabels()
    const [supervisors,{status:supervisorStatus}] = useSupervisorsByReferer()
    const [open,setOpen] = useState(false)
    const [focusPersonData,setFocusPersonData] = useState<PersonInterface>()

    const handleModal = () =>{
        setOpen(!open)
    }

    const handlePerson = (data:PersonInterface) => {
        setFocusPersonData(data)
        handleModal()
    }

    const assignPlants = (item:any) => {
        handleModal()
        sessionStorage.setItem('editUserEmail',item.email)
        setFocusPersonData(item)
    }

    const customButtons = (item:any) => {
        return(
            <CustomButton
                variant = 'iconButton'
                icon='business'
                action={()=>assignPlants(item)} 
                color={'primary'}
                popoverTitle={lang.plants.asignCancelPlants}
            /> 
        )
    }

    return (
        <OnlineOfflineMode>
            <SidebarContainer>
                <EntityList
                        title={lang.persons.supervisors}
                        icon={'person'}
                        status={supervisorStatus}
                        items={supervisors}
                        customButtons={customButtons}
                        dropDownChildren={(item)=><PageContainer>
                            <UpdatePersonForm person={item}/>
                        </PageContainer>}
                    />
                    <Paper>
                        <FlexContainer border={'4px 0px 0px solid red'}/>
                        <PageContainer padding='16px 16px 0px'>
                            <Typography component={'h6'} >{lang.persons.newSupervisor}</Typography>
                        </PageContainer>
                        <AddPersonForm
                            role='Supervisor'
                            handlePerson={handlePerson}
                        />
                </Paper>
            </SidebarContainer>
            
            
            <TgdModal title={lang.plants.asignCancelPlants} open={open} handleClose={handleModal} >
                <DuoContainer>
                    <AssignCancelPlantsRefactor 
                        action='assign' 
                        roleToEdit={'S'} 
                        title={lang.plants.clickToAssign}
                        userData={focusPersonData} 
                    />

                    <AssignCancelPlantsRefactor 
                        userData={focusPersonData} 
                        roleToEdit={'S'}  
                        action={'cancel'} 
                        title={lang.plants.clickToCancel}
                    />
                </DuoContainer>
            </TgdModal>
            
        </OnlineOfflineMode>
    );
}

export default AddSupervisorrRefactor;
