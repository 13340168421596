import { Typography } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import CustomButton from "../../../components/buttons/CustomButton"
import { ComponentUIModes } from "../../../components/componentMode/componentModesInterfaces"
import FlexContainer from "../../../components/containers/FlexContainer"
import { useModal } from "../../../components/containers/modal/hooks/useModal"
import TgdModal from "../../../components/containers/modal/TgdModal"
import PageContainer from "../../../components/containers/PageContainer"
import { errorsLog } from "../../../shared/globals/utils/utils"
import { LubricationPointInterface } from "../../lubricationPoints/model/lubricationPointsInterface"
import { useSessionContext } from "../../session/store/sessionContext"
import { useLubricationPointSurveyController } from "../../surveys/services/controllers/useLubricationPointSurveyController"
import { SurveyTasksValues, TaskType } from "../../surveys/tasks/model/TaskInterface"
import SelectSupplyType from "../atom/SelectSuplyType"
import SelectSupply from "../atom/SelectSupply"
import { LocalStateSupply, SupplyInterface, SupplyStateDimention } from "../model/SupplyInterface"
import { suppliesToObject } from "../utils/supplyToObject"
import SupplyList from "./SupplyList"


interface Props {
    outOfRoute:boolean
    listTitle?:string
    buttonTitle?:string
    formTitle?:string
    variant?: 'modal' | 'content' | 'form' | 'list'
    buttonType:'next' | 'add'
    lubricationPoint:LubricationPointInterface
    freesolo?:boolean
    disableUsedSupplies?:boolean
    taskType?:TaskType
    inmutableValues?:string[]
    propSupplies?: LocalStateSupply[]
    propDeleteSupply?: (deleteIndex: number) => void
    propSetNeededSupplies?: (value: LocalStateSupply) => void
}



const AddSupply:React.FC<Props> = ({variant,listTitle,buttonTitle,formTitle,buttonType,lubricationPoint,taskType,freesolo,disableUsedSupplies,inmutableValues,outOfRoute, propSupplies, propDeleteSupply, propSetNeededSupplies}) => {
    const {register,watch, handleSubmit, errors, setValue } = useForm()
    const {modal,handleModal} = useModal()
    const {tagFP} = useSessionContext()
    const [supplyState, setSupplyState] = useState<SupplyStateDimention>(SupplyStateDimention.needed)

    const { 
        survey, 
        deleteSupply: deleteSupplyLubricationPoint, 
        setNeededSupplies: setNeededSuppliesLubricationPoint 
    } = useLubricationPointSurveyController(lubricationPoint,outOfRoute)
    const suppliesLubricationPoint = survey?.neededSupplies || []

    const supplies = propSupplies ?? suppliesLubricationPoint
    const deleteSupply = propDeleteSupply ?? deleteSupplyLubricationPoint
    const setNeededSupplies = propSetNeededSupplies ?? setNeededSuppliesLubricationPoint

    useEffect(() => {
        if(supplyState){
            setValue('type','CONSUMIBLE')
        }
    }, [setValue, supplyState]);

    const onSubmit = (data:LocalStateSupply) => {   
      setNeededSupplies({
          ...data,
          state:supplyState
      }) 
      /* console.log(data) */
    }

    useEffect(()=>{
        setValue('supply',undefined)
        supplyState === SupplyStateDimention.needed && setValue('type',undefined)
    },[supplies])

    errorsLog(errors)

    useEffect(()=>{
        register({name:'supply',value:''},{required:{value:true,message:'Nombre Requerido'}})
        register({name:'type',value:''},{required:{value:true,message:' Tipo Requerido'}})
    },[register, survey, supplies])

    const form =  
        <PageContainer> 
            <Typography variant='h6'>
                {formTitle} 
                {supplyState === SupplyStateDimention.needed ? ' faltante' : ' utilizado'}</Typography>       
                <br/>
                <br/>               
            <FlexContainer flexDirection='column' width='100%'>

                {supplyState === SupplyStateDimention.needed &&
                    <SelectSupplyType
                        onChange={(value:string)=>setValue('type',value)}
                        defaultValue={watch('type')}
                        error={errors?.type?.message}
                    />
                }

                <SelectSupply
                    error={errors?.type?.supply}
                    by={{key:'type',value:watch('type')}}
                    defaultValue={watch('supply')}
                    onChange={(value?:string)=>setValue('supply',value?.toUpperCase())}
                    tagFP={tagFP}
                    extendInput={true}
                />

                <CustomButton icon='save' action={handleSubmit(onSubmit)}/>
            
            </FlexContainer>

        </PageContainer>

    const handleTaskSupplies = (supplyState:SupplyStateDimention) => {
        setSupplyState(supplyState)
        handleModal()
    }

    const neededSuppliesButton = <CustomButton popoverTitle={taskType === SurveyTasksValues.puntuation ? 'Solicitar agregado de insumo a este equipo' : 'insumos faltantes para la tarea'} icon='add' action={()=>handleTaskSupplies(SupplyStateDimention.needed)} title={taskType === SurveyTasksValues.puntuation ? 'SOLICITAR' : 'Faltantes'} />
    const usedSuppliesButton = <CustomButton popoverTitle='insumos usados en la tarea' icon='add' action={()=>handleTaskSupplies(SupplyStateDimention.used)} title={'Utilizados'} />

    const headerActions = <FlexContainer>
        { neededSuppliesButton}
        {(taskType !== SurveyTasksValues.puntuation && !disableUsedSupplies) && usedSuppliesButton}
    </FlexContainer>

    const list = (mode?:ComponentUIModes) =>(
        <SupplyList
        tasktype={taskType}
        handleDeleteItem={deleteSupply}
        supplies={supplies} 
        headerAction={variant === 'list' ? headerActions : <></>} 
        title={listTitle} 
        mode={mode}
        inmutableValues={inmutableValues ? suppliesToObject(inmutableValues): undefined }
        />
    ) 




    return(<>
        {(variant === 'form') && form}
        {(variant === 'content') && <>{form}{list()}</>}
        {(variant === 'list') && list()}
        {(variant === 'modal') && <>
            {buttonType === 'next' &&
                <CustomButton 
                    color='primary'
                    variant='fab'
                    action={handleModal}
                    icon='next'
                />    
            }
        </>}

        <TgdModal
            fullWidth
            open={modal}
            handleClose={handleModal}
        >
            <>{form}{list('modal')}</>
        </TgdModal>
    </>)

}
export default React.memo(AddSupply)