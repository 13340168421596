
import React from 'react'
import ComponentMode from '../../../../components/componentMode/ComponentMode'
import { ComponentUIModes } from '../../../../components/componentMode/componentModesInterfaces'
import ColectorList from '../../../surveys/ColectorList'


const LubricationPointsList:React.FC<{type:ComponentUIModes,item:any, title?:string}> = ({type,item,title}) => {

    const  primaryText:string[] = ['equipment','element', 'sector','lubricant']
    const  secondaryText:string[] = ['plantTag','component','sector en planta','lubricantType']
  
    const trashTitle:string = (
        (item.lubricant ? `Puntos de lubricacion que utilizan ${item.lubricant.type} ${item.lubricant.lubricant}` : '') ||
        (item.supply ? `Puntos de lubricacion que utilizan ${item.supply.supply}` : '') ||
        (item.routeName ? `Puntos de lubricacion de la ruta ${item.routeName}` : '') ||
        (item.permission ? `Puntos de lubricacion de la ruta ${item.permission.permissionName}` : '')
    )

    return(<>
        <ComponentMode mode={type} variant='iconButton' icon='more' title={title || trashTitle}>
            <ColectorList
                items={item.lubricationPoints}
                primaryText={primaryText}       
                secondaryText={secondaryText}       
            />
        </ComponentMode>
    </>)
}

export default React.memo(LubricationPointsList)