import { CircularProgress, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useNavigate, useNavigationType } from 'react-router-dom'
import theme from '../../assets/theme'
import {  usePbiAccessToken, usePbiReportsInGroup, usePbiTilesInDashboard } from '../../domains/powerBi/services/service.pbi'
import { useTodayDelayedAndPendingSetter } from '../../domains/session/services/sessionAdapters'
import { useSessionContext } from '../../domains/session/store/sessionContext'
import { LoginPageContainer } from '../login/components/LoginFormContainer'

 

const LoadingNecesaryData:React.FC<{}> = ({}) => {
    const navigate = useNavigate()
    const {data:addToken} = usePbiAccessToken()
    const {person,currentPbigroupName,token,user} = useSessionContext()
    const {isSuccess} = useTodayDelayedAndPendingSetter()
    /* const reports = usePbiReportsInGroup(undefined)
    usePbiTilesInDashboard(undefined) */

    useEffect(()=>{
        if(person){
            if(['Cliente'].includes(person.role)){
                !!addToken && !!user && currentPbigroupName &&
                setTimeout(()=>{
                    navigate(person.role)
                },1000)
            }
            if(['Operario','Supervisor'].includes(person.role)){
                !!token && !!person && isSuccess && !!user &&
                setTimeout(()=>{
                    navigate(person.role)
                },1000)
            } 
            if(['Super Admin'].includes(person.role)){
                !!token && !!person && !!user &&
                setTimeout(()=>{
                    
                    navigate(person.role.replace(/ /g,'-'))
                },1000)
            }
        }
        
    },[token, isSuccess, person])

  return (
    <>		
        <LoginPageContainer color={theme.palette.secondary.main as string}>
            <CircularProgress size={'24px'} style={{color:'white'}}/>
            <br/>
            {!!token && <Typography color={'inherit'} style={{color:'white'}} >Conectando servicios...</Typography>}
        </LoginPageContainer>
    
    </>
  )
}

export default LoadingNecesaryData