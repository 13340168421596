import React, { useEffect, useState } from 'react'

import { CircularProgress } from '@material-ui/core';
import { useCreateUpdateCollector } from '../services/collectorsServices';
import { useLangLabels } from '../../../shared/lang/services/useLangLabels';
import { CrudFormType } from '../../../shared/form/models/FormTypes';
import { CollectorInterface } from '../models/CollectorInterfaces';
import CustomButton from '../../../components/buttons/CustomButton';
import HandleUndefined from '../../../components/containers/handleUndefined/HandleUndefined';



interface Props{
    items:CollectorInterface[]
    tagFP:string
}

const ActivateDesactivateCollector:React.FC<Props> = ({tagFP, items}) => {
    
    const itemExist =  !!items && items.length > 0
    const [collectorIsActive,setState] = useState<boolean>(false)

    useEffect(()=>{
        if(itemExist)
        setState(items[0].state)
    },[items])

    const date = new Date().toISOString()

    const {lang} = useLangLabels()

    const {handleCreateUpdateCollector,query:{isLoading}} = useCreateUpdateCollector({
        tagFP,
        type:CrudFormType.update,
    })

    const activateDesactivate = () => {

        const egressDate = collectorIsActive ? date : '2999-12-01'

        if(itemExist){
            items.forEach((i)=>{
                return handleCreateUpdateCollector({
                    ...i,
                    state:!collectorIsActive,
                    egressDate
                })
            })
        }
        
        setState(!collectorIsActive)
    }

    const title = collectorIsActive 
        ? lang.collectors.desactivate
        : lang.collectors.activate

    return(
        <HandleUndefined isError={itemExist} callback={activateDesactivate}>
            { (callback)=>
                isLoading
                ? <CircularProgress size='20px' />
                : itemExist && <CustomButton
                    popoverTitle={title}
                    action={callback}
                    icon={collectorIsActive ? 'off' : 'power'}
                    variant='iconButton'
                />}
        </HandleUndefined>
    )

}

export default ActivateDesactivateCollector