import React from 'react'
import { AutocompleteProps } from "@material-ui/lab"
import { useAutocompleteStyles } from "./useAutocompleteStyle"

import { useMemo } from 'react'
import { TextField } from '@material-ui/core';
import { useLangLabels } from '../../shared/lang/services/useLangLabels';
import theme from '../../assets/theme';

export const useSimpleAutocompleteProps = <T,> (params:{items:T[],value:T | null,error?:string, label:string,helper?:string,autoFocus?:boolean}) => {
    const classes = useAutocompleteStyles(undefined)
    const {items, value, error, label, helper, autoFocus} = params
    const {lang} = useLangLabels()

    const props:AutocompleteProps<T, false, false, false> = useMemo(()=>({
        style:{width:'100%',flexGrow:1},
        size:'small',
        options:items,
        value:value,
        loadingText:lang.inputFields.autocomplete.loadingText,
        clearText:lang.inputFields.autocomplete.clearText,
        openText:lang.inputFields.autocomplete.openText,
        closeText:lang.inputFields.autocomplete.closeText,
        classes:{
            option:classes.option
        },
        renderInput:(params) => (
            <TextField
                {...params} 
                autoFocus={autoFocus}
                helperText={helper}
                variant="outlined"
                focused={error ? true : false}
                label={error ? `${label} ${error}` : label}
                style={{color: error ? theme.palette.error.main : 'unset' }} 
            />
        )
    }),[autoFocus, classes.option, error, helper, items, label, lang, value])

    return props
}