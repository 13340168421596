import React from 'react'
import { Typography } from '@material-ui/core';
import { CriticalityInterface } from '../models/criticalityInterface';
import FlexContainer from '../../../components/containers/FlexContainer';



const CriticalityIndicator:React.FC<{criticality:CriticalityInterface}> = ({criticality}) => {
    
    const {color,description,number} = criticality

    return(
        <FlexContainer
        margin='4px'
        borderRadius='8px' 
        padding='8px' 
        height='48px' 
        width='150px'  
        background={color} 
        align='center' 
        justify='center' 
        color='white'>
            <Typography color='inherit' align='left'>{description!} </Typography>
            <Typography color='inherit' align='left' variant='h6'>{number} </Typography>
        </FlexContainer>
    )
}

export default CriticalityIndicator