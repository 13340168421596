import React from 'react';

import { useTypeForm } from './services/useTypeForm';
import { enumTgdTypes } from './models/types';
import { CUEntityFormPropsType, uiFormInterface } from '../../shared/form/crud/types/crudTypes';
import { TypeInterface } from './models/TypeInterface';
import { useCreateUpdate } from '../../shared/form/crud/hooks/useCreateUpdate';
import { CreateType, deleteType, UpdateType } from './services/service.types';
import { useLangLabels } from '../../shared/lang/services/useLangLabels';
import { CrudFormType } from '../../shared/form/models/FormTypes';
import DefaultFormLayout from '../../components/form/DefaultFormLayout';
import FieldSelector from '../../shared/form/components/FieldSelector';
import { useSessionContext } from '../session/store/sessionContext';
import CarrouselModal, { useCarrousellModal } from '../../components/containers/modal/carrousellModal';




type props = CUEntityFormPropsType<TypeInterface> & {
    defaultType?:enumTgdTypes
    item?:TypeInterface
}


const CUtypeForm:React.FC<props> = ({actionType, item, defaultType}) => {

    const {tagFP} = useSessionContext()
    //DB 
    const  { action, resetStatus,status, error, message } = useCreateUpdate({
        create: CreateType,
        update: UpdateType,
        delete: deleteType
    },actionType, ['PlantAux'])

    //UI
    const { lang } = useLangLabels()

    const ui = (() => {
        const formName = lang.types.plural 
        switch (actionType) {
            case CrudFormType.create:
                const create:uiFormInterface = {
                    icon:'add',
                    title:`${lang.actionTypes.create} ${formName.toLowerCase()} ${defaultType ? defaultType.toLowerCase() : ''}`,
                    variant:'button',
                    mode:'modal'
                }
                return create

            case CrudFormType.update:
                const update:uiFormInterface = {
                    icon:'edit',
                    title:`${lang.actionTypes.update} ${formName.toLowerCase()}`,
                    variant:'iconButton',
                    mode:'content'
                }
                return update
        }
    })()

    const { uiModel, handleSubmit, reset } = useTypeForm(tagFP, item || (defaultType && {
        id:0,
        name:'',
        type:defaultType,
        tagFP:tagFP
    }))

   
  
    //Methods
    const submit = (data:TypeInterface) => {
        action(data)
    }

    const handleReset = () => {
        resetStatus()
        reset()
    }

    
    return(
        <DefaultFormLayout
        mode={ui.mode}
        icon={ui.icon}    
        variant={ui.variant}
        buttonTitle={ui.title}
        popoverTitle={ui.title}
        {...{status,error,message}} 
        onChange={handleSubmit(submit)}  
        onClose={handleReset} 
        >
            {uiModel.map((props,index)=>
                <FieldSelector key={index } fieldProps={props} />
            )}
        </DefaultFormLayout>
    )

}

export default  CUtypeForm
