import { AppBar, Box, Button, CircularProgress , createStyles, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Fab, Grid, Icon, IconButton, ListItemText, makeStyles, Paper, Toolbar, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { useSessionContext } from '../../session/store/sessionContext';
import { useModal } from '../../../components/containers/modal/hooks/useModal';
import { EquipmentInterface, equipmentType, LubricationPointInterface } from '../model/lubricationPointsInterface';
import { useCreateEquipment } from '../services/lubricationPointsAdapters';
import CustomButton, { CustomIcon } from '../../../components/buttons/CustomButton';
import FullscreenSurface from '../../../components/containers/modal/FullscreenSurface';
import PageContainer from '../../../components/containers/PageContainer';
import { Alert } from '@material-ui/lab';
import TgdAuxList from '../../../components/grids/TgdAuxList';
import UpdateEquipmentForm from './UpdateEquipmentForm';
import CreateEquipmentForm from './CreateEquipmentForm';
import { Plant } from '../../plants/PlantInterface';
import EquipmentVerification from './EquipmentVerification';
import FlexContainer from '../../../components/containers/FlexContainer';

const useStyles = makeStyles((theme) => createStyles({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
	},
	root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
  }))


interface Props{
}

const CreateEquipmentPage:React.FC<Props> = ({}) => {
   

    const classes = useStyles();
    const {token,currentPlant:plant} = useSessionContext()
    const {modal:modalSurface,handleModal:handleModalSurface} = useModal()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [activeStep, setActiveStep] = React.useState(0);
    const [newEquipment,setNewEquipment] = useState<EquipmentInterface | undefined | null >()
    const [lubricationPoints,setLubricationPoints] = useState<LubricationPointInterface[] | undefined | null>([])
    const {modal,handleModal,setModal} = useModal()
    const {modal:modalLubricationPoints,handleModal:handleLubricationPointModal} = useModal()
    const {mutate:createEquipment,status,data,error,reset} = useCreateEquipment()
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    


    const handleCreateEquipment = () => {
        if(lubricationPoints?.length) {
            createEquipment({
                data:lubricationPoints,
                token
            })
        } else{
            alert('Debe cargar al menos un componente en el equipo para poder crearlo')
        }
    };

    const handleRemoveItem = (e:any) => {
        const element = e.targetelement
        const component = e.component
  
        setLubricationPoints(lubricationPoints?.filter(item => (item.element !== element) && (item.component !== component)));
    };

    const handleRefresh = () => {
        var action = window.confirm("¿Estás seguro que queres hacer un reset de los datos de la plantilla?")
        if(action){
            setNewEquipment(undefined)
            setLubricationPoints([])
            reset()
            handleModal()
        }
    }

    useEffect(() => {
        if(lubricationPoints && lubricationPoints.length > 0){
            handleLubricationPointModal()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lubricationPoints]);

    useEffect(() => {
        if(!!newEquipment){
            handleModal()
            handleLubricationPointModal()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[newEquipment])
  
    return (<>
        <CustomButton icon='add' action={handleModalSurface} popoverTitle='Crear un equipo'/>
        <FullscreenSurface  modal={modalSurface} >
            <AppBar className={classes.appBar}>
                <Toolbar>
                <CustomButton
                        icon='close'
                        color="inherit"
                        action={handleModalSurface}
                        variant='iconButton'
                    />
                    <Typography variant="h6" className={classes.title}>
                        Crear y configurar nuevo equipo
                    </Typography>
                    <Button autoFocus color="inherit" onClick={handleCreateEquipment}>
                        Guardar
                    </Button>
                </Toolbar>
            </AppBar>

            
            
            <PageContainer>
                    <div style={{display:'flex',justifyContent:'center',width:'100%'}}>
                        {(status === 'loading') && 
                            <FlexContainer width={'100%'} justify='center' align={'center'}>
                                <CircularProgress size='40px' /> 
                            </FlexContainer>
                        }
                        
                        {!(status === 'loading') &&
                        
                        <>
                        <Box>
                            {error && <Alert severity='error' onClose={handleRefresh} >ha ocurrido un error</Alert>}
                            {(status === 'success') && (data?.length > 0) && <Alert onClose={handleRefresh} severity={'warning'}>Algunos puntos de lubricación no se hn podido crear de manera satisfactoria, revise los errores para terminar de cargarlos.</Alert>}
                            {(status === 'success') && ( data?.length === 0) && <Alert onClose={handleRefresh} severity='success'>El equipo y todos sus puntos de lubricación han podido cargarse con exito!</Alert>}
                            <br/>
                            <Paper elevation={3} style={{width:'850px',height:'calc(850px * 1.18)'}}>
                                <PageContainer>
                                    <Grid container spacing={3}>
                                        <Grid item md={12}>
                                            <Typography variant='h6'>{newEquipment?.tagFP ? newEquipment?.tagFP.split('|').map((i:string) => `${i} `):''}</Typography>
                                            <Divider/> 
                                        </Grid>
                                        <Grid item md={4}>
                                            <Box style={{
                                                backgroundColor:'#f6f6f6',
                                                maxWidth:'200px',
                                                maxHeight:'200px',
                                                minHeight:'150px',
                                                minWidth:'150px',
                                                width:'auto',
                                                height:'100%',
                                            }}>
                                                    
                                            </Box>
                                        </Grid>
                                        <Grid item md={8} >
                                            <Grid container spacing={3}>
                                                <Grid item md={6}>
                                                    Nombre de equipo:
                                                    <Typography variant='body2'><strong>{newEquipment?.equipment}</strong></Typography>
                                                    <Divider/>
                                                    <br/>
                                                    Tag de equipo:
                                                    <Typography variant='body2'><strong>{newEquipment?.tagTGD}</strong></Typography>
                                                    <Divider/>
                                                </Grid>
                                                <Grid item md={6}>
                                                    Tipo:
                                                    <Typography variant='body2'><strong>{newEquipment?.type}</strong></Typography>
                                                    <Divider/>
                                                    <br/>
                                                    Sector: 
                                                    <Typography variant='body2'><strong>{newEquipment?.sector}</strong></Typography>   
                                                    <Divider/>
                                                </Grid>
                                                <Grid item md={6}>
                                                    Tag de planta:
                                                    <Typography variant='body2'><strong>{newEquipment?.plantTag}</strong> </Typography>
                                                    <Divider/> 
                                                </Grid>
                                                <Grid item md={6}>
                                                    Criticidad del equipo:
                                                    <Typography variant='body2'><strong>{newEquipment?.criticality}</strong></Typography>
                                                    <Divider/> 
                                                </Grid>
                                            </Grid>
                                        </Grid>                       
                                    </Grid>
                                    <br/>
                                    <Divider/>
                                        <TgdAuxList
                                            customButtons={(e:LubricationPointInterface)=> <>
                                                <CustomIcon onClick={()=>handleRemoveItem(e)} icon='delete'></CustomIcon>   
                                            </>}
                                            title='Componentes'
                                            searchBar={false}
                                            showLabel={equipmentType.equipment}
                                            alternativeColumn={(item:LubricationPointInterface)=> <ListItemText style={{textAlign:'left'}} primary={`${item.element} ${item.component}`}/>}
                                            items={lubricationPoints}
                                            status={'idle'}
                                            children={(item:LubricationPointInterface)=><>
                                                <UpdateEquipmentForm handleState={setLubricationPoints} defaultState={item} points={lubricationPoints} equipment={newEquipment} />
                                            </>}
                                            toggleDisabled={false}
                                        />
                                </PageContainer>
                            </Paper>
                            </Box>
                        <div style={{padding:'12px',display:'flex',flexDirection:'column'}}>
                            {!lubricationPoints?.length &&<>
                                <Fab color={'primary'} onClick={handleModal}>
                                    <CustomIcon icon='edit'/>
                                </Fab>
                                <br/>
                            </>}
                            
                            {!!newEquipment && <>
                                <CreateEquipmentForm plant={plant as Plant} points={lubricationPoints || []} handleLubricationPoint={setLubricationPoints} equipment={newEquipment} />
                                <br/>  
                            </>}
                            
                            <CustomButton 
                                icon='refresh' 
                                variant='fab' 
                                color='secondary' 
                                popoverTitle='Resetear datos'
                                action={handleRefresh}
                            />
                        </div>

                        </>}
                    </div>
                </PageContainer>

            
           <Dialog open={!modal} onClose={handleModal} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Configuracion inicial del equipo</DialogTitle>
                <DialogContent>
                    <EquipmentVerification handleNext={handleNext} defaultState={newEquipment} handleState={setNewEquipment}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleModal} color="primary">
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>

         
            {status !== 'loading' && 
                <CreateEquipmentForm 
                variant='fab' 
                plant={plant as Plant} 
                points={lubricationPoints || []} 
                handleLubricationPoint={setLubricationPoints} 
                equipment={newEquipment} />
            }
        
        </FullscreenSurface>
    </>);
}

export default React.memo(CreateEquipmentPage)
