import React, { lazy, Suspense, useMemo, useState } from "react"
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels"
import { USER_ROUTES } from "../../../../shared/routes/routes"
import RouterTabs, { TabsInterface, useRouteTabProps } from "../../../../components/containers/tabs/RouterTabs"
import { useLubricationPointsGridModel } from "../../../../domains/lubricationPoints/lang/useLubricationPointsGridModel"
import { useRoutesByTagFPAndState } from "../../../../domains/routes/services/service.routes"
import { usePeriod } from "../../../../shared/date/usePeriod"
import { useSurveyGridModel } from "../../../../domains/surveys/services/lang/useSurveyGridModel"
import { useObservationsGridModel } from "../../../../domains/observations/lang/useObservationsGridModel"
import { useRoutesGridModel } from "../../../../domains/routes/lang/useRoutesGridModel"
import CustomButton, { CustomIconType } from "../../../../components/buttons/CustomButton"
import LeakLevelComponent from "../../../../domains/infoLubricationPoints/components/LeakLevelComponent"
import EquipmentInfo from "../../../../domains/infoLubricationPoints/components/EquipmentInfo"
import CustomObservationsList from "../../../../domains/observations/CustomObservationsList"
import { CarrousellActinInterface, VirtualGridProps } from "../../../../shared/react-table/VirtualGrid"
import ReScheduleRoute from "../../../../domains/routes/components/ReScheduleRoute"
import { Paper } from "@material-ui/core"
import LubricationPointSurvey from "../../../../domains/surveys/components/LubricationPointSurvey"
import EquipmentSurveysList from "../../../../domains/surveys/pages/EquipmentSurveysList"
import PendingSuppliesToAssign from "../../../../domains/supply/PendingSuppliesToAssign"
import ChangeReviewRequests from "../../../../domains/infoLubricationPoints/components/ChangeReviewRequest"
import { LubricationPointInterface } from "../../../../domains/lubricationPoints/model/lubricationPointsInterface"
import { useFilterState, usePaginationState } from "../../../../shared/react-table/hooks/usePagination"
import { usePaginatedSurveyLubricationPointByPeriod } from "../../../../domains/surveys/services/paginatedSurveyServices"
import { usePaginatedEquipmentsWithCompleteInfo } from "../../../../domains/lubricationPoints/services/paginatedLubricationPointServices"
import { usePaginatedObservationsLubricationPointByPeriod } from "../../../../domains/observations/services/paginatedObservationService"
import { useDynamicFilter } from "../../../../components/filters/DynamicFilter/services/useDynamicFilter"
import { InfoSurveyInterface, SurveyInterface } from "../../../../domains/surveys/model/SurveyInterface"
import SurveyUpdateForm from "../../../../domains/surveys/SurveyUpdateForm/SurveyUpdateForm"
import DeleteObservationForm from '../../../../domains/observations/DeleteObservationForm';
import { ObservationInterface } from "../../../../domains/observations/models/ObservationTypes"
import CUObservationForm from "../../../../domains/observations/CUObservationForm"
import { CrudFormType } from "../../../../shared/form/models/FormTypes"

const ReportsPage:React.FC<{}> = () => {
    const {period,setPeriod} = usePeriod(7)
    const {lang} = useLangLabels()

    // Lubrication Points
    const lubricationPointFilterFn = (data: LubricationPointInterface[]) => data.filter(item => item.state === true) as any
    const { paginationOptions: lubricationPointPaginationOptions } = usePaginationState() 
    const { filterOptions: lubricationPointFilterOptions } = useFilterState(lubricationPointPaginationOptions)
    const { data: {items: lubricationPoints, pagination: lubricationPointPagination }} = usePaginatedEquipmentsWithCompleteInfo(
        lubricationPointPaginationOptions, 
        lubricationPointFilterOptions,
        lubricationPointFilterFn
    )
    const lubricationPointCount = lubricationPointPagination?.count
    const lubricationPointsGridModel = useLubricationPointsGridModel()
    const dynamicLubricationPointFilters = useDynamicFilter(lubricationPoints || [], lubricationPointsGridModel, period, setPeriod, lubricationPointFilterOptions )


     // Routes
    const { data:routes} = useRoutesByTagFPAndState()
    const routesGRidModel = useRoutesGridModel()

    
     // Observations
    const { paginationOptions: observationPaginationOptions } = usePaginationState()
    const { filterOptions: observationFilterOptions } = useFilterState(observationPaginationOptions)
    const { data: { items: observations, pagination: observationPagination } = {} } = usePaginatedObservationsLubricationPointByPeriod(
        period, 
        observationPaginationOptions,
        observationFilterOptions
    )
    const observationCount = observationPagination?.count
    const observationGridModel = useObservationsGridModel()
    const dynamicObservationFilters = useDynamicFilter(observations || [], observationGridModel, period, setPeriod, observationFilterOptions )
    const observationsCarrousellComponents: CarrousellActinInterface = [{
        icon: 'edit',
        popoverTitle: lang.crudActions.update, 
        component: (item: ObservationInterface) => <CUObservationForm  item={item} actionType={CrudFormType.update} />
    }]

     // Surveys
    const { paginationOptions: surveyPaginationOptions } = usePaginationState() 
    const { filterOptions: surveyFilterOptions } = useFilterState(surveyPaginationOptions)
    const { data: { items: surveys, pagination: surveyPagination } = {} } = usePaginatedSurveyLubricationPointByPeriod(
        period, 
        surveyPaginationOptions, 
        surveyFilterOptions
    )
    const surveyCount = surveyPagination?.count
    const surveyGridModel = useSurveyGridModel()
    const dynamicSurveyFilters = useDynamicFilter(surveys || [], surveyGridModel, period, setPeriod, surveyFilterOptions )
    const surveyCarrousellComponents: CarrousellActinInterface = [
        {
            icon: 'edit',
            popoverTitle: lang.crudActions.update,
            component: (item: SurveyInterface & InfoSurveyInterface) => <SurveyUpdateForm item={item} />
        }, 
    ] 

    const VirtualGrid = useMemo(()=>lazy(()=>import('../../../../shared/react-table/VirtualGrid')),[])

    const tabs:TabsInterface<VirtualGridProps>[] = useMemo(()=>[
        {
            label:lang.reports.lubricationPoints,
            icon:'points' as CustomIconType ,
            component:'points',
            route:'',
            data:{
                gridModel:lubricationPointsGridModel,
                items:lubricationPoints,
                paginationOptions: lubricationPointPaginationOptions,
                totalItems: lubricationPointCount,
                customDynamicFilters: dynamicLubricationPointFilters,
                period:undefined,
                carrousellComponent:[
                    /* {icon:'survey',popoverTitle:lang.messages.lastTenSurveys,component:(item:any)=> <EquipmentSurveysList  mode='content' lubricationPoint ={item}/>}, */
                ],
                itemActions:(item:any)=><>
                    {/* para sacar las ultimas surveys convenidas con jere hay que usar el endpoint : SurveysByLubricantPoint */}
                    <EquipmentSurveysList  mode='modal' lubricationPoint ={item}/>
                    {!item.info.pending  && <CustomButton icon='checkedCircle' variant='iconButton' styleProps={{color:'green'}} popoverTitle={lang.reports.equipmentOnDate} /> }
                </>,
                bulkActions:(items:any[])=><>
                    <LubricationPointSurvey outOfRoute={true} items={items} />
                    <EquipmentInfo items={items} variant='modal' />
                    <CustomObservationsList  queryType='lubricationPoint' items={items} />
                    <PendingSuppliesToAssign mode='popover' items={items} />
                    <ChangeReviewRequests items={items}/>
                </>,
                renderOption:(item: any) => item.leakLevel && 
                    <LeakLevelComponent lastlubricantCuantity={item.info.lubricantQuantity} pointCapacity={item.capacity}/>
            }
        },
        {
            label:lang.reports.routes,
            icon:'routes' as CustomIconType,
            component:'routes',
            route:USER_ROUTES.routes,
            data:{
                gridModel:routesGRidModel,
                items:routes,
                renderOption:(item:any)=>item && <ReScheduleRoute item={item}/>,
                period:undefined,
            }
        },
        {
            label:lang.reports.Observations,
            icon:'observations' as CustomIconType,
            component:'observ',
            route:USER_ROUTES.observations,
            data:{
                period:period,
                periodChange:setPeriod,
                gridModel:observationGridModel,
                items:observations,
                paginationOptions: observationPaginationOptions,
                totalItems: observationCount,
                customDynamicFilters: dynamicObservationFilters,
                carrousellComponents: observationsCarrousellComponents,
                itemActions: (item: any) => <DeleteObservationForm observation={item} />,
            }
        },
        {
            label:lang.reports.surveys,
            icon:'survey' as CustomIconType,
            component:'survey',
            route:USER_ROUTES.surveys,
            data:{
                period:period,
                periodChange:setPeriod,
                bulkActions:(items:any)=><CustomObservationsList queryType='survey' items={items} />,
                gridModel:surveyGridModel,
                items:surveys,
                paginationOptions: surveyPaginationOptions,
                totalItems: surveyCount,
                customDynamicFilters: dynamicSurveyFilters,
                carrousellComponents: surveyCarrousellComponents
            }
        },
    ].map((props)=>{
        return{
            ...props,
            component:<Suspense fallback={'...'}>
                <Paper>
                    <VirtualGrid {...{
                    ...props.data,
                        title:props.label,
                        documentExportTitle:`reportes-${props.label}`,
                    }}/>
                </Paper>
            </Suspense>    
        }
    }),[VirtualGrid, lang, lubricationPoints, lubricationPointsGridModel, observationGridModel, observations, period, routes, routesGRidModel, surveyGridModel, surveys] )
    
    return(<>
        <RouterTabs {...useRouteTabProps(3,tabs,{variant:'chip'})} />
    </>)

}

export default ReportsPage

