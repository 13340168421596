import { CircularProgress } from "@mui/material"
import React, { useEffect } from "react"
import CustomButton from "../../components/buttons/CustomButton"
import { useSnackbar } from "../../components/snackbar/hooks/useSnackbar"
import { useClickPosition } from "../../shared/globals/hooks/useClickPosition"
import { ObservationInterface } from "./models/ObservationTypes"
import { useDeleteObservation } from "./services/observationServices"



const DeleteObservation:React.FC<{observation:ObservationInterface}> = ({observation}) => {

    const {deleteObs,query:{data, status:deleteStatus,message}} = useDeleteObservation()
    const {position,getClickPosition} = useClickPosition()
    const {setData} = useSnackbar()

    useEffect(()=>{
        if(deleteStatus === 'error'){
            setData({
                position:position,
                modal:true,
                errors:'ha ocurrido un error',
                severity:'error'    
            })
        }
        if(deleteStatus === 'success'){
            
            setData({
                position:position,
                modal:true,
                errors:message,
                severity:'success'   
            })
        }
    },[deleteStatus,data,message])

    const handleDelete = () => {
        deleteObs({
            tagTGD:observation.tagTGD,
            tagFP:observation.tagFP,
            id:observation.id
        })
    }

    return(
        deleteStatus === 'loading'
        ? <CircularProgress/>
        : <CustomButton 
            status={deleteStatus} 
            action={(e)=>getClickPosition(e,handleDelete)} 
            icon='delete' 
            popoverTitle='Eliminar Observación' 
            variant='iconButton'
        />
    )
}

export default DeleteObservation
