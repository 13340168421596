import React, { useEffect, useState } from 'react';
import { Button, Icon, TextField, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { LubricationPointInterface } from '../../lubricationPoints/model/lubricationPointsInterface';
import { TaskType } from '../tasks/model/TaskInterface';
import { QueryStatus } from 'react-query';
import { LubricantInterface } from '../../lubricant/model/LubricantInterface';
import { useAllLubricantsByPlant } from '../../lubricant/services/service.lubricants';
import { EquipmentFormRegex, TypeFormRegex } from '../../../shared/regExp/regExp';
import { useLubricationPointSurveyController } from '../services/controllers/useLubricationPointSurveyController';
import { suppliesToString } from '../../supply/utils/supplyToString';
import { ObservationInterface } from '../../observations/models/ObservationTypes';
import { bakupAddSurveyWithObservations } from '../../../shared/dexie-js/backup/backupRepository';
import { errorsLog } from '../../../shared/globals/utils/utils';
import CustomButton, { CustomIcon } from '../../../components/buttons/CustomButton';
import PageContainer from '../../../components/containers/PageContainer';
import FlexContainer from '../../../components/containers/FlexContainer';
import SelectLubricantBy from '../../lubricant/molecules/SelectLubricantBy';
import LubricantQuantityCalculator from './LubricantQuantityCalculator';
import SelectAddedReason from '../../added-reason/components/SelectAddedReason';
import AddSupply from '../../supply/molecules/AddSupply';
import AddObservation from '../../observations/components/AddObservation';
import TgdFab from '../../../components/buttons/TgdFab';
import { useSessionContext } from '../../session/store/sessionContext';
import { useDate } from '../../../shared/date/useDate';



const SurveyLubrication:React.FC<{item:LubricationPointInterface,taskType:TaskType,statusController:(status:QueryStatus)=>void,outOfRoute:boolean}> = ({statusController,item,taskType,outOfRoute}) => {

    //COMPONENT STATE
    const tagFP:any = item.tagFP
    const [step,setStep] = useState<any>()
    const { register, handleSubmit, errors, watch, setValue } = useForm()
    const {person,user,selectedOperator} =  useSessionContext()
    const {isToday} = useDate()
    
    //SURVEY INFO

    const { survey,backTaskStep, nextTaskStep, setLubricate, setImpossibleToLubricate, setAddedReason,setLubricant, setQuantity, setTaskDone, setTaskTime, createSurvey, updateEquipmentInfo, createObservation } = useLubricationPointSurveyController(item,outOfRoute)
    const observations = survey?.observations || []
    const anomalies = survey?.anomalies || []
    const taskDone = survey?.lubrication.taskDone
    const taskTime = survey?.lubrication.taskTime
    const addedReason = survey?.lubrication.addedReason 
    const lubricant = survey?.lubrication.lubricant
    const quantity = survey?.lubrication.quantity 
    const needsLubrication = survey?.inspection.needsLubrication
    const impossibleToLubricate = !!survey?.lubrication.impossibleToLubricate
    const supplies = survey?.neededSupplies || []
    const lubricates = survey?.lubrication.lubricates
    const startTime = survey?.inspection.startTime    
/*     console.log(supplies)

    const infoSupplies = supplyStringToInfoSupplyString([...(new Set([
        ...currentEquipmentinfoSupplies,
        ...suppliesToString(supplies.filter((supply)=>supply.state === 'FALTANTE'))
    ]))]) */
    
    const requiredObservation = useAllLubricantsByPlant().data?.find((l:LubricantInterface)=>l.lubricant === (lubricant || ''))?.requiresObservation
 
    //ACTIONS

    useEffect(()=>{
            register({name:'taskDone',value:taskDone})
            register('taskTime',{ 
                required:{value:!!lubricates,message:'requerido'},
                pattern:{value:EquipmentFormRegex.integer,message:'Solo números enteros'},
                validate: !!lubricates ? (value)=>value > 0 ? true : 'requerida' : undefined
            })
            register('addedReason',{
                required:{value:!!lubricates ,message:'requerido'}
            })
            register('lubricant',{
                required:{value:!!lubricates ,message:'Lubricante requerido'}
            })
            register({name:'quantity',type:'number'},{
                required:{value:!!lubricates ,message:'requerida'},
                validate: !!lubricates ? (value)=>value > 0 ? true : 'requerida' : undefined
            })
        
            setValue('taskTime',taskTime)
            setValue('addedReason',addedReason)
            setValue('lubricant',lubricant)
            setValue('quantity',quantity)
       
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[taskTime, taskDone, addedReason, lubricant, quantity, lubricates])
    
    useEffect(()=>{
        if (!needsLubrication) {
            setTaskDone(taskType, true)
        }
    }, [])

    const getTaskDone = () => {
        if(needsLubrication === false) return true
        if(impossibleToLubricate === true) return true
        if(lubricates === true) return true
        else return false
    }

    const submit = async () => {
        statusController('loading')
        const { surveyDone, ...restLubrication } = survey?.lubrication || {};
        const newSurvey = {
            ...survey?.inspection,
            ...restLubrication,
            startTime:survey?.inspection.startTime!,
            inaccessible:!!survey?.inspection.inaccessible,
            tagTGD:survey?.inspection.tagTGD!,
            tagFP:survey?.inspection.tagFP!,
            equipment:survey?.inspection.equipment!,
            sector:survey?.inspection.sector!,
            needsLubrication:!!survey?.inspection.needsLubrication,
            review:survey?.inspection.review!,
            route:survey?.inspection.route!,
            routeDate:survey?.inspection.routeDate!,
            measureUnit:survey?.inspection.measureUnit!,
            manualRegistration:!!survey?.inspection.manualRegistration,
            outOfRoute,
            taskType,
            lubricantType:survey?.lubrication.lubricantType!,
            sampleExtraction:!!survey?.lubrication.sampleExtraction,
            lubricates:!!survey?.lubrication.lubricates,
            impossibleToLubricate,
            taskTime:taskTime!.toString(),
            taskDone:getTaskDone(),
            reason:addedReason!, 
            endTime:isToday(survey?.inspection.startTime!) ? new Date().toISOString() : survey?.inspection.startTime!,
            lubricant:lubricant!,
            quantity:quantity,
            consumables:JSON.stringify(suppliesToString(supplies)),
            user:user?.email!
        } as any

        const newObservations = (id:number) =>{
            return [
                ...anomalies.map((o:ObservationInterface)=>({...o,surveyId:id})),
                ...observations.map((o:ObservationInterface)=>({...o,surveyId:id})),
            ]
        }

        bakupAddSurveyWithObservations(newSurvey,newObservations)

        createSurvey(newSurvey)
        .then((data:any)=>{
            if (survey) {
                survey.lastSurveyId = newSurvey.lubricates ? data.id : item.info?.lastSurveyId;
                survey.lubrication.quantity = newSurvey.lubricates ? data.quantity : item.info?.lubricantQuantity; 
                survey.lubrication.surveyDone = true; 
            }

            createObservation(newObservations(data.id))
            .then((createdobservation:any)=>{
                if(!!createdobservation.error) return statusController('error')
                else{
                    updateEquipmentInfo({
                        ...item.info,
                        ...survey?.inspection,
                        user:data.user,
                        lubricantQuantity:survey?.lubrication.quantity,
                        updatedToday:true,
                        lastSurveyId:survey?.lastSurveyId,
                        lastLubrication: !!survey?.lubrication.lubricates ? startTime! : item?.info?.lastLubrication!,
                        lastInspectionDate: startTime!,
                        updateReview:survey?.inspection.updateReview === false ? item.info?.updateReview : survey?.inspection.updateReview!,
                        labelReplacement:survey?.inspection.labelReplacement === false ? item.info?.labelReplacement : survey?.inspection.labelReplacement!,
                        pending:true,
                    } as any)
                    .then(async (updatedInfoEquipment:any)=>{
                        if(updatedInfoEquipment.error) return statusController('error')
                        else{
                            statusController('success') 
                            nextTaskStep(taskType)
                        }
                    })
                }
            })
        }).catch((err)=> {
            statusController( 'error')
            console.log(err)
        })

    }


    errorsLog(errors)


    return(<>
        
        <div>
            <CustomButton action={()=>backTaskStep(taskType)} icon='before' variant='iconButton' title='Atras' />
        </div>

        {(needsLubrication === true) && <>

                <PageContainer>
                    <FlexContainer>
                      
                            <Button color={lubricates ? 'secondary' : 'inherit' } variant='contained' onClick={() => {
                                setTaskDone(taskType, true)
                                setLubricate(true)
                                setStep(undefined)
                            }}>
                                Lubrica <CustomIcon icon='check'/>
                            </Button>

                            <Button color={((lubricates === false) && (!impossibleToLubricate)) ? 'secondary' :'inherit'} variant='contained' onClick={() => {
                                setTaskDone(taskType, false)
                                setLubricate(false)
                                setStep(undefined)
                            }}>
                                No lubrica <CustomIcon icon='close'/>
                            </Button>

                            <Button color={impossibleToLubricate ? 'secondary' : 'inherit' } variant='contained' onClick={()=>{
                                setTaskDone(taskType, true)
                                setImpossibleToLubricate()
                                setStep(undefined)
                                
                                }}>
                                Imposible de lubricar <CustomIcon icon='close'/>
                            </Button>

                    </FlexContainer>
                </PageContainer>

                {(lubricates === true) && <>

                    {/* maneja la tarea específica de lubricación */}
                    <PageContainer>
                        {/* <SelectLubricant
                            lubricantType={item.lubricantType}
                            plant={plant}
                            onChange={(value)=>setLubricant(value)}
                            error={errors?.lubricant?.message}
                            color={errors?.lubricant?.message ? 'primary' : 'secondary'}
                            defaultValue={lubricant}
                        /> */}
                        <SelectLubricantBy
                            error={errors?.lubricant?.message}
                            tagFP={item.tagFP}
                            onChange={(value:string)=>setLubricant(value)}
                            defaultValue={lubricant}
                            by={{
                                key:'type',
                                value:item.lubricantType
                            }}
                        />
                           
                    
                        <br/>
                        <FlexContainer gap={'12px'}>
                            <TextField
                                name='quantity'
                                variant='outlined'
                                defaultValue={quantity}
                                onChange={(e)=>setQuantity(e.target.value === '' ? 0 : parseFloat(e.target.value))}
                                size='small'
                                style={{width:'100%'}}
                                color={errors?.quantity?.message ? 'primary' : 'secondary'}
                                label={'Cantidad '}
                                inputRef={register({
                                    required:{value:true ,message:'Cantidad requerida'},
                                    pattern:{value:TypeFormRegex.float,message:'Solo números'}
                                })}
                            />
                            
                            <LubricantQuantityCalculator
                            unit={survey?.inspection.measureUnit!} 
                            quantity={watch('quantity') || 0} 
                            onChange={setQuantity} 
                            lubricant={survey?.lubrication.lubricant!} 
                            />

                        </FlexContainer>
                        <br/>

                        <TextField
                            name='taskTime'
                            variant='outlined'
                            defaultValue={taskTime}
                            size='small'
                            onChange ={(event)=> {
                                setTaskTime(taskType,JSON.parse(event.target.value !== ''? event.target.value : '0'))
                            }}
                            style={{width:'100%'}}
                            color={errors?.taskTime?.message ? 'primary' : 'secondary'}
                            label={'Tiempo empleado'}
                            inputRef={register()}
                            helperText={'En minutos'}
                        />

                        <br/>
                        <br/>
                        <SelectAddedReason
                            tagFP={tagFP}
                            onChange={(value:any)=>{
                                setAddedReason(value)
                            }}
                            error={errors?.addedReason?.message}
                            defaultValue={addedReason}
                        />
                    </PageContainer>
                
                    <AddSupply
                        outOfRoute={outOfRoute}
                        listTitle='Insumos' 
                        formTitle={'Nuevo insumo'}
                        buttonTitle={'Insumos'}
                        variant={'list'}
                        buttonType={'add'} 
                        lubricationPoint={item}
                    />

                        {!requiredObservation ? 
                        <FlexContainer  width='100%' align='center' justify='flex-end'>
                            {(!step?.observation && (observations?.length === 0)) && 
                                <CustomButton icon='close' title='Sin Observaciones' action={()=>setStep({observation:'Sin Observaciones'})} />
                            }
                        <AddObservation
                            outOfRoute={outOfRoute}
                            taskType = {taskType}
                            lubricationPoint={item}
                            type='OBSERVACION'
                            buttonTitle='Observaciones'
                            formTitle='Nueva observación'
                            variant={(step?.observation || (observations?.length !== 0))  ? 'list' : 'modal'} 
                            listTitle={(step?.observation) || 'Observaciones'}
                            buttonType='add'
                            />
                        </FlexContainer>

                        :<AddObservation
                            outOfRoute={outOfRoute}
                            taskType={taskType}
                            lubricationPoint={item}
                            type='OBSERVACION'
                            buttonTitle='Observaciones'
                            formTitle='Observación requerida'
                            variant={observations.length > 0 ? 'list' : 'form' } 
                            listTitle={'Observaciones'}
                            buttonType='add'
                        />
                    }
                    
                </>}
                {(lubricates === false) && (!impossibleToLubricate) && <>
                    
                    <AddSupply
                        outOfRoute={outOfRoute}
                        listTitle='Insumos' 
                        formTitle={'Nuevo insumo'}
                        buttonTitle={'Insumos'}
                        variant={'list'}
                        buttonType={'add'} 
                        lubricationPoint={item}
                        freesolo
                        disableUsedSupplies={true}
                    />
                    
                    <AddObservation
                        listTitle='Observaciones'
                        outOfRoute={outOfRoute}
                        taskType = {taskType}
                        type = 'NO LUBRICA'
                        lubricationPoint={item} 
                        variant={observations.length > 0 ? 'list' : 'content' } 
                        formTitle='¿Porqué no lubrica?'
                        buttonType='add'
                    />
                </>}

                <TgdFab
                    disabled={!(step?.observation || (observations?.length !== 0))}
                    bottom={4}
                    right={4}
                    color='primary' 
                    onClick={handleSubmit(submit)}
                    children={<CustomIcon icon='next' />}
                />

                {(impossibleToLubricate === true) && <>
                    
                    <AddObservation 
                        outOfRoute={outOfRoute}
                        taskType = {taskType}
                        type = 'IMPOSIBLE DE LUBRICAR'
                        lubricationPoint={item}
                        listTitle='Observaciones' 
                        variant='content' 
                        formTitle='¿Porqué fue imposible de lubricar?'
                        buttonType='add'
                    />
                    
                    <PageContainer>
                        <AddSupply
                            outOfRoute={outOfRoute}
                            listTitle='Insumos faltantes' 
                            formTitle={'Nuevo Insumo'}
                            buttonTitle={'Insumos'}
                            variant={(supplies?.length > 0) ? 'list' : 'modal'}
                            buttonType={'add'} 
                            lubricationPoint={item}
                        />
                    </PageContainer>
                    
                    <TgdFab 
                        disabled={!(step?.observation || (observations?.length !== 0))}
                        bottom={4}
                        right={4}
                        color='primary' 
                        onClick={handleSubmit(submit)}
                        children={<CustomIcon icon={'next'}/>}
                    />

                </>}
               
                
        </>}
        {(needsLubrication === false) && <>
                <PageContainer>
                    <Typography>El punto no necesita ser Lubricado <br/></Typography>
                </PageContainer>
                <FlexContainer  width='100%' align='center' justify='flex-end'>
                    <br/>
                    {(!step?.observation && (observations?.length === 0)) && 
                    <CustomButton icon='close' title='Sin Observaciones' action={()=>setStep({observation:'Sin Observaciones'})} />
                    }

                    <AddObservation
                        outOfRoute={outOfRoute}
                        taskType = {taskType}
                        lubricationPoint={item}
                        type='OBSERVACION'
                        buttonTitle='observaciones'
                        formTitle='Nueva observación'
                        variant={(step?.observation || (observations?.length !== 0)) ? 'list' : 'modal'} 
                        listTitle={(step?.observation) || 'Observaciones'}
                        buttonType='add'
                    />

                    {(step?.observation || (observations?.length !== 0)) &&
                        <TgdFab 
                            bottom={3}
                            right={4.5}
                            color='primary' 
                            onClick={submit}
                            children={<CustomIcon icon={'next'}/>}
                        />
                    }
                </FlexContainer>
        </>}
    </>)
}

export default React.memo(SurveyLubrication)