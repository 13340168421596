import { Divider, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { QueryStatus } from 'react-query';
import CustomButton, { CustomIcon } from '../../../components/buttons/CustomButton';
import DoNotDoButton from '../../../components/buttons/DoNotDoButton';
import TgdFab from '../../../components/buttons/TgdFab';
import FlexContainer from '../../../components/containers/FlexContainer';
import PageContainer from '../../../components/containers/PageContainer';
import { useDate } from '../../../shared/date/useDate';
import { bakupAddSurveyWithObservations } from '../../../shared/dexie-js/backup/backupRepository';
import { queryClient } from '../../../shared/react-query/react-query-conf';
import { LubricationPointInterface } from '../../lubricationPoints/model/lubricationPointsInterface';
import AddObservation from '../../observations/components/AddObservation';
import { ObservationInterface } from '../../observations/models/ObservationTypes';
import { useSessionContext } from '../../session/store/sessionContext';
import AddSupply from '../../supply/molecules/AddSupply';
import { suppliesToString } from '../../supply/utils/supplyToString';
import { supplyStringToInfoSupplyString } from '../../supply/utils/typeSupplyToInfoSupply';
import { LUBRICATOR_DATA_KEY } from '../../users/lubricator/lubricatorStore';
import { useLubricationPointSurveyController } from '../services/controllers/useLubricationPointSurveyController';
import { TaskType } from '../tasks/model/TaskInterface';


const SurveyClean:React.FC<{item:LubricationPointInterface, taskType:TaskType, statusController:(status:QueryStatus)=>void, outOfRoute:boolean}> = ({outOfRoute, statusController,item,taskType}) => {

    
    const {register, handleSubmit, errors} = useForm()
    const {isToday} = useDate()
    //SURVEY DATA
    const { survey, setTaskTime, nextTaskStep, setTaskDone, createSurvey, createObservation, backTaskStep,updateEquipmentInfo } = useLubricationPointSurveyController(item,outOfRoute)
    const observations = survey?.observations || []
    const anomalies = survey?.anomalies || []
    const taskDone = survey?.cleaning.taskDone
    const taskTime = survey?.cleaning.taskTime
    const supplies = survey?.neededSupplies || []
    const currentEquipmentinfoSupplies = item.info?.supplies?.filter(s=> s !== 'SIN INSUMOS') || []
 
    /* const infoSupplies = supplyStringToInfoSupplyString([...(new Set([
        ...currentEquipmentinfoSupplies,
        ...suppliesToString(supplies.filter((supply)=>supply.state === 'FALTANTE'))
    ]))]) */

    //COMPONENT STATE
    const [step,setStep]  = useState<any>()
    
    //COMPONENT METHODS
    useEffect(()=>{
        register({name:'taskDone',value:taskDone})
        register({name:'taskTime',value:taskTime},{
            required:{value:true,message:'debe introducir un tiempo de tarea'},
            validate:value=>value > 0 || 'debe introducir un tiempo de tarea'
        })
    },[taskTime, taskDone, register])

    const submit = (data?:any) => {
        statusController('loading')
        const { surveyDone: lubricationSurveyDone, ...restLubrication } = survey?.lubrication || {};
        const { surveyDone: cleaningSurveyDone, ...restCleaning } = survey?.cleaning || {};
        const newSurvey:any = {
            ...survey?.inspection,
            ...restLubrication,
            ...restCleaning,
            //TASK DATA
            taskType:taskType,
            taskDone:!!taskDone,
            taskTime:`${taskTime || 0}`,
            endTime:isToday(survey?.inspection.startTime!) ? new Date().toISOString() : survey?.inspection.startTime!, 
            //LUBRICATION DATA
            lubricates:survey?.lubrication.lubricates || false,
            sampleExtraction:false,
            consumables:JSON.stringify(suppliesToString(supplies))
        }

        const newObservations = (id:number) =>{
            return [
                ...(observations.map((o:ObservationInterface)=>({...o,surveyId:id}))),
            ]
        }
        bakupAddSurveyWithObservations(newSurvey,newObservations)
        
        createSurvey(newSurvey)
        .then((data)=>{
            if (survey) {
                survey.cleaning.surveyDone = true; 
            }
            createObservation(newObservations(data.id)).then((obs)=>{
                if(obs.error) statusController('error')
                else updateEquipmentInfo({
                    ...item.info,
                    lastInspectionDate: survey?.inspection.startTime!,
                    lastLubrication: !!survey?.lubrication.lubricates ? survey?.inspection.startTime! : item?.info?.lastLubrication!,
                    updateReview:survey?.inspection.updateReview === false ? item.info?.updateReview : survey?.inspection.updateReview!,
                    labelReplacement:survey?.inspection.labelReplacement === false ? item.info?.labelReplacement : survey?.inspection.labelReplacement!,
                    lastSurveyId: survey?.lastSurveyId ?? item.info?.lastSurveyId, 
                    lubricantQuantity: survey?.lubrication.quantity ?? item.info?.lubricantQuantity,
                } as any).then(async(update)=>{
                    if(update.error) statusController( 'error')
                    else{
                        statusController('success') 
                        nextTaskStep(taskType)
                    }
                })
            })
        }).catch((err)=>{
            statusController('error')
            console.log(err)
        })
    }


    return(<>

            {outOfRoute && /* BACK BUTTON */
                <div>
                    <CustomButton
                        action={()=>backTaskStep(taskType)} 
                        icon='before' 
                        variant='iconButton' 
                        title='Atras' 
                    />
                </div>
            }

            {taskDone === undefined &&    
                <FlexContainer justify='center' align='center' gap='24px' height='100%' width='100%'>
                    <DoNotDoButton action={submit} icon='close' title='Sin limpieza'/>
                    <DoNotDoButton action={()=>setTaskDone(taskType,true)} icon='check' title='Limpieza'/>
                </FlexContainer>
            }

            {!outOfRoute && taskDone === true &&
                <div>
                    <CustomButton
                        action={()=>setTaskDone(taskType,false)} 
                        icon='cancelOutlined' 
                        variant='iconButton'
                        popoverTitle='Cancelar limpieza' 
                    />
                </div>

            }
            
            <>
                {taskDone && <>
                    <PageContainer>
                        <TextField
                        variant='outlined'
                        style={{width:'100%'}}
                        type='number'
                        name={'taskTime'}
                        defaultValue={taskTime}
                        onChange={(event)=>{
                            setTaskTime(taskType,JSON.parse(event.target.value))
                        }}
                        label={(`Tiempo de limpieza ${errors?.taskTime?.message || ''}` || 'Tiempo de limpieza')}
                        helperText='En minutos'
                        color={errors?.taskTime?.message ? 'primary' : 'secondary'}
                        inputRef={register({
                            required:{
                                value:taskDone,
                                message:'Requerido'
                            }
                        })}
                        />
                    </PageContainer>
                    <br/>
                    <Divider/>
                    <br />

                    {/* <FlexContainer flexDirection='column' width='100%'  justify='flex-end'> */}
                        
                        <FlexContainer justify='flex-end'>
                            {(!step?.observation && (survey?.observations?.length === 0)) && 
                            <CustomButton icon='close' action={()=>setStep({observation:'Sin observaciones'})}>
                                Sin observaciones
                            </CustomButton>
                        /*  <Button size='small' variant='contained' color='secondary'  onClick={()=>setStep({observation:'Sin observaciones'})}>
                        <Icon >close</Icon> Sin observaciones
                        </Button> */}

                            <AddObservation
                                outOfRoute={outOfRoute}
                                taskType = {taskType}
                                lubricationPoint={item}
                                type='OBSERVACION' 
                                buttonTitle='observaciones'
                                formTitle='Nueva observación'
                                variant={(step?.observation || (survey?.observations?.length !== 0)) ? 'list' : 'modal'} 
                                listTitle={(step?.observation) || 'Observaciones'}
                                buttonType='add'
                            />
                        </FlexContainer>

                            <AddSupply
                                outOfRoute={outOfRoute}
                                listTitle='Insumos' 
                                formTitle={'Nuevo insumo'}
                                buttonTitle={'Insumos'}
                                variant={'list'}
                                buttonType={'add'} 
                                lubricationPoint={item}
                            />

                        
                    {/* </FlexContainer> */} <br />

                    {(step?.observation || (survey?.observations?.length !== 0)) &&
                         <TgdFab
                         bottom={4}
                         right={4}
                         color='primary' 
                         onClick={handleSubmit(submit)}
                         children={<CustomIcon icon='next' />}
                     />
                    }
                </>}
            </>
    </>)
}

export default React.memo(SurveyClean)