import { InfoLubricationPointInterface } from "../../infoLubricationPoints/model/InfoLubricationPointInterface"

export enum equipmentType {
	equipment='equipment',
	element='element',
	component='component'
}

export interface EquipmentInterface{
    tagTGD: string
    sector: string
    plantTag: string
    tagFP: string
    equipment: string
    type: string
    criticality:string
    lubricationPoints?:LubricationPointInterface[]
    elements?:[]
}

export interface LubricationPointInterface{
    id: number
    tagTGD: string
    sector: string
    plantTag: string
    tagFP: string
    equipment: string
    type: string
    criticality:string
    state: boolean
    egressDate: string
    lowReason: string
    admissionDate: string
    brand: string
    capacity: number
    component: string
    element: string
    function: string
    labelId: number
    location: string
    lubricant: string
    lubricantType: string
    measureUnit: string
    model: string
    observations: string
    oilAnalysis: string | boolean
    review: string
    routes: string[] | []
    supplies: string[] | []
    permissions: string[] | []
    tagTGDBefore:string | null
    info: InfoLubricationPointInterface | undefined
    routeName?:string | undefined
    suggestedQuantity: string
    //route addings to survey
    /* info?:InfoLubricationPointInterface
    routeName?:string
    leakLevel?:LeakLevelInterface

    */
}

export const equipmentCreateSuggestedQuantityFactory = (measureUnit:LubricationPointInterface['measureUnit']) => {
    return `0 ${measureUnit}`
}

export const lubricationPointName = (lubricationPoint:LubricationPointInterface):string =>{
    return `${lubricationPoint.equipment} | ${lubricationPoint.element} | ${lubricationPoint.component}`
} 