import React, { useContext } from 'react';
//components
//material
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

//services

//powerbi
import { Tile  } from 'powerbi-report-component'

import { CircularProgress } from '@material-ui/core';
import { CustomUserPbiData } from '../../../../domains/powerBi/models/powerBiInterfaces';
import { usePbiAccessToken, usePbiHomeDashboard, usePbiTilesInDashboard } from '../../../../domains/powerBi/services/service.pbi';
import NotificationsPage from '../../NotificationsPage';
import { useSessionContext } from '../../../../domains/session/store/sessionContext';


const useStyles = makeStyles((theme) => ({
    content1: {
      display: 'flex',
      gap:'24px',
      flexWrap: 'wrap',
      justifyContent:'center',
      alignItems:'flex-start',
      /* height:'500px', */
      margin: '24px 0px',
      width:'auto',
      '& > *': {
        margin: theme.spacing(0),
        flex:'1',
        minWidth: theme.spacing(30),
        /* maxHeight: theme.spacing(30),
        minHeight: theme.spacing(18), */
        padding:theme.spacing(0),
        },
    },
    root: {
        display: 'flex',
        flexDirection:"column",
        /* height:'fit-content', */
        '& > *': {
        flex:'1',
          margin: theme.spacing(1),
          /* height: 'fit-content', */
        },
    }
  }));

const HomeContent:React.FC<{hide?:{notifications?:boolean},defaultConfig?:CustomUserPbiData}> = ({hide,defaultConfig}) => {
    const {user} = useSessionContext()
    const classes = useStyles();
    const dashboardId = usePbiHomeDashboard(defaultConfig)
    const {data:tiles, isLoading, isSuccess:tilesSuccess,error:tileError} = usePbiTilesInDashboard(defaultConfig)
    const {data:aadToken} = usePbiAccessToken()

    const showNotif = user?.type === 'C' && !hide?.notifications
   
    return (
    <>
        <div className={classes.content1}>
            {tiles && aadToken
            ?   tiles.map((item:any)=>
                    <Paper elevation={3} key={item.id} style={{height:'max-content',padding:'16px'}} >
                        <Tile
                            tokenType='Aad'
                            accessToken={aadToken}
                            embedUrl={item.embedUrl}
                            embedId={item.id}
                            dashboardId={dashboardId}
                            style={{height:'300px', position:'relative',border:'none',}} // style tile for report component
                            /* onClick={(data) => {
                                console.log('Data from tile', data);
                            }}
                            onLoad={(data) => {
                                console.log('Tile loaded', data);
                            }} */
                        />
                    </Paper>
                )
            :   <div>
                    {!tileError
                        ?   <CircularProgress/>
                        :   <Alert severity="error">
                            'No posee graficos destacados, para consultar gráficos destacados, deberá verificar con un administrador que su servicio de trazabilidad de lubricacion esté activo'
                            </Alert>
                    }
                </div>
            }
        </div>
        {showNotif
            ? <NotificationsPage /> 
            : null 
        }
    </>
    )
}

export default HomeContent;
