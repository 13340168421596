import React, { useCallback, useMemo, useState } from "react"
import {NavLink, Routes, Route, Navigate} from 'react-router-dom'
import { useLangLabels } from "../../../shared/lang/services/useLangLabels"
import VirtualGrid, { CarrousellActinInterface } from "../../../shared/react-table/VirtualGrid"
import { useSessionContext } from "../../session/store/sessionContext"
import { useLubricationPointsGridModel } from "../lang/useLubricationPointsGridModel"
import { useEquipmentsByTagFP, useEquipmentsTag, useProcesedEquipmentsByTagFPRefactor } from "../services/lubricationPointsAdapters"
import { equipmentType, LubricationPointInterface } from "../model/lubricationPointsInterface"
import UpdateLubricationPointR from "../components/actions/UpdateLubricationPointR"
import CustomButton from "../../../components/buttons/CustomButton"
import FlexContainer from "../../../components/containers/FlexContainer"
import { EnumSupplyTypes } from "../../supply/model/SupplyInterface"
import IconStatus from "../../../components/IconStatus"
import ChangeRevision from "../components/actions/ChangeRevision"
import ViewLubricationPoint from "../components/actions/ViewLubricationPoint"
import AssignCancelRouteForm from "../../routes/components/AssignCancelRouteForm"
import AssignCancelSupplyForm from "../../supply/molecules/AssignCancelSupplyForm"
import AssignCancelPermissionForm from "../../permisions/components/AssignCancelPermissionForm"
import DesactivateActivateEquipment from "../components/actions/DesactivateActivateEquipment"
import CreateEquipmentPage from "../components/CreateEquipmentPage"
import { AppBar, Box, Divider, Grid, Tab, Tabs, Toolbar, Typography } from '@material-ui/core';
import { useModal } from "../../../components/containers/modal/hooks/useModal"
import TgdEquipmentsTable from "../components/TgdEquipmentsTable"
import CreateLubricationPoint from "../components/actions/CreateLubricationPoint"
import { USER_ROUTES } from "../../../shared/routes/routes"
import FullscreenSurface from "../../../components/containers/modal/FullscreenSurface"
import PageContainer from "../../../components/containers/PageContainer"
import TabPanel from '../../../components/tabs/TabPanel';
import UpdateEquipment from "../components/actions/UpdateEquipment"
import RouterTabs, { TabsInterface, useRouteTabProps } from "../../../components/containers/tabs/RouterTabs"
import UpdateEquipmentSuggestedQuantity from "../components/actions/UpdateEquipmentSuggestedQuantity"


const a11yProps = (index: any) => {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

interface Props{
	virtualGridWidth:string
}


const ManageEquipmentsPage:React.FC<Props> = ({virtualGridWidth}) => {

    const {tagFP} =useSessionContext()
    const {lang} = useLangLabels()


    const lubricationPointsGridModel = useLubricationPointsGridModel()
    const {getTag} = useEquipmentsTag()


    const [draftTab, setDraftTab] = useState<boolean>(false)
    const handleDraftTab = useCallback(() => {
		setDraftTab(!draftTab)
	},[draftTab])

    const {equipments,lubricationPoints,elements} = useProcesedEquipmentsByTagFPRefactor()

    const  items = lubricationPoints?.filter(item => item.state === true) 
    const  draftItems = lubricationPoints?.filter(item => item.state === false)


    const carrousellComponents:CarrousellActinInterface = [
        {icon:'edit',popoverTitle:lang.crudActions.update ,component:( item:LubricationPointInterface ) => <UpdateLubricationPointR mode='content' item={item}/>}
    ]

    const tabs:TabsInterface[] = [
        {
            label:lang.components.title.plural,
            route:USER_ROUTES.components,
            component:
                <VirtualGrid {...{
                    width:virtualGridWidth,
                    gridModel:lubricationPointsGridModel,
                    items:draftTab ? draftItems : items,
                    documentExportTitle:lang.components.title.plural,
                    title:`${lang.components.title.plural} ${draftTab ? 'inactivos' : 'activos'}`,
                    carrousellComponents,
                    headerActions:(item)=><>
                        {!draftTab && <CustomButton icon='delete' popoverTitle={lang.lubricationPoints.options.disabled} action={handleDraftTab} />}	
                        {draftTab && <CustomButton icon='off' popoverTitle={lang.lubricationPoints.options.active} action={handleDraftTab} />}
                    </>,
                    itemActions:(item)=><FlexContainer  gap={'16px'} align='center' flexWrap="wrap">
                        {item.state && <> 
                            <IconStatus state={item?.state} />
                            <UpdateEquipmentSuggestedQuantity item={item} />
                            <ChangeRevision item={item}/>
                            <ViewLubricationPoint item= {item} />
                            <AssignCancelRouteForm item={item} />
                            <AssignCancelSupplyForm type={EnumSupplyTypes.tool} item={item}/>
                            <AssignCancelSupplyForm type={EnumSupplyTypes.protection} item={item}/>
                            <AssignCancelSupplyForm type={EnumSupplyTypes.consumable} item={item}/>
                            <AssignCancelPermissionForm item={item}/>
                        </>}
                        <DesactivateActivateEquipment type={equipmentType.component} tagTGD={getTag(item,equipmentType.component)} item={item}/>
                    </FlexContainer>
                }}/>
        },
        {
            label:lang.elements.title.plural,
            route:USER_ROUTES.elements,
            component:
            <VirtualGrid {...{
                title:lang.elements.title.plural,
                documentExportTitle:lang.elements.title.plural,
                width:virtualGridWidth,
                items:elements,
                gridModel:lubricationPointsGridModel,
            }}/>
        },
        {
            label:lang.lubricationPoints.equipments,
            route:`${USER_ROUTES.equipments}/*`,
            component:
            <VirtualGrid {...{
                width:virtualGridWidth,
                items:equipments,
                title:lang.lubricationPoints.equipments,
                documentExportTitle:lang.lubricationPoints.equipments,
                gridModel:lubricationPointsGridModel,
                headerActions:(value)=><CreateEquipmentPage />,
                itemActions:(item)=> <ManageEquipment item={item}/>
            }}/>
        }
    ]

    const tabProps = useRouteTabProps(5,tabs)

    return (<>
    
        <RouterTabs {...tabProps} />
    
    </>
    )
}


const EquipmentFileInfoVertical:React.FC<{item:any}> = ({item}) => {
    return(<>
        <FlexContainer>
            <UpdateEquipment item={item} />
            <DesactivateActivateEquipment tagTGD={item.tagTGD} item={item} type={equipmentType.equipment} />
        </FlexContainer>
        <Grid container spacing={3}>
			<Grid item md={12}>
				<Box style={{
					backgroundColor:'#f6f6f6',
					maxWidth:'300px',
					maxHeight:'300px',
					minHeight:'200px',
					minWidth:'200px',
					width:'auto',
					height:'auto',
				}}>
						
				</Box>
			</Grid>
			<Grid item md={12} >
				<Grid container spacing={3}>
					<Grid item md={12}>
						Nombre de equipo:
						<Typography variant='body2'><strong>{item?.equipment}</strong></Typography>
						<Divider/>
						<br/>
						Tag de equipo:
						<Typography variant='body2'><strong>{item?.tagTGD}</strong></Typography>
						<Divider/>
					</Grid>
					<Grid item md={12}>
						Tipo:
						<Typography variant='body2'><strong>{item?.type}</strong></Typography>
						<Divider/>
						<br/>
						Sector: 
						<Typography variant='body2'><strong>{item?.sector}</strong></Typography>   
						<Divider/>
					</Grid>
					<Grid item md={12}>
						Tag de planta:
						<Typography variant='body2'><strong>{item?.plantTag}</strong> </Typography>
						<Divider/> 
					</Grid>
					<Grid item md={12}>
						Criticidad del equipo:
						<Typography variant='body2'><strong>{item?.criticality}</strong></Typography>
						<Divider/> 
					</Grid>
				</Grid>
			</Grid>                       
		</Grid>
		<br/>

        
        </>)
}



const ElementSection:React.FC<{elementsTabItems:TabsInterface[]}> = ({elementsTabItems})=>{

    const [value,setValue] = useState(0)
    
    return(<>
        <Tabs
			value={value}
            onChange={(e,newValue)=>setValue(newValue)}
			indicatorColor="secondary"
			textColor="secondary"
			variant="scrollable"
			scrollButtons="auto"
			aria-label="scrollable auto tabs example"
			>
            
            {elementsTabItems.map((item,index:number) => 
                <Tab key={index} label={item?.label} {...a11yProps(index)} />
            )}
            

        </Tabs>
        
            {elementsTabItems.map((item,index:number) =>
                <TabPanel value={value} index={index} key={index} >
                    {item.component}
                </TabPanel>
            )}
        

    </>)
}

export const ManageEquipment:React.FC<{item:any}> = React.memo(({item}) => {

    const {lang} = useLangLabels()
    const {getTag} = useEquipmentsTag()
    const [value,setValue] = useState(0)
    const {data:Equipments} = useEquipmentsByTagFP() 

    
	const lubricationPoints = Equipments?.filter(eq => eq.tagTGD.includes(item.tagTGD))
    

	const elements = useMemo(()=>{
		let flag:string[] = []
		let result:any = {}

		lubricationPoints?.forEach((lp:any)=>{
			const element = lp.element
			if(!!flag.includes(element)){
				result[element] = {
					...result[element],
					lubricationPoints:[
						...result[element].lubricationPoints,
						lp
					]
				}
			}
			else{
				flag.push(element)
				result={
					...result,
					[element]:{
						element: element,
						tagFP:lp.tagFP,
						sector:lp.sector,
						plantTag:lp.plantTag,
						type: lp.type,
						criticality:lp.criticality,
						tagTGD:getTag(lp,equipmentType.element),
						equipment:lp.equipment,
						lubricationPoints: [lp]
					}
				}
			}
		})

		return flag.map((key) =>result[key])

	},[lubricationPoints])

    const currentItem = {
        ...item,
        lubricationPoints
    }

    const {modal,handleModal} = useModal()

    const elementsTabItems = useMemo(()=>elements.map(el => ({
        label:`${el.element}`,
        route:el.element,
        component: 
        <TgdEquipmentsTable 
            height='auto'
            title={`Componentes: ${el.lubricationPoints?.length}`}
            items={el.lubricationPoints || []}
            type={equipmentType.component} 
            headerAction={<CreateLubricationPoint item={currentItem} />}
            itemActions={(item:LubricationPointInterface) => <>
                <ViewLubricationPoint item= {item} /> 
            </>
            }
        />
    })),[elements, item])


    return(<>
        <CustomButton variant='iconButton' icon='config' action={handleModal} popoverTitle='Gestionar Equipo'/>

        <FullscreenSurface  modal={modal} >
            <AppBar>
                <Toolbar>
                    <CustomButton
                        icon='close'
                        color="inherit"
                        action={handleModal}
                        variant='iconButton'
                    />
                    <Typography variant="h6" >
                        {item.equipment}
                    </Typography>

                </Toolbar>
            </AppBar>
            <br/>
            <br/>
            <br/>
            <PageContainer>
                <Grid container spacing={3}>
                    <Grid item md={12}>
                   
                        <Typography variant='h6'>{item?.tagFP ? item?.tagFP.split('|').map((i:string) => `${i} `):''}</Typography>
                        <Divider/> 
                    </Grid>
                    <Grid item md={2}>
                        <EquipmentFileInfoVertical item={item}/>
                    </Grid>
                    <Grid item md={10}>
                        <ElementSection {...{elementsTabItems}}/>
                    </Grid>
                </Grid>
            </PageContainer>
            
        </FullscreenSurface>
    </>)
})


export default React.memo(ManageEquipmentsPage)

