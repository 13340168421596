import React, { useState } from 'react';
import {  TextField } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { Plant } from '../../plants/PlantInterface';
import { equipmentCreateSuggestedQuantityFactory, EquipmentInterface, LubricationPointInterface } from '../model/lubricationPointsInterface';
import { QueryStatus } from 'react-query';
import { ButtonVariant } from '../../../components/buttons/CustomButton';
import { useAllAuxByPlant } from '../../allAux/services/allAuxServices';
import DefaultFormLayout from '../../../components/form/DefaultFormLayout';
import SelectElement from '../../elements/molecules/SelectElement';
import SelectComponent from '../../components/components/SelectComponent';
import SelectLubricant from '../../lubricant/molecules/SelectLubricant';
import SelectTypeBy from '../../types/components/SelectTypeBy';
import { enumTgdTypes } from '../../types/models/types';
import SelectSimpleEntity from '../../../components/autocomplete/old/SelectSimpleEntity';
import { EquipmentFormRegex } from '../../../shared/regExp/regExp';
import { lubricationPointVerificationArray } from '../services/lubricationPointsAdapters';
import SelectSuggestedQuantity from './actions/SelectSuggestedQuantity';

interface Props{
    plant:Plant
    equipment:EquipmentInterface | null | undefined
    defaultState?:LubricationPointInterface
    handleLubricationPoint:any
    points:LubricationPointInterface[]
    variant?:ButtonVariant
}

const useCustomStatus = ()=> {

    const [status, setStatus] = useState<QueryStatus >('idle')
    const [error, setError] = useState<QueryStatus | boolean>(false)
    const [_message, setMessage] = useState<{error:any,success:any}>({
        error:undefined,
        success:undefined
    })

    const handleStatus = (status:'error' | 'success' | 'loading',message?:string) => {
        (status === 'error' ) &&
            setError('error');
                setStatus('error');
                setMessage({
                    ..._message,
                    error:message
                });
            
        (status === 'loading' ) &&
                setStatus('loading');

        (status === 'success' ) &&
                setStatus('success');
                setMessage({
                    ..._message,
                    error:message
                });
            
    }

    const messageExport = () => (status === 'success') ? _message.success : _message.error
    

    return {
        status,
        message:messageExport(),
        error,
        handleStatus
    }

}


const CreateEquipmentForm:React.FC<Props> = ({plant,points,equipment,defaultState,handleLubricationPoint, variant}) => {
    const _tagFP = plant?.tagFP

    const {data:plantAux} = useAllAuxByPlant()
    const lubricants = plantAux?.lubricants

    const { register, handleSubmit, setValue, watch, control, errors,reset } = useForm({
        shouldUnregister: false
    });

    const  {handleStatus,status,message,error} = useCustomStatus()

    const selectedLubricant = lubricants?.find((l:any) => l.lubricant === watch().lubricant)

    const onSubmit = (body:LubricationPointInterface) =>{
            handleStatus('loading')
            handleLubricationPoint([...points,{
                frontId: Date(), 
                tagTGD: equipment?.tagTGD,
                sector: equipment?.sector,
                equipment: equipment?.equipment,
                plantTag: equipment?.plantTag,
                tagFP: equipment?.tagFP,
                type: equipment?.type,
                criticality:equipment?.criticality,
                brand: body.brand,
                model:  body.model,
                capacity: body.capacity,
                component: body.component,
                element: body.element,
                function: !!body.function || 'Sin funcion definida',
                location: body.location,
                lubricant: body.lubricant,
                lubricantType: selectedLubricant?.type,
                measureUnit: body.measureUnit,
                observations: !!body.observations || 'Sin observación ',
                oilAnalysis: !!(body.oilAnalysis === 'SI'),
                review: 1,
                suggestedQuantity:body.suggestedQuantity
            }])
            handleStatus('success','Bien hecho')
            reset()
            
        };

  

   

    return (
            <DefaultFormLayout variant={variant || 'fab'} status={status} message={message} error={error} onChange={handleSubmit(onSubmit)} buttonTitle='Agregar nuevo componente'>              
               
                <TextField
                    inputRef={register({
                        required:{value:true,message:'requerida'},
                    })} 
                    name="brand" 
                    placeholder='Marca del componente' 
                    defaultValue={defaultState?.brand || ''}
                    variant='outlined'
                    label={`Marca del componente ${errors?.brand?.message || ''}`} 
                    color={errors?.brand?.message  ? 'primary' : 'secondary'}
                    size="small"
                    />

                <TextField
                    inputRef={register({
                        required:{value:true,message:'requerido'},
                    })} 
                    name="model" 
                    defaultValue={defaultState?.model || ''}
                    variant='outlined'
                    label={`Modelo del componente ${errors?.model?.message|| ''}` } 
                    color={errors?.model?.message ? 'primary' : 'secondary'}
                    size="small"
                    />

                <TextField
                    name="function" 
                    defaultValue={defaultState?.function || ''}
                    variant='outlined'
                    label={'Función del componente'} 
                    color={errors?.function?.message ? 'primary' : 'secondary'}
                    size="small"
                    />

                <TextField
                    inputRef={register()} 
                    name="location" 
                    defaultValue={defaultState?.location || ''}
                    variant='outlined'
                    label={'Ubicación del componente'} 
                    color={'secondary'}
                    size="small"
                    />

                <TextField
                    inputRef={register()} 
                    name="observations" 
                    defaultValue={defaultState?.location || ''}
                    variant='outlined'
                    label={'Observaciones'} 
                    color={'secondary'}
                    size="small"
                    />

               <Controller
                    as={<SelectElement
                        tagFP={_tagFP}
                        error={errors?.element?.message}
                        onChange={(value)=>setValue('element',value)}
                        defaultValue={watch('element')}
                        />}
                    name='element'
                    defaultValue={defaultState?.element || ''}
                    rules={{
                        required:{value:true, message:'requerido'} 
                    }}
                    control={control}
                />

               <Controller
                    as={<SelectComponent
                        tagFP={_tagFP}
                        error={errors?.component?.message}
                        onChange={(value:string)=> setValue('component',value)}
                        defaultValue={watch('component')}
                    />}
                    name='component'
                    defaultValue={defaultState?.component || ''}
                    rules={{
                        required:{value:true,message:'obligatorio'},
                        validate:{exist: () => lubricationPointVerificationArray(points,watch('element'),watch('component'))}
                    }}
                    control={control}
                />
                
               <Controller
                    as={<SelectLubricant
                        tagFP={_tagFP}
                        error={errors?.lubricant?.message}
                        onChange={(value:string)=>setValue('lubricant',value)}
                        defaultValue={watch('lubricant')}
                        />}
                    name='lubricant'
                    defaultValue={defaultState?.lubricant || ''}
                    rules={{required:{value:true,message:'Campo Obligatorio'}}}
                    control={control}
                />

                <TextField
                    inputRef={register({
                        required:{value:true,message:'Campo Obligatorio'},
                        pattern:{value:EquipmentFormRegex.float, message:'El número no es válido'},
                    })} 
                    name="capacity" 
                    placeholder='Capacidad de lubricante' 
                    defaultValue={defaultState?.capacity || ''}
                    variant='outlined'
                    label={errors?.capacity?.message || 'Capacidad de lubricante'} 
                    color={errors?.capacity?.message ? 'primary' : 'secondary'}
                    size="small"
                    />                

                <Controller
                    as={<SelectTypeBy
                        disabled
                        error={errors?.measureUnit?.message}
                        by={{key:'type',value:enumTgdTypes.unity}}
                        tagFP={_tagFP}
                        onChange={()=>{}/* (value)=>setValue('measureUnit',value) */}
                        defaultValue={selectedLubricant?.measureUnit}
                        />}
                    name='measureUnit'
                    defaultValue={defaultState?.measureUnit}
                    rules={{required:{value:true,message:'Campo Obligatorio'}}}
                    control={control}
                />

                <Controller
                    as={
                        <SelectSimpleEntity
                            error={errors?.oilAnalysis?.message}
                            label={'Análisis de aceite'} 
                            entityList={[{label:'SI',value:true},{label:'NO',value:false}]}
                            showTitle={'label'}
                            onChange={setValue}
                            defaultValue={watch('oilAnalysis')}
                        />
                    }
                    name='oilAnalysis'
                    defaultValue={defaultState?.oilAnalysis || ''}
                    rules={{required:{value:true,message:'obligatorio'}}}
                    control={control}
                />

                <Controller
                   as={
                       <SelectSuggestedQuantity
                            defaultValue={equipmentCreateSuggestedQuantityFactory(watch('measureUnit'))}
                            onChange={(value)=>setValue('suggestedQuantity',value)}
                        />
                   }
                   name='suggestedQuantity'
                   rules={{required:{value:true,message:'obligatorio'}}}
                   control={control}
               />

                
            </DefaultFormLayout>

    );
}

export default CreateEquipmentForm;
