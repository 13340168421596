import { GridModelInterface } from "../../../shared/gridModel/gridModelEntity"
import { useLangLabels } from "../../../shared/lang/services/useLangLabels"



export const useColectorGridModel = () => {
    
    const {lang} = useLangLabels()

    const gridModel:GridModelInterface = {
        id:{label:lang.globalentityValues.id},
        name:{label:lang.collectors.form.name},
        collectorCode:{label:lang.collectors.form.collectorCode},
        serialNumber:{label:lang.collectors.form.serialNumber},
        tagFP:{label:lang.collectors.form.tagFP},
        state:{
            label:lang.collectors.form.state,
            options:[
                {
                    name:lang.collectors.form.active.toUpperCase(),
                    value:true
                },
                {
                    name:lang.collectors.form.disabled.toUpperCase(),
                    value:true
                }
        ]
        },
        admissionDate:{label:lang.collectors.form.admissionDate},
        egressDate:{label:lang.collectors.form.egressDate},
    }
    
    return gridModel
}