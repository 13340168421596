import React from 'react'
import FlexContainer from '../../containers/FlexContainer'
import { TextField } from '@material-ui/core'
import { PeriodInterface } from '../../../shared/date/usePeriod'
import CustomButton from '../../buttons/CustomButton'
import { usePeriodForm } from '../../filters/DynamicFilter/services/usePeriodForm'
import { useLangLabels } from '../../../shared/lang/services/useLangLabels'

interface PeriodFiltersProps{
    defaultPeriodValues: PeriodInterface
    onChange:(period: PeriodInterface | undefined) => void
}

export const PeriodFields:React.FC<PeriodFiltersProps> = ({defaultPeriodValues, onChange}) => {

    const {lang} = useLangLabels()

    const {errors, handlePeriod, periodSubmit, periodValues, handleReset} = usePeriodForm(defaultPeriodValues, onChange)
  return (
    <FlexContainer flexWrap='wrap'>
        <TextField
            focused={!!errors?.firstDate}
            error={!!errors?.firstDate}
            InputLabelProps={{ shrink: true }}
            placeholder={periodValues.firstDate || ''}
            size='small'
            variant='outlined'
            name='firstDate'
            label={errors?.firstDate?.message || lang.filters.from}
            value={periodValues.firstDate}
            type='date'
            onChange={handlePeriod}
        />

        <TextField
            focused={!!errors?.secondDate}
            error={!!errors?.secondDate}
            InputLabelProps={{ shrink: true }}
            placeholder={periodValues.secondDate || ''}
            size='small'
            variant='outlined'
            name='secondDate'
            label={errors?.secondDate?.message || lang.filters.to}
            defaultValue={defaultPeriodValues.secondDate}
            value={defaultPeriodValues.secondDate}
            type='date'
            onChange={handlePeriod}
        />

        <CustomButton icon='search' variant='iconButton' action={periodSubmit} popoverTitle={lang.filters.queryDateRange} />
        <CustomButton icon='delete' variant='iconButton' action={handleReset} popoverTitle={lang.filters.resetDateRange} />
    </FlexContainer>
  )
}
