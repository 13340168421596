import React, { useMemo } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { TablePagination, CircularProgress } from '@material-ui/core';
import { equipmentType, LubricationPointInterface } from '../model/lubricationPointsInterface';
import { InfoLubricationPointInterface } from '../../infoLubricationPoints/model/InfoLubricationPointInterface';
import PageContainer from '../../../components/containers/PageContainer';
import { Alert } from '@material-ui/lab';
import TgdListToolbar from '../../../components/containers/toolbar/TgdListToolbar';
import { useSearchAll } from '../../../shared/globals/hooks/useSearchAll';






const RowsPerPage = [8,25,100,200]
    
    
const lubricationPointColumns:{label:string,atribute:string}[] = [
    {label:'Tag TGD',atribute:'tagTGD'},
    {label:'Equipo',atribute:'equipment'},
    //{label:'Elemento',atribute:'element'},
    {label:'Componente',atribute:'component'},
    {label:'Lubricante',atribute:'lubricant'},
    //{label:'Permisos',atribute:'permissions'}
]

const equipmentColumns:{label:string,atribute:string}[] = [
    {label:'Tag TGD',atribute:'tagTGD'},
    {label:'Sector',atribute:'sector',},
    {label:'Tag Planta',atribute:'plantTag'},
    {label:'Equipo',atribute:'equipment'},
    {label:'Tipo',atribute:'type'},
    {label:'Criticidad',atribute:'criticality'},
    
]

const elementColumns:{label:string,atribute:string}[] = [
    {label:'Tag TGD', atribute:'tagTGD'},
    //{label:'Sector',atribute:'sector',},
    {label:'Elemento', atribute:'element'}
]

interface Props{
    items:any[]
    type:equipmentType
    headerAction?:React.ReactNode
    itemActions:any
    title?:string
    height?:string | undefined | null
    routeAction?:(item:LubricationPointInterface)=>void
    supplyAction?:(item:InfoLubricationPointInterface)=>void
    permissionAction?:(item:LubricationPointInterface)=>void
}

const TgdSimpleTable:React.FC<Props> = ({height,items,type,headerAction,itemActions,title,routeAction,supplyAction,permissionAction})=> {
    
    const rows = useMemo(()=>items || [],[items])

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(8);
    const {search,handleSearch,filteredItems} = useSearchAll(items)

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };


    return (
        (!items) ? <PageContainer><CircularProgress size='24px' color='primary' /></PageContainer> :
        (items?.length < 1) ? <><br/><Alert severity='warning'>No hay Equipos en esta planta</Alert></>:
    <>
        <TgdListToolbar
        title={title ? title : ''}
        searchValue={search}
        handleSearch={handleSearch}
        actions={headerAction}
        searchBar={false}
        /> 
       
        <TableContainer style={{height:height || '45vh'}} component={Paper}>
            <Table size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        
                            {type === 'component' &&
                            lubricationPointColumns.map((item) =>
                                <TableCell key={item.label}>{item.label}</TableCell>
                                
                            )} 

                            {type === 'equipment' &&
                            equipmentColumns.map((item) =>
                                <TableCell key={item.label}>{item.label}</TableCell>
                            )} 

                            {type === 'element' &&
                            elementColumns.map((item) =>
                                <TableCell key={item.label}>{item.label}</TableCell>
                            )} 
                           {/*  {type==='component' && <>
                                <TableCell >Rutas</TableCell>
                                <TableCell >Insumos</TableCell>
                            </>}
                            <TableCell align='right' width={160}>Más</TableCell> */}
                    
                    </TableRow>
                </TableHead>
                <TableBody>
                    {type === 'component' && !!filteredItems &&
                    filteredItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row:any) => (
                        <TableRow key={row.tagTGD}>
                            {lubricationPointColumns.map(({label,atribute}:{label:string,atribute:string}) => 
                            <TableCell key={atribute}>{row[atribute]}</TableCell>    
                            )}
                            <TableCell align='right'>
                                {itemActions && itemActions(row)}  
                            </TableCell>
                        </TableRow>
                    ))}
                    {type === 'equipment' &&
                    filteredItems?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row:any) => (
                        <TableRow key={row.tagTGD}>
                            {equipmentColumns.map(({atribute},index:number) => 
                            <TableCell key={index+atribute}>{row[atribute]}</TableCell>    
                            )}
                            <TableCell align='right'>
                                {itemActions && itemActions(row)}  
                            </TableCell>
                        </TableRow>
                    ))}
                    {type === 'element' &&
                    filteredItems?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row:any) => (
                        <TableRow key={row.tagTGD}>
                            {elementColumns.map((i) => 
                            <TableCell key={row[i.atribute]+i.atribute}>{row[i.atribute]}</TableCell>    
                            )}
                            <TableCell align='right'>
                            {itemActions && itemActions(row)}    
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
        rowsPerPageOptions={RowsPerPage}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        /*v5 deprecation onChangePage={handleChangePage} */
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      
    </>);
    }

export default React.memo(TgdSimpleTable)

