import React,{useEffect} from 'react';
import { useDeleteLubricantVerification } from './services/service.lubricants';
import CustomButton from '../../components/buttons/CustomButton';
import { useSnackbar } from '../../components/snackbar/hooks/useSnackbar';
import { useStatusProcessor } from '../../shared/queries/StatusProcessor';
import { LubricantInterface } from './model/LubricantInterface';
import { useClickPosition } from '../../shared/globals/hooks/useClickPosition';
import { CircularProgress } from '@material-ui/core';

interface Props{
    item:LubricantInterface    
}
const DeleteLubricantform:React.FC<Props> = ({item}) => {
    const { validate, errors, status:validationStatus, query:{status, message}}  = useDeleteLubricantVerification()

    const {position,getClickPosition} = useClickPosition()
    const { setData } = useSnackbar()

    useEffect(()=>{
        if(validationStatus === 'error'){
            const componentsError = (
                (errors?.equipments?.length 
                    ? (errors.equipments.length > 1 
                        ? `${errors.equipments.length} Equipos` 
                        : `${errors.equipments.length} Equipo`) 
                    : '')
            )
         
            const containersError = (
                (errors?.containers?.length 
                    ? (errors.containers.length > 1 
                        ? `${errors.containers.length} Recipientes` 
                        : `${errors.containers.length} Recipiente`) 
                    : '')
            )
            setData({
                modal:true,
                errors:`Este lubricante no se puede eliminar porque está siendo usado en: ${containersError && containersError} ${componentsError && componentsError}` ,
                severity:'warning',
                position:position
            })
        }
        if(validationStatus === 'success'){
            setData({
                modal:true,
                errors:message,
                severity:status ? 'success' : 'warning',
                position:position
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[validationStatus])

    return (
        validationStatus === 'loading' || status === 'loading' 
        ? <CircularProgress size='24px' /> 
        : <CustomButton 
            popoverTitle='Eliminar lubricante'
            action={(e:any)=>getClickPosition(e,()=>validate(item))}
            status={status} 
            variant='iconButton' 
            icon ='delete' 
          />
    );
}

export default React.memo(DeleteLubricantform);
