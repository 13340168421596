import React, {  } from 'react'
import { Outlet,} from 'react-router-dom'
import Layout from '../../layout/Layout'

const UserPanel:React.FC<{}> = ({}) => {
  return (<>
    <Layout>
        <Outlet/>
    </Layout>
  </>
  )
}

export default UserPanel

