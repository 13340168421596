import { useEffect, useMemo, useState } from "react"
import { PeriodChange } from "../../components/filters/DynamicFilter/services/usePeriodForm"


export interface PeriodInterface {firstDate:string,secondDate:string}

type dayPeriods = number |7|30|60|120|365

const dateYYYYMMDD = (date:string) => date.split('T')[0] 

const periodInTime = (period:dayPeriods)=> {
    return period * 24 * 60 * 60 * 1000
}

const getPeriodDate = (date:Date, period?:number) => {
    const secondDateTime = date.getTime()
    const resultPeriodDate = secondDateTime - periodInTime(period || 7)
    const result = dateYYYYMMDD(new Date(resultPeriodDate).toISOString())
    return result
}

const initialPeriod = (defaultPeriod?:dayPeriods) => {
    const date = new Date()
    const today = dateYYYYMMDD(date.toISOString())

    return {
        firstDate:getPeriodDate(date,defaultPeriod || 7), 
        secondDate:today
    }
}
export const usePeriod = (defaultPeriod?:dayPeriods) =>{

    

    
    const [period,setPeriod] = useState<PeriodInterface >(initialPeriod(defaultPeriod))


    const handlePeriod = (period: PeriodInterface | undefined): void => {
        setPeriod(period || initialPeriod(defaultPeriod))
    }
   /*  useEffect(()=>{
        
        setPeriod({
            firstDate:getPeriodDate(defaultPeriod || 7), 
            secondDate:today
        })
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[defaultPeriod]) */

    /* const returnPeriod = useMemo(()=>period,[period]) */

    return {
        period,
        setPeriod: handlePeriod
    }
}