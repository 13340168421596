import React,{useEffect} from 'react';
import { useDeleteElementVerification } from './services/service.elements';
import TgdPopover from '../../components/popovers/TgdPopover';
import CustomButton, { CustomIcon } from '../../components/buttons/CustomButton';

import { CircularProgress, IconButton } from '@material-ui/core';
import { ElementInterface } from './models/ElementInterface';
import { useClickPosition } from '../../shared/globals/hooks/useClickPosition';
import { useStatusProcessor } from '../../shared/queries/StatusProcessor';
import { useSnackbar } from '../../components/snackbar/hooks/useSnackbar';
import { useLangLabels } from '../../shared/lang/services/useLangLabels';



interface Props{
    item:ElementInterface
}

const DeleteElementForm:React.FC<Props> = ({item}) => {

    const {errors,status:deleteStatus,validate,query} = useDeleteElementVerification()
    const { status, message } = useStatusProcessor(query)
    const {position,getClickPosition} = useClickPosition()
    const { setData } = useSnackbar()

    useEffect(()=>{
        if(deleteStatus === 'error'){
            const componentsError = (
                (errors?.equipments?.length 
                    ? (errors.equipments.length > 1 
                        ? `${errors.equipments.length} Equipos` 
                        : `${errors.equipments.length} Equipo`) 
                    : '')
            )
            setData({
                modal:true,
                errors:`Este elemento no se puede eliminar porque está siendo usado en: ${componentsError}` ,
                severity:'warning',
                position:position
            })
        }
        if(status === 'success'){
            setData({
                modal:true,
                errors:message,
                severity:'success',
                position
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[deleteStatus,status,errors])
        

    const {lang} = useLangLabels()
    const handleDelete = (e:any) => {
        getClickPosition(e,()=>validate(item))
    }

    return (
        deleteStatus === 'loading' || status === 'loading'
        ? <CircularProgress size='24px'/>
        : <CustomButton
            variant='iconButton'
            icon='delete'
            status={deleteStatus}
            popoverTitle={lang.actionTypes.deleteItem}
            action={handleDelete}
        />
        
    );
}

export default DeleteElementForm;
