import { CircularProgress, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { QueryStatus } from 'react-query';
import theme from '../../../assets/theme';
import CustomButton, { CustomIcon } from '../../../components/buttons/CustomButton';
import TgdFab from '../../../components/buttons/TgdFab';
import FlexContainer from '../../../components/containers/FlexContainer';
import PointerContainer from '../../../components/containers/PointerContainer';
import { bakupAddSurveyWithObservations } from '../../../shared/dexie-js/backup/backupRepository';
import { errorsLog } from '../../../shared/globals/utils/utils';
import { queryClient } from '../../../shared/react-query/react-query-conf';
import { CriticalityInterface, CriticalityTypes } from '../../criticalities/models/criticalityInterface';
import { useCriticalityByTypeByTagFP } from '../../criticalities/services/service.criticality';
import { LubricationPointInterface } from '../../lubricationPoints/model/lubricationPointsInterface';
import { ObservationInterface } from '../../observations/models/ObservationTypes';
import { useSessionContext } from '../../session/store/sessionContext';
import AddSupply from '../../supply/molecules/AddSupply';
import { suppliesToString } from '../../supply/utils/supplyToString';
import { LUBRICATOR_DATA_KEY } from '../../users/lubricator/lubricatorStore';
import { CreateSurveyInterface } from '../model/SurveyInterface';
import { useLubricationPointSurveyController } from '../services/controllers/useLubricationPointSurveyController';
import { SurveyTasksValues, TaskType } from '../tasks/model/TaskInterface';


const SurveyPuntuation:React.FC<{item:LubricationPointInterface, onFinish:()=>void, taskType:TaskType,statusController:(status:QueryStatus)=>void, outOfRoute:boolean}> = ({outOfRoute, statusController, taskType,item,onFinish}) => {

    const {person,selectedOperator} = useSessionContext()
    const {register, handleSubmit, errors, setValue, watch} = useForm()
    
    //SURVEY DATA
    const { survey, createSurvey, setAssetCriticality ,updateEquipmentInfo, finishPointOnRoute, backTaskStep, createObservation, resetSurvey } = useLubricationPointSurveyController(item,outOfRoute)
   
    const anomalies = survey?.anomalies || []
    const selectedCriticality = survey?.puntuation?.assetCriticality
    const supplies = survey?.neededSupplies?.map(({type,supply})=>({
        type,
        supply,
        state:undefined
    })) || []
    const currentEquipmentinfoSupplies = item.info?.supplies?.filter(s=> s !== 'SIN INSUMOS') || []
 
    const infoSupplies = [...(new Set([
        ...currentEquipmentinfoSupplies,
        ...suppliesToString(supplies)
    ]))]

    useEffect(()=>{
        register({name:'assetCriticality',value:selectedCriticality},{required:{value:true,message:'Seleccionar una criticidad es requerido'}})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    const finish = /* () => finishSurvey( */async () => {
        await queryClient.refetchQueries(`${LUBRICATOR_DATA_KEY}-${person?.email}-${selectedOperator?.lubricatorNumber}`)
        .then(()=>{
            onFinish()
            if(!outOfRoute){
                finishPointOnRoute() 
            }
        })     
    }

    const {data:criticalities,status} = useCriticalityByTypeByTagFP(CriticalityTypes.puntuation)
    //COMPONENT METHODS
    const submit = () => {
        statusController('loading')
        if(!(survey?.lubrication.surveyDone || survey?.cleaning.surveyDone || survey?.repairment.surveyDone)){ 
            
            const newSurvey:any = {
                ...survey?.inspection,
                taskDone:true,
                taskTime:'0',
                quantity:0,
                outOfRoute:outOfRoute,
                taskType:SurveyTasksValues.control,
                manualRegistration:true,
                review:item.review,
                lubricant:item.lubricant,
                measureUnit:item.measureUnit,
                lubricantType:item.lubricantType,
                impossibleToLubricate:false,
                sampleExtraction:false,
                lubricates:false,
                reason:'',
                route:'',
                endTime:new Date().toISOString(),
                consumables:JSON.stringify([]),
            } as CreateSurveyInterface

            const newObservations = (id:number) =>{
                return [
                    ...anomalies.map((o:ObservationInterface)=>({
                        ...o,
                        surveyId:id,
                        taskType:SurveyTasksValues.control
                    })),
                ]
            }
            bakupAddSurveyWithObservations(newSurvey,newObservations)

            createSurvey(newSurvey)
            .then((data)=>{
                createObservation(newObservations(data.id))
                .then(()=>{
                    updateEquipmentInfo({
                        ...item.info,    
                        supplies:infoSupplies.length === 0 ? ["SIN INSUMOS"] : infoSupplies,
                        lastInspectionDate: survey?.inspection.startTime!,
                        lastLubrication: !!survey?.lubrication.lubricates ? survey?.inspection.startTime! : item?.info?.lastLubrication!,
                        assetCriticality:selectedCriticality!,
                        pending:outOfRoute ? item.info?.pending : false,
                        updateReview:survey?.inspection.updateReview === false ? item.info?.updateReview : survey?.inspection.updateReview!,
                        labelReplacement:survey?.inspection.labelReplacement === false ? item.info?.labelReplacement : survey?.inspection.labelReplacement!,
                        lastSurveyId: survey?.lastSurveyId ?? item.info?.lastSurveyId, 
                        lubricantQuantity: survey?.lubrication.quantity ?? item.info?.lubricantQuantity,
                    } as any)
                    .then((data)=>{
                        if(data.error) statusController('error')
                        resetSurvey()
                        finish()
                        statusController('success')
                    })
                })
            })
            .catch((err)=>{
                statusController('error')
                console.log(err)
            })
        }
        else{
            updateEquipmentInfo({
                ...item.info,           
                supplies:infoSupplies.length === 0 ? ["SIN INSUMOS"] : infoSupplies,
                lastInspectionDate: survey?.inspection.startTime!,
                lastLubrication: !!survey?.lubrication.lubricates ? survey?.inspection.startTime! : item?.info?.lastLubrication!,
                assetCriticality:selectedCriticality!,
                pending:outOfRoute ? item.info?.pending : false,
                updateReview:survey?.inspection.updateReview === false ? item.info?.updateReview : survey?.inspection.updateReview!,
                labelReplacement:survey?.inspection.labelReplacement === false ? item.info?.labelReplacement : survey?.inspection.labelReplacement!,
                lastSurveyId: survey?.lastSurveyId ?? item.info?.lastSurveyId, 
                lubricantQuantity: survey?.lubrication.quantity ?? item.info?.lubricantQuantity,
            } as any).then((data)=>{
                if(data.error) statusController('error')
                resetSurvey()
                finish()
                statusController('success')
            }).catch((err)=>{
                statusController('error')
            })
        }
        
    } 
    
    errorsLog(errors)

    const defaultColor = (item:CriticalityInterface) => (
        selectedCriticality
            ? selectedCriticality === item.description 
                ? item.color
                : theme.palette.grey[500]
            : item.color
    )

    return(<>
            
        {outOfRoute && /* BACK BUTTON */
            <div>
                <CustomButton
                    action={()=>backTaskStep(taskType)} 
                    icon='before' 
                    variant='iconButton' 
                    title='Atras' 
                />
            </div>
        }
        <FlexContainer height='100%' flexDirection='column' padding='24px' align='center' justify='center'>
            <Typography variant='h6' >Estado general del punto </Typography>
                <br />
                <FlexContainer justify='center'>
                    <FlexContainer flexWrap='wrap' justify='center'>
                        {status === 'loading' 
                            ? <CircularProgress size='40px'/>
                            : criticalities?.map((item,index)=>
                                    <FlexContainer 
                                    key={index} 
                                    align='center'
                                    >
                                        <PointerContainer>
                                            <FlexContainer 
                                            onClick={()=>{
                                                setAssetCriticality(item)
                                                setValue('assetCriticality',item)
                                            }} 
                                            borderRadius='10px' 
                                            width='290px' 
                                            height='50px' 
                                            background={defaultColor(item)} 
                                            color='#FFF'
                                            style={{textAlign:'center'}} 
                                            justify='center' 
                                            align='center' 
                                            children={item.description} />
                                        </PointerContainer>
                                    </FlexContainer>

                            )
                        }
                </FlexContainer>
            
            </FlexContainer>
            <br/>
            <AddSupply
                outOfRoute={outOfRoute}
                listTitle='Insumos del equipo' 
                formTitle={'Nuevo insumo'}
                buttonTitle={'Nuevo insumo'}
                variant={'list'}
                buttonType={'add'} 
                lubricationPoint={item}
                freesolo
                taskType={taskType}
                inmutableValues= {item.supplies}
            />

            <TgdFab 
                disabled={!watch('assetCriticality')}
                bottom={4}
                right={4}
                color={watch('assetCriticality') ? 'primary' : 'inherit'} 
                onClick={handleSubmit(submit) }
                children={<CustomIcon icon='check'/>}
            />

        </FlexContainer>

    </>)
}

export default React.memo(SurveyPuntuation)