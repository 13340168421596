import React, { Children, useEffect } from "react"
import { CustomIconType } from "../../components/buttons/CustomButton"
import FlexContainer from "../../components/containers/FlexContainer"
import HandleUndefined from "../../components/containers/handleUndefined/HandleUndefined"
import { useCarrousellModal } from "../../components/containers/modal/carrousellModal"
import { useModal } from "../../components/containers/modal/hooks/useModal"
import Modal from "../../components/containers/modal/Modal"
import CarrousellToolbar from "../../components/containers/toolbar/CarrousellToolbar"
import { useCarrousell } from "../../components/containers/toolbar/hooks/useCarrousell"
import { valueFromFlatObjectkey } from "../globals/utils/valueFromFlatObjectkey"
import { useLangLabels } from "../lang/services/useLangLabels"
import { language, useLang } from "../lang/services/useLanguages"



export interface CarrousellActionsProps<T=any>{
    items?:T[]
    carrousellComponents:{
        icon:CustomIconType
        component:(item:T)=>React.ReactNode,
        popoverTitle?:string
    }[]
}


const CarrousellActions:React.FC<CarrousellActionsProps> = ({items,carrousellComponents}) => {
    
    const actionValidation =  !!(items && items.length > 0)
    const modalProps = useModal()
    const carrouselProps = useCarrousell(items?.length || 0)
    const item = items && items[carrouselProps.current]
    const {lang} = useLangLabels()

    /* useEffect(()=>{
        !item?.id && modalProps.setModal(false)
    },[item]) */

    return(
    <FlexContainer flexWrap="wrap">
       {carrousellComponents.map(({icon,component,popoverTitle},index) =>
            <HandleUndefined key={index} isError={actionValidation} callback={modalProps.onOpen} >
                {(callback)=>
                    <Modal {...{
                        ...modalProps,
                        onOpen:callback,
                        icon,
                        variant:'iconButton',
                        popoverTitle:popoverTitle 
                    }}>
                        <CarrousellToolbar {...carrouselProps} />
                        <div  key={carrouselProps.current} >
                            {component(item)}
                        </div>
                    </Modal>
                }
            </HandleUndefined>
       )}

    </FlexContainer>)
}

export default React.memo(CarrousellActions)