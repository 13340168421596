import React, { useMemo } from 'react'

import styled from '@emotion/styled'
import FlexContainer from './containers/FlexContainer'
import Title from './Typography/Title'
import { getGridModelkey, getGridModelValue, GridModelInterface } from '../shared/gridModel/gridModelEntity'

const Text = styled('div')`
    font-size:14px;
    margin:0px;
`



const EntityProperties:React.FC<{title?:string, entity:any,gridModel:GridModelInterface}> = ({title,entity,gridModel}) => {

    const keyValues = entity ? Object.entries(entity) : []

    return(
        <FlexContainer flexDirection='column' padding='12px'>
            <Title>
                <strong>{title && title}</strong>
            </Title>
            {keyValues && keyValues.map(([key,value]:any)=>
                value === null || value === undefined ? null :
                <Text key={key}>
                    {getGridModelkey(key,gridModel)}: {getGridModelValue(key,value,gridModel)}
                </Text>
                
            )}
        </FlexContainer>
    )

}
export default React.memo(EntityProperties)