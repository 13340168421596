import React, { useState } from 'react';
import SurveyInspection from './SurveyInspection';
import SurveyLubrication from './SurveyLubrication';
import SurveyRepairment from './SurveyRepairment';
import SurveyClean from './SurveyClean';
import SurveyPuntuation from './SurveyPuntuation';
import { CircularProgress } from '@material-ui/core';
import { LubricationPointInterface } from '../../lubricationPoints/model/lubricationPointsInterface';
import { useLubricationPointSurveyController } from '../services/controllers/useLubricationPointSurveyController';
import { SurveyTasksValues, TaskType } from '../tasks/model/TaskInterface';
import QueryStatus from '../../tickets/status/QueryStatus';
import FlexContainer from '../../../components/containers/FlexContainer';


interface Props{
    item:LubricationPointInterface
    outOfRoute:boolean
    onFinish:()=> void
    tasks:any
}


const CreateSurveyForm:React.FC<Props> = ({tasks, item, outOfRoute, onFinish}) => {

    const { survey, setGlobalStatus } = useLubricationPointSurveyController(item,outOfRoute)
    const taskType:TaskType = (survey && tasks ) && tasks[survey.taskStep].type 
/*     const [ status, setStatus ] = useState({status:'idle',data:undefined})
 */
    return( survey?.globalStatus === 'loading' 
    ? <FlexContainer align='center' justify='center' height='100%' width='100%'>
            <CircularProgress size='40px'/>
      </FlexContainer> 
    :<FlexContainer flexDirection='column' width='100%' margin={'0px 0px 200px 0px'}>

        {(taskType === SurveyTasksValues.inspection) && 
            <SurveyInspection
            taskType={taskType} 
            item={item} 
            outOfRoute={outOfRoute}
            statusController={setGlobalStatus}
            onFinish={onFinish} 
            />
        }

        {(taskType === SurveyTasksValues.lubrication) && 
            <SurveyLubrication 
            taskType={taskType}
            item={item}
            outOfRoute={outOfRoute}
            statusController={setGlobalStatus}
            />
        }

        {(taskType === SurveyTasksValues.cleaning) && 
            <SurveyClean
            taskType={taskType}
            item={item}
            outOfRoute={outOfRoute}
            statusController={setGlobalStatus}
            />
        }

        {(taskType === SurveyTasksValues.repairment) && 
            <SurveyRepairment
            taskType={taskType}
            item={item}
            outOfRoute={outOfRoute}
            statusController={setGlobalStatus}
            />
        }

        {(taskType === SurveyTasksValues.puntuation) && 
            <SurveyPuntuation
            taskType={taskType}
            item={item}
            onFinish={onFinish}
            statusController={setGlobalStatus}
            outOfRoute={outOfRoute}
            />
        }

        <br />
        <br />
        <br />
        <br />

    </FlexContainer>)
}


export default CreateSurveyForm;
