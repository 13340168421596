import { createFilterOptions } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import SimpleAutocompleteSelect from '../../../../components/autocomplete/SimpleAutocompleteSelect';
import { useEquipmentsByTagFP } from '../../services/lubricationPointsAdapters';
import { LubricationPointInterface } from '../../model/lubricationPointsInterface';

interface Props{
    onChange:(item?:any)=>void
    complete?:boolean
    equipmentName?:boolean
    defaultValue?:any,
    clearable?:boolean
    children?:React.ReactNode
    fromItems?: LubricationPointInterface[]
}


const lubricationPointByTagTGD = (items:any[] | undefined,tagTGD:string) => {
    return items?.find((i)=>i.tagTGD === tagTGD)
}

const SelectLubricationPoint:React.FC<Props> = ({children, onChange,complete,equipmentName, defaultValue, clearable, fromItems}) => {

    const {data:LubricationPoints} = useEquipmentsByTagFP()
    const separator = ` >> `
    const  [state, setstate] = useState<any>();
    const customName = `${state?.equipment}${separator}${state?.element}${separator}${state?.component}`

    const itemsPool = fromItems ? fromItems : LubricationPoints
    useEffect(() => {
        complete 
        ? equipmentName 
            ? onChange(customName) 
            : onChange({...lubricationPointByTagTGD(itemsPool,state),customName})
        : onChange(state)
    }, [state]);

    return (
        <SimpleAutocompleteSelect
            clearable={clearable}
            filterOptions={createFilterOptions({
                matchFrom: 'any',
                stringify: (option:any) => option.equipment,
            })}
            items={itemsPool || []}
            label='Seleccionar punto de lubricación'
            showTitle={'tagTGD'}
            onChange={(lubricationPoint:any) => setstate(lubricationPoint)}
            renderOption={(option)=><>
                {option.equipment}{separator}{option.element}{separator}{option.component}
            </>}
            defaultValue={defaultValue}
            children={children}
        />
    );
}

export default SelectLubricationPoint;
