import React from 'react';

import { ContainerCRUDDependencies, CreateContainer, DeleteContainer, UpdateContainer } from './services/service.containers';
import FieldSelector from '../../shared/form/components/FieldSelector';
import { useContainerForm } from './services/useContainerForm';
import { CUEntityFormPropsType, uiFormInterface } from '../../shared/form/crud/types/crudTypes';
import { ContainersInterface } from './ContainersInterfaces';
import { useCreateUpdate } from '../../shared/form/crud/hooks/useCreateUpdate';
import { CrudFormType } from '../../shared/form/models/FormTypes';
import { useLangLabels } from '../../shared/lang/services/useLangLabels';
import DefaultFormLayout from '../../components/form/DefaultFormLayout';
import { useSessionContext } from '../session/store/sessionContext';

type props = CUEntityFormPropsType<ContainersInterface>

const CUContainerForm: React.FC<props> = ({item, actionType}) => {

    const {tagFP} = useSessionContext()

    //DB 
    const { action, resetStatus,status, error, message } = useCreateUpdate({
        create: CreateContainer,
        update: UpdateContainer,
        delete: DeleteContainer
    },actionType, ContainerCRUDDependencies)

    //UI
    const { lang } = useLangLabels()

    const { uiModel, fields, handleSubmit, reset } = useContainerForm(tagFP, actionType, item)

    const ui = (() => {
        const formName = lang.containers.title.plural 
        switch (actionType) {
            case CrudFormType.create:
                const create:uiFormInterface = {
                    icon:'add',
                    title:`${lang.actionTypes.create} ${formName.toLowerCase()}`,
                    variant:'button',
                    mode:'modal'
                }
                return create

            case CrudFormType.update:
                const update:uiFormInterface = {
                    icon:'edit',
                    title:`${lang.actionTypes.update} ${formName.toLowerCase()}`,
                    variant:'iconButton',
                    mode:'content'
                }
                return update
        }
    })()

    //Methods
    const submit = (data:ContainersInterface) => {
        switch(actionType){
            case CrudFormType.create:
                action({
                    ...data,
                    lubricant:fields.lubricant.value.lubricant,
                    lubricantType:fields.lubricant.value.type
                })

            break
            
            case CrudFormType.update:
                action({
                    ...item,
                    ...data
                })
            break
        }
    }

    const handleReset = () => {
        resetStatus()
        reset()
    }


    return(
        <DefaultFormLayout
            mode={ui.mode}
            icon={ui.icon}    
            variant={ui.variant}
            buttonTitle={ui.title}
            popoverTitle={ui.title}
            {...{status,error,message}} 
            onChange={handleSubmit(submit)}  
            onClose={handleReset} 
        >
            {uiModel.map((props,index)=>
                <FieldSelector key={index } fieldProps={props} />
            )}
        </DefaultFormLayout>
    )
};

export default CUContainerForm;