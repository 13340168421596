import React, { useEffect } from 'react';
import CustomButton from '../../../../components/buttons/CustomButton';
import { useSnackbar } from '../../../../components/snackbar/hooks/useSnackbar';
import { useClickPosition } from '../../../../shared/globals/hooks/useClickPosition';
import { LubricationPointInterface } from '../../model/lubricationPointsInterface';
import { useUpdateEquipmentNextRevision } from '../../services/lubricationPointsAdapters';




interface Props{
    item:LubricationPointInterface
}



const ChangeRevision:React.FC<Props> = ({item}) => {

    const {updateEquipmentNextRevision, status,error,message} = useUpdateEquipmentNextRevision()

    const info ='Está cambiando a la siguiente revision de este componente, está seguro?. No se podrá volver a una revision anterior, deberá contactar al equipo de soporte para realizarlo.'

    const handleNextRevision = () => {
        const alert = window.confirm(`${info}`)
        if(alert){   
            updateEquipmentNextRevision(item)
        }
    }

    const { getClickPosition, position } = useClickPosition()

    const {setData} = useSnackbar()

    useEffect(()=>{
        if(status === 'success'){
            setData({
                modal:true,
                errors:message || 'Todo salió bien' ,
                severity:'success',
                position:position
            })
        }
        if(status === 'error'){
            setData({
                modal:true,
                errors:message || 'Ha ocurrido un error inesperado',
                severity:'warning',
                position:position
            })
        }

    },[status])



    return (
        <CustomButton 
            status={status} 
            variant='iconButton' 
            color='primary' 
            popoverTitle='Cambio automático de revision' 
            icon='nextState' 
            action={(e)=>getClickPosition(e,handleNextRevision)}
        />
    );
}

export default ChangeRevision;
