import React from 'react';
import { usePlantAreas } from '../../services/service.areas';

import { useLangLabels } from '../../../../shared/lang/services/useLangLabels';
import { useSessionContext } from '../../../session/store/sessionContext';
import VirtualGrid, { CarrousellActinInterface } from '../../../../shared/react-table/VirtualGrid';
import { AreaInterface } from '../../models/AreaTypes';
import CUAreaForm from '../../CUAreaForm';
import { CrudFormType } from '../../../../shared/form/models/FormTypes';
import { useAreasGridModel } from '../../lang/useAreasGridModel';
import DeleteAreaForm from '../../DeleteAreaForm';

interface Props{
    virtualGridWidth:string
}

const ManageAreaPage:React.FC<Props> = ({virtualGridWidth}) => {
    
    const {lang} = useLangLabels()
    const {data:areas} = usePlantAreas()
    
    const carrousellActions:CarrousellActinInterface = [
        {icon:'edit',popoverTitle:lang.crudActions.update,component:(item:AreaInterface)=><CUAreaForm item={item} actionType={CrudFormType.update} />}
    ] 

    return(<>

        <VirtualGrid
            {...{
                title:lang.areas.title.plural,
                width:virtualGridWidth,
                items:areas,
                gridModel:useAreasGridModel(),
                carrousellComponents:carrousellActions,
                headerActions:(item:any)=> <CUAreaForm actionType={CrudFormType.create} />,
            }}
        >
            {(items)=>
                items.length === 1
                ? <DeleteAreaForm item={items[0]}/>
                : <></>
            }
        </VirtualGrid>

    </>)
    
}

export default React.memo(ManageAreaPage);
