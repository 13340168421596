import React from 'react';
import DefaultFormLayout from '../../components/form/DefaultFormLayout';

import FieldSelector from '../../shared/form/components/FieldSelector';
import { useCreateUpdate } from '../../shared/form/crud/hooks/useCreateUpdate';
import { uiFormInterface } from '../../shared/form/crud/types/crudTypes';
import { CrudFormType } from '../../shared/form/models/FormTypes';
import { useLangLabels } from '../../shared/lang/services/useLangLabels';
import { LubricantInterface } from './model/LubricantInterface';

import {  CreateLubricant, UpdateLubricant, DeleteLubricant } from './services/service.lubricants';
import { useLubricantForm } from './services/useLubricantForm';

type CreateUpdateLubricantProps = {
    tagFP:string
    actionType:CrudFormType.create
    item?:LubricantInterface
} | {
    tagFP:string
    actionType:CrudFormType.update,
    item:LubricantInterface,
}


const CULubricantForm:React.FC<CreateUpdateLubricantProps> = ({tagFP, actionType, item}) => {

    //DB 
    const  { action, resetStatus,status, error, message } = useCreateUpdate({
        create: CreateLubricant,
        update: UpdateLubricant,
        delete: DeleteLubricant
    },actionType, ['PlantAux'])

    //UI
    const { lang } = useLangLabels()
    const { uiModel, handleSubmit, reset } = useLubricantForm(tagFP,item)
    const ui = (() => {
        const formName = lang.lubricantForm.singular
        switch (actionType) {
            case CrudFormType.create:
                const create:uiFormInterface
                 = {
                    icon:'add',
                    title:`${lang.actionTypes.create} ${formName}`,
                    variant:'button',
                    mode:'modal'
                }
                return create

            case CrudFormType.update:
                const update:uiFormInterface = {
                    icon:'edit',
                    title:`${lang.actionTypes.update} ${formName}`,
                    variant:'iconButton',
                    mode:'content'
                }
                return update
        }
    })()
   
  
    //Methods
    const submit = (data:LubricantInterface) => {
        action(data)
    }

    const handleReset = () => {
        resetStatus()
        reset()
    }


    return(
        <DefaultFormLayout
        mode={ui.mode}
        icon={ui.icon}    
        variant={ui.variant}
        buttonTitle={ui.title}
        popoverTitle={ui.title}
        {...{status,error,message}} 
        onChange={handleSubmit(submit)}  
        onClose={handleReset} 
        >
            {uiModel.map((props,index)=>
            <FieldSelector key={index} fieldProps={props} />
            )}
        </DefaultFormLayout>
    )

}

export default React.memo( CULubricantForm)
