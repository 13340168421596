
import { TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import SimpleAutocompleteSelect from '../../../../components/autocomplete/SimpleAutocompleteSelect'
import { ComponentUIModes } from '../../../../components/componentMode/componentModesInterfaces'
import FlexContainer from '../../../../components/containers/FlexContainer'
import DefaultFormLayout from '../../../../components/form/DefaultFormLayout'
import FieldSelector from '../../../../shared/form/components/FieldSelector'
import { InputComponentTypes } from '../../../../shared/form/models/FormTypes'
import { useForm } from '../../../../shared/form/useForm'
import { useLangLabels } from '../../../../shared/lang/services/useLangLabels'
import { useStatusProcessor } from '../../../../shared/queries/StatusProcessor'
import { FormRegExp } from '../../../../shared/regExp/regExp'
import { useAllAuxByPlant, useGetAllAuxByPlantData } from '../../../allAux/services/allAuxServices'
import { useAllLubricantsByPlant } from '../../../lubricant/services/service.lubricants'
import { useToken } from '../../../session/store/sessionStore'
import { useTypesByPlant } from '../../../types/services/service.types'
import { equipmentCreateSuggestedQuantityFactory, LubricationPointInterface } from '../../model/lubricationPointsInterface'
import { useUpdateLubricationPoint } from '../../services/lubricationPointsAdapters'



interface updateComponentInterface{
    id: number
    function: string
    brand: string
    capacity: number
    component: string
    element: string
    lubricant: string
    lubricantType: string
    measureUnit: string
    model: string
    observations: string
    oilAnalysis: string | boolean
    review: string
    routes: string
    supplies: string[]
    tagFP:string
    tagTGD:string,
    suggestedQuantity:LubricationPointInterface['routeName']
}

const useUpdateComponentForm = (tagFP:string,item:LubricationPointInterface) => {
    const {lang} = useLangLabels()
    return useForm<updateComponentInterface>({fields:{
        id:{
            label:lang.globalentityValues.id,
            placeholder:lang.globalentityValues.id,
            value:item?.id || 0,
            component:InputComponentTypes.none
        },
        //frontID:{
        //    label:lang.globalentityValues.id,
        //    placeholder:lang.globalentityValues.id,
        //    value:item?.frontID || uuid(),
        //    component:InputComponentTypes.none
        //},
        //egressDate:{
        //    label:lang.lubricationPoints.egressDate,
        //    placeholder:lang.lubricationPoints.egressDate,
        //    value:item.egressDate,
        //    component:InputComponentTypes.none
        //},
        //type:{
        //    label:lang.lubricationPoints.type,
        //    placeholder:lang.lubricationPoints.type,
        //    value:item.type,
        //    component:InputComponentTypes.none
        //},
        //lowReason:{
        //    label:lang.lubricationPoints.lowReason,
        //    placeholder:lang.lubricationPoints.lowReason,
        //    value:item.lowReason,
        //    component:InputComponentTypes.none
        //},
        //location:{
        //    label:lang.lubricationPoints.location,
        //    placeholder:lang.lubricationPoints.location,
        //    value:item.location,
        //    component:InputComponentTypes.none
        //},
        //admissionDate:{
        //    label:lang.lubricationPoints.admissionDate,
        //    placeholder:lang.lubricationPoints.admissionDate,
        //    value:item.admissionDate,
        //    component:InputComponentTypes.none
        //},
        function: {
            label: lang.lubricationPoints.function,
            placeholder: lang.lubricationPoints.function,
            value: item?.function || lang.stringDefaultValues.notSpecified,
            component: InputComponentTypes.text,
            validations: {
                required: {
                    value: true,
                    message: lang.validations.messages.required
                }
            }
        },
        //sector:{
        //    label:lang.lubricationPoints.sector,
        //    placeholder:lang.lubricationPoints.sector,
        //    value:item.sector,
        //    component:InputComponentTypes.none
        //},
        //plantTag:{
        //    label:lang.lubricationPoints.plantTag,
        //    placeholder:lang.lubricationPoints.plantTag,
        //    value:item.plantTag,
        //    component:InputComponentTypes.none
        //},
        //equipment:{
        //    label:lang.lubricationPoints.equipment,
        //    placeholder:lang.lubricationPoints.equipment,
        //    value:item.equipment,
        //    component:InputComponentTypes.none
        //},
        //criticality:{
        //    label:lang.lubricationPoints.criticality,
        //    placeholder:lang.lubricationPoints.criticality,
        //    value:item.criticality,
        //    component:InputComponentTypes.none
        //},
        //state:{
        //    label:lang.lubricationPoints.state,
        //    placeholder:lang.lubricationPoints.criticality,
        //    value:item.criticality,
        //    component:InputComponentTypes.none
        //},
        //labelId:{
        //    label:lang.lubricationPoints.labelId,
        //    placeholder:lang.lubricationPoints.labelId,
        //    value:item.labelId,
        //    component:InputComponentTypes.none
        //},
        review:{
            label:lang.lubricationPoints.review,
            placeholder:lang.lubricationPoints.review,
            value:item.review,
            component:InputComponentTypes.none
        },
        routes:{
            label:lang.lubricationPoints.routes,
            placeholder:lang.lubricationPoints.routes,
            value:item.routes,
            component:InputComponentTypes.none
        },
        supplies:{
            label:lang.lubricationPoints.supplies,
            placeholder:lang.lubricationPoints.supplies,
            value:item.supplies,
            component:InputComponentTypes.none
        },
        brand:{
            label: lang.lubricationPoints.brand,
            placeholder: lang.lubricationPoints.brand,
            component:InputComponentTypes.text,
            value:item?.brand || lang.stringDefaultValues.notSpecified,
            validations:{
                required:{
                    value:true,
                    message:lang.validations.messages.required
                }
            }
        },
        model:{
            label: lang.lubricationPoints.model,
            placeholder: lang.lubricationPoints.model,
            component:InputComponentTypes.text,
            value:item?.model || lang.stringDefaultValues.notSpecified,
            validations:{
                required:{
                    value:true,
                    message:lang.validations.messages.required
                }
            }
        },
        observations:{
            label: lang.lubricationPoints.observations,
            placeholder: lang.lubricationPoints.observations,
            component:InputComponentTypes.text,
            value:item?.observations || lang.stringDefaultValues.notSpecified,
            validations:{
                required:{
                    value:true,
                    message:lang.validations.messages.required
                }
            }
        },
        element:{
            label: lang.lubricationPoints.element,
            placeholder: lang.lubricationPoints.element,
            component:InputComponentTypes.element,
            inputConfig:{
                tagFP:tagFP
            },
            value:item?.element || null,
            validations:{
                required:{
                    value:true,
                    message:lang.validations.messages.required
                }
            }
        },
        component:{
            label: lang.lubricationPoints.component,
            placeholder: lang.lubricationPoints.component,
            component:InputComponentTypes.component,
            inputConfig:{
                tagFP:tagFP
            },
            value:item?.component || null,
            validations:{
                required:{
                    value:true,
                    message:lang.validations.messages.required
                }
            }
        },
        lubricant:{
            label: lang.lubricationPoints.lubricant,
            placeholder: lang.lubricationPoints.lubricant,
            component:InputComponentTypes.lubricant,
            inputConfig:{
                tagFP:tagFP
            },
            value:item?.lubricant || null,
            validations:{
                required:{
                    value:true,
                    message:lang.validations.messages.required
                }
            }
        },
        lubricantType:{
            label:lang.lubricationPoints.lubricantType,
            placeholder:lang.lubricationPoints.lubricantType,
            value:item.lubricantType,
            component:InputComponentTypes.none
        },
        capacity:{
            label: lang.lubricationPoints.capacity,
            placeholder: lang.lubricationPoints.capacity,
            component:InputComponentTypes.number,
            value:item?.capacity || null,
            validations:{
                required:{
                    value:true,
                    message:lang.validations.messages.required
                }
            }
        },

        measureUnit:{
            label: lang.lubricationPoints.measureUnit,
            placeholder: lang.lubricationPoints.measureUnit,
            component:InputComponentTypes.none,
            /* inputConfig:{
                tagFP,
                by:{
                    key:'type',
                    value:'UNIDAD'
                }
            }, */
            value:item?.measureUnit || null,
            validations:{
                required:{
                    value:true,
                    message:lang.validations.messages.required
                }
            }
        },

        oilAnalysis:{
            label: lang.lubricationPoints.oilAnalysis,
            placeholder: lang.lubricationPoints.oilAnalysis,
            component:InputComponentTypes.oilAnalysis,
            value:item?.oilAnalysis !== undefined ? item.oilAnalysis : undefined,
            validations:{
                required:{
                    value:true,
                    message:lang.validations.messages.required
                }
            }
        },

        suggestedQuantity:{
            label:lang.lubricationPoints.suggestedQuantity,
            placeholder:lang.lubricationPoints.suggestedQuantity,
            component:InputComponentTypes.none,
            value:item?.suggestedQuantity || equipmentCreateSuggestedQuantityFactory(item.measureUnit),
            validations:{
                required:{
                    value:true,
                    message:lang.validations.messages.required
                }
            }
        },
        tagTGD:{
            label:lang.globalentityValues.tagTGD,
            placeholder:lang.globalentityValues.tagTGD,
            value:item?.tagTGD || undefined,
            component:InputComponentTypes.none,
            validations:{
                required:{
                    value:true,
                    message:lang.globalentityValues.tagTGD
                }
            },
        },
        tagFP:{
            label:lang.globalentityValues.tagFP,
            placeholder:lang.globalentityValues.tagFP,
            value:tagFP,
            component:InputComponentTypes.none
        },
    }})
}





interface Props {
    mode?:ComponentUIModes
    item:LubricationPointInterface
}

const UpdateLubricationPoint:React.FC<Props> = ({mode,item}) => {

    const [defaultItem, setDefaultItem] = useState(item);

    const {lang} = useLangLabels()

    const icon = 'edit'
    const title = `${lang.actionTypes.update} - ${item.component} >> ${item.element} >> ${item.equipment}`

    const {data:lubricants} = useAllLubricantsByPlant()

    const { uiModel, handleSubmit,setValue, dataModel } = useUpdateComponentForm(item.tagFP,defaultItem)

    const query = useUpdateLubricationPoint()
    const mutationStatus = useStatusProcessor(query)
    const token = useToken()
    
    const submit = (data:updateComponentInterface) => {
        const isChangeReview = data.lubricant !== item.lubricant // isLubricantChange
        const newReview = `${parseInt(data?.review || '') + 1}`

        query.mutate({
            data:{
                ...data,
                review: isChangeReview ? newReview : data.review,
                oilAnalysis:((data.oilAnalysis === 'SI' ) ? true : false),
            },
            token
        })
    }
    
    const { status,error,message, handleReset } = {
        ...query,
        status:mutationStatus.status,
        error:mutationStatus.error,
        message:mutationStatus.message,
        handleReset:() => {
            mutationStatus.reset()
            query.reset()
        }
    }

    useEffect(()=>{
        if(dataModel.lubricant !== item.lubricant){
            const newLubricant = lubricants?.find(l=>l.lubricant === dataModel.lubricant)
            if(newLubricant && newLubricant?.type !== item.lubricantType){
                setValue('lubricantType',newLubricant.type)
                setValue('measureUnit',newLubricant.measureUnit)
                setDefaultItem({
                    ...defaultItem,
                    lubricantType:newLubricant.type,
                    lubricant:newLubricant.lubricant
                })
            }
        }
    },[dataModel.lubricant, item.lubricant, item.lubricantType, lubricants])

    return(
        <DefaultFormLayout
        mode={mode}
        icon={icon}    
        variant={'iconButton'}
        buttonTitle={title}
        popoverTitle={lang.actionTypes.update}
        {...{status,error,message}} 
        onChange={handleSubmit(submit)}  
        onClose={handleReset} 
        >
            {uiModel.map(
                (props, index) => 
                    <>
                        <FieldSelector key={props.name} fieldProps={props} />
                    </>
            )}
        </DefaultFormLayout>
    )

}

export default React.memo(UpdateLubricationPoint)
