import React from 'react';
import { useStockByTagFP } from '../../../../domains/stock/services/service.stock';

import NewStockQuantityForm from '../../../../domains/stock/components/NewStockQuantityForm';

import UpdateStockForm from '../../../../domains/stock/components/UpdateStockForm';
import { useStockGridModel } from '../../../../domains/stock/lang/useStockGridModel';
import VirtualGrid from '../../../../shared/react-table/VirtualGrid';
import CreatePurchaseRequestForm from '../../../../domains/stock/purchase-requests/components/CreatePurchaseRequestForm';





interface Props{
}
const StockGrid:React.FC<Props> = () => {
    
    const {data:stockItems} = useStockByTagFP()
    const  stockGridModel = useStockGridModel()

    return ( <>

        <VirtualGrid
            {...{
                title:`Todos los items de stock`,
                items:stockItems,
                gridModel:stockGridModel,
                renderOption:(item)=><>
                    <NewStockQuantityForm item={item}/>
                    <CreatePurchaseRequestForm mode='popover' variant='iconButton' stock={item}/>
                </>
            }}
        >
            {(items)=><>
                 {/* <UpdateStockForm items={items}/>   */}    
            </>
            }
        </VirtualGrid>


    {
        // <Divider/>
        // <DynamicGrid
        //     rowsPerPage={12}
        //     gridModel={useStockGridModel()}
        //     height='50vh'
        //     items={stockItems || []}
        //     loading={stockItems ? false : true}
        //     title={`Todos los items de stock`}
        //    /*  headerActions={(item:StockInterface)=><>
        //     <UpdateSubCategoryForm defaultValue={subcategory} mode='modal'/>
        //     <CreateStockForm mode='modal' subcategory={subcategory}/>
        //     </>} */
        //     itemActions={(item:any)=><>
        //         <UpdateStockForm defaultValue={item}/> 
        //         <NewStockQuantityForm item={item}/>
        //         <CreatePurchaseRequestForm mode='popover' variant='iconButton' stock={item}/>
        //     </>}
        // />
    }

    </>);
}

export default React.memo(StockGrid);
