import React, { useMemo, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { Typography} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { title } from 'process';
import { TaskType } from '../../surveys/tasks/model/TaskInterface';
import { ObservationInterface, ObservationType } from '../models/ObservationTypes';
import { LubricationPointInterface } from '../../lubricationPoints/model/lubricationPointsInterface';
import { usePlants } from '../../session/store/sessionStore';
import { useModal } from '../../../components/containers/modal/hooks/useModal';
import { useLubricationPointSurveyController } from '../../surveys/services/controllers/useLubricationPointSurveyController';
import { useObservationTypesByTagFP } from '../../types/services/service.types';
import { AnomalyInterface } from '../../anomalies/models/anomaliesTypes';
import PageContainer from '../../../components/containers/PageContainer';
import FlexContainer from '../../../components/containers/FlexContainer';
import SelectAnomaly from '../../anomalies/components.tsx/SelectAnomaly';
import SelectTabulatedObservation from '../../types/components/SelectTabulatedObservation';
import SelectArea from '../../areas/components/SelectArea';
import SelectCriticality from '../../criticalities/components/SelectCriticality';
import CustomButton from '../../../components/buttons/CustomButton';
import { capitalize } from '../../../shared/globals/utils/capitalize';
import ObservationsList from './ObservationsList';
import { CriticalityTypes } from '../../criticalities/models/criticalityInterface';
import TgdModal from '../../../components/containers/modal/TgdModal';

interface Props {
    outOfRoute:boolean
    taskType:TaskType
    type:ObservationType 
    listTitle?:string
    buttonTitle?:string
    formTitle?:string
    variant?: 'modal' | 'content' | 'form' | 'list'
    buttonType:'next' | 'add'
    lubricationPoint:LubricationPointInterface
    onCreate?:()=>void
}

const CreateObservations:React.FC<Props> = ({type,variant,listTitle,buttonTitle,formTitle,buttonType,lubricationPoint,taskType,onCreate,outOfRoute}) => {
    
    const plants = usePlants()
    const {register, handleSubmit, errors, setValue, control,reset, watch} = useForm()
    const {modal,handleModal} = useModal()
    const tagFP = lubricationPoint.tagFP

    const { survey, addObservation, deleteObservation:handleDelete ,addAnomaly } = useLubricationPointSurveyController(lubricationPoint,outOfRoute)
    const observations = survey?.observations || []
    const anomalies = survey?.anomalies || []

    const {data:TabObservations} = useObservationTypesByTagFP(lubricationPoint.tagFP)

    const onSubmit = (data:ObservationInterface) => {   
        switch(type){
            case 'ANOMALIA':
                /*console.log(data)*/
                addAnomaly(data)
                break
            
            default:    
                addObservation({
                    ...data,
                    anomaly:JSON.stringify(anomalies?.map((a)=>a.anomaly) || [])
                })
                break
        }
        onCreate && onCreate()
    }
    
    useEffect(()=>{
        setValue('observation',undefined)
        setValue('criticality',undefined)
        setValue('area',undefined)
        setValue('anomaly',undefined)
    },[observations,anomalies])


    /*console.log(watch('anomaly'))*/

    useEffect(()=>{
        register({name:'type',value:type})
        register({name:'anomaly',value:type},{required:{value:type === 'ANOMALIA' ? true : false,message:'requerido'}})
        register({name:'taskType',value:taskType})
        register({name:'equipment',value:lubricationPoint.equipment})
        register({name:'tagTGD',value:lubricationPoint.tagTGD})
        register({name:'surveyId',value:''})
        register({name:'tagFP',value:lubricationPoint.tagFP})
        register({name:'date',value:new Date()})
        register({name:'state',value:''})
        register({name:'criticality',value:''},{required:{value:true,message:'requerida'}})
        register({name:'observation',value:''},{required:{value:true,message:'requerida'}})
        register({name:'area',value:''},{required:{value:true,message:'requerida'}})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
 


    const form =  
        <PageContainer > 
            <Typography variant='h6'>{formTitle}</Typography>       
            <br/>
            <br/>    
            
            <FlexContainer flexDirection='column' width='100%'>
          
                {type === 'ANOMALIA' &&<>
                    <SelectAnomaly
                        onChange={(value)=>setValue('anomaly',value)}
                        error={errors?.anomaly?.message} 
                        defaultValue={watch('anomaly')}
                    />
                    
                </>}

                <SelectTabulatedObservation
                    tagFP={tagFP}
                    error={errors?.observation?.message}        
                    onChange={(value?:string)=>setValue('observation', value)}    
                    defaultValue={watch('observation')}
                />

                <SelectArea
                    onChange={(value)=>setValue('area', value)}
                    error={errors?.area?.message}
                    defaultValue={watch('area')}
                />
            
                <SelectCriticality
                    by={{key:'criticalityType', value:CriticalityTypes.observation}}
                    onChange={(value)=>setValue('criticality', value)}
                    defaultValue={watch('criticality')}
                    error={errors?.criticality?.message} 
                />

                <CustomButton popoverTitle={`Crear ${capitalize(type)}`} icon='save' action={handleSubmit(onSubmit)}/>

            </FlexContainer>
           
        </PageContainer>

    const addButton = <FlexContainer>
        <CustomButton icon='add' action={handleModal} title={buttonTitle} popoverTitle={`Agregar ${type==='ANOMALIA' ? 'Anomalia' : 'Observacion'}`} />
    </FlexContainer>
    
    const list = useMemo(()=>(<ObservationsList
        handleDeleteItem={handleDelete}
        type={type}
        observations={(type === 'ANOMALIA') ? anomalies : observations} 
        headerAction={variant === 'list' ? addButton : <></>} 
        title={listTitle} 
    />),[addButton, anomalies, handleDelete, title, observations, type, variant])
 


    return(<>
        {(variant === 'form') && form}
        {(variant === 'content') && <>{form}{list}</>}
        {(variant === 'list') && list}
        {(variant === 'modal') && <>
            {buttonType === 'add' && addButton}
            {buttonType === 'next' &&
                <CustomButton
                variant='fab'
                color='primary' 
                action={handleModal}
                children={<ArrowForwardIcon/>}
                />
            }
        </>}

        <TgdModal
            fullWidth
            open={modal}
            handleClose={handleModal}
        >
            <>{form}{list}</>
        </TgdModal>
    </>)

}
export default React.memo(CreateObservations)