import React from 'react'
import { Paper, Typography } from "@material-ui/core"

import Modal from '../../../components/containers/modal/Modal'
import { useModal } from '../../../components/containers/modal/hooks/useModal'
import FlexContainer from '../../../components/containers/FlexContainer'
import PageContainer from '../../../components/containers/PageContainer'
import CollectorQrCode from './CollectorQrCode'
import theme from '../../../assets/theme'
import { useLangLabels } from '../../../shared/lang/services/useLangLabels'
import { CollectorInterface } from '../models/CollectorInterfaces'
import ComponentMode from '../../../components/componentMode/ComponentMode'




const ViewQrCode:React.FC<{item:(CollectorInterface & {ip:string}) }> = ({item}) => {
    
    const {lang} = useLangLabels()
    const modalProps = useModal()
    
    return(
        <ComponentMode icon='view' mode='popover' variant='iconButton' >
           <FlexContainer flexDirection='column' width={'100%'} >
                <FlexContainer padding={`${theme.spacing(3)}px`} align='center' justify={'center'}>
                    <Paper elevation={3}>
                        <PageContainer>
                            <CollectorQrCode colector={item} />
                        </PageContainer>
                    </Paper>
                    <FlexContainer padding={`${theme.spacing(3)}`} align='center' >
                        <FlexContainer flexDirection='column' padding={`${theme.spacing(3)}`}>
                            <Typography>ID: {item.id}</Typography>
                            <Typography>NOMBRE: <strong>{item.name}</strong></Typography>
                            <Typography>CÓDIGO: <strong>{item.collectorCode}</strong></Typography>
                            <Typography>Nº SERIE: <strong>{item.serialNumber}</strong></Typography>
                            <Typography>ESTADO: <strong>{item.state ? 'ACTIVO' : 'INACTIVO'}</strong></Typography>
                        </FlexContainer>
                    </FlexContainer>
                </FlexContainer>
            </FlexContainer>
        </ComponentMode>
   
    )
}

export default ViewQrCode