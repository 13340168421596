import React from 'react';
import { CircularProgress, IconButton} from '@material-ui/core';
import { useEffect } from 'react';
import { useDeleteSupplyVerification } from './services/service.supplies';
import TgdPopover from '../../components/popovers/TgdPopover';
import CustomButton, { CustomIcon } from '../../components/buttons/CustomButton';

import { useSnackbar } from '../../components/snackbar/hooks/useSnackbar';
import { SupplyInterface, SupplyType } from './model/SupplyInterface';
import { useStatusProcessor } from '../../shared/queries/StatusProcessor';
import { useClickPosition } from '../../shared/globals/hooks/useClickPosition';
import { useLangLabels } from '../../shared/lang/services/useLangLabels';

interface Props{
    item:SupplyInterface,
    type:SupplyType
}
const DeleteSupplyForm:React.FC<Props> = ({item,type}) => {

    const {errors,status:deleteStatus,validate,query} = useDeleteSupplyVerification()
    const { status, message } = useStatusProcessor(query)
    const {position,getClickPosition} = useClickPosition()
    const { setData } = useSnackbar()

    useEffect(()=>{
        if(deleteStatus === 'error'){
            const componentsError = (
                (errors?.equipments?.length 
                    ? (errors.equipments.length > 1 
                        ? `${errors.equipments.length} Equipos` 
                        : `${errors.equipments.length} Equipo`) 
                    : '')
            )
            setData({
                modal:true,
                errors:`Este/a ${type} no se puede eliminar porque está siendo usado/a en: ${componentsError}` ,
                severity:'warning',
                position:position
            })
        }

        if(status === 'success'){
            setData({
                modal:true,
                errors:message,
                severity:'success',
                position
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[deleteStatus,status,errors])
    
    const {lang} = useLangLabels()
    
    const handleDelete = (e:any)=> {
        getClickPosition(e,()=>validate(item))
    }

    return (
        status === 'loading' || deleteStatus==='loading'
        ? <CircularProgress size='20px' />
        : <CustomButton
            popoverTitle={lang.actionTypes.deleteItem}
            icon='delete'
            action={handleDelete}
            variant='iconButton'
        />
    );
}

export default React.memo(DeleteSupplyForm);
