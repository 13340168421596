import { AppBar, CircularProgress,Toolbar, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Badge } from '@mui/material'
import React, { useMemo  } from 'react'
import ComponentMode from '../../../components/componentMode/ComponentMode'
import { useModal } from '../../../components/containers/modal/hooks/useModal'
import PageContainer from '../../../components/containers/PageContainer'
import TgdSnackbar from '../../../components/snackbar/TgdSnackbar'
import { objectMapper } from '../../../shared/globals/utils/objectMapper'
import { VirtualizedList } from '../../../shared/react-window/VirtualList'
import { LubricationPointInterface } from '../../lubricationPoints/model/lubricationPointsInterface'
import { InfoLubricationPointInterface } from '../model/InfoLubricationPointInterface'
import { useEquipmentsInfoByTagFP } from '../service/infoLubricationPointsService'
import ChangeViewRequestListItem from './ChangeViewRequestListItem'

interface ChangeRevewRequestsProps {
    items:LubricationPointInterface[]
}


const selectInfoEquipmentsByTagTGD = (items:InfoLubricationPointInterface[] | undefined,tagTgdList:LubricationPointInterface['tagTGD'][] | undefined):InfoLubricationPointInterface[]  | [] => {
    if(!!items && tagTgdList){
        if(items?.length > 0 && tagTgdList?.length > 0 ){
            return items.filter((i)=>tagTgdList.includes(i.tagTGD))
        }else return []
    }else{
        return []
    }
}


const selectChangeReviewIsRequired = (items:InfoLubricationPointInterface[]) => {
    return items.filter(i => i.updateReview)
} 

const useEquipmentsThatRequiresAnReviewUpdate = (filter?:(LubricationPointInterface['tagTGD'])[]) => {
    const {data} = useEquipmentsInfoByTagFP()
    const items = useMemo(()=>selectInfoEquipmentsByTagTGD(data || [] ,filter),[filter,data])
    return useMemo(()=>selectChangeReviewIsRequired(items),[items])
}


export const ChangeReviewRequests:React.FC<ChangeRevewRequestsProps> = ({items}) => {

    const modalProps = useModal(false)
    const _items = useMemo(()=>items?.map((i)=>i.tagTGD) || [], [items])
    const {status} =  useEquipmentsInfoByTagFP()
    const mappedItems = useMemo(()=>objectMapper(items,'tagTGD'),[items])
    const selectedInfoEquipments  = useEquipmentsThatRequiresAnReviewUpdate(_items)
    const equipmentsQuantity = selectedInfoEquipments.length

    return (
        <Badge badgeContent={equipmentsQuantity} variant='standard' color={'error'} >
            
            {status === 'loading' ? <CircularProgress size={'24px'}/> 
                :<ComponentMode
                    icon='nextState'
                    buttonColor='primary'
                    mode='drawer'
                    drawerProps={{
                        open:modalProps.modal,
                        onClose:modalProps.handleModal,
                        styles:{
                            drawerWidth:'70vw',
                            color:'#000'
                        },
                        anchor:'right'
                    }}
                    popoverTitle={`Equipos con solicitud de cambio de revision`}
                    variant='iconButton'
                    title='Cambiar revision'
                >
                    <AppBar position='relative'>
                        <Toolbar >
                            <Typography variant='h6'>Gestionar Cambios de revision</Typography>
                        </Toolbar>
                    </AppBar>
                    <TgdSnackbar />
                    <PageContainer >

                        <Alert severity='warning'>
                            Realizar cambios dentro de este apartado, solo es recomendable si: <br/> 
                            - Todas las sincronizaciones del dia han sido procesadas con éxito <br/>
                            - No habrá posteriores sincronizaciones en el día. <br/> 
                            <br/>
                            De otra manera al sincronizarse lo datos de un colector que relevó equipos en ruta o fuera de ruta que estén en este listado, poría ocasionar una pérdida de la trazabilidad del cambio de revisión y generar solicitudes de cambio de revision innecesarios. (Esto provocaria varios cambios de revision innecesarios).
                        </Alert>
                        <br></br>

                       {/*  <List> */}
                            
                            <VirtualizedList 
                                height={700}
                                width={'100%'}
                                itemCount={equipmentsQuantity}
                                itemSize={50}
                                renderRow={(props)=>
                                    <div style={props.style} onClick={()=>console.log(props)}>
                                        <ChangeViewRequestListItem {...{
                                            item:selectedInfoEquipments[props.index],
                                            mappedItems,
                                        }}/>
                                    </div>
                                }
                                /> 
                        {/* </List> */}
                       
                        {/* <List>
                            {selectedInfoEquipments?.map((item)=>
                                <ChangeViewRequestListItem {...{
                                    item,
                                    mappedItems
                                }}/>
                            )}
                        </List>  */}
                       
                  </PageContainer>
                </ComponentMode>
            }
        </Badge>
    )
}

export default React.memo(ChangeReviewRequests)